import React, {useEffect, useState} from "react";
import Tabs, { TabPane } from "rc-tabs";
import { Card, CardBody, CardTitle, Col, Container, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, UncontrolledTooltip, ListGroup, ListGroupItem } from "reactstrap";
import { TherapistService } from "src/services/TherapistService";
import '../../assets/css/ShowTherapistScoreFullDetailsPage.css'



const ShowTherapistScoreFullDetailsPage: React.FC<any> = () => {
  const [scoresList, setScoreList] = useState<any>(null);
  const [isLoading, setisLoading] = useState<boolean>(true);
  
  const getTherapistScoreData = async () => {
    try {
      const res = await TherapistService.getTherapistScoreByTherapistId();
      if(res.success){
        setisLoading(false);
        setScoreList(res.data);
      }
    } catch (error) {
      console.error("Error fetching therapist score details:", error);
    }
  }

  useEffect(() => {
    getTherapistScoreData();
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading ? (
          <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
              <CardBody>
                <Row>
                  <div className="card-heading">
                    <h1 className="notifications-title">Loading...</h1>

                    <div className="siler-line mb20"></div>
                  </div>
                </Row>
              </CardBody>
            </Card>
            </Col>
          </Row>
        </Container>
        ) : (
          <Container fluid>
          <Row>
            <Col lg={12}>
            {scoresList ? (
              <Card>
              <CardBody>
                <Row>
                  <div className="card-heading">
                    <h1 className="notifications-title">Therapist Score Overview</h1>

                    <div className="siler-line mb20"></div>
                  </div>
                </Row>
                <Row>
                  <div className="score-page-sec1">
                    <h5>Your Score </h5>
                    <div className="earning-sec2"></div>
                    <span className="sub-item1-text" style={{ color: scoresList?.therapistId.score > 50 ? '#34c38f' : 'red' }}>
                        {Number(scoresList?.therapistId.score).toFixed(2)}%
                    </span>
                  </div>
                </Row>
                <Row>
                  <Col>
                  <div className="row pt-4">
                  <h3 className="mb-3">Key factors used to calculate the score</h3>
                    <div className="col-lg-12 es1">
                    <div className="score-list">
                      <div className="score-page-sec3">
                        <span className="sub-item-black-text">Response Time</span>
                        <p className="sub-text-sec3">A number representing the therapists average response time to clients</p>
                      </div>

                      <div className="score-page-sec3">
                        <span className="sub-item-black-text">Availability</span>
                        <p className="sub-text-sec3" >A score indicating the therapist&#39;s availability for sessions within the next 48 hours, excluding blocked time slots.</p>
                      </div>

                      <div className="score-page-sec3">
                        <span className="sub-item-black-text">Follow-up Appointments</span>
                        <p className="sub-text-sec3">For each client, check completed appointments from the last three months, and find the most recent one. If the client has a future pending appointment, it&#39;s considered a follow-up appointment.</p>
                      </div>

                      <div className="score-page-sec3">
                        <span className="sub-item-black-text">Total Sessions</span>
                        <p className="sub-text-sec3">The number of sessions the therapist has completed</p>
                      </div>

                      <div className="score-page-sec3">
                        <span className="sub-item-black-text">Missed Appointments</span>
                        <p className="sub-text-sec3">Show the number of missed appointments</p>
                      </div>

                      <div className="score-page-sec3">
                        <span className="sub-item-black-text">Loyalty Years</span>
                        <p className="sub-text-sec3">Number of years the therapist has been associated with the platform</p>
                      </div>

                      <div className="score-page-sec3">
                        <span className="sub-item-black-text">Scheduled Appointments</span>
                        <p className="sub-text-sec3">The number of appointments scheduled ahead</p>
                      </div>

                      <div className="score-page-sec3">
                        <span className="sub-item-black-text">Number of Matches</span>
                        <p className="sub-text-sec3">Number of clients matched with this therapist</p>
                      </div>
                    </div>
                    </div>
                  </div>
                  </Col>
                </Row>
                <Row>
                  <div className="row pt-4">
                          <h3 className="mb-3">Your scores for each factors</h3>

                          <div className="col-lg-12 es1">
                            <div className="score-page-sec4">
                            <div className="row4-sub-header">
                              <h5>Response Time</h5>
                            </div>
                              <span className="sub-item-black-text">{(Number(scoresList?.responseTime)*10).toFixed(2)}/10</span>
                            </div>

                            <div className="earning-sec2"></div>

                            <div className="score-page-sec4">
                              <div className="row4-sub-header">
                                <h5>Availability</h5>
                              </div>
                              <span className="sub-item-black-text">{(Number(scoresList?.availability)*10).toFixed(2)}/10</span>
                            </div>

                            <div className="earning-sec2"></div>

                            <div className="score-page-sec4">
                              <div className="row4-sub-header">
                                <h5>Follow-up Appointments</h5>
                              </div>
                              <span className="sub-item-black-text">{(Number(scoresList?.followUpAppointments)*10).toFixed(2)}/10</span>
                            </div>

                            <div className="earning-sec2"></div>

                            <div className="score-page-sec4">
                              <div className="row4-sub-header">
                                <h5>Total Sessions</h5>
                              </div>

                              <span className="sub-item-black-text">{(Number(scoresList?.totalSessions)*10).toFixed(2)}/10</span>
                            </div>
                            <div className="earning-sec2"></div>

                            <div className="score-page-sec4 border-none">
                            <div className="row4-sub-header">
                              <h5>Missed Appointments</h5>
                            </div>
                              <span className="sub-item-black-text">{(Number(scoresList?.missedAppointments)*10).toFixed(2)}/10</span>
                            </div>
                            <div className="earning-sec2"></div>

                            <div className="score-page-sec4 border-none">
                              <div className="row4-sub-header">
                                <h5>Loyalty Years</h5>
                              </div>
                              <span className="sub-item-black-text">{(Number(scoresList?.loyaltyYears)*10).toFixed(2)}/10</span>
                            </div>
                            <div className="earning-sec2"></div>

                            <div className="score-page-sec4 border-none">
                              <div className="row4-sub-header">
                                <h5>Scheduled Appointments</h5>
                              </div>
                              <span className="sub-item-black-text">{(Number(scoresList?.scheduledAppointments)*10).toFixed(2)}/10</span>
                            </div>
                            <div className="earning-sec2"></div>

                            <div className="score-page-sec4 border-none">
                              <div className="row4-sub-header">
                                <h5>Number of Matches</h5>
                              </div>
                              <span className="sub-item-black-text">{(Number(scoresList?.noOfMatches)*10).toFixed(2)}/10</span>
                            </div>

                          </div>
                  </div>
                </Row>
                
              </CardBody>
            </Card>
            ) : (
              <Card>
                <CardBody>
                  <Row>
                    <div className="card-heading">
                      <h1 className="notifications-title">Therapist Score Details Not Found</h1>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            )}
              
            </Col>
          </Row>
        </Container>
        )}
        
      </div>
    </React.Fragment>
  );
};

export default ShowTherapistScoreFullDetailsPage;
