import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, NavLink } from "reactstrap";
import "../../assets/css/admin.css";
import { AdminService } from "../../services/AdminService";
import { Article } from "../../models/Article";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../common/spinner/spinner";

const ViewArticles: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const LIMIT = 10;
  const [isOffset, setOffset] = useState<number>(0);
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    getAllArticles();
  }, []);

  const getAllArticles = () => {
    setIsLoading(true)
    AdminService.getAllArticles(LIMIT, isOffset).then(res => {
      if (res.success) {
        setArticles(res.data);
        setIsLoading(false)
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
      } else {
        setSeeMore(false);
        setArticles([]);
        setIsLoading(false);
      }
    });
  };

  const deleteArticle = (articleId: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure want to delete this article?",
      showCancelButton: true,
      confirmButtonText: "Sure",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.deleteArticleById(articleId).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Successfully deleted!",
              confirmButtonColor: "#FD7F00",
            });
            
            setArticles(articles.filter(item => item._id !== articleId));
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to delete. Please try again later!",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });
  };

  const redirectToProfile = (userId: string) => {
    history.push(`/profile/${userId}`);
  };

  const redirectToArticleView = (articleId: string) => {
    history.push(`/article/${articleId}`);
  };

  const seeMoreFc = () => {
    setIsLoading(true)
    const updatedSkip = isOffset + LIMIT;
    setOffset(updatedSkip);

    AdminService.getAllArticles(LIMIT, updatedSkip).then(res => {
      if (res.success) {
        setArticles(prev => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMore(res.data.length > 0 && res.data.length == LIMIT);
        setIsLoading(false)
      } else {
        setSeeMore(false);
        setIsLoading(false);
      }
    });

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Articles"} breadcrumbItem={"Articles"} />
          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                {!isLoading ? (
                  <div className="table-responsive">
                    {articles && articles !== undefined ? (
                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Article Title</th>
                            <th scope="col">Publisher</th>
                            <th scope="col">Published Date</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {articles &&
                            articles.map((article, index) => (
                              <tr key={article?._id}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <NavLink
                                    onClick={() => {
                                      redirectToArticleView(article?._id);
                                    }}
                                    className="px-0"
                                  >
                                    {article?.articleTitle}
                                  </NavLink>
                                </td>
                                <td>
                                  <NavLink
                                    onClick={() => {
                                      redirectToProfile(article?.createdBy._id);
                                    }}
                                    className="px-0"
                                  >
                                    {article?.createdBy?.firstname} {article?.createdBy?.lastname}
                                  </NavLink>
                                </td>
                                <td>{moment(article?.createdAt).format("YYYY-MM-DD")}</td>
                                <td>
                                  <button className="btn btn-danger btn-sm" onClick={() => deleteArticle(article?._id)}>
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                         )
                         : (
                           <>
                             <div>Articles not found.</div>
                           </>
                         )}
                     </div>
                   ) : (
                     <Spinner />
                   )}
                </Col>
              </Row>
              <Row>
                <Col xl="12 mt30 text-center">
                  {seeMore && (
                      <button className="btn btn-warning" onClick={() => seeMoreFc()}>
                      See More
                      </button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewArticles;
