import React, { useEffect, useState } from "react";
import { Card, Col, Input, Row } from "reactstrap";
import { AuthFormType } from "src/models/AuthorizationFormData";
import PhoneInput from "react-phone-number-input";
import { UnitedHealthCareAuthForm } from "src/models/AuthorizationForm/united-health-care-auth-form-model";
import { htmlToText } from "html-to-text";
import moment from "moment";

const UnitedHealthCareAuthFormPdf: React.FC<{ authorizationFormType: AuthFormType; authorizationFormData: UnitedHealthCareAuthForm; }> = props => {
  const authorizationFormType = props.authorizationFormType;
  const authorizationFormData = props.authorizationFormData;
  const [formData, setFormData] = useState<UnitedHealthCareAuthForm>();

  useEffect(() => {
    const updateUnitedHealthCareData: UnitedHealthCareAuthForm = {
      memberInformation: authorizationFormData?.memberInformation,
      prescriberInformation: authorizationFormData?.prescriberInformation,
      medicationInformation: authorizationFormData?.medicationInformation,
      clinicalInformation: authorizationFormData?.clinicalInformation,
      additionalInformation: authorizationFormData?.additionalInformation,
      date: authorizationFormData?.date,
      signature: authorizationFormData?.signature,
    };
    setFormData(updateUnitedHealthCareData);
  }, [authorizationFormData]);

  function htmlToTextFunction(htmlTextMessage: string) {
    try {
      if (htmlTextMessage) {
        const finalText = htmlToText(htmlTextMessage);
        return finalText;
      }
      return "";
    } catch (error) {
      return "";
    }
  }

  // const formatDate = (date: any) => {
  //   if (!date) return '';
  //   const d = new Date(date);
  //   const year = d.getFullYear();
  //   const month = String(d.getMonth() + 1).padStart(2, '0');
  //   const day = String(d.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };

  const formatDate = (date: any) => {
    if (!date) return '';
    return moment.utc(date).format('YYYY-MM-DD');
  };

  const sharedStyle: React.CSSProperties = {
    fontSize: "11px",
    width: '100%',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    resize: 'none',
    lineHeight: '1'
  };

  return (
    <>
      {authorizationFormType === AuthFormType.UnitedHealthCareAuthForm && (
        <>
          <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1 form-page">
            <Card style={{ marginBottom: "0.5rem", marginTop: "0.85rem", backgroundColor: "#F1F1F1", border: "none", borderRadius: "8px" }}>
              <Row className="align-items-center pt-2">
                <Col xs="12" sm="5" className="text-center text-sm-start">
                  <div>
                    <img
                      src="/static/assets/img/authorization/united_health_care_community.png"
                      alt="United Healthcare Insurance Company Logo"
                      className="img-fluid"
                      style={{ maxWidth: '250px' }}
                    />
                  </div>
                </Col>
                <Col xs="12" sm="7" className="text-center text-sm-end">
                  <p className="p-3" style={{ margin: "0", fontSize: "13px", color: "#333", lineHeight: "1.6" }}>
                    <span style={{ fontSize: "20px", color: "#333", fontWeight: "500" }}>Prior Authorization Request Form</span><br />
                  </p>
                </Col>
              </Row>
              <hr style={{ border: "1px solid #B2B2B2", margin: "0" }} />
              <Row>
                <div className="card-bodyDiv" style={{ width: '100%' }}>

                  <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                    <p style={{ margin: "0" }}>
                      Please complete this entire form and fax it to:<strong> 866-940-7328</strong>. If you have questions, please call<strong> 800-310-6826</strong>.
                      This form may contain multiple pages. Please complete all pages to avoid a delay in our decision.
                      Allow at least 24 hours for review.
                    </p>
                  </div>

                </div>
              </Row>
            </Card>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Member Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Member Name :</label>
                        </Col>
                        <Col xs="12" sm="10" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.memberInformation?.memberName || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                memberInformation: {
                                  ...formData?.memberInformation,
                                  memberName: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Member ID:</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.memberInformation?.medicaidID || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                memberInformation: {
                                  ...formData?.memberInformation,
                                  medicaidID: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                        <Col xs="12" sm="2">
                          <label>Date of birth :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formatDate(formData?.memberInformation?.dateOfBirth)}
                              onChange={(e) => {
                                const date = new Date(e.target.value);
                                setFormData({
                                  ...formData,
                                  memberInformation: {
                                    ...formData?.memberInformation,
                                    dateOfBirth: date
                                  }
                                });
                              }} /></div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Phone :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              value={formData?.memberInformation?.phone || ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  memberInformation: {
                                    ...formData?.memberInformation,
                                    phone: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>

                        <Col xs="12" sm="2" >
                          <label>Allergies:</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                          <textarea
                              style={sharedStyle}
                              value={formData?.memberInformation?.allergies || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                memberInformation: {
                                  ...formData?.memberInformation,
                                  allergies: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2">
                          <label> Street Address :</label>
                        </Col>
                        <Col xs="12" sm="10">
                          <div className="d-flex align-items-center">
                            <textarea
                              style={sharedStyle}
                              value={formData?.memberInformation?.streetAddress || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                memberInformation: {
                                  ...formData?.memberInformation,
                                  streetAddress: e.target.value
                                }
                              })}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="1" className="mb-2 mb-sm-0">
                          <label>City :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.memberInformation?.city || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                memberInformation: {
                                  ...formData?.memberInformation,
                                  city: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                        <Col xs="12" sm="1">
                          <label>State :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.memberInformation?.state || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                memberInformation: {
                                  ...formData?.memberInformation,
                                  state: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                        <Col xs="12" sm="1">
                          <label>Zip code :</label>
                        </Col>
                        <Col xs="12" sm="3">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.memberInformation?.zipCode || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                memberInformation: {
                                  ...formData?.memberInformation,
                                  zipCode: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div style={{ height: '2px', backgroundColor: '#848484', margin: '1rem 0', width: '100%' }}></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={4}><h6 className="fw-500" style={{ margin: "0.2rem" }}>Is the requested medication:</h6></Col>
                        <Col xl={3}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                              type="radio"
                              className="form-check-input"
                              name="new"
                              checked={formData?.memberInformation?.isRequestedMedication?.new === true}
                              onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isRequestedMedication: { new: true } } })}
                            />
                            <label className="fw-500 " style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>New or</label>
                          </div>
                        </Col>
                        <Col xl={5}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                              type="radio"
                              name="new"
                              className="form-check-input"
                              checked={formData?.memberInformation?.isRequestedMedication?.new === false}
                              onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isRequestedMedication: { new: false } } })}
                            />
                            <label className="fw-500 " style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Continuation of Therapy</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={12}>
                          <div className="col-auto d-flex">
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.75rem" }}>
                                <div className="row">
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>If continuation, list start date: </h6>
                                  </div>
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="date"
                                      disabled={formData?.memberInformation?.isRequestedMedication?.new !== false}
                                      value={formatDate(formData?.memberInformation?.isRequestedMedication?.continuationDate)}
                                      onChange={e => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isRequestedMedication: { continuationDate: new Date(e.target.value), new: false } } })}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={4}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is this patient currently hospitalized ?</h6>
                        </Col>
                        <Col xl={3}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                              type="radio"
                              className="form-check-input"
                              name="isHospitalized"
                              checked={formData?.memberInformation?.isHospitalized?.yes === true}
                              onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isHospitalized: { yes: true } } })}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                          </div>
                        </Col>
                        <Col xl={5}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                              type="radio"
                              className="form-check-input"
                              name="isHospitalized"
                              checked={formData?.memberInformation?.isHospitalized?.yes === false}
                              onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isHospitalized: { yes: false } } })}
                            />
                            <label className="fw-500 " style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                          </div>
                        </Col></Row>
                      <Row>
                        <Col xl={12}>
                          <div className="col-auto  d-flex">
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.75rem" }}>
                                <div className="row">
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>If recently discharged, list discharge date: </h6>
                                  </div>
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="date"
                                      disabled={formData?.memberInformation?.isHospitalized?.yes !== false}
                                      value={formatDate(formData?.memberInformation?.isHospitalized?.dischargeDate)}
                                      onChange={e => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isHospitalized: { dischargeDate: new Date(e.target.value), yes: false } } })}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={4}><h6 className="fw-500" style={{ margin: "0.2rem" }}>Is this member pregnant ?</h6></Col>
                        <Col xl={3}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                              type="radio"
                              className="form-check-input"
                              name="isPregnant"
                              checked={formData?.memberInformation?.isPregnant?.yes === true}
                              onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: { yes: true } } })}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                          </div>
                        </Col>
                        <Col xl={5}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem", marginLeft: "2rem" }}
                              type="radio"
                              className="form-check-input"
                              name="isPregnant"
                              checked={formData?.memberInformation?.isPregnant?.yes === false}
                              onClick={() => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: { yes: false } } })}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                          </div>
                        </Col></Row>
                      <Row>
                        <Col xl={12}>
                          <div className="row">
                            <div className="col">
                              <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.75rem" }}>
                                <div className="row">
                                  <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}> If yes, what is this member’s due date? </h6>
                                  </div>
                                  <div className="col">
                                    <input
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        marginTop: "0.1rem",
                                        backgroundColor: "unset",
                                      }}
                                      type="date"
                                      disabled={formData?.memberInformation?.isPregnant?.yes !== true}
                                      value={formatDate(formData?.memberInformation?.isPregnant?.dueDate)}
                                      onChange={e => setFormData({ ...formData, memberInformation: { ...formData?.memberInformation, isPregnant: { dueDate: new Date(e.target.value), yes: true } } })}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Prescriber Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Provider Name :</label>
                        </Col>
                        <Col xs="12" sm="10" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.prescriberInformation?.providerName || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                prescriberInformation: {
                                  ...formData?.prescriberInformation,
                                  providerName: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>NPI# :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.prescriberInformation?.npi || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                prescriberInformation: {
                                  ...formData?.prescriberInformation,
                                  npi: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" >
                          <label>Speciality:</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <textarea
                              style={sharedStyle}
                              value={formData?.prescriberInformation?.specialty || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                prescriberInformation: {
                                  ...formData?.prescriberInformation,
                                  specialty: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Office Phone :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              value={formData?.prescriberInformation?.officePhone || ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  prescriberInformation: {
                                    ...formData?.prescriberInformation,
                                    officePhone: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" >
                          <label>Office Fax :</label>
                        </Col>
                        <Col xs="12" sm="4">
                          <div className="d-flex align-items-center">
                            <PhoneInput
                              style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                              defaultCountry="US"
                              value={formData?.prescriberInformation?.officeFax || ''}
                              onChange={(value) => {
                                setFormData({
                                  ...formData,
                                  prescriberInformation: {
                                    ...formData?.prescriberInformation,
                                    officeFax: value
                                  }
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="3">
                          <label> Office Street Address :</label>
                        </Col>
                        <Col xs="12" sm="9">
                          <div className="d-flex align-items-center">
                            <textarea
                              style={sharedStyle}
                              value={formData?.prescriberInformation?.officeAddress || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                prescriberInformation: {
                                  ...formData?.prescriberInformation,
                                  officeAddress: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="1" className="mb-2 mb-sm-0">
                          <label>City :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.prescriberInformation?.city || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                prescriberInformation: {
                                  ...formData?.prescriberInformation,
                                  city: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                        <Col xs="12" sm="1">
                          <label>State :</label>
                        </Col>
                        <Col xs="12" sm="3" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.prescriberInformation?.state || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                prescriberInformation: {
                                  ...formData?.prescriberInformation,
                                  state: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                        <Col xs="12" sm="1">
                          <label>Zip code :</label>
                        </Col>
                        <Col xs="12" sm="3">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.prescriberInformation?.zipCode || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                prescriberInformation: {
                                  ...formData?.prescriberInformation,
                                  zipCode: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Medication Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Medication :</label>
                        </Col>
                        <Col xs="12" sm="10" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <textarea
                              style={sharedStyle}
                              value={formData?.medicationInformation?.medication || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                medicationInformation: {
                                  ...formData?.medicationInformation,
                                  medication: e.target.value
                                }
                              })} />
                          </div>
                        </Col>

                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Directions for use:</label>
                        </Col>
                        <Col xs="12" sm="10" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <textarea
                              style={sharedStyle}
                              value={formData?.medicationInformation?.useDirections || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                medicationInformation: {
                                  ...formData?.medicationInformation,
                                  useDirections: e.target.value
                                }
                              })} />
                          </div>
                        </Col>

                      </Row>
                      <Row className="align-items-center mb-2">
                        <Col xs="12" sm="2" >
                          <label>Strength :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <textarea
                              style={sharedStyle}
                              value={formData?.medicationInformation?.strength || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                medicationInformation: {
                                  ...formData?.medicationInformation,
                                  strength: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                        <Col xs="12" sm="2" >
                          <label>Quantity :</label>
                        </Col>
                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                          <div className="d-flex align-items-center">
                            <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                              value={formData?.medicationInformation?.quantity || ''}
                              onChange={(e) => setFormData({
                                ...formData,
                                medicationInformation: {
                                  ...formData?.medicationInformation,
                                  quantity: e.target.value
                                }
                              })} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={3}>
                          <h6 className="fw-500" style={{ margin: "0.2rem" }}>Medication Administered:</h6>
                        </Col>
                        <Col xl={3}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              name="medicationAdministered"
                              checked={formData?.medicationInformation?.medicationAdministered?.selfAdministered === true}
                              onClick={() => setFormData({ ...formData, medicationInformation: { ...formData?.medicationInformation, medicationAdministered: { selfAdministered: true } } })}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Self-Administered</label>
                          </div>
                        </Col>
                        <Col xl={3}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              name="medicationAdministered"
                              checked={formData?.medicationInformation?.medicationAdministered?.physicianOffice === true}
                              onClick={() => setFormData({ ...formData, medicationInformation: { ...formData?.medicationInformation, medicationAdministered: { physicianOffice: true } } })}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Physician’s Office</label>
                          </div>
                        </Col>
                        <Col xl={3}>
                          <div className="col-auto  d-flex">
                            <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                              type="radio"
                              className="form-check-input"
                              name="medicationAdministered"
                              checked={formData?.medicationInformation?.medicationAdministered?.other === true}
                              onClick={() => setFormData({ ...formData, medicationInformation: { ...formData?.medicationInformation, medicationAdministered: { other: true } } })}
                            />
                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Other</label>
                          </div>
                        </Col>
                      </Row>
                      {formData?.medicationInformation?.medicationAdministered?.other && (
                        <Row>
                          <Col xl={12}>
                            <div className="row">
                              <div className="col">
                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.75rem", marginTop: "0.4rem" }}>
                                  <div className="row">
                                    <div className="col-auto">
                                      <h6 className="fw-500" style={{ margin: "0.3rem" }}> If Other ? </h6>
                                    </div>
                                    <div className="col">
                                      <input
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          marginTop: "0.1rem",
                                          backgroundColor: "unset",
                                        }}
                                        type="text"
                                        value={formData?.medicationInformation?.medicationAdministered?.otherDescription || ""}
                                        onChange={e => setFormData({ ...formData, medicationInformation: { ...formData?.medicationInformation, medicationAdministered: { otherDescription: e.target.value, other: true } } })}
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Clinical Information</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <Card style={{ padding: '1rem', backgroundColor: '#FFFFFF', border: '1px solid #D3D3D3', marginBottom: '0.5rem' }}>
                        <Row className="align-items-center">
                          <Col xs="12" sm="12" >
                            <label>What is the patient’s diagnosis for the medication being requested? </label>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="12" className="mb-2 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <textarea
                                style={sharedStyle}
                                value={formData?.clinicalInformation?.diagnosisForMedication || ""}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  clinicalInformation: {
                                    ...formData?.clinicalInformation,
                                    diagnosisForMedication: e.target.value
                                  }
                                })} />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center mb-2">
                          <Col xs="12" sm="3">
                            <label>ICD-10 Code(s) :</label>
                          </Col>
                          <Col xs="12" sm="9">
                            <div className="d-flex align-items-center">
                              <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                value={formData?.clinicalInformation?.icdCode || ""}
                                onChange={(e) => setFormData({
                                  ...formData,
                                  clinicalInformation: {
                                    ...formData?.clinicalInformation,
                                    icdCode: e.target.value
                                  }
                                })} />
                            </div>
                          </Col>
                        </Row>
                      </Card>
                      <Row>

                        <Card className="match-card-body p-3">
                          <Row><h6 style={{ fontWeight: "600", textDecoration: "underline" }}>Please refer to the patient’s PDL at <a href="https://www.uhcprovider.com" target="_blank" rel="noreferrer noopener">www.uhcprovider.com</a> for a list of preferred alternatives</h6></Row>
                          <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                              <h6 style={{ fontWeight: "600", display: "inline" }}>What medication(s) does the patient have a history of failure to ?</h6>
                              <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                (Please specify ALL medication(s)/strengths tried, directions,
                                length of trial, and reason for discontinuation of each medication)
                              </span>
                              <textarea
                                  style={sharedStyle}
                                  value={htmlToTextFunction(formData?.clinicalInformation?.medicationHistoryOfFailure || "")}
                              />
                          </Card>
                          <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                              <h6 style={{ fontWeight: "600", display: "inline" }}>What medication(s) does the patient have a contraindication or intolerance to ? </h6>
                              <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                (Please specify ALL medication(s) with the
                                associated contraindication to or specific issues resulting in intolerance to each medication)
                              </span>
                              <textarea
                                style={sharedStyle}
                                value={htmlToTextFunction(formData?.clinicalInformation?.medicationContraindication || "")}
                              />
                          </Card>
                          <Card className="bg-light-gray" style={{ marginBottom: "0.5rem" }}>
                            <div style={{ margin: "0.2rem" }}>
                              <h6 style={{ fontWeight: "600", display: "inline" }}>Are there any supporting laboratory or test results related to the patient’s diagnosis? </h6>
                              <span style={{ fontWeight: "300", fontSize: "smaller" }}>
                                (Please specify or provide
                                documentation)
                              </span>
                            </div>
                            <textarea
                                style={sharedStyle}
                                value={htmlToTextFunction(formData?.clinicalInformation?.labResults || "")}
                            />
                          </Card>
                        </Card>
                      </Row>

                    </div>
                  </div>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="card-bodyDiv">
                <Card className="match-card-header p-2 mb-2">
                  <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>Additional Information That May Be Important For This Review</h5>
                </Card>
                <Card className="match-card-body p-3">
                  <div className="table-responsive">
                    <div className="clinical-form">
                      <textarea
                        style={sharedStyle}
                        value={htmlToTextFunction(formData?.additionalInformation || "")}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
            <Row className="align-items-center mb-2 ">
              <Col xs="12" sm="4">
                <div className="d-flex flex-column align-items-center">
                  <div className="sign">
                    {formData?.signature && (
                      <img className="sigCanvasNew" src={formData?.signature} />
                    )}
                  </div>
                  <label style={{ marginTop: "0.5rem" }}>Provider Signature </label>
                </div>
              </Col>
              <Col xs="12" sm="4">
              </Col>
              <Col xs="12" sm="4">
                <div className="d-flex flex-column align-items-center">
                  <Input
                    type="date"
                    style={{
                      fontSize: "0.70rem",
                      lineHeight: "1",
                      border: "none",
                      borderBottom: "1px solid #000",
                      borderRadius: "0",
                      backgroundColor: "transparent"
                    }}
                    value={formatDate(formData?.date)}
                    onChange={(e) => setFormData({
                      ...formData,
                      date: new Date(e.target.value)
                    })}
                  />
                  <label style={{ marginTop: "0.5rem" }}>Date </label>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="card-bodyDiv" style={{ width: '100%' }}>
                <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "none", borderRadius: "8px" }}>
                  <div className="p-3" style={{ fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                    <p style={{ margin: "0" }}>
                      <strong>Confidentiality Notice:</strong> This transmission contains confidential information belonging to the sender and UnitedHealthcare. This
                      information is intended only for the use of UnitedHealthcare. If you are not the intended recipient, you are hereby notified that any
                      disclosure, copying, distribution or action involving the contents of this document is prohibited. If you have received this telecopy in
                      error, please notify the sender immediately.
                    </p>
                  </div>
                </Card>
              </div>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

export default UnitedHealthCareAuthFormPdf;
