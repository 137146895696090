import React from "react";
import { Modal } from "reactstrap";
import { SelectedExperienceTag, ExperienceTag } from "../../../../models/ExperienceTag";
import { HashTag, SelectedHashTag } from "../../../../models/HashTag";
import ClientFilters from "./ClientFilters";
import TherapistFilters from "./TherapistFilters";

interface FilterModalProps {
  userRole: string | undefined;
  showModal: boolean;
  hashTags: HashTag[];
  selectedHashTags: SelectedHashTag[];
  selectedExperienceTags: SelectedExperienceTag[];
  experienceTags: ExperienceTag[];
  selectedOwnership: string;
  handleExperienceTags: (value: any) => void;
  setSelectedOwnership: (value: string) => void;
  removeExperienceTag: (value: string) => void;
  setTherapistQuery: () => void;
  setShowModal: (value: boolean) => void;
  handleHashTags: (value: any) => void;
  removeHashTag: (value: string) => void;
  setQuery: () => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
  userRole,
  showModal,
  hashTags,
  selectedHashTags,
  selectedExperienceTags,
  experienceTags,
  selectedOwnership,
  handleExperienceTags,
  setSelectedOwnership,
  removeExperienceTag,
  setTherapistQuery,
  setShowModal,
  handleHashTags,
  removeHashTag,
  setQuery,
}: FilterModalProps) => {
  return showModal ? (
    <>
      <Modal isOpen={showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          {userRole == "CLIENT" ? (
            <ClientFilters
              experienceTags={experienceTags}
              handleExperienceTags={handleExperienceTags}
              handleHashTags={handleHashTags}
              hashTags={hashTags}
              selectedExperienceTags={selectedExperienceTags}
              removeExperienceTag={removeExperienceTag}
              removeHashTag={removeHashTag}
              selectedHashTags={selectedHashTags}
              setQuery={setQuery}
            />
          ) : (
            <TherapistFilters
              experienceTags={experienceTags}
              handleExperienceTags={handleExperienceTags}
              removeExperienceTag={removeExperienceTag}
              selectedExperienceTags={selectedExperienceTags}
              selectedOwnership={selectedOwnership}
              setSelectedOwnership={setSelectedOwnership}
              setTherapistQuery={setTherapistQuery}
            />
          )}
        </div>
      </Modal>
    </>
  ) : null;
};

export default FilterModal;
