import axios from "axios";
import { Util } from '../../../Util'
import { AppResponse } from "src/models/Response";

export class VonageServices {
    public static async createVonageSession(name: any){
        const url = Util.apiAuthUrl(`vonage_session/${name}`);
        return await axios.get(url);
    }

    public static async generateVonageToken(sessionId: any){
        const url = Util.apiAuthUrl(`vonage_token/${sessionId}`);
        return await axios.get(url);
    }

    public static async getMeetingByMeetingId(id: any){
        const url = Util.apiAuthUrl(`meeting/${id}`)
        return await axios.get(url);
    }

    public static async startArchive(sessionId: any){
        const url = Util.apiAuthUrl(`startArchive/${sessionId}`)
        const res= await axios.get(url);
        return res;
        
    }

    public static async stopArchive(archieveId: any){
        const url = Util.apiAuthUrl(`stopArchive/${archieveId}`)
        return await axios.get(url);
    }

    public static async updateFirstSpeaker(sessionName: any, firstSpeakerId: any){
        const url = Util.apiAuthUrl(`meeting/updateFirstSpeaker`)
        return await axios.post(url,{
            sessionName,
            firstSpeaker: firstSpeakerId
        });
    }
    public static async cancellCall(meetingId: any){
        const url = Util.apiAuthUrl("cancel/vonage/call");

        const data = {
          sessionName: meetingId
        };

        return await axios.post(url, data);
    }

    public static async initializeVonageCall(
        recieverId: string,
        appointmentId: string | undefined,
        callDuration: number,
        isTranscribeAllowed: boolean,
        isAppointmentBased: boolean,
        isAudio: boolean,
        transcribeAllowedForLoggedUser: boolean | undefined
    ): Promise<AppResponse<any>> {
        
        const url = Util.apiAuthUrl("initialize/vonage/call");
        console.log(url);
        
        const data = {
        recieverId: recieverId,
        appointmentId: appointmentId,
        callDuration: callDuration,
        isTranscribeAllowed: isTranscribeAllowed,
        isAppointmentBased: isAppointmentBased,
        isAudioCall: isAudio,
        transcribeAllowedForLoggedUser
        };

        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
    }

    public static async startVonageMeeting(
    sessionName: string,
    meetingId: string,
    recieverId: string,
    appointmentId: string | undefined,
    callDuration: number,
    isTranscribeAllowed: boolean,
    isAppointmentBased: boolean
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("start/vonage/call");

    const data = isAppointmentBased
      ? {
          sessionName: sessionName,
          meetingId: meetingId,
          recieverId: recieverId,
          appointmentId: appointmentId,
          callDuration: callDuration,
          isTranscribeAllowed: isTranscribeAllowed,
          isAppointmentBased: isAppointmentBased,
        }
      : {
          sessionName: sessionName,
          meetingId: meetingId,
          recieverId: recieverId,
          callDuration: callDuration,
          isTranscribeAllowed: isTranscribeAllowed,
          isAppointmentBased: isAppointmentBased,
        };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async joinVonageMeeting(roomName: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`join/vonage/call/${roomName}`);

    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async updateSessionIdInMeeting(roomName: string, sessionId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`vonage/updateSessionId/${roomName}`);
    const data = {
        sessionId 
    }

    return await axios.put<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getMeetingDataUsingSessionName(roomName: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`vonage/getMeetingData/${roomName}`);

    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async initializeVonageGroupCall(
        callDuration: number,
        isTranscribeAllowed: boolean,
        isAudio: boolean,
        transcribeAllowedForLoggedUser: boolean | undefined,
        isGroupCall: boolean,
        chatGroupCallId: string
    ): Promise<AppResponse<any>> {
        
        const url = Util.apiAuthUrl("initialize/vonage/group/call");
        console.log(url);
        
        const data = {
          callDuration: callDuration,
          isTranscribeAllowed: isTranscribeAllowed,
          isAudioCall: isAudio,
          transcribeAllowedForLoggedUser,
          isGroupCall,
          chatGroupCallId
        };

        return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async startVonageMeetingGroup(
    sessionName: string,
    meetingId: string,
    groupCallId: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("start/vonage/group/call");

    const data = {
          sessionName: sessionName,
          meetingId: meetingId,
          groupCallId
        };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getMeetingDetailsByGroupCallId(
    groupCallId: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getMeetingDetailsByGroupCallId/${groupCallId}`);

    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async joinVonageGroupMeeting(meetingId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("join/vonage/group/call");

    const data = {
      meetingId: meetingId
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async acceptVonageGroupCall(meetingId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("accept/vonage/group/call");

    const data = {
      meetingId: meetingId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async cancelVonageGroupCall(sessionName: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("cancel/vonage/group/call");

    const data = {
      sessionName: sessionName,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async checkOngoingGroupCallForClient(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("checkOngoingGroupCall");

    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  public static async generateSessionForNetworkTest(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("generateSessionForNetworkTest");

    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

    public static async busyVonageCall(meetingId: any){
        const url = Util.apiAuthUrl("busy/vonage/call");

        const data = {
          meetingId
        };

        return await axios.post(url, data);
    }

  public static async saveSessionFeedback(sessionFeedbackData: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("addSessionFeedback");

    return await axios.post<Partial<any>, AppResponse<any>>(url, sessionFeedbackData);
  }

}