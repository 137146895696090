import { DiagnosisModel, GoalInformationModel, TreatmentSessionModel, SignatureDetailsModel } from "./therapyPlanSubModel";

export interface TherapyPlan {
  stepCount?: number;
  _id?: string;
  clientId?: string;
  isSignature?: boolean;
  planCreationDate?: Date;
  creationDate?: Date;
  diagnosis?: DiagnosisModel[];
  goalDate?: Date;
  goalInformation?: GoalInformationModel;
  treatmentSession?: TreatmentSessionModel[];
  clientSignature?: any;
  clientSignatureDetails?: SignatureDetailsModel;
  lrpSignature?: any;
  lrpSignatureDetails?: SignatureDetailsModel;
  clinicianSignature?: any;
  clinicianSignatureDetails?: SignatureDetailsModel;
  clinicianTwoSignature?: any;
  clinicianTwoSignatureDetails?: SignatureDetailsModel;
  goalDate2?: Date;
  goalInformation2?: GoalInformationModel;
  treatmentSession2?: TreatmentSessionModel[];
  goalDate3?: Date;
  goalInformation3?: GoalInformationModel;
  treatmentSession3?: TreatmentSessionModel[];
}

export interface TherapyPlanFormOne {
  stepCount?: number;
  _id?: string,
  clientId?: string,
  isSignature?: boolean;
  isSendSignatureEmail?: boolean;
  planCreationDate?: Date;
  creationDate?: Date;
  diagnosis?: DiagnosisModel[];
  goalDate?: Date;
  goalInformation?: GoalInformationModel;
  treatmentSession?: TreatmentSessionModel[];
  clientSignature?: any;
  clientSignatureDetails?: SignatureDetailsModel;
  lrpSignature?: any;
  lrpSignatureDetails?: SignatureDetailsModel;
  clinicianSignature?: any;
  clinicianSignatureDetails?: SignatureDetailsModel;
}

export interface ClientAndTherapistNames {
  clientName?: string;
  therapistName?: string;
}

export enum TPType {
  ORIGINAL = "ORIGINAL",
  VERSION = "VERSION"
}

export interface TherapyPlanSinglePage {
  stepCount?: number;
  _id?: string;
  clientId?: string;
  isSignature?: boolean;
  planCreationDate?: Date;
  creationDate?: Date;
  diagnosis?: DiagnosisModel[];
  goalDate?: Date;
  goalInformation?: GoalInformationModel;
  treatmentSession?: TreatmentSessionModel[];
  clientSignature?: any;
  clientSignatureDetails?: SignatureDetailsModel;
  lrpSignature?: any;
  lrpSignatureDetails?: SignatureDetailsModel;
  clinicianSignature?: any;
  clinicianSignatureDetails?: SignatureDetailsModel;
  clinicianTwoSignature?: any;
  clinicianTwoSignatureDetails?: SignatureDetailsModel;
  goalDate2?: Date;
  goalInformation2?: GoalInformationModel;
  treatmentSession2?: TreatmentSessionModel[];
  goalDate3?: Date;
  goalInformation3?: GoalInformationModel;
  treatmentSession3?: TreatmentSessionModel[];
  isSendSignatureEmail?: boolean;
}