import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Card, Input } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InsuranceCompanyTags } from "../../models/InsuranceCompany";
import { InsuranceCompanyService } from "../../services/InsuranceCompanyService";
import { Upload } from "../../models/Upload";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import { ReceiverModel, SubscriberModel } from "src/models/Insurance";
import moment from "moment";
import { ClientService } from "src/services/ClientService";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/services/AdminService";

const AdInsuranceByAdmin: React.FC = () => {
  // const [user, setUser] = useContext(UserContext);
  const [twoFeeTypes, setTwoFeeTypes] = useState(false);
  const addFormTwoHandler = () => setTwoFeeTypes(true);
  const removeFormTwoHandler = () => setTwoFeeTypes(false);
  const [organization, setOrganization] = useState("");
  const [insuranceData, setInsuranceData] = useState<ReceiverModel>({} as ReceiverModel);
  const [insuranceCard, selectInsuranceCard] = useState({ preview: "", preview2: "", raw: "" });
  const insuranceCardRef = useRef<any>();
  insuranceCardRef.current = insuranceCard;
  const [insuranceCardBack, selectInsuranceCardBack] = useState({ preview: "", preview2: "", raw: "" });
  const insuranceCardBackRef = useRef<any>();
  insuranceCardBackRef.current = insuranceCardBack;
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [selectedInsuranceCompanyTag, setSelectedInsuranceCompanyTag] = useState('');
  const [subscriberData, setSubscriberData] = useState<SubscriberModel>({} as SubscriberModel);
  const [user, setUser] = useState<any>({} as any);
  const [dateValue, setDateValue] = useState(null);
  const insurance = localStorage.getItem("insuranceCompany");
  const [saveDisabled, setDisabled] = useState(false);
  const setOrganizationData = (organizationName: string) => {
    setOrganization(organizationName);
  };

  const history = useHistory();

  const [subscriberDetails, setSubscriberDetails] = useState({ firstname: "", lastname: "", state: "", city: "", address1: "" });

  function handleDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDateValue(dateValue);
  }
  const db = moment.utc(dateValue || user?.dateOfBirth).format("YYYY-MM-DD");

  const splittedURL = window.location.pathname.split("/");
  const userId = splittedURL[splittedURL.length - 1];

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserClient()

    if (insurance && insurance !== "insurance") {
      ClientService.ViewInsuranceById(insurance).then(res => {
        if (res.success) {
          setSelectedInsuranceCompanyTag(res.data?.insuranceCompany)
        } else {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  }, []);


  const getUserClient = () => {
    ClientService.getClientByClientId(userId).then(res => {
      if (res.success) {
        setUser(res.data)
          const data: any = {
            state: res.data?.state,
          }
          InsuranceCompanyService.getInsuaranceCompanyByState(data).then((res: any) => {
            if (res.success) {
              setSearchInsuranceCompanyTag(res.data);
            } else {
              setSearchInsuranceCompanyTag([]);
            }
          });
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    })
  }

  const selectInsuranceFile = (e: any) => {
    if (e.target.files.length) {
      selectInsuranceCard({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };

  const selectInsuranceBackFile = (e: any) => {
    if (e.target.files.length) {
      selectInsuranceCardBack({
        preview: URL.createObjectURL(e.target.files[0]) as any,
        preview2: e.target.files[0],
        raw: e.target.files,
      });
    }
  };



  const FileThumbnail: React.FC<{ file: Upload }> = ({ file }) => {
    if (file) {
      switch (file.extension || undefined || null) {
        case ".PNG":
        case ".png":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        case ".docx":
        case ".DOCX":
          return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit"></i>;
        case ".pdf":
        case ".PDF":
          return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit"></i>;
        case ".txt":
        case ".TXT":
          return <i className="icon-copy fa fa-file-text-o iconFiles iconEdit"></i>;
        case ".mp4":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".mkv":
          return <i className="icon-copy fa fa-file-video-o iconFiles iconEdit p-2"></i>;
        case ".jpg":
        case ".JPG":
        case ".JPEG":
        case ".jpeg":
        case ".tiff":
        case ".TIFF":
        case ".gif":
        case ".GIF":
          return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit"></i>;
        default:
          return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
      }
    } else {
      return <i className="icon-copy fa fa-file-o iconFiles iconEdit"></i>;
    }
  };

  const InsuranceCardFrontFiles = () => {
    switch (insuranceCardRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCard({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const InsuranceCardBackFiles = () => {
    switch (insuranceCardBackRef.current.preview2.type) {
      case "image/png":
      case "IMAGE/PNG":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      case ".docx":
      case ".DOCX":
      case "image/doc":
      case "application/msword":
        return <i className="icon-copy fa fa-file-word-o iconFiles iconEdit p-2"></i>;
      case "application/pdf":
        return <i className="icon-copy fa fa-file-pdf-o iconFiles iconEdit  p-2"></i>;
      case "text/plain":
      case ".TXT":
        return <i className="icon-copy fa fa-file-text-o iconFiles  iconEdit p-2"></i>;
      case "image/jpeg":
      case "image/jpg":
      case ".JPEG":
      case ".jpeg":
        return <i className="icon-copy fa fa-file-image-o iconFiles iconEdit p-2"></i>;
      default:
        selectInsuranceCardBack({
          preview: "",
          preview2: "",
          raw: "",
        });
        return toast.error("Only images & pdf can be uploaded.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
    }
  };

  const createOption = (label: string) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  });

  const updateTherapistDetails = () => {

    setDisabled(true);

    if (!subscriberData?.memberId) {
      toast.error("Member id is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else if (!selectedInsuranceCompanyTag) {
      toast.error("Organization Name id is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      const address: any = {
        state: user?.state || subscriberDetails?.state,
      };

      // const resive: any = {
      //   insuranceCompanyId: selectedInsuranceCompanyTag,
      // };
      const subcribersData: any = {
        memberId: subscriberData?.memberId,
        firstName: user?.firstname || subscriberDetails?.firstname,
        lastName: user?.lastname || subscriberDetails?.lastname,
        address: address,
      };
      const data: any = {
        clientId: userId,
        insuranceCompanyId: selectedInsuranceCompanyTag,
        subscriber: subcribersData,
        deletingInsuranceCardFrontId: "none",
        deletingInsuranceCardBackId: "none",
      };
      AdminService.AddInsurancePlanByAdmin(userId, data, insuranceCardRef.current.raw, insuranceCardBackRef.current.raw).then(res => {
        if (res.success) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar"
          })
          setDisabled(false);
          history.goBack();
        } else {
          setDisabled(false);
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    }
  };

  const back = () => {
    history.goBack();
  };

  return (
    <>
      <div id="targetSection" className="content-wrapper mt-22rem">
        <section className="wrapper bg-light2 angled upper-end" id="recoverform">
          <div className="container1 pb-14 pb-md-16">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222">
                <div className="card">
                  <div className="card-body padding-13 text-center">
                    <div>
                    <button
                        type="button"
                        className={
                          saveDisabled ? "btn btn-primary relative mb-4 updating" : "btn btn-primary relative mb-4"
                        }
                        style={{
                          bottom: "0",
                          float: "right",
                        }}
                        disabled={saveDisabled}
                        onClick={() => {
                          updateTherapistDetails();
                        }}
                      >
                        {saveDisabled ? "Saving..." : "Save"}
                      </button> 

                      <button
                        type="button"
                        className="btn btn-light  relative mr-2 mb-4"
                        style={{
                          bottom: "0",
                          float: "right",
                        }}
                        onClick={() => {
                          back();
                        }}
                      >
                        <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                        Back
                      </button>
                    </div>
                    <Row >
                      <Col xl="12" sm="12" className="pading-signupInsurance">
                        <div className="row">
                          {/* {insurance == "insurance" &&
                            <div className="col-xl-12 mb-3 d-flex float-left jestifyStart">
                              <span><button className="btn btn-skip float-left" onClick={() => back()}><i className='bx bx-left-arrow-alt' ></i> &nbsp; Back</button></span>
                            </div>
                          } */}
                        </div>
                        <h1 className="mb-3 text-start">Insurance Information</h1>
                        {/* <p className="lead mb-6 text-start">Enter your insurance information to confirm your appointment</p> */}
                        <form className="text-start mb-3 needs-validation">
                          <Col xl={12}>

                            <Card className="BorderRadiusLeftRight paddingInsurance">
                              <div className="card-bodyDiv">
                                <Row>
                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">First Name</h5>
                                      </div>
                                      <div className="card-heading mb-4 flex">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="First name"
                                          name="First name"
                                          defaultValue={user?.firstname || ""}
                                          onChange={e => setSubscriberDetails({ ...subscriberDetails, firstname: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Last Name</h5>
                                      </div>
                                      <div className="card-heading mb-4 flex">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Last name"
                                          name="Last name"
                                          defaultValue={user?.lastname || ""}
                                          onChange={e => setSubscriberDetails({ ...subscriberDetails, lastname: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Member Id</h5>
                                      </div>
                                      <div className="card-heading mb-4 flex">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Member Id "
                                          name="Member Id "
                                          value={subscriberData?.memberId || ""}
                                          onChange={e => setSubscriberData({ ...subscriberData, memberId: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">Organization Name</h5>
                                      </div>

                                      <select
                                        className="form-control mb-4"
                                        value={selectedInsuranceCompanyTag}
                                        onChange={e => setSelectedInsuranceCompanyTag(e.target.value)}
                                      >
                                        <option>Select</option>
                                        {searchInsuranceCompanyTag &&
                                          searchInsuranceCompanyTag.map(opt => (
                                            <option value={opt?._id} key={opt._id}>
                                              {opt?.insuranceCompany}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </Col>

                                  <Col xl="6">
                                    <div>
                                      <div className="card-heading mb-2">
                                        <h5 className="font-size-14 profileFont mb-2">State</h5>
                                      </div>
                                      <div className="card-heading mb-4 flex">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="State"
                                          name="State"
                                          defaultValue={user?.state || ""}
                                          onChange={e => setSubscriberDetails({ ...subscriberDetails, state: e.target.value })}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="6">
                                    {insuranceCardRef.current.preview ? (
                                      <div>
                                        {insuranceCardRef.current.preview && (
                                          <label htmlFor="file-upload1" className="file-upload m-auto">
                                            <span className="text-primary mt-5 m-auto cursor-pointer">Change Your insurance card front picture</span>

                                            <input
                                              id="file-upload1"
                                              type="file"
                                              style={{ display: "none" }}
                                              accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                                              onChange={selectInsuranceFile}
                                              multiple
                                            />
                                          </label>
                                        )}
                                        <div>
                                          <div className="flex">
                                            <div style={{ fontSize: "62px" }}>{InsuranceCardFrontFiles()}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {!insuranceCardRef.current.preview && (
                                      <label
                                        htmlFor="file-upload1"
                                        style={{ border: "1px dashed" }}
                                        className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer"
                                      >
                                        <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                                          <img src={uploadIcon} />
                                          <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card front picture</p>
                                        </div>

                                        <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                                        <input
                                          id="file-upload1"
                                          type="file"
                                          style={{ display: "none" }}
                                          className="file_upload_input mb-4"
                                          accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                          onChange={selectInsuranceFile}
                                          multiple
                                        />
                                      </label>
                                    )}
                                  </Col>

                                  <Col xl="6">
                                    {insuranceCardBackRef.current.preview ? (
                                      <div>
                                        {insuranceCardBackRef.current.preview && (
                                          <label htmlFor="file-upload2" className="file-upload m-auto">
                                            <span className="text-primary mt-5 m-auto cursor-pointer">Change Your insurance card back picture</span>

                                            <input
                                              id="file-upload2"
                                              type="file"
                                              style={{ display: "none" }}
                                              accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                                                                                 text/plain, image/doc"
                                              onChange={selectInsuranceBackFile}
                                              multiple
                                            />
                                          </label>
                                        )}
                                        <div>
                                          <div className="flex">
                                            <div style={{ fontSize: "62px" }}>{InsuranceCardBackFiles()}</div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {!insuranceCardBackRef.current.preview && (
                                      <label
                                        htmlFor="file-upload3"
                                        style={{ border: "1px dashed" }}
                                        className=" mb-4 file_upload_profile file-upload relative flex flex-col text-gray-400 z-10  border-custom-text-gray-500  rounded cursor-pointer"
                                      >
                                        <div className="flex flex-wrap items-center pl-5 pt-2 pb-1 file_upload_content">
                                          <img src={uploadIcon} />
                                          <p className="m-0 text-black">&nbsp;&nbsp;Upload your insurance card back picture </p>
                                        </div>
                                        <p className="m-0 pl-10 text-sm pb-2 file_upload_p">.PDF, .JPG, .PNG</p>

                                        <input
                                          id="file-upload3"
                                          type="file"
                                          style={{ display: "none" }}
                                          className="file_upload_input mb-4"
                                          accept="image/png, image/jpeg, application/pdf,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, image/doc"
                                          onChange={selectInsuranceBackFile}
                                          multiple
                                        />
                                      </label>
                                    )}
                                  </Col>
                                </Row>
                              </div>

                            </Card>
                          </Col>
                        </form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default AdInsuranceByAdmin;
