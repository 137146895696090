import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  ButtonGroup,
  Button,
} from "reactstrap";
const classNames = require("classnames");
import WelcomeCompNew from "./WelcomeCompNew";
import UpcomingAppointmentsNew from "./UpcommingAppointmentNew";
import NewAppointmentsNew from "./NewAppointment";
import DiscoverRequestIcon from "../../assets/images/icons/u_users-alt.png";
import ClientRequests from "./ClientRequests";
import CompletedSessions from "./CompletedSessions";
import { Ethinicity } from "src/models/Ethnicity";
import { FriendRequest } from "src/models/FriendRequest";
import { TherapistService } from "src/services/TherapistService";
import { ClientService } from "src/services/ClientService";
import { Appointment } from "src/models/Appointment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { NotificationEvent, NotificationModel, NotificationVarient } from "src/models/Notification";
import UserContext from "src/context/UserContext";
import { NotificationService } from "src/services/NotificationService";
import { sendNotificationSocketData } from "src/models/sendNotificationCallSocketData";
import { SocketContext } from "src/context/ScoketContext";
import TherapistQuickChat from "./TherapistQuickChat";
import { CommonService } from "src/services/CommonService";
import OtpInput from "react-otp-input";
import UpComingGroupCall from "./UpComingGroupCall";
import ShowTherapistScore from "./ShowTherapistScore";
import ShowOngoingCalls from "./common/ShowOngoingCalls";

const TherapistDashboardNew: React.FC = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tabNo, setTabNo] = useState<string>("1");
  const [gender, setGender] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [ethnicityList, setEthnicityList] = useState<Ethinicity[]>([]);
  const [matchedClients, setMatchedClients] = useState([] as FriendRequest[]);
  const [upcomingAppointments, setupcomingAppointments] = useState([] as Appointment[]);
  const [skip, setSkip] = useState(1);
  const [therapistAppointmentStats, setTherapistAppointmentStats] = useState();
  const [allTreatmentSessionStats, setAllTreatmentSessionStats] = useState([] as any[]);
  const [user, setUser] = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenPinNumberModal, setIsOpenPinNumberModal] = useState<boolean>(false);
  const [therapistEarningsStats, setTherapistEarningsStats] = useState<any>();
  const [pinNumber, setPinNumber] = useState("");
  const [invalidOtp, setInvalidOtp] = useState("");
  const [therapistTotalEarningsStats, setTherapistTotalEarningsStats] = useState<boolean>();
  const [newAppointments, setNewAppointments] = useState([] as Appointment[]);
  const [upCommingGroupCalls, setUpCommingGroupCalls] = useState([]);
  const [therapistMeetingStats, setTherapistMeetingStats] = useState<any>();
  const [sortAlphabetically, setSortAlphabetically] = useState("");
  const [therapistScore, setTherapistScore] = useState("");
  const isNewDashboard = user && (user.isNewDashboard ?? true);

  const Limit = 3;
  let updatedSkip = 3;

  useEffect(() => {
    CommonService.getEthinicityList().then(res => {
      setEthnicityList(res.data);
    });

    loadData();
    checkPinNumber();
  }, []);

  const toggle = (tab: string) => {
    setTabNo(tab);
  };
  const toggleFilterCanvas = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const changeGender = useCallback(
    (gender: string) => () => {
      setGender(gender);
    },
    []
  );
  const checkPinNumber = () => {
    if (user?.role == "THERAPIST" && !user?.pinNumber) {
      setIsOpenPinNumberModal(true);
    }
  };

  const loadData = async () => {
    const data = {
      filterValue: "YEAR",
    };

    await TherapistService.getDashboardStats(data).then(res => {
      console.log(res.data);

      setMatchedClients(res.data?.matchedClientStats);
      setupcomingAppointments(res.data?.upcommingAppointmentStats);
      setTherapistAppointmentStats(res.data?.therapistAppointmentStats);
      setAllTreatmentSessionStats(res.data?.allTreatmentSessionStats);
      setTherapistEarningsStats(res.data?.therapistEarningsStats);
      setTherapistTotalEarningsStats(res.data?.therapistTotalEarningsStats);
      setNewAppointments(res.data?.pendingAppointmentStats);
      setUpCommingGroupCalls(res?.data?.allUpCommingGroupCalls);
      setTherapistMeetingStats(res?.data?.therapistMeetingStats);
      setIsLoading(false);
      setTherapistScore(res.data?.therapistScore?.score);
    });
  };

  const handleNext = () => {
    updatedSkip = skip + 1;
    const data = {
      gender: gender,
      ethnicity: selectedEthnicity,
      searchClientName: selectedName,
      sortAlphabetically: sortAlphabetically
    };

    ClientService.getRequestsByTherapist(data, 3, updatedSkip).then(res => {
      setMatchedClients(res.data);
      setSkip(updatedSkip);
    });
  };

  const handleBack = () => {
    updatedSkip = skip - 1;
    const data = {
      gender: gender,
      ethnicity: selectedEthnicity,
      searchClientName: selectedName,
      sortAlphabetically: sortAlphabetically
    };

    ClientService.getRequestsByTherapist(data, 3, updatedSkip).then(res => {
      setMatchedClients(res.data);
      setSkip(updatedSkip);
    });
  };

  const applyFilters = () => {
    updatedSkip = 1;
    const data2 = {
      gender: gender,
      ethnicity: selectedEthnicity,
      searchClientName: selectedName,
      sortAlphabetically: sortAlphabetically
    };

    ClientService.getRequestsByTherapist(data2, 3, updatedSkip).then(res => {
      setMatchedClients(res.data);
      setSkip(1);
      toggleFilterCanvas();
    });
  };

  const refreshCurruntPage = () => {
    const data = {
      gender: gender,
      ethnicity: selectedEthnicity,
      searchClientName: selectedName,
      sortAlphabetically: sortAlphabetically
    };

    ClientService.getRequestsByTherapist(data, 3, skip).then(res => {
      if (res && res.success) {
        setMatchedClients(res.data);
        toast.success(`Client removed from your friend list!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const removeFriend = (request: FriendRequest) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure want to stop seeing this client? (Please note this will remove all your scheduled appointments with this client.) Do you want to proceed?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "Later",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        TherapistService.unfriendClient(request._id).then(res => {

          if (res && res.success) {
            refreshCurruntPage();
            const notificationData: NotificationModel = {
              senderId: user?._id,
              receiverId: request?.clientId?._id,
              event: NotificationEvent.REQUEST_REMOVED,
              link: "/dashboard",
              content: "You have removed from friend list By " + user?.firstname + " " + user?.lastname,
              variant: NotificationVarient.WARNING,
            };

            NotificationService.sendNotification(notificationData).then((res: any) => {
              const socketData: sendNotificationSocketData = {
                socketId: res.data.socketId,
                notifyData: notificationData,
                receiverId: request?.clientId?._id,
                senderId: user?._id,
              };

              socket.emit("send-notification", socketData);
            });
          } else {
            toast.error(`Something went wrong!`, {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
        });

      }
    });
  };

  const updatePinNumber = () => {
    const data = {
      pinNumber: pinNumber,
    };

    if (pinNumber?.length == 4) {
      TherapistService.createPinNumber(data).then(res => {
        if (res.success) {
          setUser(res.data);
          setPinNumber("");
          setIsOpenPinNumberModal(false);
          toast.success(`Pin number successfully created.`, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error(res?.error, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else {
      toast.error(`Invalid Pin Number.`, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };

  function updateDiagnosisData(isUpdate: boolean) {
    if (isUpdate == true) {
      loadData();
    }
  }

  return (
    <React.Fragment>
      <div className="page-content m-bg-color-white">
        <div>
          <Row>
            <Col xl="4">
              <Row>
                <div className="overflow-hidden">
                  <WelcomeCompNew
                    therapistAppointmentStats={therapistAppointmentStats}
                    clientStats={0}
                    therapistTotalEarningsStats={therapistTotalEarningsStats}
                    therapistEarningsStats={therapistEarningsStats}
                    isLoading={isLoading}
                    therapistMeetingStats={therapistMeetingStats}
                  />
                  {newAppointments?.length > 0 && !isLoading && <NewAppointmentsNew newAppointments={newAppointments} isLoading={isLoading} />}
                  {/* {upcomingAppointments?.length > 0 && !isLoading && <UpcomingAppointmentsNew upcomingAppointments={upcomingAppointments} isLoading={isLoading} />} */}
                </div>
              </Row>
              {(isNewDashboard === false) && (
                <Row>
                  <div className="overflow-hidden">
                    {upcomingAppointments?.length > 0 && !isLoading && <UpcomingAppointmentsNew upcomingAppointments={upcomingAppointments} isLoading={isLoading} />}
                  </div>
                </Row>
              )}
              {(isNewDashboard === false) && (
                <Row>
                  <div className="overflow-hidden">
                    {upCommingGroupCalls != undefined && upCommingGroupCalls.length > 0 && <UpComingGroupCall upcomingGroupCalls={upCommingGroupCalls} isLoading={isLoading} />}
                  </div>
                </Row>
              )}
              {(isNewDashboard === false) && (
                <Row>
                  <div className="overflow-hidden">
                    <ShowTherapistScore score={therapistScore} isLoading={isLoading} />
                  </div>
                </Row>
              )}
            </Col>
            {isNewDashboard === undefined || isNewDashboard === true ? (
              <Col xl="4">
                <Row>
                  <div className="overflow-hidden">
                    {upcomingAppointments?.length > 0 && !isLoading && (
                      <UpcomingAppointmentsNew upcomingAppointments={upcomingAppointments} isLoading={isLoading} />
                    )}
                  </div>
                </Row>
                <Row>
                  <div className="overflow-hidden">
                    {upCommingGroupCalls != undefined && upCommingGroupCalls.length > 0 && <UpComingGroupCall upcomingGroupCalls={upCommingGroupCalls} isLoading={isLoading} />}
                  </div>
                </Row>
                <Row>
                  <div className="overflow-hidden">
                    <ShowOngoingCalls />
                  </div>
                </Row>
              </Col>
            ) : null}

            {isNewDashboard === undefined || isNewDashboard === true ? (
              <Col xl="4">
                <Row>
                  <div className="overflow-hidden">
                    <ShowTherapistScore score={therapistScore} isLoading={isLoading} />
                  </div>
                </Row>
              </Col>
            ) : null}

            {(isNewDashboard === false) && (
              <Col xl="8">
                <Row>
                  <Col xl="6" className="d-flex justify-content-center">
                    <Card className="w-100">
                      <CardBody className="m-p-zero">
                        <div className="d-flex justify-content-between">
                          <div className="flex">
                            <span className="rounded-icon-bg-n d-flex justify-content-center align-items-center">
                              <img className="icon-h-20" src={DiscoverRequestIcon} />
                            </span>
                            <h5 className="mb-0 d-flex justify-content-center align-items-center ms-3 fc-black">
                              Client Request
                            </h5>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary personalized-btn-dashboard btn-sm"
                              onClick={toggleFilterCanvas}
                            >
                              <i className="fa fa-filter"></i>
                            </button>
                          </div>
                        </div>
                        <Row>
                          <Col sm="12">
                            <ClientRequests
                              matchedClients={matchedClients}
                              setMatchedClients={setMatchedClients}
                              removeFriend={removeFriend}
                              onNext={handleNext}
                              onBack={handleBack}
                              offset={skip}
                              isLoading={isLoading}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="6" className="d-flex justify-content-center">
                    <CompletedSessions
                      allTreatmentSessionStats={allTreatmentSessionStats}
                      updateDiagnosisNote={updateDiagnosisData}
                      isLoading={isLoading}
                    />
                  </Col>
                </Row>
                <TherapistQuickChat />
                <Row>
                  <Col xl="6" className="d-flex justify-content-center">
                    <ShowOngoingCalls />
                  </Col>
                  <Col></Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </div>
      <Offcanvas isOpen={isFilterOpen} direction="end" toggle={toggleFilterCanvas} className="discover-canvas">
        <OffcanvasHeader toggle={toggleFilterCanvas}>Organize Clients</OffcanvasHeader>

        <OffcanvasBody>
          <div className="row  pt20 pl10">
            <div className="filter-option">
              <div className="mb-3">Name:</div>

              <div className="col-xl-12 mb-3">
                <input
                  className="form-control"
                  placeholder="Enter First Name or Last Name..."
                  onChange={e => setSelectedName(e.target.value)}
                  value={selectedName}
                />
              </div>
            </div>

            <div className="filter-option">
              <div className="mb-3">Gender:</div>

              <div className="mb-3">
                <div className="flex-wrap">
                  <ButtonGroup className="flex-wrap flex">
                    <Button color="primary" defaultChecked defaultValue={""} outline onClick={changeGender("")} active={gender === ""}>All</Button>
                    <Button color="primary" outline onClick={changeGender("Male")} active={gender === "Male"}>Male</Button>
                    <Button color="primary" outline onClick={changeGender("Female")} active={gender === "Female"}>Female</Button>
                    <Button color="primary" outline onClick={changeGender("NonBinary")} active={gender === "NonBinary"}>Non Binary</Button>
                    <Button color="primary" outline onClick={changeGender("Other")} active={gender === "Other"}>Other</Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>

            <div className="filter-option">
              <div className="mb-3">Ethnicity:</div>

              <div className="col-xl-12 mb-3">
                <select className="form-control" onChange={e => setSelectedEthnicity(e.target.value)} value={selectedEthnicity}>
                  <option value="">Select Ethnicity</option>
                  {ethnicityList &&
                    ethnicityList.map(opt => (
                      <option value={opt._id} key={opt._id}>
                        {opt.ethnicity}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="filter-option">
              <div className="mb-3">Select Sorting Option:</div>

              <div className="col-xl-12 mb-3">
                <select
                  className="form-control"
                  onChange={e => setSortAlphabetically(e.target.value)}
                  value={sortAlphabetically}
                >
                  <option value="">Select an option</option>
                  <option value="alphabetically">Alphabetical Order</option>
                </select>
              </div>
            </div>

            <div className="filter-option text-center">
              <br />

              <button type="button" className="btn btn-primary mb-3 pt-2 pb-2 profile_watch_buttons" onClick={applyFilters}>
                Apply
              </button>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
      <Modal isOpen={isOpenPinNumberModal} centered className="modal-sm">
        <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "3vh" }}>
          <div className="call-title" style={{ paddingBottom: "2vh" }}>
            <h5>Create Pin Number</h5>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mb-6">
            <OtpInput
              value={pinNumber}
              onChange={setPinNumber}
              numInputs={4}
              renderSeparator={<span className="mx-2"></span>}
              renderInput={props => <input {...props} className="input-pin-box" />}
              inputType={"number"}
            />
            {invalidOtp?.length > 0 && <p className="text-danger mb-0 mt-1">{invalidOtp}</p>}
          </div>
          <div className="call-action-btns" style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingBottom: "2vh" }}>
            <div
              className="btn btn-info accept-btn"
              onClick={() => {
                updatePinNumber();
              }}
            >
              Create
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TherapistDashboardNew;
