import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Input, Spinner } from "reactstrap";
import "../../assets/css/admin.css";
import { AdminService } from "../../services/AdminService";
import { toast } from "react-toastify";
const EditTherapistScoreWeights: React.FC = () => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [therapistScoreWeights, setTherapistScoreWeights] = useState<any[]>([]);
  const [formValues, setFormValues] = useState({
    responseTimeWeight: 0,
    availabilityWeight: 0,
    followUpAppointmentsWeight: 0,
    totalSessionsWeight: 0,
    missedAppointmentsWeight: 0,
    loyalityYearsWeight: 0,
    scheduledAppointmentsWeight: 0,
    noOfMatchesWeight: 0,
  });
  useEffect(() => {
    getTherapistScoresWeights();
  }, []);
  const getTherapistScoresWeights = async () => {
    setIsloading(true);
    const res = await AdminService.getTherapistScoreConstants();
    console.log(res.data);
    
    if (res.success) {
      setFormValues({
        responseTimeWeight: Number(res.data[0]?.responseTimeWeight) || 0,
        availabilityWeight: Number(res.data[0]?.availabilityWeight) || 0,
        followUpAppointmentsWeight: Number(res.data[0]?.followUpAppointmentsWeight) || 0,
        totalSessionsWeight: Number(res.data[0]?.totalSessionsWeight) || 0,
        missedAppointmentsWeight: Number(res.data[0]?.missedAppointmentsWeight) || 0,
        loyalityYearsWeight: Number(res.data[0]?.loyalityYearsWeight) || 0,
        scheduledAppointmentsWeight: Number(res.data[0]?.scheduledAppointmentsWeight) || 0,
        noOfMatchesWeight: Number(res.data[0]?.noOfMatchesWeight) || 0,
      });
      setTherapistScoreWeights(res.data);
      setIsloading(false);
    } else {
      setIsloading(false);
    }
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Allowing empty string or a number with an optional decimal point and digits after the decimal point
    if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  
  const isValidRange = (value: number) => value >= 0 && value <= 1;

  const submit = async () => {
    // const data = {
    //   responseTimeWeight: formValues.responseTimeWeight || 0,
    //   availabilityWeight: formValues.availabilityWeight || 0,
    //   followUpAppointmentsWeight: formValues.followUpAppointmentsWeight || 0,
    //   totalSessionsWeight: formValues.totalSessionsWeight || 0,
    //   missedAppointmentsWeight: formValues.missedAppointmentsWeight || 0,
    //   loyalityYearsWeight: formValues.loyalityYearsWeight || 0,
    //   scheduledAppointmentsWeight: formValues.scheduledAppointmentsWeight || 0,
    //   noOfMatchesWeight: formValues.noOfMatchesWeight || 0,
    // };

    const validatedFormValues = Object.entries(formValues).reduce((acc: Record<string, number> | null, [key, value]) => {
      if (acc === null) return null;
      const numberValue = Number(value);
      if (!isValidRange(numberValue)) {
        toast.error(`Value for ${key} must be between 0 and 1`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return null; // Exit if any value is invalid
      }
      acc[key] = numberValue;
      return acc;
    }, {} as Record<string, number>);

    if (!validatedFormValues) {
      return; // Exit submission if validation failed
    }
    const res = await AdminService.createTherapistScoreConstants(validatedFormValues);
    if (res.success) {
      toast.success("Therapist score heights changed!", {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
    }
  };
  return (
    <div className="page-content" style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
      <Container fluid>
        <Breadcrumbs title={"Therapist Score Weights"} breadcrumbItem={"Therapist Score Weights"} />
        {isLoading && <Spinner className="bouncer" />}
        <div className="modal-body" style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
          <Row>
            <Col xl="8">
              <Container fluid>
                {[
                  { label: 'Response Time Weight', name: 'responseTimeWeight', value: formValues.responseTimeWeight },
                  { label: 'Availability Weight', name: 'availabilityWeight', value: formValues.availabilityWeight },
                  { label: 'Followed Up Appointment Weight', name: 'followUpAppointmentsWeight', value: formValues.followUpAppointmentsWeight },
                  { label: 'Total Sessions Weight', name: 'totalSessionsWeight', value: formValues.totalSessionsWeight },
                  { label: 'Missed Appointments Weight', name: 'missedAppointmentsWeight', value: formValues.missedAppointmentsWeight },
                  { label: 'Loyality Years Weight', name: 'loyalityYearsWeight', value: formValues.loyalityYearsWeight },
                  { label: 'Scheduled Appointments Weight', name: 'scheduledAppointmentsWeight', value: formValues.scheduledAppointmentsWeight },
                  { label: 'No. Of Matches Weight', name: 'noOfMatchesWeight', value: formValues.noOfMatchesWeight },
                ].map((field, index) => (
                  <Row style={{ marginTop: '10px' }} key={index}>
                    <Col xl="6">
                      <div className="card-heading mb-2 add-session-popup">
                        <h5 className="font-size-14 profileFont mb-2">
                          {field.label}: <span style={{ color: '#990000' }}>*</span>
                        </h5>
                      </div>
                    </Col>
                    <Col xl="2">
                      <div className="card-heading mb-2 flex">
                        <Input
                          type="text"
                          name={field.name}
                          value={field.value}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
                <Row style={{ marginTop: '15px' }}>
                  <Col xs="6" className="mb-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ width: '100%', marginBottom: '10px' }}
                      onClick={submit}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default EditTherapistScoreWeights;