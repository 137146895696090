import React, { useEffect, useState } from "react";
import { Card, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Repeat } from "../../models/Appointment";
import { AppointmentService } from "../../services/AppointmentService";
import moment from "moment";
import { Therapist } from "../../models/Therapist";
import { TherapistService } from "../../services/TherapistService";
import user1 from "../../assets/images/default_profile.png";
import profileBackground from "../../assets/images/default_cover.png";
import { TwitterPicker } from "react-color";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { WorkingHour } from "../../models/WorkingHour";
import MessageModal from "../../pages/Popup/MessageModal";
import Swal from "sweetalert2";
import images from "../../assets/images";
import { Client } from "../../models/Client";
import { Util } from "../../Util";
import Modal5 from "../../pages/Popup/Modal5";
import Modal11 from "../../pages/Popup/Modal11";
import Modal12 from "../../pages/Popup/Modal12";
import Modal13 from "../../pages/Popup/Modal13";
import Modal15 from "../../pages/Popup/Modal15";
import Modal17 from "../../pages/Popup/Modal17";
import Modal18 from "../../pages/Popup/Modal18";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import HeaderMain from "./HeaderMain";
import FooterMain from "./FooterMain";
import PublicAppointmentCalendar from "./public-appointment/PublicAppointmentCalendar";
import CustomTimePickerForPublicAppointment from "./public-appointment/CustomTimePickerForPublicAppointment";

const PublicAppointments: React.FC = (props: any) => {
  const todayDate: Date = new Date();
  const tomorrowDate: Date = moment(todayDate).add(1, "day").startOf("day").toDate();

  const [isLoadingNextMonth, setIsLoadingNextMonth] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<Date>(tomorrowDate);

  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEventModal, setIsEventModal] = useState(false);
  const [haveRead, setHaveRead] = useState(false);
  const [haveAgreed, setHaveAgreed] = useState(false);
  const [haveAgreedPolicy, setHaveAgreedPolicy] = useState(false);
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [isView, setIsView] = useState<boolean>(true);
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [therapistAvailableHours, setTherapistAvailableHours] = useState([] as any);
  const [disabledCreateBtn, setDisabledCreateBtn] = useState(false);
  const [showAlreadyCompleted, setShowAlreadyCompleted] = useState(false);
  const [showTooEarly, setShowTooEarly] = useState(false);
  const [appointmentUpdated, setAppointmentUpdated] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const [showModalExceeded, setShowModalExceeded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [storedTherapistData, setStoredTherapistData] = React.useState({} as any);
  const state = localStorage.getItem("state");
  const [singleAppointment, setSingleAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: "",
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
    selectedDate: "",
  });

  const [eventOnHolidays, setEventOnHolidays] = useState<boolean>(false);

  const history = useHistory();

  const [allBlockDatesListInTherapist, setAllBlockDatesListInTherapist] = useState([] as any[]);
  const [blockDatesListForSelectedMonth, setBlockDatesListForSelectedMonth] = useState([] as any[]);

  const [allAppointmentListInTherapist, setAllAppointmentListInTherapist] = useState([] as any[]);
  const [appointmentListForSelectedMonth, setAppointmentListForSelectedMonth] = useState([] as any[]);

  useEffect(() => {
    const splittedURL = window.location.pathname.split("/");
    const userId = splittedURL[splittedURL.length - 1];

    if (userId) {
      viewSelectedUserProfile(userId);
    } else {
      history.push("/main");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const viewSelectedUserProfile = async (id: any) => {
    try {
      const res = await TherapistService.getTherapistDetailsbyIdPublicLimited(id);
      if (res && res.success && res.data) {
        if (state) {
          const therapistDataFrom = res.data;
          const newStatesList = res.data?.therapyState;
          const stateFrom = therapistDataFrom.state;

          if (stateFrom && newStatesList && !newStatesList.includes(stateFrom)) {
            newStatesList.push(stateFrom);
          }

          if (newStatesList && newStatesList?.includes(state)) {
            setStoredTherapistData(therapistDataFrom);

            localStorage.setItem("therapist", JSON.stringify(therapistDataFrom));

            const blockedSlots = therapistDataFrom.blockedDates || [];

            setAllBlockDatesListInTherapist(blockedSlots);

            getAllAppointmentsBySelectedTherapistId(therapistDataFrom, blockedSlots);
          } else {
            history.push(`/public-user-details/${id}`);
          }
        } else {
          history.push(`/public-user-details/${id}`);
        }
      } else {
        return toast.error("Error retrieving therapist data", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      return toast.error("Error retrieving therapist data", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const getAvailableTimeBySelectedDay = (dayNumber: number, isAvailableHours?: any) => {

    const availableHours: string[][] = [];

    isAvailableHours
      .filter((obj: any) => obj.daysOfWeek[0] === dayNumber)
      .map((obj: any) => {
        const availableHourSingle: string[] = [];

        for (let hour = parseInt(obj.startTime); hour <= parseInt(obj.endTime); hour++) {
          if (hour == parseInt(obj.startTime)) {
            const mST = moment(obj.startTime, "HH:mm").minute();

            if (mST == 0) {
              availableHourSingle.push(moment({ hour }).format("H:mm A"));
            }
          } else {
            availableHourSingle.push(moment({ hour }).format("H:mm A"));
          }

          if (hour != parseInt(obj.endTime)) {
            availableHourSingle.push(
              moment({
                hour,
                minute: 30,
              }).format("H:mm A")
            );
          } else {
            const mET = moment(obj.endTime, "HH:mm").minute();

            if (mET == 30) {
              availableHourSingle.push(
                moment({
                  hour,
                  minute: 30,
                }).format("H:mm A")
              );
            }
          }
        }

        availableHours.push(availableHourSingle);
      });

    return availableHours;

  };

  const getAllAppointmentsBySelectedTherapistId = async (therapistData?: any, blockedDatesListInFu?: any) => {
    try {
      if (therapistData && therapistData._id) {
        const res = await AppointmentService.getAllAppointmentByTherapistIdPublicForMonth(therapistData?._id, selectedMonth);

        if (res && res.success && res.data) {
          const appointmentList = res.data;

          const updatedAppointmentList = appointmentList?.map(appointment => {
            return { ...appointment, start: moment(appointment.start).toDate(), end: moment(appointment.end).toDate() };
          });

          setAllAppointmentListInTherapist(updatedAppointmentList);

          setArraysOnlyForSelectedMonth(selectedMonth, blockedDatesListInFu, updatedAppointmentList);

          checkAvailableTime(therapistData?.workingHours);
        } else {
          return toast.error("Error retrieving appointment data", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        return toast.error("Invalid therapist data", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      return toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const eventModalToggle = () => {
    setDisabledCreateBtn(false);
    setIsEventModal(!isEventModal);
  };

  const eventTimeValidationModalToggle = () => {
    setEventTimeValidationViewModal(!isEventTimeValidationViewModal);
    setErrorModalText("");
  };

  const handleChangeComplete = (color: any) => {
    singleAppointment.color = color.hex;
  };

  const addEvent = (selectedDetails: any) => {
    try {
      const selectedDate = moment(selectedDetails.startStr).toISOString();

      const isInRange = allBlockDatesListInTherapist.some(range => {
        return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate));
      });

      if (isInRange) {
        return false;
      }

      if (selectedDetails) {
        if (therapistAvailableHours == null || !therapistAvailableHours || therapistAvailableHours.length == 0) {
          setEventOnHolidays(true);
          openCreateAppointmentPopup(selectedDetails);
        } else {
          setEventOnHolidays(false);
          const dayNumber = dayOfWeekAsNumber(moment(selectedDetails.start).format("dddd"));
          const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, therapistAvailableHours);

          let isAvailableTime = false;

          hoursSlots.map((slotArray: string[], i: number) => {
            if(slotArray.includes(moment(selectedDetails.start).format("H:mm A")) && slotArray.includes(moment(selectedDetails.end).format("H:mm A"))){
              isAvailableTime = true;
            }
          });

          if (!isAvailableTime) {
            Swal.fire({
              icon: "error",
              title: storedTherapistData.firstname + " " + storedTherapistData.lastname + " is not available during this time.",
              showCancelButton: false,
              confirmButtonText: "Okay",
              confirmButtonColor: "#f46a6a",
            });
          } else {
            openCreateAppointmentPopup(selectedDetails);
          }
        }
      }
    } catch (error) {
      return toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const openCreateAppointmentPopup = (selectedDetails: any) => {
    if (storedTherapistData?._id != undefined) {
      if (!selectedDetails.start) {
        return toast.error("Please select valid date.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      const updatedTomorrowDate: Date = moment(new Date()).add(1, "day").startOf("day").toDate();

      if (!moment(updatedTomorrowDate).isSameOrBefore(moment(selectedDetails.start))) {
        return toast.error(`Sorry! You can only create appointment starting from tomorrow!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }

      setTimeValue(selectedDetails.start.toString());
      setHourValue(moment(selectedDetails.start).hour());
      setMinuteValue(moment(selectedDetails.start).minute());

      setSingleAppointment({
        ...singleAppointment,
        date: moment(selectedDetails.start).format("YYYY/MM/DD HH:mm"),
        startTime: moment(selectedDetails.start).format("H:mm A"),
        endTime: moment(selectedDetails.start).add(60, "minutes").format("H:mm A"),
        selectedDate: moment(selectedDetails.startStr).toISOString(),
      });
      setDisabledCreateBtn(false);
      setIsEventModal(!isEventModal);
    }
  };

  const checkAvailableTime = (workingHoursOfTherapist: WorkingHour[] | undefined) => {
    try {
      const availableDays: { startTime: any; endTime: any; daysOfWeek: any[] }[] = [];

      if (workingHoursOfTherapist?.length) {
        workingHoursOfTherapist?.map((obj: any, i: number) => {
          const dayAsNumber = dayOfWeekAsNumber(obj.day);

          if (Util.convertUTCDateToLocalDate(obj.endTime) > Util.convertUTCDateToLocalDate(obj.startTime)) {
            return availableDays.push({
              startTime: Util.convertUTCDateToLocalDate(obj.startTime),
              endTime: Util.convertUTCDateToLocalDate(obj.endTime),
              daysOfWeek: [dayAsNumber],
            });
          } else {
            availableDays.push({
              startTime: Util.convertUTCDateToLocalDate(obj.startTime),
              endTime: "24:00 AM",
              daysOfWeek: [dayAsNumber - 1],
            });

            return availableDays.push({
              startTime: "00:00 AM",
              endTime: Util.convertUTCDateToLocalDate(obj.endTime),
              daysOfWeek: [dayAsNumber],
            });
          }
        });
      } else {
        availableDays.push({
          startTime: "00:00 AM",
          endTime: "00:00 PM",
          daysOfWeek: [10],
        });
      }

      setTherapistAvailableHours(availableDays);

      setIsLoading(false);
    } catch (error) {
      return toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const createAppointment = async () => {
    try {
      if (storedTherapistData && storedTherapistData._id && allBlockDatesListInTherapist) {
        setDisabledCreateBtn(true);

        if (!singleAppointment.selectedDate) {
          setDisabledCreateBtn(false);
          return toast.error("Please select valid date.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        const selectedDate = moment(singleAppointment.selectedDate);

        // start
        const startTNew = moment(selectedDate);

        const endTNew = moment(startTNew).add(60, "minutes");

        const updatedTomorrowDate: Date = moment(new Date()).add(1, "day").startOf("day").toDate();

        if (!moment(updatedTomorrowDate).isSameOrBefore(moment(startTNew))) {
          setDisabledCreateBtn(false);
          return toast.error(`Sorry! You can only create appointment starting from tomorrow!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        if (therapistAvailableHours == null || !therapistAvailableHours || therapistAvailableHours.length == 0) {
          setEventOnHolidays(true);
        } else {
          setEventOnHolidays(false);
          const dayNumber = dayOfWeekAsNumber(moment(startTNew).format("dddd"));
          const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, therapistAvailableHours);

          let isAvailableTime = false;

          hoursSlots.map((slotArray: string[], i: number) => {
            if(slotArray.includes(moment(startTNew).format("H:mm A")) && slotArray.includes(moment(endTNew).format("H:mm A"))){
              isAvailableTime = true;
            }
          });

          if (!isAvailableTime) {
            setDisabledCreateBtn(false);
            return Swal.fire({
              icon: "error",
              title: storedTherapistData.firstname + " " + storedTherapistData.lastname + " is not available during this time.",
              showCancelButton: false,
              confirmButtonText: "Okay",
              confirmButtonColor: "#f46a6a",
            });
          }
        }

        // end

        if (selectedDate.minutes() != 0 && selectedDate.minutes() != 30) {
          setErrorModalText("You have selected invalid time.");
          setDisabledCreateBtn(false);
          return setEventTimeValidationViewModal(true);
        }

        const isInRange = allBlockDatesListInTherapist.some((range: any) => {
          return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate.toISOString()));
        });

        if (isInRange) {
          setErrorModalText("Sorry! Therapist `" + storedTherapistData.firstname + " " + storedTherapistData.lastname + "` has blocked this date.");
          setDisabledCreateBtn(false);
          return setEventTimeValidationViewModal(true);
        }

        // validate

        const appointmentAlreadyExists = allAppointmentListInTherapist.some((range: any) => {
          return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate.toISOString()));
        });

        if (appointmentAlreadyExists) {
          setErrorModalText("Therapist has already scheduled an appointment during the selected time slot.");
          setDisabledCreateBtn(false);
          return setEventTimeValidationViewModal(true);
        }

        // validate

        AppointmentService.getAllAppointmentAndDetailsByUserIdPublic(
          Util.calculateWeekNumberAndDatesPublic(singleAppointment.date, storedTherapistData?._id)
        ).then(res => {
          if (res.success) {
            const timeStart: any = new Date(singleAppointment.date).setHours(
              parseInt(singleAppointment.startTime.split(":")[0]),
              parseInt(singleAppointment.startTime.split(":")[1]),
              0,
              0
            );

            const timeEnd: any = new Date(singleAppointment.date).setHours(
              parseInt(singleAppointment.endTime.split(":")[0]),
              parseInt(singleAppointment.endTime.split(":")[1]),
              0,
              0
            );

            let humanTime;

            if (timeStart > timeEnd) {
              const tStart1: any = new Date(singleAppointment.date).setHours(
                parseInt(singleAppointment.startTime.split(":")[0]),
                parseInt(singleAppointment.startTime.split(":")[1]),
                0,
                0
              );

              const tEnd1: any = new Date(singleAppointment.date).setHours(24, 0, 0, 0);

              const ht1 = Math.abs(tEnd1 - tStart1) / 60000;

              const tStart2: any = new Date(singleAppointment.date).setHours(0, 0, 0, 0);

              const tEnd2: any = new Date(singleAppointment.date).setHours(
                parseInt(singleAppointment.endTime.split(":")[0]),
                parseInt(singleAppointment.endTime.split(":")[1]),
                0,
                0
              );

              const ht2 = Math.abs(tEnd2 - tStart2) / 60000;

              humanTime = ht1 + ht2;
            } else {
              humanTime = Math.abs(timeStart - timeEnd) / 60000;
            }

            if (humanTime > 60) {
              setErrorModalText("You can create only 1 hour sessions.");
              setDisabledCreateBtn(false);
              return setEventTimeValidationViewModal(true);
            }

            if (!singleAppointment.startTime) {
              setDisabledCreateBtn(false);
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            const mST = moment(singleAppointment.startTime, "HH:mm").minute();

            if (mST != 0 && mST != 30) {
              setDisabledCreateBtn(false);
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!singleAppointment.date) {
              setDisabledCreateBtn(false);
              return toast.error("Please select valid date.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            const updatedTomorrowDate: Date = moment(new Date()).add(1, "day").startOf("day").toDate();

            if (!moment(updatedTomorrowDate).isSameOrBefore(singleAppointment.date)) {
              setDisabledCreateBtn(false);
              return toast.error(`Sorry! You can only create appointment starting from tomorrow!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!singleAppointment.title) {
              setDisabledCreateBtn(false);
              return toast.error(`Please add title.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!singleAppointment.repeat) {
              setDisabledCreateBtn(false);
              return toast.error(`Pleas select repeat type.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!haveRead && storedTherapistData.disclosureStatementId) {
              setDisabledCreateBtn(false);
              return toast.error(`You must read disclosure statement & accept before creating appointment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!haveAgreed) {
              setDisabledCreateBtn(false);
              return toast.error(`You must agree to the terms & conditions of Lavni Inc. before creating appointment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!haveAgreedPolicy) {
              setDisabledCreateBtn(false);
              return toast.error(`You must agree to the No Show and Cancellation Policy of Lavni Inc. before creating appointment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            const appointmentObj = {
              therapistId: storedTherapistData._id,
              start: new Date(
                new Date(singleAppointment.date).setHours(
                  parseInt(singleAppointment.startTime.split(":")[0]),
                  parseInt(singleAppointment.startTime.split(":")[1]),
                  0,
                  0
                )
              ),
              end: new Date(
                new Date(singleAppointment.date).setHours(
                  parseInt(singleAppointment.endTime.split(":")[0]),
                  parseInt(singleAppointment.endTime.split(":")[1]),
                  0,
                  0
                )
              ),
              reminders: [30],
              title: singleAppointment.title,
              repeatInfo: {
                repeatType: singleAppointment.repeat,
                interval: "",
                repeatDays: {
                  sunday: false,
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                  saturday: false,
                },
                endingDate: Util.monthsNextFrom(new Date(singleAppointment.date), 1),
                endingAfter: 10,
                endingType: "",
              },
              color: singleAppointment.color,
              groupId:
                "_" +
                Util.dateConvertToMilisecond(
                  new Date(
                    new Date(singleAppointment.date).setHours(
                      parseInt(singleAppointment.startTime.split(":")[0]),
                      parseInt(singleAppointment.startTime.split(":")[1]),
                      0,
                      0
                    )
                  )
                ),
              eventOnHolidays: eventOnHolidays,
            };

            if (appointmentObj) {
              localStorage.setItem("appointmentObj", JSON.stringify(appointmentObj));

              // setAppointments((prevAppointments: any) => [...prevAppointments, appointmentObj]);

              setHaveRead(false);
              setHaveAgreed(false);
              setHaveAgreedPolicy(false);
              setIsEventModal(false);
              setEventOnHolidays(false);
              setDisabledCreateBtn(false);

              Swal.fire({
                icon: "success",
                title: "Your appointment is scheduled successfully & Waiting for Therapist's approval.",
                confirmButtonText: "Okay",
                confirmButtonColor: "#00a67d",
              }).then(result => {
                if (result.isConfirmed) {
                  localStorage.setItem("skip", "false");
                  history.push("/public-signup");
                }
              });
            }
            setDisabledCreateBtn(false);
          } else {
            setDisabledCreateBtn(false);
            return toast.error(`Error retrieving appointment details !`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      } else {
        setErrorModalText("No valid therapist is selected.");
        setDisabledCreateBtn(false);
        return false;
      }
    } catch (error) {
      setDisabledCreateBtn(false);
      return toast.error(`Something went wrong !`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const dayOfWeekAsNumber = (day: string) => {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
  };

  const [showNotApproved, setShowNotApproved] = useState(false);
  const [showNotAfriend, setShowNotAfriend] = useState(false);

  const hideAndShow = () => {
    setIsView(!isView);
  };

  const selectAnotherMonthAndRefreshAppointmentList = (selectedMonthIn: Date) => {
    try {
      if (storedTherapistData && storedTherapistData._id) {
        setIsLoadingNextMonth(true);

        setArraysOnlyForSelectedMonth(selectedMonthIn, allBlockDatesListInTherapist, allAppointmentListInTherapist);

        setSelectedMonth(selectedMonthIn);

        setIsLoadingNextMonth(false);
      }
    } catch (error) {
      setIsLoadingNextMonth(false);
      toast.error(`Something went wrong`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  function setArraysOnlyForSelectedMonth(monthForCheckFrom: Date, allBlockedSlotsFrom: any[] | undefined, allAppointmentsFrom: any[] | undefined) {
    const monthForCheck = moment(monthForCheckFrom).month();
    const yearForCheck = moment(monthForCheckFrom).year();

    if (allBlockedSlotsFrom) {
      const filteredBlockedDatesForThisMonth = allBlockedSlotsFrom.filter(item => {
        const startMonth = moment(item.start).month();
        const startYear = moment(item.start).year();
        const endMonth = moment(item.end).month();
        const endYear = moment(item.end).year();

        return (startMonth === monthForCheck && startYear === yearForCheck) || (endMonth === monthForCheck && endYear === yearForCheck);
      });

      setBlockDatesListForSelectedMonth(filteredBlockedDatesForThisMonth);
    }

    if (allAppointmentsFrom) {
      const filteredAppointmentsForThisMonth = allAppointmentsFrom.filter(item => {
        const startMonth = moment(item.start).month();
        const startYear = moment(item.start).year();
        const endMonth = moment(item.end).month();
        const endYear = moment(item.end).year();

        return (startMonth === monthForCheck && startYear === yearForCheck) || (endMonth === monthForCheck && endYear === yearForCheck);
      });
      setAppointmentListForSelectedMonth(filteredAppointmentsForThisMonth);
    }
  }

  return (
    <>
      <div className="content-wrapper ">
        <HeaderMain />
        <section
          className="bg-image-set wrapper image-wrapper bg-image bg-overlays bg-overlay-light-600 text-white"
          data-image-src="./../static/assets/img/photos/bg3.jpg"
        >
          <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center"></div>
        </section>

        <section className="wrapper bg-light2 angled upper-end" id="recoverform">
          <div className="container1 pb-14 pb-md-16">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222 md-n576">
                <div className="displaycenter">
                  <div className="card cardChange">
                    <div className="card-body text-center p-4">
                      <Row>
                        <Col xl="12" sm="12">
                          <form className="text-start mb-3 needs-validation">
                            <Row>
                              {showNotAfriend && <Modal18 setShowModal={setShowNotAfriend} showModal={showNotAfriend} />}
                              {showNotApproved && <Modal17 setShowModal={setShowNotApproved} showModal={showNotApproved} />}
                              {showAlreadyCompleted && <Modal13 setShowModal={setShowAlreadyCompleted} showModal={showAlreadyCompleted} />}
                              {showExpired && <Modal12 setShowModal={setShowExpired} showModal={showExpired} />}
                              {showTooEarly && <Modal11 setShowModal={setShowTooEarly} showModal={showTooEarly} />}
                              {appointmentUpdated && <Modal15 setShowModal={setAppointmentUpdated} showModal={appointmentUpdated} />}
                              {showModal && (
                                <MessageModal
                                  setShowModal={setShowModal}
                                  showModal={showModal}
                                  message={"Please subscribe to a provided plan to access this feature."}
                                />
                              )}
                              {showModalExceeded && <Modal5 setShowModal={setShowModalExceeded} showModal={showModalExceeded} />}

                              <div>
                                <Container fluid={true}>
                                  <div className="button-with-breadcrumb">
                                    {isMobile && (
                                      <button className="btn cus-padding" onClick={() => history.push("/main")}>
                                        <i className="bx bx-left-arrow-alt"></i>
                                      </button>
                                    )}
                                    <Breadcrumbs title="Lavni" breadcrumbItem="Calendar" />
                                  </div>
                                  {isLoading && (
                                    <div className="flex justify-content-center mt-5">
                                      <img src={images.cubicLoader} style={{ width: "90px" }} />
                                    </div>
                                  )}

                                  {!isLoading && (
                                    <>
                                      {!isMobile && (
                                        <div id="targetSection" className="row">
                                          <div className="col-xl-12 mb-3 d-flex float-left jestifyStart">
                                            <span>
                                              <button className="btn btn-skip float-left" onClick={() => history.push("/main")}>
                                                <i className="bx bx-left-arrow-alt"></i> &nbsp; Back
                                              </button>
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      <Row>
                                        <Col lg={3}>
                                          <div id="external-events">
                                            {storedTherapistData?._id && (
                                              <>
                                                <Label className="mb-2 d-flex j-btwn">
                                                  <span> Therapist</span>
                                                  {isView ? (
                                                    <i className="bx bxs-down-arrow mr-2 more-icon" onClick={hideAndShow}></i>
                                                  ) : (
                                                    <i className="bx bxs-up-arrow mr-2 more-icon" onClick={hideAndShow}></i>
                                                  )}
                                                </Label>

                                                <Card className={!isView ? `view-therapist-card` : "hide-therapist-card"}>
                                                  <div className="col-xl-12">
                                                    <div className="row">
                                                      <div className="col-xl-12">
                                                        <Row>
                                                          <Col>
                                                            <div
                                                              className="profileImage-appoint imageFit BorderRadiusLeftRight"
                                                              style={{
                                                                backgroundImage:
                                                                  storedTherapistData?.coverPhotoId == null || storedTherapistData?.coverPhotoId == undefined
                                                                    ? `url(${profileBackground})`
                                                                    : `url("${Util.fileURL(storedTherapistData?.coverPhotoId)}")`,
                                                              }}
                                                            ></div>

                                                            <Row>
                                                              <Col xl={4} lg={4}>
                                                                <div className="avatar-md profile-user-wid mb-4">
                                                                  <div
                                                                    className="img-thumbnail avatar-md profileImageShow rounded-circle imageFit mr-profile"
                                                                    style={{
                                                                      backgroundImage:
                                                                        storedTherapistData?.photoId == null || storedTherapistData?.photoId == undefined
                                                                          ? `url(${user1})`
                                                                          : `url("${Util.fileURL(storedTherapistData?.photoId)}")`,
                                                                      borderRadius: "10px",
                                                                      position: "relative",
                                                                    }}
                                                                  ></div>
                                                                </div>
                                                              </Col>

                                                              <Col xl={8} lg={8}>
                                                                {storedTherapistData?.role == "THERAPIST" && (
                                                                  <span className="appointment-profile-name2">
                                                                    {storedTherapistData?.firstname} {storedTherapistData?.lastname}
                                                                  </span>
                                                                )}
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        </Row>
                                                        <Row>
                                                          <Col xl={12}>
                                                            <div className="working-hours">
                                                              <Label className="px-2">
                                                                <i className="bx bx-time-five"></i> &nbsp;Working Hours:
                                                              </Label>
                                                              <div>
                                                                {storedTherapistData?.workingHours &&
                                                                  storedTherapistData?.workingHours.map((opt: any, index: any) => {
                                                                    return (
                                                                      <Label className="flex-wrap -mx-2 md:space-y-0 mt-2 px-2" key={index}>
                                                                        {opt.day} {opt.day && "|"} {Util.convertUTCDateToLocalDate(opt.startTime)} -
                                                                        {Util.convertUTCDateToLocalDate(opt.endTime)}
                                                                      </Label>
                                                                    );
                                                                  })}
                                                                {!storedTherapistData?.workingHours?.length && <p>No working hours added.</p>}
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Card>
                                              </>
                                            )}
                                          </div>
                                        </Col>
                                        <Col lg={9} sm={12}>
                                          <PublicAppointmentCalendar
                                            availableSlotsFromNew={therapistAvailableHours}
                                            prevAppointmentsNew={appointmentListForSelectedMonth}
                                            prevBlockedDatesListNew={blockDatesListForSelectedMonth}
                                            addEvent={addEvent}
                                            selectedMonthFrom={selectedMonth}
                                            selectMonth={selectAnotherMonthAndRefreshAppointmentList}
                                            isLoadingNextMonth={isLoadingNextMonth}
                                          />
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </Container>
                              </div>

                              {showErrorModal && (
                                <MessageModal
                                  showModal={showErrorModal}
                                  setShowModal={setShowErrorModal}
                                  message={"Please subscribe to a provided plan to access this feature."}
                                />
                              )}

                              <Modal isOpen={isEventModal} centered toggle={eventModalToggle} unmountOnClose={true}>
                                <ModalHeader toggle={eventModalToggle}>
                                  <div className="title-input">
                                    <FormGroup>
                                      <Input
                                        className="modal-title new-input-v"
                                        name="title"
                                        type="text"
                                        placeholder="Add title - Optional"
                                        onChange={e => setSingleAppointment({ ...singleAppointment, title: e.target.value })}
                                        value={singleAppointment.title}
                                      />
                                      <span className="awsome_input_border" />
                                    </FormGroup>
                                  </div>
                                </ModalHeader>

                                <ModalBody className="awsome-area">
                                  <Row className="mb-4">
                                    <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                                      <i className="bx bx-time-five"></i>
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs={12}>
                                      <Row>
                                        <Col lg={4} md={4} sm={4} xs={6} className="sm-mb5">
                                          <DatePicker
                                            minDate={tomorrowDate}
                                            value={moment(singleAppointment?.date).toDate()}
                                            onChange={(date: Date) => {
                                              const selectedTime = moment(date);

                                              selectedTime.hour(hourValue).minute(minuteValue);

                                              const stateT = moment(selectedTime).format("H:mm A");
                                              const endT = moment(selectedTime).add(60, "minutes").format("H:mm A");
                                              const sDate = moment(selectedTime).toISOString();

                                              setSingleAppointment({
                                                ...singleAppointment,
                                                startTime: stateT,
                                                endTime: endT,
                                                selectedDate: sDate,
                                                date: selectedTime.format("YYYY/MM/DD"),
                                              });
                                            }}
                                          />
                                        </Col>

                                        <Col lg={8} md={8} sm={8} xs={6}>
                                          <CustomTimePickerForPublicAppointment
                                            timeValue={timeValue}
                                            setTimeValue={setTimeValue}
                                            appointment={singleAppointment}
                                            setAppointment={setSingleAppointment}
                                            setHourValue={setHourValue}
                                            setMinuteValue={setMinuteValue}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Row className="mb-4">
                                    <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                                      <i className="bx bx-video"></i>
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs={12}>
                                      Video - 1 Hour Session
                                    </Col>
                                  </Row>

                                  <Row className="mb-4">
                                    <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                                      <i className={Util.meetingRepeatTypeIcon(singleAppointment.repeat)}></i>
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs={12}>
                                      <Input
                                        type="select"
                                        name="repeat"
                                        onChange={e => setSingleAppointment({ ...singleAppointment, repeat: e.target.value as Repeat })}
                                        value={singleAppointment.repeat}
                                      >
                                        <option defaultChecked value={Repeat.DOES_NOT_REPEAT}>
                                          Does Not Repeat
                                        </option>
                                        <option value={Repeat.WEEKLY}>Weekly</option>
                                      </Input>
                                    </Col>
                                  </Row>

                                  <Row className="mb-4">
                                    <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                                      <i className="bx bx-palette"></i>
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs={12}>
                                      <TwitterPicker width="100%" onChangeComplete={handleChangeComplete} color={singleAppointment.color} />
                                    </Col>
                                  </Row>

                                  {storedTherapistData.disclosureStatementId && (
                                    <Row className="mb-4">
                                      <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
                                      <Col className="" lg={11} md={11} sm={11} xs={12}>
                                        <FormGroup check inline>
                                          <Input type="checkbox" onChange={(e: any) => setHaveRead(e.target.checked)} />
                                          <Label check>
                                            I have read the&nbsp;
                                            <Link to={{ pathname: Util.fileURL(storedTherapistData.disclosureStatementId) }} target="_blank">
                                              disclosure statement&nbsp;
                                            </Link>
                                            & Accept.
                                          </Label>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  )}

                                  <Row className="mb-4">
                                    <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
                                    <Col className="" lg={11} md={11} sm={11} xs={12}>
                                      <FormGroup check inline>
                                        <Input type="checkbox" onChange={(e: any) => setHaveAgreedPolicy(e.target.checked)} />
                                        <Label check>
                                          I agree to&nbsp;
                                          <a href="/noshow-policy" className="hover" target="_blank">
                                            No Show and Cancellation Policy&nbsp;
                                          </a>
                                          of Lavni Inc.
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row className="mb-4">
                                    <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
                                    <Col className="" lg={11} md={11} sm={11} xs={12}>
                                      <FormGroup check inline>
                                        <Input type="checkbox" onChange={(e: any) => setHaveAgreed(e.target.checked)} />
                                        <Label check>
                                          I agree to&nbsp;
                                          <a href="/consent-document" className="hover" target="_blank">
                                            Consent Document&nbsp;
                                          </a>
                                          of Lavni Inc.
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col className="cont-center" lg={1}></Col>
                                    <Col className="appoint-btn">
                                      <button
                                        className={disabledCreateBtn ? "session-btn updating" : "session-btn"}
                                        disabled={disabledCreateBtn}
                                        onClick={createAppointment}
                                      >
                                        Create
                                      </button>
                                    </Col>
                                  </Row>
                                </ModalBody>
                              </Modal>

                              <Modal isOpen={isEventTimeValidationViewModal} centered toggle={eventTimeValidationModalToggle}>
                                <ModalBody>
                                  <div className="modal-val">
                                    <h5 className="model-error">{errorModalText}</h5>

                                    <button className="btn btn-sm text-center btn-success mt-3" onClick={() => eventTimeValidationModalToggle()}>
                                      Got It
                                    </button>
                                  </div>
                                </ModalBody>
                              </Modal>
                            </Row>
                          </form>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="overflow-hidden">
          <div className="divider text-navy mx-n2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" style={{ color: "#343f52" }}>
              <path
                fill="currentColor"
                d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z"
              />
            </svg>
          </div>
        </div>
        <FooterMain />
      </div>
    </>
  );
};

export default PublicAppointments;
