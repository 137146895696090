import React, { useEffect, useRef, useState, useContext } from "react";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import AiIcon from "../../assets/images/icons/AI_animation_logo.gif";
import editIcon from "../../assets/images/icons/fi_edit.png";
import moment from "moment";
import AIGeneratedNote from "../Chat/DiagnosisNote/AIGenratedNote";
import deleteIcon from "../../assets/images/icons8-delete.svg";
import Swal from "sweetalert2";
import { VideoChatService } from "src/services/VideoChatService";
import { toast } from "react-toastify";
import UserContext from "src/context/UserContext";
import { AINotesType } from 'src/models/Therapist';
import  NoteTypeSelectModal  from 'src/pages/Popup/NoteTypeSelectionModal';
import AIGeneratePieNoteNew from './../Chat/DiagnosisNote/AIGeneratePieNoteNew';

interface completedSessionsProps {
  transcribeData: any;
  selectedTreatmentHistoryForMerge: any;
  setSelectedTreatmentHistoryForMerge: any;
  setSelectedClient: any;
  showDiagnosisFunction: (diagnosisNoteId: string, totalSpentDuration: number, noteType: any, isVersion: boolean) => void;
  isCreating: boolean;
  showAudioFunction: (audioId: string, showAudio: boolean) => void;
  updateDiagnosisData: (isUpdate: boolean) => void;
  saveNewNote: (transcribeData: any, noteType: string) => void;
}
const SessionCard: React.FC<completedSessionsProps> = ({
  transcribeData,
  selectedTreatmentHistoryForMerge,
  setSelectedTreatmentHistoryForMerge,
  setSelectedClient,
  showDiagnosisFunction,
  isCreating,
  showAudioFunction,
  updateDiagnosisData,
  saveNewNote
}) => {
  const [user, setUser] = useContext(UserContext);
  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState<boolean>(false);
  const mounted = useRef(false);

  const [showTypeSelectModal, setShowTypeSelectModal] = useState(false);
  const [showPIENote, setShowPIENote] = useState(false);

  const [showTypeSelectModalForViewEdit, setShowTypeSelectModalForViewEdit] = useState(false);

  useEffect(() => {
    setSelectedClient(transcribeData.clientId);

    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleClickedAiGenerate = (boolean: boolean) => {

    // setShowAiGeneratedPopup(boolean);

    const noteType = transcribeData?.diagnosisNoteDetails?.noteType;
    const userNoteType = user?.aiNotesType
    if(noteType) {
       if(noteType === 'PIE') {
          setShowPIENote(true);
       } else {
          setShowAiGeneratedPopup(true);
       }

    } else if(!noteType && userNoteType) {
       if(userNoteType=== 'PIE') {
          setShowPIENote(true);
       } else {
          setShowAiGeneratedPopup(true);
       }
    } else {
      setShowTypeSelectModal(true);
    }


  };
  const handleChildFunction = (isCalled: boolean) => {
    setShowAiGeneratedPopup(isCalled);
  };

  const saveNote = (transcribeData: any, noteType: string) => {
    saveNewNote(transcribeData, noteType);
  };

  const handleCheckBoxChange = (e: any) => {
    const id: string = e.target.name;

    if (selectedTreatmentHistoryForMerge.includes(id)) {
      if (!e.target.value) {
        setSelectedTreatmentHistoryForMerge((prev: any[]) => {
          return [...prev.filter((item, i) => item !== id)];
        });
      }
    } else {
      setSelectedTreatmentHistoryForMerge([...selectedTreatmentHistoryForMerge, id]);
    }
  };

  const deleteDiagnosisNoteCode = (e: any) => {
    const diagnosisNoteId = e.currentTarget.getAttribute("data-value1");
    Swal.fire({
      icon: "info",
      title: "Are you sure want to delete this treatment history?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#50a5f1",
      cancelButtonText: "No",
      cancelButtonColor: "#f46a6a",
    }).then(result => {
      if (result.isConfirmed) {
        const data = {
          noteId: diagnosisNoteId,
        };
        VideoChatService.deleteTreatmentHistoryByTherapist(data).then(res => {
          if (res.success) {
            updateDiagnosisData(true);
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: 'foo-bar'
            });
          } else {
            toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: 'foo-bar'
            });
          }
        });
      }
    });
  }


  //start

  const openDiagnosisNoteInitial= () => {

    const noteDetails = transcribeData?.diagnosisNoteDetails;


    if (!noteDetails?.noteType && noteDetails?.updatedByTherapist) {

      openDiagnosisNote(AINotesType.SOAP);
    } else if (!user?.aiNotesType && noteDetails?.noteType) {

      openDiagnosisNote(noteDetails?.noteType);
    } else if (user?.aiNotesType && noteDetails?.noteType) {

      openDiagnosisNote(noteDetails?.noteType);
    } else if(!noteDetails?.noteType && user?.aiNotesType) {

      openDiagnosisNote(user?.aiNotesType);
    } else {
      setShowTypeSelectModalForViewEdit(true)
    }

  };

  const openDiagnosisNote= (noteTypeFrom: any) => {

    if (transcribeData.isMeetingTranscribe) {
      let totalSpentDuration = 0;

      if (transcribeData.mergedMeetings && transcribeData.mergedMeetings.length > 0) {
          transcribeData.mergedMeetings.map((v: any) => {
          totalSpentDuration = totalSpentDuration + v.spentDuration;
        });
      } else {
        totalSpentDuration = transcribeData.meetingId.spentDuration;
      }

      showDiagnosisFunction(transcribeData.diagnosisNoteId, totalSpentDuration, noteTypeFrom, false);

    }

  };

  const slectTypeFunction = (type: string, showModal: boolean) => {
    setShowTypeSelectModal(showModal);

    if(type && (type == AINotesType.PIE || type == AINotesType.SOAP)){

      if(type == AINotesType.SOAP){
        setShowAiGeneratedPopup(true);
      }else{
        setShowPIENote(true);
      }
    }
  };

   const slectTypeFunctionForViewEdit = (type: string, showModal: boolean) => {
    setShowTypeSelectModalForViewEdit(showModal);

    if(type && (type == AINotesType.PIE || type == AINotesType.SOAP)){
      openDiagnosisNote(type);
    }
  };

  const closePIENoteFunction = (value: boolean) => {
    setShowPIENote(value);
  };
  //end

  return (
    <React.Fragment>
       {showTypeSelectModal && <NoteTypeSelectModal setShowModal={slectTypeFunction} showModal={showTypeSelectModal} />}
      {showTypeSelectModalForViewEdit && <NoteTypeSelectModal setShowModal={slectTypeFunctionForViewEdit} showModal={showTypeSelectModalForViewEdit} />}
      {/* {showAiGeneratedPopup && <AIGeneratedNote OpenAiModel={showAiGeneratedPopup} transcribeData={transcribeData} closeModelFunction={handleChildFunction} saveNote={saveNote} />} */}

      <Card className="bg-light-orange">
        <CardBody>
          <div className="row">
            <div className="col-5">
              {transcribeData?.meetingId?.appointmentId ? (
                <>
                  <h5 className="fs-13 fc-black">Appointment Start Time</h5>
                  <h5 className="fs-13 fc-primary">

                    {`${moment(transcribeData?.appointmentDetails?.start).format("YYYY-MM-DD")}`} - {`${moment(transcribeData?.appointmentDetails?.start).format("h:mm:ss a")}`}
                  </h5>
                </>
              ) : (
                <>
                  <h5 className="fs-13 fc-black">Meeting Started At</h5>

                  <h5 className="fs-13 fc-primary">

                    {`${moment(transcribeData?.meetingStartedTime).format("YYYY-MM-DD")}`} - {`${moment(transcribeData?.meetingStartedTime).format("h:mm:ss a")}`}
                  </h5>
                </>
              )}

              <h5 className="fs-13 fc-black">

                <span className="fc-primary">
                  {transcribeData?.clientId?.firstname}&nbsp;{transcribeData?.clientId?.lastname}
                </span>
              </h5>

              {
                transcribeData?.meetingId.callingStatus &&

                <h5 className="font-size-11">
                  Calling Status&nbsp;

                  <span className={transcribeData?.meetingId.callingStatus + " session-h5"}>
                    {transcribeData?.meetingId.callingStatus}
                  </span>
                </h5>
              }
            </div>

            <div className="col-7  left-border-discover ">
              <div className="d-flex justify-content-end">
                <div className=" d-flex justify-content-center">
                  {((transcribeData.therapistId._id == "62f17eb1b81f31a68505d9ad" && transcribeData?.meetingId.callingStatus == "COMPLETED")
                    || (transcribeData?.meetingId?.clientAllowedTranscribe && transcribeData?.diagnosisNoteDetails?.updatedByTherapist == false && transcribeData?.meetingId?.therapistAllowedTranscribe == true && transcribeData?.meetingId?.firstSpeaker && transcribeData?.meetingId?.transcribeCreated)) && (
                    <>
                      <button id="Imagetooltip2" className="btn btn-secondary btn-black1 convert-pending-note  d-flex justify-content-center flex-wrap" onClick={() => handleClickedAiGenerate(true)}>
                        <img src={AiIcon} className="ai-icon-size " />
                      </button>

                      <UncontrolledTooltip placement="bottom" target="Imagetooltip2">
                        Generate Note
                      </UncontrolledTooltip>
                    </>
                  )}
                </div>

                <div className=" d-flex justify-content-center me-2 ms-2">
                  {transcribeData.isMeetingTranscribe && transcribeData?.meetingId?.callingStatus == "COMPLETED" && (
                    <>
                      <button
                        className="btn btn-primary-edit p-c-7 "
                        id="Imagetooltip3"
                        onClick={() => {
                          // if (transcribeData.isMeetingTranscribe) {
                          //   let totalSpentDuration = 0;

                          //   if (transcribeData.mergedMeetings && transcribeData.mergedMeetings.length > 0) {
                          //     transcribeData.mergedMeetings.map((v: any) => {
                          //       totalSpentDuration = totalSpentDuration + v.spentDuration;
                          //     });
                          //   } else {
                          //     totalSpentDuration = transcribeData.meetingId.spentDuration;
                          //   }

                          //   showDiagnosisFunction(transcribeData.diagnosisNoteId, totalSpentDuration);
                          // }
                          openDiagnosisNoteInitial()
                        }}
                      >
                        <img src={editIcon} className="ai-icon-size " />
                      </button>

                      <UncontrolledTooltip placement="bottom" target="Imagetooltip3">
                        Update Note
                      </UncontrolledTooltip>
                    </>
                  )
                  }
                </div>

                {transcribeData?.diagnosisNoteDetails?.updatedByTherapist == false && (
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-danger p-c-7  "
                      id="Imagetooltip4"
                      onClick={deleteDiagnosisNoteCode} data-value1={transcribeData._id}
                    >
                      <img src={deleteIcon} className="ai-icon-size " />
                    </button>
                    <UncontrolledTooltip placement="bottom" target="Imagetooltip4">
                      Delete Session
                    </UncontrolledTooltip>
                  </div>
                )}



              </div>


            </div>
               {!transcribeData?.meetingId?.transcribeCreated  && transcribeData?.meetingId?.s3AudioPath && (
                   <h5 className="font-size-15 py-1 text-start">
                     <span className='text-success'>Transcribe will be generated within two minutes.</span>
                   </h5>
              )}
          </div>

      {showAiGeneratedPopup && (
        <AIGeneratedNote
          OpenAiModel={showAiGeneratedPopup}
          transcribeData={transcribeData}
          closeModelFunction={handleChildFunction}
          saveNote={saveNote}
        />
      )}

      {showPIENote && (
        <AIGeneratePieNoteNew
          OpenAiModel={showPIENote}
          transcribeData={transcribeData}
          closeModelFunction={closePIENoteFunction}
          saveNote={saveNote}

        />
      )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SessionCard;
