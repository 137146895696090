import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, CardTitle, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, UncontrolledTooltip } from "reactstrap";
import arrowIcon from "../../assets/images/icons/fi_arrow-right-circle.svg";
import NoUpcommingIcon from "../../assets/images/icons/no_upcomming.svg";
import UserContext from "src/context/UserContext";
import { Role } from "src/models/Role";
import { CallInitializeData } from "src/models/CallInitializeData";
import CallInitializationContext from "src/context/CallInitializationContext";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";

interface upcomingGroupCallProps {
  upcomingGroupCalls: any[];
  isLoading: boolean;
}

const UpComingGroupCall: React.FC<upcomingGroupCallProps> = ({ upcomingGroupCalls, isLoading }) => {
  const [groupCall, setGroupCall] = useState<any>();
  const [isRight, setIsRight] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const history = useHistory();

  useEffect(() => {
    console.log(upcomingGroupCalls);
  }, []);

  const setValueGroupCall = (groupCall: any) => {
    setGroupCall(groupCall);
  };

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const startVideoCall = () => {
    if (user?.role == Role.THERAPIST) {
      const callInitializeData: CallInitializeData = {
        start: true,
        isInstantMeeting: true,
        isAppointmentBased: false,
        appointmentId: "ddddddd",
        recieverId: "",
        meetingTime: 60,
        isTranscribeAllowed: true,
        meetingId: "",
        isAudioCall: false,
        isGroupCall: true,
        groupCallId: groupCall?.groupCallId,
      };

      // setLocalCallInitialize(true);

      setLocalCallInitializeData(callInitializeData);
      // setOutGoinCallTimeOutFunction();
      const roomNamePartOne = user?._id;
      const roomNamePartTwo = groupCall?.groupId;
      const uniqueId = uuidv4();
      const roomName = roomNamePartOne + roomNamePartTwo + uniqueId;
      const vonageUrl = `/room-group/${roomName}`;
      history.push(vonageUrl);
    }
  };

  return (
    <React.Fragment>
      <Card className="p-2">
        <CardBody className="p-7-dashboard">
          <h5 className="mb-0 fs-14 fc-black mb-2">Upcoming Group Calls</h5>
          {upcomingGroupCalls.length > 0 ? (
            upcomingGroupCalls?.map((upcomingGroupCall: any, index: number) => (
              <Card
                key={index}
                className="appointment-card1 cursor-pointer"
                onClick={() => {
                  if (user?.role == Role.THERAPIST) {
                    toggleRightCanvas();
                    setValueGroupCall(upcomingGroupCall);
                  }
                }}
              >
                <CardBody className="p-10-dashboard">
                  <div className="d-flex justify-content-between  row">
                    <div className="flex col-6">
                      <span className="d-none d-sm-flex justify-content-center align-items-center">
                        <img className="icon-h-24" src={arrowIcon} />
                      </span>
                      <div className="d-flex justify-content-center align-items-center min-width-100">
                        <p className="mb-0 d-flex justify-content-center align-items-center ms-1 fc-black">{upcomingGroupCall?.groupName}</p>
                      </div>
                    </div>
                    <div className="left-border-discover col-6 d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center align-items-center min-width-100">
                        <p className="mb-0 d-flex justify-content-center align-items-center ms-1 fc-black">
                          {`${moment(upcomingGroupCall?.startTime).format("hh:mm A")}`} - {`${moment(upcomingGroupCall?.startTime).format("YYYY-MM-DD")}`}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))
          ) : (
            <div className="">
              <div className="container no-upcoming-card d-flex justify-content-center align-items-center">
                <div className="overlay-upcoming"></div>
                <img className="image-upcoming" src={NoUpcommingIcon} alt="Overlay Image" />
                <div className="text-upcoming">No Upcoming Appointments</div>
              </div>
            </div>
          )}

          <Offcanvas isOpen={isRight} direction="end" toggle={toggleRightCanvas} className={"appointment-sidebar"}>
            <OffcanvasHeader toggle={toggleRightCanvas}>
              <i className="fa fa-circle"></i> {groupCall?.groupName}
            </OffcanvasHeader>
            <OffcanvasBody>
              <div className="row mb-3">
                <div className="col-md-12">
                  <Label className="form-label">Group Name</Label>
                  <h5>{groupCall?.groupName}</h5>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <Label className="form-label">Session Type</Label>
                  <h5>Group Call</h5>
                </div>
              </div>
              <div className="text-center mt-4">
                <button
                  onClick={() => {
                    startVideoCall();
                  }}
                  className="btn btn-primary  btn-bg"
                >
                  Start Video Call
                </button>
              </div>
            </OffcanvasBody>
          </Offcanvas>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UpComingGroupCall;
