import React, { useContext, useEffect, useState } from "react";
import { CardTitle, Col, Row, Modal, CardBody, CardFooter } from "reactstrap";
import { Client } from "src/models/Client";
import { ClientService } from "src/services/ClientService";
import images from "src/assets/images";

const Modal29: React.FC<{ showModal: boolean; setShowModal: (value: boolean) => void; copaymentAmount: any; clientId: any; setCallModal: () => void; }> = props => {
  const [client, setClient] = useState<Client>({} as Client);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    ClientService.getClientByClientId(props.clientId).then(res => {
      setIsLoading(true)
      if (res.success) {
        setClient(res.data);
        setIsLoading(false)
      }
    });
  }, [props.clientId]);


  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} size="lg" centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <div className="notify-icon">
              <i className="fa fa-warning text-danger"></i>
            </div>
            {
              isLoading ? (
                <div className="flex justify-content-center mt-5">
                  <img src={images.cubicLoader} style={{ width: "90px" }} />
                </div>
              ) : (
                <>
                  <CardTitle className="mb-4 text-center font-size-20">Prior to joining the session, please note that {client?.firstname} {client?.lastname} has an outstanding copayment from their last session. We&apos;ll prompt them to settle this balance. Your patience and understanding are appreciated.</CardTitle>

                  <div className="flex justify-content-center">
                    <button type="button" className="btn btn-info mr-2" onClick={() => props.setCallModal()} >
                      Ok
                    </button>
                    <button type="button" onClick={() => props.setShowModal(false)} className="btn btn-primary">
                      Close
                    </button>
                  </div>
                </>
              )
            }
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default Modal29;
