import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ClinicalAssessmentPdfModal from './Popup/ClinicalAssessmentPdfModal';
import { TPType } from 'src/models/therapyPlan/therapyPlan';

const OldClinicalAssessmentPdf: React.FC = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(true);

  const params = new URLSearchParams(location.search);
  const identifierId = params.get('identifierId');
  const clinicalAssessmentType = (params.get('clinicalAssessmentType') as TPType) || TPType.ORIGINAL;

  if (!identifierId) {
    return <div>Missing identifierId parameter</div>;
  }

  return (
    <div>
      <ClinicalAssessmentPdfModal
        setShowModal={setShowModal}
        showModal={showModal}
        identifierId={identifierId}
        isAdmin={true}
        eventType="DOWNLOAD"
        insuranceDocApprovalId={null}
        setModalRefresher={null}
        clinicalAssessmentType={clinicalAssessmentType}
      />
    </div>
  );
};

export default OldClinicalAssessmentPdf;
