import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CallInitializationContext from "../../../../context/CallInitializationContext";
import LocalCallContext from "../../../../context/LocalCallContext";
import { SocketContext } from "../../../../context/ScoketContext";
import UserContext from "../../../../context/UserContext";
import { LocalCall } from "../../../../models/LocalCall";
import { Role } from "../../../../models/Role";
import { StartCallSocketData } from "../../../../models/startCallSocketData";
import { VideoChatService } from "../../../../services/VideoChatService";
import Modal4 from "../../../Popup/Modal4";
import Modal5 from "../../../Popup/Modal5";
import Modal18 from "../../../Popup/Modal18";
import Modal7 from "../../../Popup/Modal7";
import { CallInitializeData } from "../../../../models/CallInitializeData";
import zoomContext from "../context/ZoomContext";
import { AppResponse } from "../../../../models/Response";
import produce from "immer";
import ZoomMediaContext from "../context/ZoomMediaContext";
import ZoomVideo, { ConnectionState, ReconnectReason } from "@zoom/videosdk";
import CallEndContext from "../../../../context/CallEndContext";
import VideoCallControllScreen from "./VideoCallControllScreen";
import "./video_call_media.css";
import LocalCallTimeOutContext from "../../../../context/LocalCallTimeOutlContext";
import Modal9 from "../../../Popup/Modal9";
import { TimeOut } from "../../../../models/TimeOut";
import { RecordingClient } from "../types/zoomTypes";
import ZoomRecordingContext from "../context/ZoomRecordingContext";

// const mediaShape = {
//   audio: {
//     encode: false,
//     decode: false,
//   },
//   video: {
//     encode: false,
//     decode: false,
//   },
//   share: {
//     encode: false,
//     decode: false,
//   },
// };

// const mediaReducer = produce((draft, action) => {
//   switch (action.type) {
//     case "audio-encode": {
//       draft.audio.encode = action.payload;
//       break;
//     }
//     case "audio-decode": {
//       draft.audio.decode = action.payload;
//       break;
//     }
//     case "video-encode": {
//       draft.video.encode = action.payload;
//       break;
//     }
//     case "video-decode": {
//       draft.video.decode = action.payload;
//       break;
//     }
//     case "share-encode": {
//       draft.share.encode = action.payload;
//       break;
//     }
//     case "share-decode": {
//       draft.share.decode = action.payload;
//       break;
//     }
//     case "reset-media": {
//       Object.assign(draft, { ...mediaShape });
//       break;
//     }
//     default:
//       break;
//   }
// }, mediaShape);

// declare global {
//   interface Window {
//     webEndpoint: string | undefined;
//     zmClient: any | undefined;
//     mediaStream: any | undefined;
//     crossOriginIsolated: boolean;
//     ltClient: any | undefined;
//   }
// }

const VideoCallScreenNew: React.FC = () => {
  return <div></div>;

  // const history = useHistory();

  // let start: boolean;
  // let isAppointmentBased: boolean;
  // let appointmentId: string;
  // let recieverId: string;
  // let meetingTime: number;
  // const sessionIdleTimeoutMins = 15;

  // const [loggedUser] = useContext(UserContext);
  // const socket = useContext(SocketContext);
  // const zmClient = useContext(zoomContext);
  // const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  // const [localUserOnCall, setLocalUserOnCall] = useContext(LocalCallContext);

  // const [showModalForNavigationDetect, setShowModalForNavigationDetect] = useState(false);
  // const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  // const [showModalExceeded, setShowModalExceeded] = useState(false);
  // const [showNotAfriend, setShowNotAfriend] = useState(false);
  // const [isOnline, setNetwork] = useState(window.navigator.onLine);
  // const [isConnecting, setIsConnecting] = useState(true);
  // const [loadingText, setLoadingText] = useState("Joining");
  // const [errorHappened, setErrorHappened] = useState(true);
  // const [isFailover, setIsFailover] = useState<boolean>(false);
  // const [status, setStatus] = useState<string>("closed");
  // const [meetingIdFromResponse, setMeetingIdFromResponse] = useState<string>("");
  // const [signatureFromResponse, setSignatureFromResponse] = useState<string>("");
  // const [passwordFromResponse, setPasswordFromResponse] = useState<string>("");
  // const [nameFromResponse, setNameFromResponse] = useState<string>("");
  // const [mediaStream, setMediaStream] = useState<any | null>(null);
  // const [recordingClient, setRecordingClient] = useState<RecordingClient | null>(null);
  // const [startAudioCalled, setStartAudioCalled] = useState(false);

  // const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  // const [endCallValue] = useContext(CallEndContext);
  // const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);

  // const updateNetwork = () => {
  //   setNetwork(window.navigator.onLine);
  // };

  // useEffect(() => {
  //   initFunction();
  //   window.addEventListener("offline", updateNetwork);
  //   window.addEventListener("online", updateNetwork);
  //   return () => {
  //     console.log("destroy client");
  //     window.removeEventListener("offline", updateNetwork);
  //     window.removeEventListener("online", updateNetwork);
  //     ZoomVideo.destroyClient();
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isOnline && status == "connecting" && isFailover) {
  //     reJoinTosession();
  //   }
  // }, [isOnline]);

  // const [isInitial, setIsInitial] = useState(true);
  // const [callTimeOut, setCallTimeOut, setShowPopUp] = useContext(LocalCallTimeOutContext);
  // const [showExtendTime, setShowExtendTime] = useState(false);

  // useEffect(() => {
  //   if (!callTimeOut.isTimeOutRunning && !isInitial) {
  //     leaveMeeting();
  //   }

  //   if (callTimeOut.isTimeOutRunning && callTimeOut.showExtendPopup && loggedUser?.role == Role.THERAPIST && !isInitial) {
  //     setShowExtendTime(true);
  //   }
  // }, [callTimeOut]);

  // const extendMeeting = (time: number, showModal: boolean, extend: boolean) => {
  //   setShowExtendTime(showModal);

  //   if (extend) {
  //     if (callTimeOut.isTimeOutRunning && callTimeOut.showExtendPopup && loggedUser?.role == Role.THERAPIST) {
  //       setShowPopUp(time);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (localUserOnCall.onOngoingCall && endCallValue && !isInitial) {
  //     resetContextData();
  //     closeAllPopupsAndGoBack(false);
  //   }
  // }, [endCallValue]);

  // const leaveMeeting = () => {
  //   try {
  //     VideoChatService.cancellCall(meetingIdFromResponse, localCallInitializeData.isAppointmentBased, localCallInitializeData.appointmentId);
  //     resetContextData();
  //     closeAllPopupsAndGoBack(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // // End call
  // const reJoinTosession = async () => {
  //   try {
  //     await zmClient.join(meetingIdFromResponse, signatureFromResponse, nameFromResponse, passwordFromResponse, sessionIdleTimeoutMins);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const onConnectionChange = useCallback(
  //   (payload: any) => {
  //     try {
  //       if (payload.state === ConnectionState.Fail) {
  //         const { reason, subsessionName } = payload;
  //         if (reason === "connection error") {
  //           setLoadingText("Network connection lost, Please reload to connect!");
  //         }
  //       }

  //       if (payload.state === ConnectionState.Reconnecting) {
  //         setIsConnecting(true);

  //         setIsFailover(true);

  //         setStatus("connecting");
  //         const { reason, subsessionName } = payload;
  //         if (reason === ReconnectReason.Failover) {
  //           setLoadingText("Network connection lost, Please reload to connect!");
  //         } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
  //           setLoadingText(`Joining ${subsessionName}...`);
  //         } else if (reason === ReconnectReason.BackToMainSession) {
  //           setLoadingText("Returning to Main Session...");
  //         }
  //       } else if (payload.state === ConnectionState.Connected) {
  //         setStatus("connected");
  //         if (isFailover) {
  //           setIsConnecting(false);
  //           setIsFailover(false);
  //         }
  //         window.zmClient = zmClient;
  //         window.mediaStream = zmClient.getMediaStream();
  //       } else if (payload.state === ConnectionState.Closed) {
  //         setStatus("closed");
  //         dispatch({ type: "reset-media" });
  //         if (payload.reason === "ended by host") {
  //           toast.error("This meeting has been ended by host", {
  //             position: toast.POSITION.TOP_RIGHT,
  //             className: "foo-bar",
  //           });
  //         }
  //         resetContextData();
  //         closeAllPopupsAndGoBack(false);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  //   [isFailover, zmClient]
  // );

  // const onMediaSDKChange = useCallback((payload: any) => {
  //   const { action, type, result } = payload;

  //   dispatch({ type: `${type}-${action}`, payload: result === "success" });
  // }, []);

  // const startAudioAutomatically = async () => {
  //   try {
  //     const stream = zmClient.getMediaStream();
  //     if (!startAudioCalled && stream) {
  //       await stream.startAudio({ autoStartAudioInSafari: true });
  //       setStartAudioCalled(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const onDialoutChange = useCallback((payload: any) => {
  //   console.log("onDialoutChange", payload);
  // }, []);

  // const onAudioMerged = useCallback((payload: any) => {
  //   console.log("onAudioMerged", payload);
  // }, []);

  // const onUserRemoved = useCallback((payload: any) => {
  //   console.log("onUserRemoved", payload);
  // }, []);

  // useEffect(() => {
  //   zmClient.on("connection-change", onConnectionChange);
  //   zmClient.on("media-sdk-change", onMediaSDKChange);
  //   zmClient.on("dialout-state-change", onDialoutChange);
  //   zmClient.on("merged-audio", onAudioMerged);

  //   return () => {
  //     zmClient.off("connection-change", onConnectionChange);
  //     zmClient.off("media-sdk-change", onMediaSDKChange);
  //     zmClient.off("dialout-state-change", onDialoutChange);
  //     zmClient.off("merged-audio", onAudioMerged);
  //   };
  // }, [zmClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);

  // async function initFunction() {
  //   try {
  //     console.log(history.action);
  //     if (!history || !history.action || history.action != "PUSH" || !localCallInitializeData) {
  //       history.push("/dashboard");
  //     } else {
  //       setErrorHappened(false);

  //       await zmClient.init("en-US", "CDN", {
  //         // webEndpoint:"zoom.us",
  //         // enforceMultipleVideos: false,
  //         stayAwake: true,
  //       });

  //       start = localCallInitializeData.start;
  //       isAppointmentBased = localCallInitializeData.isAppointmentBased;
  //       appointmentId = localCallInitializeData.appointmentId;
  //       recieverId = localCallInitializeData.recieverId;
  //       meetingTime = localCallInitializeData.meetingTime;

  //       if (start) {
  //         initializeInstantCall();
  //       } else {
  //         joinZoomCall();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Please check your internet connection !", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: "foo-bar",
  //     });

  //     resetContextData();

  //     closeAllPopupsAndGoBack(true);
  //   }
  // }

  // function tabNavigationDetect(location: any): boolean {
  //   setShowModalForNavigationDetect(true);
  //   return false;
  // }

  // const resetContextData = () => {
  //   const timeoutData: TimeOut = {
  //     isTimeOutRunning: true,
  //     userOnMeetingScreen: false,
  //     showExtendPopup: false,
  //     callAcceptedByReciever: false,
  //   };

  //   setCallTimeOut(timeoutData);

  //   const callInitializeDataForReset: CallInitializeData = {
  //     start: false,
  //     isInstantMeeting: false,
  //     isAppointmentBased: false,
  //     isTranscribeAllowed: false,
  //     appointmentId: "",
  //     recieverId: "",
  //     meetingTime: 30,
  //     meetingId: "",
  //   };

  //   setLocalCallInitialize(false);
  //   setLocalCallInitializeData(callInitializeDataForReset);

  //   const resetDataForCall: LocalCall = {
  //     callId: "",
  //     onOngoingCall: false,
  //     recieversUseDefaultAvatarValue: true,
  //     recieversAvatarId: "",
  //     recieversAvatarBackgroundId: "",
  //     recieversIncogniteModeOn: true,
  //     meetingMainTherapistId: "",
  //     isVideoCall: false,
  //     meetingDuration: 30,
  //     recieversUserId: "",
  //     recieversName: "",
  //     recieverCallRecordingAllowed: false,
  //     recieversSocketId: "",
  //     callEndedForBothUsers: false,
  //     videoSdkToken: "",
  //   };
  //   setLocalUserOnCall(false, resetDataForCall, true);
  // };

  // const closeAllPopupsAndGoBack = async (backToDashboard: boolean) => {
  //   try {
  //     try {
  //       const sessionInfo = zmClient.getSessionInfo();

  //       if (sessionInfo && sessionInfo.isInMeeting) {
  //         // stop recording
  //         const userInfo = zmClient.getCurrentUserInfo();

  //         try {
  //           if (userInfo && userInfo.isHost && recordingClient) {
  //             if (recordingClient?.canStartRecording()) {
  //               const recordingStatus = recordingClient?.getCloudRecordingStatus();
  //               if (recordingStatus == "Recording") {
  //                 console.log("stop cloud recording");
  //                 await recordingClient?.stopCloudRecording();
  //               }
  //             } else {
  //               console.log("can't stop recording");
  //             }
  //           }
  //         } catch (error) {
  //           console.log(error);
  //         }

  //         if (userInfo && userInfo.isHost) {
  //           await zmClient.leave(true);
  //         } else if (userInfo && !userInfo.isHost) {
  //           await zmClient.leave();
  //         }

  //         return true;
  //       }
  //     } catch (error) {
  //       toast.error("SDK error occured when leaving", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         className: "foo-bar",
  //       });

  //       return false;
  //     }

  //     // leave if joined
  //     setShowExtendTime(false);
  //     setErrorHappened(true);
  //     setShowModalForTherapist(false);
  //     setShowModalExceeded(false);
  //     setShowNotAfriend(false);

  //     history.push("/chat");

  //     return true;
  //   } catch (error) {
  //     toast.error("SDK error occured when leaving", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: "foo-bar",
  //     });

  //     return false;
  //   }
  // };

  // const handleHttpError = (res: AppResponse<any>, type: string) => {
  //   resetContextData();

  //   if (res.error && res.errorCode && res.errorCode == 456) {
  //     setShowModalForTherapist(true);
  //   } else if (res.error && res.errorCode && res.errorCode == 457) {
  //     setShowModalExceeded(true);
  //   } else if (res.error && res.errorCode && res.errorCode == 453) {
  //     setShowNotAfriend(true);
  //   } else {
  //     toast.error(type == "INITIALIZE" ? "Call Initialization Error Occured." : "Start Call Error Occured", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: "foo-bar",
  //     });

  //     closeAllPopupsAndGoBack(false);
  //   }
  // };

  // const initializeInstantCall = async () => {
  //   try {
  //     const initializeResponse = await VideoChatService.initializeZoomCall(recieverId, appointmentId, meetingTime, true, isAppointmentBased);

  //     if (initializeResponse.success) {
  //       const meetingIdFromResponseInitial: string = initializeResponse.data.meetingId;
  //       const signatureInitial: string = initializeResponse.data.sdkToken;
  //       const passwordInitial: string = initializeResponse.data.password;
  //       const nameInitial: string = loggedUser?.username ? loggedUser?.username : loggedUser?.role ? loggedUser?.role : "User";

  //       setMeetingIdFromResponse(meetingIdFromResponseInitial);
  //       setSignatureFromResponse(signatureInitial);
  //       setPasswordFromResponse(passwordInitial);
  //       setNameFromResponse(nameInitial);

  //       if (initializeResponse.data.alreadyStarted == false) {
  //         await zmClient.join(meetingIdFromResponseInitial, signatureInitial, nameInitial, passwordInitial, sessionIdleTimeoutMins);
  //         const sessionId = zmClient.getSessionInfo().sessionId;
  //         startInstantCall(sessionId, meetingIdFromResponseInitial, signatureInitial);
  //       } else {
  //         toast.error("Call Already Started", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: "foo-bar",
  //         });
  //         resetContextData();
  //         closeAllPopupsAndGoBack(false);
  //       }
  //     } else {
  //       handleHttpError(initializeResponse, "INITIALIZE");
  //     }
  //   } catch (error) {
  //     toast.error("Call Initialization Error Occured.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: "foo-bar",
  //     });

  //     resetContextData();
  //     closeAllPopupsAndGoBack(false);
  //   }
  // };

  // const startInstantCall = async (sessionId: string, meetingId: string, signature: string) => {
  //   try {
  //     const res = await VideoChatService.startZoomMeeting(sessionId, meetingId, recieverId, appointmentId, meetingTime, true, isAppointmentBased);
  //     const userId = recieverId;
  //     const isVideoCall = true;
  //     const token = signature;
  //     if (res.success) {
  //       const stream = zmClient.getMediaStream();
  //       setMediaStream(stream);
  //       const recordingClient = zmClient.getRecordingClient();
  //       setRecordingClient(recordingClient);
  //       const dataForCall: LocalCall = {
  //         callId: meetingId,
  //         onOngoingCall: true,
  //         recieversUseDefaultAvatarValue: res.data.recieverData.useDefaultAvatar,
  //         recieversAvatarId: res.data.recieverData.avatarId,
  //         recieversAvatarBackgroundId: res.data.recieverData.avatarBackgroundId,
  //         recieversIncogniteModeOn: res.data.recieverData.incognito,
  //         meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
  //         isVideoCall: isVideoCall,
  //         meetingDuration: meetingTime,
  //         recieversUserId: userId,
  //         recieversName: res.data.recieverData.callerName,
  //         recieverCallRecordingAllowed: res.data.recieverData.callRecordingAllowed,
  //         recieversSocketId: res.data.recieverData.socketId,
  //         callEndedForBothUsers: false,
  //         videoSdkToken: token,
  //       };

  //       setLocalUserOnCall(true, dataForCall, false);
  //       // setOutGoinCallTimeOutFunction();
  //       setIsConnecting(false);
  //       setIsInitial(false);

  //       const data: StartCallSocketData = {
  //         socketId: res.data.recieverData.socketId,
  //         senderSocketId: socket.id,
  //         callId: meetingId,
  //         myUserId: loggedUser?._id,
  //         therapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id,
  //         meetingDuration: meetingTime,
  //         useDefaultAvatar: res.data.ownData.useDefaultAvatar,
  //         avatarId: res.data.ownData.avatarId,
  //         avatarBackgroundId: res.data.ownData.avatarBackgroundId,
  //         incognito: loggedUser?.incognito,
  //         callerName: loggedUser?.firstname,
  //         isVideoCall: isVideoCall,
  //         recieverRcordingAllowed: loggedUser?.callRecordingAllowed,
  //         videoSdkToken: token,
  //         receiverId: userId,
  //         senderId: loggedUser?._id,
  //       };

  //       socket.emit("call-user", data);
  //     } else {
  //       handleHttpError(res, "START_CALL");
  //     }
  //   } catch (error) {
  //     toast.error("Start Call Error Occured", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: "foo-bar",
  //     });
  //     resetContextData();
  //     closeAllPopupsAndGoBack(false);
  //   }
  // };

  // // start instant call
  // // join call
  // const joinZoomCall = async () => {
  //   try {
  //     const res = await VideoChatService.joinZoomMeeting(
  //       localCallInitializeData.meetingId,
  //       localCallInitializeData.isAppointmentBased,
  //       localCallInitializeData.appointmentId
  //     );
  //     const isVideoCall = true;

  //     if (res.success) {
  //       const remainingTime = res.data.meetingData.remainingMeetingTimeForCurrentMeeting;
  //       const userId = res.data.recieverData.userId;
  //       const token: string = res.data.meetingData.sdkToken;
  //       const sessionIdFromResponse = res.data.meetingData.sessionId;
  //       const meetingIdFromResponseInitial: string = res.data.meetingData.meetingId;
  //       const passwordInitial: string = res.data.meetingData.password;
  //       const nameInitial: string = loggedUser?.username ? loggedUser?.username : loggedUser?.role ? loggedUser?.role : "User";

  //       setMeetingIdFromResponse(meetingIdFromResponseInitial);
  //       setSignatureFromResponse(token);
  //       setPasswordFromResponse(passwordInitial);
  //       setNameFromResponse(nameInitial);

  //       await zmClient.join(meetingIdFromResponseInitial, token, nameInitial, passwordInitial, sessionIdleTimeoutMins);

  //       const sessionId = zmClient.getSessionInfo().sessionId;

  //       if (sessionIdFromResponse == sessionId) {
  //         const stream = zmClient.getMediaStream();
  //         setMediaStream(stream);
  //         const recordingClient = zmClient.getRecordingClient();
  //         setRecordingClient(recordingClient);
  //         const dataForCall: LocalCall = {
  //           callId: meetingIdFromResponseInitial,
  //           onOngoingCall: true,
  //           recieversUseDefaultAvatarValue: res.data.recieverData.useDefaultAvatar,
  //           recieversAvatarId: res.data.recieverData.avatarId,
  //           recieversAvatarBackgroundId: res.data.recieverData.avatarBackgroundId,
  //           recieversIncogniteModeOn: res.data.recieverData.incognito,
  //           meetingMainTherapistId: loggedUser?.role == Role.CLIENT ? userId : loggedUser?._id != null ? loggedUser?._id : "",
  //           isVideoCall: isVideoCall,
  //           meetingDuration: remainingTime,
  //           recieversUserId: userId,
  //           recieversName: res.data.recieverData.callerName,
  //           recieverCallRecordingAllowed: res.data.recieverData.callRecordingAllowed,
  //           recieversSocketId: res.data.recieverData.socketId,
  //           callEndedForBothUsers: false,
  //           videoSdkToken: token,
  //         };

  //         setLocalUserOnCall(true, dataForCall, false);
  //         setIsConnecting(false);
  //         setIsInitial(false);
  //       } else {
  //         VideoChatService.cancellCall(meetingIdFromResponseInitial, localCallInitializeData.isAppointmentBased, localCallInitializeData.appointmentId);
  //         toast.error("Session was ended automatically !", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: "foo-bar",
  //         });
  //         resetContextData();
  //         closeAllPopupsAndGoBack(false);
  //       }
  //     } else {
  //       handleHttpError(res, "START_CALL");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Start Call Error Occured", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: "foo-bar",
  //     });
  //     resetContextData();
  //     closeAllPopupsAndGoBack(false);
  //   }
  // };

  // return (
  //   <>
  //     <Prompt message={tabNavigationDetect} when={!errorHappened} />

  //     <React.Fragment>
  //       {showExtendTime && <Modal9 setShowModal={extendMeeting} showModal={showExtendTime} meetingTime={30} />}

  //       {!showModalForTherapist && showModalForNavigationDetect && (
  //         <Modal7 setShowModal={setShowModalForNavigationDetect} showModal={showModalForNavigationDetect} />
  //       )}

  //       {showNotAfriend && <Modal18 setShowModal={setShowNotAfriend} showModal={showNotAfriend} />}
  //       {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
  //       {showModalExceeded && <Modal5 setShowModal={setShowModalExceeded} showModal={showModalExceeded} />}

  //       {isConnecting && (
  //         <div className="call_screen">
  //           <div className="main_cam_screen">
  //             <p className="temp">
  //               <h1 style={{ fontSize: "24px" }}>Connecting ...</h1>
  //             </p>
  //           </div>
  //         </div>
  //       )}

  //       {!isConnecting && (
  //         <ZoomMediaContext.Provider value={mediaContext}>
  //           <ZoomRecordingContext.Provider value={recordingClient}>
  //             <VideoCallControllScreen leaveMeeting={leaveMeeting}></VideoCallControllScreen>
  //           </ZoomRecordingContext.Provider>
  //         </ZoomMediaContext.Provider>
  //       )}
  //     </React.Fragment>
  //   </>
  // );
};

export default VideoCallScreenNew;
