import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepWizard from "react-step-wizard";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import classNames from "classnames";
import { Client } from "./../../models/Client";
import NewFormOne from "./NewFormOne";
import NewFormTwo from "./NewFormTwo";
import NewFormThree from "./NewFormThree";
import NewFormFive from "./NewFormFive";
import NewFormSix from "./NewFormSix";
import NewFormEight from "./NewFormEight";
import NewFormEleven from "./NewFormEleven";
import NewFormTen from "./NewFormTen";
import NewFormNine from "./NewFormNine";
import NewFormFour from "./NewFormFour";
import NewFormSeven from "./NewFormSeven";
import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";
import { CommonService } from "src/services/CommonService";
import { ClientService } from "./../../services/ClientService";
import UserContext from "../../context/UserContext";
import { AdminService } from "src/services/AdminService";
import { handleAmendment } from "./VersionPage";
import moment from "moment";
import {
  InpatientTreatementHistoryApplicableEnum
} from "../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";

toast.configure();

const AssessmentOnboardingPage: React.FC<{
  identifierId: any;
  isAdmin: boolean;
  setModalRefresher: ((value: boolean) => void) | null;
  closeClinicalAssessmentModal: () => void;
  aiGeneratedAssessmentData: any;
  isMeeting: boolean;
}> = props => {
  const [customActiveTab, setcustomActiveTab] = useState(0);

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [assesmenData, setAssesmenData] = useState<ClinicalAssetment>();
  const [client, setClient] = useState<Client>();
  const [dateOfSessionFrom, setDateOfSessionFrom] = useState<string>("");
  const clientId = props.identifierId;
  const identifierId = props.identifierId;
  const isAdmin = props.isAdmin;
  const aiGeneratedAssessmentData = props.aiGeneratedAssessmentData;
  const [isNewForm, setIsNewForm] = useState(false);
  const [isVersionCreated, setIsVersionCreated] = useState(false);
  const [isVersionCreatedToday, setIsVersionCreatedToday] = useState(false);

  const [formData, setFormData] = useState<DigitalForm>({
    _id: "",
    clientId: "",
    therapistId: "",
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    generalInformation: {
      name: "",
      dob: "",
      knownAs: "",
      age: "",
      primaryLanguage: {
        primaryLanguageReadablityStatus: null,
        english: null,
        other: null,
        otherLanguage: "",
      },
      consumerPhone: {
        consumerPhone: {
          home: null,
          work: null,
          cellPhone: null,
          other: null,
        },
        otherConsumerPhoneType: "",
        otherConsumerPhoneNumber: "",
      },
      legalGuarianship: {
        legalGuarianship: {
          self: null,
          other: null,
        },
        otherLegalGuarianship: "",
      },
      guardianPhone: {
        sameasAbove: null,
        home: null,
        work: null,
        cell: null,
        guardianPhoneNumber: "",
      },
      primaryInformant: {
        primaryInformant: {
          self: null,
          other: null,
        },
        other: "",
      },
      informantPhone: {
        sameasAbove: null,
        home: null,
        work: null,
        cell: null,
        informantPhoneNumber: "",
      },
      genderAtBirth: {
        male: null,
        female: null,
        femalePregnant: null,
      },
      genderIdentity: {
        male: null,
        female: null,
        nonBinary: null,
      },
      sexualOrientation: {
        sexualOrientations: "",
        isInterestingLGBTService: null,
      },
      ethnicity: {
        notHispanicOrigin: null,
        hispanicCuban: null,
        hispanicMexicanAmerican: null,
        hispanicPuertoRican: null,
        hispanicOther: null,
      },
      raceType: {
        race: {
          blackAfricanAmerican: null,
          alaskaNative: null,
          whiteCaucasian: null,
          asian: null,
          americanIndianNativeAmerican: null,
          pacificIslander: null,
          multiracial: null,
          other: null,
        },
        otherRace: "",
      },
      livingArrangement: {
        livingArrangement: {
          privateResidence: null,
          institution: null,
          ownResidence: null,
          other: null,
          roomHouseDorm: null,
          adultCareHome: null,
          alternativeFamilyLiving: null,
          nursingHome: null,
          communityICFMR: null,
          correctionalFacility: null,
          homeless: null,
          residentialFacility: null,
        },
        other: "",
      },
      maritalStatus: {
        married: null,
        widowed: null,
        divorced: null,
        singleNeverMarried: null,
        separated: null,
        domesticPartnership: null,
      },
      familySize: "",
      currentEmploymentStatus: {
        unemployed: null,
        notavailableforwork: null,
        employedFullTime: null,
        homemaker: null,
        armedForcesNationalGuard: null,
        employedPartTime: null,
        retired: null,
        student: null,
        disabilityIncome: null,
      },
      employmentHistory: "",
      education: {
        highestGradeCompleted: null,
        highSchoolgraduate: null,
        ged: null,
        someCollege: null,
        associateDegree: null,
        bachelordegree: null,
        graduateSchool: null,
        specialEdClasses: null,
        technicalTradeSchool: null,
      }
    },
    presentingProblem: {
      description: "",
      historyOfProblem: "",
    },
    historyOfProblem: "",
    symptomChicklist: {
      depressiveSymptoms: { symtoms: {}, comment: "" },
      manicSymptoms: { symtoms: {}, comment: "" },
      conductLegalProblem: { symtoms: {}, comment: "" },
      psychosis: { symtoms: {}, comment: "" },
      anxietySymptoms: { symtoms: {}, comment: "" },
      attentionSymptoms: { symtoms: {}, comment: "" },
    },
    biologicalFunction: {
      sleep: { sleepStatus: {}, comment: "" },
      nutritionalStatus: { nutritionalStatus: {}, comment: "" },
      otherBiologicalFunction: {
        amenorrhea: "",
        encopresis: "",
        increased: "",
        decreased: "",
        other: "",
        enuresis: "",
      },
      sexualActivity: {
        sexuallyActive: { sexuallyActiveness: null, activeWith: "" },
        protectionAgainstHepatitisHiv: { protection: null, how: "" },
        protectionAgainstPregnancy: { protection: null, how: "" },
        atRiskBehavior: { risk: null, describe: "" },
        otherSymtoms: "",
        comments: "",
      },
    },
    alcoholAndDrugUseHistory: {
      historyStatus: "",
    },
    nameOfSubstance: [
      {
        ageOfFirstUse: "",
        regularUse: "",
        dateLastUse: "",
        route: "",
        amount: "",
        frequency: "",
        problemWithUse: {},
        comment: "",
      },
    ],
    tobaccoUseTypeDetails: {
      tobaccoUse: null,
      tobaccoType: "",
      howOften: "",
      howMuch: "",
      howLong: "",
      interestEndUse: null,
      interestReducingUse: null,
      endingUse: "",
      longestAbstinence: "",
      whenWas: "",
      relapse: "",
      comment: "",
      experiencedSymptoms: {
        agitationRestlessness: "",
        confusion: "",
        nauseaVomiting: "",
        muscleTwitching: "",
        chills: "",
        deliriumTremens: "",
        tremorsShaking: "",
        anxiety: "",
        racingPulse: "",
        rapidBreathing: "",
        sweats: "",
        hallucinations: "",
        backJointPain: "",
        other: "",
        difficultySleeping: "",
        runnyNose: "",
        tearyEyes: "",
        craving: "",
        cramps: "",
        seizures: "",
        none: "",
      },
    },
    releventMedicalInformation: {
      physicianDiagnosedConditions: {
        allergies: "",
        gynecological: "",
        pancreatitis: "",
        anemia: "",
        headInjury: "",
        respiratory: "",
        arthritis: "",
        heartDisease: "",
        seizureDisorder: "",
        asthma: "",
        hepatitis: "",
        std: "",
        brainDisorder: "",
        highBloodPressure: "",
        stroke: "",
        cancer: "",
        lowBloodPressure: "",
        thyroidDisease: "",
        chronicPain: "",
        cirrhosisoftheliver: "",
        immuneDisease: "",
        tuberculosis: "",
        diabetes: "",
        kidneyDisease: "",
        ulcer: "",
        eatingDisorder: "",
        muscleDisorder: "",
        mensHealthProblems: "",
        hivAids: "",
        none: "",
        other: "",
      },
      cancerType: "",
      otherType: "",
      lastVisitPrimaryCarePhysician: "",
      comment: "",
      anyAllergies: "",
      hypersensitivities: "",
      patientPainLevel: {
        currentLevelOfPhysicalPain: "",
        isCurrentReceivingTreatementPain: "",
        isReceivingTreatement: "",
        treatementDetails: [],
        disablityStatus: {
          hearingImpairment: "",
          sightImpairment: "",
          intellectualDevelopmentalDisability: "",
          other: "",
          none: "",
          comment: "",
        },
        adjustDisability: "",
        requireEquipmentOrServices: "",
      },
    },
    outPatientTreatementHistory: {
      applicableState: null,
      outPatienttreatementHistoryDetails: [],
    },
    inpatientTreatementHistory: {
      applicableState: "",
      inPatientTreatementHistory: [],
      pastTreatement: "",
      additionalMedicalInformation: "",
    },
    bioPsychosocialDevelopemntHistory: {
      developmentHistory: "",
      childhoodHistory: "",
      fosterCare: "",
      siblings: "",
      familyHistoryOfMHOrSAIssues: "",
      currentSpouse: "",
      childrenStepChildren: "",
      relationsIssues: "",
      otherSupports: {
        family: "",
        church: "",
        employer: "",
        friends: "",
        other: "",
      },
    },
    bioPsychosocialEducation: {
      isApplicable: null,
      schoolGradeName: "",
      lastIep: "",
      teacher: "",
      learningAbility: "",
      disabilityArea: "",
      educationalProblems: "",
      behaviorProblem: "",
      repetedGrades: "",
      socialInteraction: "",
      suspension: "",
      expulsion: "",
      isPsychologicalTestingCompleted: null,
      date: "",
      expulsionTestResults: "",
    },
    traumaHistory: {
      isBadAccident: null,
      badAccidentDescribe: "",
      isNaturalDisaster: null,
      naturalDisasterdDescribe: "",
      isMilitaryCombat: null,
      militoryCombatDescribe: "",
      isSeriousAttack: null,
      seriousAttackDescribe: "",
      isSexualCoercion: null,
      ageOfSexualCoercion: 0,
      describeSexualCoercion: "",
      isTraumaticExperience: null,
      describeTraumaticExperience: "",
      intrusiveThoughts: null,
      avoidanceBehavior: null,
      hypervigilance: null,
      emotionalDetachment: null,
      selfBlame: null,
    },
    riskToSelfAndOthers: {
      currentRiskToSelf: {
        isSelfInjured: null,
        describeSelfInjured: "",
        isSuicidalThreats: null,
        describeSuicidalThreats: "",
        repetitiveIdeation: null,
        isSuicidePlan: null,
        isLethalIntent: null,
        isCommandHallucinations: null,
        describeCommandHallucinations: "",
      },
      currentRiskToOthers: {
        isHomicidalThreats: null,
        isPersistentIdeation: null,
        isSpecificPlan: null,
        isLethalIntent: null,
        isHallucinationCommands: null,
        describeHallucination: "",
      },
      historyOfSuicidalBehavior: {
        isHistoryOfSuicidalBehavior: null,
        describeHistoryOfSuicidalBehavior: "",
        isHomicidalBehavior: null,
        describeHomicidalBehavior: "",
        isSeriousHarmSelfOthers: null,
        describeSeriousPhysicalHarm: "",
        isAggressionViolenceOthers: null,
        describeAggression: "",
      },
      evaluationOfRisk: {
        selfHarming: {
          lowToNoRisk: null,
          moderateRisk: null,
          highRisk: null,
          imminentRisk: null,
        },
        assaultive: {
          lowToNoRisk: null,
          moderateRisk: null,
          highRisk: null,
          imminentRisk: null,
        },
        actionEvaluation: "",
        beliefSystem: "",
        roleOfBeliefinlife: "",
        roleOfBeliefRecovery: "",
      },
    },
    mentalStatus: {
      physicalStature: {
        small: null,
        average: null,
        tall: null,
      },
      weight: {
        thin: null,
        average: null,
        overweight: null,
        obese: null,
      },
      grooming: {
        wellGroomed: null,
        normal: null,
        neglected: null,
        bizarre: null,
      },
      clothing: {
        neatClean: null,
        inappropriate: null,
        dirty: null,
        seductive: null,
        disheveled: null,
        bizarre: null,
      },
      posture: {
        normal: null,
        tense: null,
        stooped: null,
        rigid: null,
        slumped: null,
        bizarre: null,
      },
      attitude: {
        cooperative: null,
        passive: null,
        guarded: null,
        irritable: null,
        manipulative: null,
        seductive: null,
        suspicious: null,
        defensive: null,
        dramatic: null,
        silly: null,
        hostile: null,
        critical: null,
        resistant: null,
        sarcastic: null,
        uninterested: null,
        argumentative: null,
      },
      motor: {
        nonremarkable: null,
        tremor: null,
        slowed: null,
        tics: null,
        restless: null,
        agitated: null,
      },
      speech: {
        normal: null,
        rapid: null,
        slurred: null,
        loud: null,
        paucity: null,
        pressured: null,
        mute: null,
      },
      affect: {
        appropriate: null,
        inappropriate: null,
        flat: null,
        restricted: null,
        blunted: null,
        labile: null,
      },
      mood: {
        euthymic: null,
        confused: null,
        pessimistic: null,
        depressed: null,
        anxious: null,
        euphoric: null,
        apathetic: null,
        angry: null,
      },
      thoughtForm: {
        goaldirected: null,
        appropriate: null,
        logical: null,
        tangentialthinking: null,
        circumstantial: null,
        looseassociations: null,
        confused: null,
        incoherent: null,
        perseverations: null,
        flightofidea: null,
        slownessofthought: null,
      },
      thoughtContent: {
        appropriate: null,
        paranoid: null,
        suspicions: null,
        persecutions: null,
        paucity: null,
        delusions: null,
        bizarre: null,
        hypochondriac: null,
        ideasofreference: null,
      },
      preoccupations: {
        phobias: null,
        guilt: null,
        other: null,
        somatic: null,
        religion: null,
        suicide: null,
        homicidal: null,
      },
      hallucinations: {
        auditory: null,
        other: null,
        visual: null,
        sensory: null,
      },
      orientation: {
        person: null,
        place: null,
        time: null,
        situation: null,
      },
      levelOfIntellectualFunctioning: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      fundofKnowledge: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      judgment: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      insightIntoProblems: {
        belowAverage: null,
        average: null,
        aboveAverage: null,
      },
      clinicalImpressionSummary: "",
    },
    releventDDSInformation: {
      ddsInvolvement: null,
      ddsCare: "",
      ddsCareDescription: "",
      ddsSocialWorker: "",
      phone: "",
    },
    relevantLegalInformation: {
      legalInvolvement: null,
      countOfArrestet: 0,
      currentCharges: "",
      attorneyName: "",
      currentProbationRequirement: "",
      probationOffice: "",
      previousChargesJailOrPrison: "",
    },
    diagnoseRecommendationDetails: {
      diagnosis: [],
      recommendation: {},
      printedName: "",
      signature: "",
      clientSignature: "",
      date: "",
      dateOfClinicianSignature: "",
    },
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchClentAndDigitalAssessmentData();
  }, [isNewForm]);

  const calculateAge = (dob: Date): number => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust if the birthdate hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const [user] = useContext(UserContext);

  const signature = user?.signature;
  const getCurrenAddress=(client:Client)=>{
    const streetAddress=(client?.streetAddress!==undefined && client?.streetAddress!==null)?client?.streetAddress:"";
    const unit=(client?.unit!==undefined && client?.unit!==null)?client?.unit:"";
    const city=(client?.city!==undefined && client?.city!==null)?client?.city:"";
    const state=(client?.state!==undefined && client?.state!==null)?client?.state:"";
    const zipCode=(client?.zipCode!==undefined && client?.zipCode!==null)?client?.zipCode:"";

    const currentAddress={
      streetAddress: streetAddress,
      unit: unit,
      city: city,
      state: state,
      zipCode: zipCode,
    }
    return currentAddress;
  }
  const fetchClentAndDigitalAssessmentData = async () => {
    try {
      // Fetch both client and digital assessment data in parallel
      if (isAdmin) {
        const data = {
          assesmentId: identifierId,
        };

        const requestData = { ["assesmentId"]: identifierId };

        const [clientRes, assessmentRes, dateOfSesionRes] = await Promise.all([
          AdminService.getClientUsingDigitalAssessmentIdByAdmin(data),
          AdminService.getDigitalAssessmentDataByAdmin(requestData),
          AdminService.getDigitalAssestmentSessionDataByAdmin(requestData),
        ]);

        const dateOfSession = moment.utc(dateOfSesionRes?.data?.dateOfSession).format("YYYY-MM-DD");
        setIsVersionCreatedToday(dateOfSesionRes?.data?.isVersionCreatedToday);

        if (clientRes && clientRes.success && assessmentRes && assessmentRes?.data) {
          if (
            assessmentRes?.data?.formHeader?.date ||
            assessmentRes?.data?.formHeader?.insuranceId ||
            assessmentRes?.data?.formHeader?.lastName ||
            assessmentRes?.data?.formHeader?.firstname ||
            assessmentRes?.data?.formHeader?.mi
          ) {
            setIsNewForm(false);
            setFormData((prevFormData: DigitalForm) => ({
              ...prevFormData,
              ...assessmentRes?.data,
            }));
          } else {
            // setIsNewForm(true);
            // setFormData((prevFormData: DigitalForm) => ({
            //   ...prevFormData,
            //   ...assessmentRes?.data,
            //   formHeader: {
            //     ...prevFormData?.formHeader,
            //     date: dateOfSession ?? "",
            //     insuranceId: clientRes?.data?.insuranceId ?? "",
            //     lastName: clientRes?.data?.lastname ?? "",
            //     firstname: clientRes?.data?.firstname ?? "",
            //     mi: prevFormData?.formHeader?.mi ?? "",
            //   },
            // }));
          }
        } else {
          toast.error("Something went wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        const [clientRes, assessmentRes, dateOfSesionRes] = await Promise.all([
          ClientService.getClientByClientId(clientId),
          CommonService.getDigitalAssessmentDataByTherapist({ clientId: clientId }),
          CommonService.getDigitalAssestmentSessionData({ clientId: clientId }),
        ]);
        const dateOfSession = moment.utc(dateOfSesionRes?.data?.dateOfSession).isValid()
          ? moment.utc(dateOfSesionRes?.data?.dateOfSession).format("YYYY-MM-DD")
          : "";
        
        setIsVersionCreatedToday(dateOfSesionRes?.data?.isVersionCreatedToday);

        if (clientRes && clientRes.success && assessmentRes && assessmentRes?.success) {
          if (
            assessmentRes?.data?.formHeader?.date ||
            assessmentRes?.data?.formHeader?.insuranceId ||
            assessmentRes?.data?.formHeader?.lastName ||
            assessmentRes?.data?.formHeader?.firstname ||
            assessmentRes?.data?.formHeader?.mi
          ) {
            const insuranceMemberId = clientRes?.data?.insuranceId?.subscriber?.memberId;
            setIsNewForm(false);
            if (aiGeneratedAssessmentData !== null && (aiGeneratedAssessmentData !== undefined)) {
              let currentAddress=assessmentRes?.data?.formHeader?.currentAddress;
              if(currentAddress===undefined||!currentAddress){
                currentAddress=getCurrenAddress(clientRes?.data);
              }
              setFormData((prevFormData: DigitalForm) => ({
                ...prevFormData,
                ...assessmentRes?.data,
                generalInformation: {
                  ...assessmentRes?.data?.generalInformation,
                  dob: moment.utc(clientRes?.data?.dateOfBirth).format("YYYY-MM-DD") ?? "",
                  name: clientRes?.data?.username ?? "",
                  age: calculateAge(clientRes?.data?.dateOfBirth) ?? "",
                },
                formHeader: {
                  ...assessmentRes?.data?.formHeader,
                  date: dateOfSession ?? "",
                  insuranceId: insuranceMemberId ?? "",
                  lastName: clientRes?.data?.lastname ?? "",
                  firstname: clientRes?.data?.firstname ?? "",
                  mi: prevFormData?.formHeader?.mi ?? "",
                  currentAddress: currentAddress,
                },
                diagnoseRecommendationDetails: {
                  // ...prevFormData?.diagnoseRecommendationDetails,
                  ...assessmentRes?.data?.diagnoseRecommendationDetails,

                  signature: signature ?? "",
                },
                historyOfProblem: aiGeneratedAssessmentData?.historyOfProblem,
                symptomChicklist: {
                  ...assessmentRes?.data?.symptomChicklist,
                  depressiveSymptoms: {
                    ...assessmentRes?.data?.symptomChicklist?.depressiveSymptoms,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.depressiveSymptoms?.comment,
                  },
                  manicSymptoms: {
                    ...assessmentRes?.data?.symptomChicklist?.manicSymptoms,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.manicSymptoms?.comment,
                  },
                  conductLegalProblem: {
                    ...assessmentRes?.data?.symptomChicklist?.conductLegalProblem,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.conductLegalProblem?.comment,
                  },
                  psychosis: {
                    ...assessmentRes?.data?.symptomChicklist?.psychosis,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.psychosis?.comment,
                  },
                  anxietySymptoms: {
                    ...assessmentRes?.data?.symptomChicklist?.anxietySymptoms,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.anxietySymptoms?.comment,
                  },
                  attentionSymptoms: {
                    ...assessmentRes?.data?.symptomChicklist?.attentionSymptoms,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.attentionSymptoms?.comment,
                  },
                },
                biologicalFunction: {
                  ...assessmentRes?.data?.biologicalFunction,
                  sleep: {
                    ...assessmentRes?.data?.biologicalFunction?.sleep,
                    comment: aiGeneratedAssessmentData?.biologicalFunction?.sleep?.comment,
                  },
                  nutritionalStatus: {
                    ...assessmentRes?.data?.biologicalFunction?.nutritionalStatus,
                    comment: aiGeneratedAssessmentData?.biologicalFunction?.nutritionalStatus?.comment,
                  },
                },
                traumaHistory: {
                  ...assessmentRes?.data?.traumaHistory,
                  badAccidentDescribe: aiGeneratedAssessmentData?.traumaHistory?.badAccidentDescribe,
                  seriousAttackDescribe: aiGeneratedAssessmentData?.traumaHistory?.seriousAttackDescribe,
                  describeSexualCoercion: aiGeneratedAssessmentData?.traumaHistory?.describeSexualCoercion,
                  describeTraumaticExperience: aiGeneratedAssessmentData?.traumaHistory?.describeTraumaticExperience,
                },
                presentingProblem: {
                  ...assessmentRes?.data?.presentingProblem,
                  description: aiGeneratedAssessmentData?.presentingProblem?.description,
                },
                releventMedicalInformation: {
                  ...assessmentRes?.data?.releventMedicalInformation,
                  comment: aiGeneratedAssessmentData?.releventMedicalInformation?.comment,
                },
                mentalStatus: {
                  ...assessmentRes?.data?.mentalStatus,
                  clinicalImpressionSummary: aiGeneratedAssessmentData?.mentalStatus?.clinicalImpressionSummary,
                },
              }));
            } else {
              let currentAddress=assessmentRes?.data?.formHeader?.currentAddress;
              if(currentAddress===undefined||!currentAddress){
                currentAddress=getCurrenAddress(clientRes?.data);
              }
              setFormData((prevFormData: DigitalForm) => ({
                ...prevFormData,
                ...assessmentRes?.data,
                generalInformation: {
                  ...assessmentRes?.data?.generalInformation,
                  dob: moment.utc(clientRes?.data?.dateOfBirth).format("YYYY-MM-DD") ?? "",
                  name: clientRes?.data?.username ?? "",
                  age: calculateAge(clientRes?.data?.dateOfBirth) ?? "",
                },
                formHeader: {
                  ...assessmentRes?.data?.formHeader,
                  date: dateOfSession ?? "",
                  insuranceId: insuranceMemberId ?? "",
                  lastName: clientRes?.data?.lastname ?? "",
                  firstname: clientRes?.data?.firstname ?? "",
                  mi: prevFormData?.formHeader?.mi ?? "",
                  currentAddress: currentAddress,
                },
                diagnoseRecommendationDetails: {
                  // ...prevFormData?.diagnoseRecommendationDetails,
                  ...assessmentRes?.data?.diagnoseRecommendationDetails,

                  signature: signature ?? "",
                },
              }));
            }
          } else {
            const insuranceMemberId = clientRes?.data?.insuranceId?.subscriber?.memberId;
            setIsNewForm(true);
            if (aiGeneratedAssessmentData !== null && (aiGeneratedAssessmentData !== undefined)) {
              let currentAddress=assessmentRes?.data?.formHeader?.currentAddress;
              if(currentAddress===undefined||!currentAddress){
                currentAddress=getCurrenAddress(clientRes?.data);
              }
              setFormData((prevFormData: DigitalForm) => ({
                ...prevFormData,
                ...assessmentRes?.data,
                generalInformation: {
                  ...assessmentRes?.data?.generalInformation,
                  dob: moment.utc(clientRes?.data?.dateOfBirth).format("YYYY-MM-DD") ?? "",
                  name: clientRes?.data?.username ?? "",
                  age: calculateAge(clientRes?.data?.dateOfBirth) ?? "",
                },
                formHeader: {
                  ...assessmentRes?.data?.formHeader,
                  date: dateOfSession ?? "",
                  insuranceId: insuranceMemberId ?? "",
                  lastName: clientRes?.data?.lastname ?? "",
                  firstname: clientRes?.data?.firstname ?? "",
                  mi: prevFormData?.formHeader?.mi ?? "",
                  currentAddress: currentAddress,
                },
                diagnoseRecommendationDetails: {
                  // ...prevFormData?.diagnoseRecommendationDetails,
                  ...assessmentRes?.data?.diagnoseRecommendationDetails,

                  signature: signature ?? "",
                },
                historyOfProblem: aiGeneratedAssessmentData?.historyOfProblem,
                symptomChicklist: {
                  ...assessmentRes?.data?.symptomChicklist,
                  depressiveSymptoms: {
                    ...assessmentRes?.data?.symptomChicklist?.depressiveSymptoms,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.depressiveSymptoms?.comment,
                  },
                  manicSymptoms: {
                    ...assessmentRes?.data?.symptomChicklist?.manicSymptoms,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.manicSymptoms?.comment,
                  },
                  conductLegalProblem: {
                    ...assessmentRes?.data?.symptomChicklist?.conductLegalProblem,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.conductLegalProblem?.comment,
                  },
                  psychosis: {
                    ...assessmentRes?.data?.symptomChicklist?.psychosis,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.psychosis?.comment,
                  },
                  anxietySymptoms: {
                    ...assessmentRes?.data?.symptomChicklist?.anxietySymptoms,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.anxietySymptoms?.comment,
                  },
                  attentionSymptoms: {
                    ...assessmentRes?.data?.symptomChicklist?.attentionSymptoms,
                    comment: aiGeneratedAssessmentData?.symptomChicklist?.attentionSymptoms?.comment,
                  },
                },
                biologicalFunction: {
                  ...assessmentRes?.data?.biologicalFunction,
                  sleep: {
                    ...assessmentRes?.data?.biologicalFunction?.sleep,
                    comment: aiGeneratedAssessmentData?.biologicalFunction?.sleep?.comment,
                  },
                  nutritionalStatus: {
                    ...assessmentRes?.data?.biologicalFunction?.nutritionalStatus,
                    comment: aiGeneratedAssessmentData?.biologicalFunction?.nutritionalStatus?.comment,
                  },
                },
                traumaHistory: {
                  ...assessmentRes?.data?.traumaHistory,
                  badAccidentDescribe: aiGeneratedAssessmentData?.traumaHistory?.badAccidentDescribe,
                  seriousAttackDescribe: aiGeneratedAssessmentData?.traumaHistory?.seriousAttackDescribe,
                  describeSexualCoercion: aiGeneratedAssessmentData?.traumaHistory?.describeSexualCoercion,
                  describeTraumaticExperience: aiGeneratedAssessmentData?.traumaHistory?.describeTraumaticExperience,
                },
                presentingProblem: {
                  ...assessmentRes?.data?.presentingProblem,
                  description: aiGeneratedAssessmentData?.presentingProblem?.description,
                },
                releventMedicalInformation: {
                  ...assessmentRes?.data?.releventMedicalInformation,
                  comment: aiGeneratedAssessmentData?.releventMedicalInformation?.comment,
                },
                mentalStatus: {
                  ...assessmentRes?.data?.mentalStatus,
                  clinicalImpressionSummary: aiGeneratedAssessmentData?.mentalStatus?.clinicalImpressionSummary,
                },
              }));
            } else {
              let currentAddress=assessmentRes?.data?.formHeader?.currentAddress;
              if(currentAddress===undefined||!currentAddress){
                currentAddress=getCurrenAddress(clientRes?.data);
              }
              setFormData((prevFormData: DigitalForm) => ({
                ...prevFormData,

                ...assessmentRes?.data,
                generalInformation: {
                  ...prevFormData?.generalInformation,
                  dob: moment.utc(clientRes?.data?.dateOfBirth).format("YYYY-MM-DD") ?? "",
                  name: clientRes?.data?.username ?? "",
                  age: calculateAge(clientRes?.data?.dateOfBirth) ?? "",
                },
                ...aiGeneratedAssessmentData,
                formHeader: {
                  ...prevFormData?.formHeader,
                  date: dateOfSession ?? "",
                  insuranceId: insuranceMemberId ?? "",
                  lastName: clientRes?.data?.lastname ?? "",
                  firstname: clientRes?.data?.firstname ?? "",
                  mi: prevFormData?.formHeader?.mi ?? "",
                  currentAddress: currentAddress,
                },
                diagnoseRecommendationDetails: {
                  // ...prevFormData?.diagnoseRecommendationDetails,
                  ...assessmentRes?.data?.diagnoseRecommendationDetails,

                  signature: signature ?? "",
                },
              }));
            }
          }
        } else {
          toast.error("Something went wrong! ", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  function modalRefresher() {
    props.setModalRefresher?.(true);
  }

  function closeClinicalAssessmentModal() {
    props.closeClinicalAssessmentModal();
  }

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [customActiveTab]);

  const memoizedData = useMemo(() => {
    return formData; // Memoize the data to prevent unnecessary changes
  }, [formData]);

  const toggleCustom = (tab: number) => {
    setcustomActiveTab(tab);
    // window.scrollTo({ top: 0, behavior: "smooth", block: "start" });
  };

  const previousStep = () => {
    setcustomActiveTab(customActiveTab - 1);

    // window.scrollTo({ top: 0, behavior: "smooth", block: "start" });
  };

  const nextStep = () => {
    if (customActiveTab < 10) {
      setcustomActiveTab(customActiveTab + 1);
    }
  };

  const validateForm = (tab: number, values: Partial<DigitalForm>) => {
    let hasError = false;

    const showError = (message: string) => {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      hasError = true;
    };

    const validateTab = (tabToValidate: number) => {
      if(props?.isMeeting) {
        return
      }
      switch (tabToValidate) {
        case 0:
          if (!values?.generalInformation?.employmentHistory) {
            showError("Employment History is required Form One");
          }
          break;

        case 1:
          if (!values?.historyOfProblem) {
            showError("History of Problem is required Form Two");
          }
          if (!values?.symptomChicklist?.depressiveSymptoms?.comment) {
            showError("Depressive Symptoms Comment is required Form Two");
          }
          if (!values?.symptomChicklist?.manicSymptoms?.comment) {
            showError("Manic Symptoms Comment is required Form Two");
          }
          if (!values?.symptomChicklist?.conductLegalProblem?.comment) {
            showError("Conduct/Legal Problems Comment is required Form Two");
          }
          if (!values?.symptomChicklist?.psychosis?.comment) {
            showError("Psychosis Comment is required Form Two");
          }
          if (!values?.symptomChicklist?.anxietySymptoms?.comment) {
            showError("Anxiety Symptoms Comment is required Form Two");
          }
          if (!values?.symptomChicklist?.attentionSymptoms?.comment) {
            showError("Attention Symptoms Comment is required Form Two");
          }
          break;

        case 5:
          if (values?.inpatientTreatementHistory?.applicableState!== InpatientTreatementHistoryApplicableEnum.yes && !values?.inpatientTreatementHistory?.pastTreatement) {
            showError("inpatientTreatementHistory pastTreatement is required Form Six");
          }
          if (values?.inpatientTreatementHistory?.applicableState!== InpatientTreatementHistoryApplicableEnum.yes && !values?.inpatientTreatementHistory?.additionalMedicalInformation) {
            showError("inpatientTreatementHistory additionalMedicalInformation is required Form Six");
          }
          break;

        case 6:
          if (!values?.bioPsychosocialDevelopemntHistory?.developmentHistory) {
            showError("BioPsychosocialDevelopemntHistory developmentHistory is required Form Seven");
          }
          if (!values?.bioPsychosocialDevelopemntHistory?.childhoodHistory) {
            showError("BioPsychosocialDevelopemntHistory childhoodHistory is required Form Seven");
          }
          if (!values?.bioPsychosocialDevelopemntHistory?.fosterCare) {
            showError("BioPsychosocialDevelopemntHistory fosterCare is required Form Seven");
          }
          if (!values?.bioPsychosocialDevelopemntHistory?.siblings) {
            showError("BioPsychosocialDevelopemntHistory siblings is required Form Seven");
          }
          if (!values?.bioPsychosocialDevelopemntHistory?.familyHistoryOfMHOrSAIssues) {
            showError("Family history of MH or SA issues is required Form Seven");
          }
          if (!values?.bioPsychosocialDevelopemntHistory?.currentSpouse) {
            showError("Current Spouse/Significant Other is required Form Seven");
          }
          if (!values?.bioPsychosocialDevelopemntHistory?.childrenStepChildren) {
            showError("Children/Step-Children is required Form Seven");
          }
          if (!values?.bioPsychosocialDevelopemntHistory?.relationsIssues) {
            showError("Current Relational Issues w/ Family is required Form Seven");
          }
          break;

        case 8:
          if (!values?.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem) {
            showError("Your belief system is required Form Nine");
          }
          if (!values?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife) {
            showError("Beliefs/religion/spirituality in your life is required Form Nine");
          }
          if (!values?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery) {
            showError("Your wellness/recovery is required Form Nine");
          }
          break;

        default:
          break;
      }
    };

    if (tab === 10) {
      // Validate all tabs for final submission
      for (let i = 0; i <= 10; i++) {
        validateTab(i);
      }
    } else {
      // Validate only the current tab
      validateTab(tab);
    }

    return !hasError;
  };

  const handleBulkFormDataUpdate = async (updatedValues: Partial<DigitalForm>, isSendClientEmail = false) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      ...updatedValues,
    }));

    //validation
    // if (customActiveTab === 0) {
    //   if (!updatedValues?.generalInformation?.employmentHistory) {
    //     toast.error("Employment History is required Form One", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     return;
    //   }
    // }
    // if (customActiveTab === 1) {
    //   let hasError = false
    //   if (!updatedValues?.historyOfProblem) {
    //     toast.error("History of Problem is required Form Two", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError = true;
    //   }

    //   if (!updatedValues?.symptomChicklist?.depressiveSymptoms?.comment) {
    //     toast.error("Depressive Symptoms Comment is required Form Two", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //      hasError = true;
    //   }

    //   if (!updatedValues?.symptomChicklist?.manicSymptoms?.comment) {
    //     toast.error("Manic Symptoms Comment is required Form Two", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //      hasError = true;
    //   }

    //   if (!updatedValues?.symptomChicklist?.conductLegalProblem?.comment) {
    //     toast.error("Conduct/Legal Problems Comment is required Form Two", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //      hasError = true;
    //   }
    //   if (!updatedValues?.symptomChicklist?.psychosis?.comment) {
    //     toast.error("Psychosis Comment is required Form Two", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError = true;
    //   }
    //   if (!updatedValues?.symptomChicklist?.anxietySymptoms?.comment) {
    //     toast.error("Anxiety Symptoms Comment is required Form Two", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //      hasError = true;
    //   }

    //   if (!updatedValues?.symptomChicklist?.attentionSymptoms?.comment) {
    //     toast.error("Attention Symptoms Comment is required Form Two", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError = true;
    //   }
    //   if(hasError) {
    //     return;
    //   }
    // }

    // if (customActiveTab === 5) {
    //    let hasError = false;
    //   if (!updatedValues?.inpatientTreatementHistory?.pastTreatement) {
    //     toast.error("inpatientTreatementHistory pastTreatement is required Form Six", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError = true;
    //   }
    //   if (!updatedValues?.inpatientTreatementHistory?.additionalMedicalInformation) {
    //     toast.error("inpatientTreatementHistory additionalMedicalInformation is required Form Six", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError = true;
    //   }

    //    if (hasError) {
    //      return;
    //    }
    // }

    // if (customActiveTab === 6) {
    //    let hasError = false;
    //   if (!updatedValues?.bioPsychosocialDevelopemntHistory?.developmentHistory) {
    //     toast.error("BioPsychosocialDevelopemntHistory developmentHistory is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError= true;
    //   }

    //   if (!updatedValues?.bioPsychosocialDevelopemntHistory?.childhoodHistory) {
    //     toast.error("BioPsychosocialDevelopemntHistory childhoodHistory is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError= true;
    //   }
    //   if (!updatedValues?.bioPsychosocialDevelopemntHistory?.fosterCare) {
    //     toast.error("BioPsychosocialDevelopemntHistory fosterCare is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError= true;
    //   }

    //   if (!updatedValues?.bioPsychosocialDevelopemntHistory?.siblings) {
    //     toast.error("BioPsychosocialDevelopemntHistory siblings is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError= true;
    //   }

    //   if (!updatedValues?.bioPsychosocialDevelopemntHistory?.familyHistoryOfMHOrSAIssues) {
    //     toast.error("Family history of MH or SA issues is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError= true;
    //   }

    //   if (!updatedValues?.bioPsychosocialDevelopemntHistory?.currentSpouse) {
    //     toast.error("Current Spouse/Significant Other is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError= true;
    //   }

    //   if (!updatedValues?.bioPsychosocialDevelopemntHistory?.childrenStepChildren) {
    //     toast.error("Children/Step-Children is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError= true;
    //   }

    //   if (!updatedValues?.bioPsychosocialDevelopemntHistory?.relationsIssues) {
    //     toast.error("Current Relational Issues w/ Family is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError= true;
    //   }

    //   if (hasError) {
    //     return;
    //   }

    // }

    // if (customActiveTab === 8) {
    //    let hasError = false;
    //   if (!updatedValues?.riskToSelfAndOthers?.evaluationOfRisk?.beliefSystem) {
    //     toast.error("Your belief system is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError = true;
    //   }

    //   if (!updatedValues?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefinlife) {
    //     toast.error(" beliefs/religion/spirituality in your life is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError = true;
    //   }

    //   if (!updatedValues?.riskToSelfAndOthers?.evaluationOfRisk?.roleOfBeliefRecovery) {
    //     toast.error(" Your wellness/recovery is required Form Seven", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "foo-bar",
    //     });

    //     hasError = true;
    //   }
    //    if (hasError) {
    //      return;
    //    }
    // }
    if (customActiveTab === 10) {
      if (!validateForm(customActiveTab, formData)) {
        return;
      }
    } else {
      if (!validateForm(customActiveTab, updatedValues)) {
        return;
      }
    }

    try {
      let data = {
        data: {
          ...formData,
          ...updatedValues,
          clientId: formData?.clientId == "" ? clientId : formData?.clientId,
          therapistId: formData?.therapistId == "" ? user?._id : formData?.therapistId,
        },
        _id: formData?._id ?? "",
        isAdmin: isAdmin,
        isSendClientEmail: isSendClientEmail,
      };

      if (customActiveTab === 10) {
        data = {
          data: {
            ...formData,
            ...updatedValues,
            clientId: formData?.clientId == "" ? clientId : formData?.clientId,
            therapistId: formData?.therapistId == "" ? user?._id : formData?.therapistId,
          },
          _id: formData?._id ?? "",
          isAdmin: isAdmin,
          isSendClientEmail: isSendClientEmail,
        };
      }

      if (isAdmin) {
        if (!isNewForm) {
          if (customActiveTab === 10 && isVersionCreated === false && isVersionCreatedToday === false && !props?.isMeeting) {
            handleAmendment({
              formId: data._id,
              updateAssesment: data,
              nextStep: nextStep,
              onModalRefresher: modalRefresher,
              setIsVersionCreated: setIsVersionCreated,
              closeClinicalAssessmentModal: closeClinicalAssessmentModal,
            });
          } else {
            const result = await CommonService.updateDigitalAssessmentDetails(data);
            if (result && result.success) {
              setIsNewForm(false);

              toast.success(`Successfully update digital assessment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
              if (customActiveTab == 10) {
                props.closeClinicalAssessmentModal();
              }
              nextStep();
            } else {
              toast.error("Digital assessment update is failed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          }
        }
      } else {
        if (isNewForm) {
          const result = await CommonService.addDigitalAssessmentDetails(data);
          if (result && result.success) {
            setIsNewForm(false);
            setIsVersionCreated(true);
            modalRefresher();
            toast.success(`Successfully saved digital assessment.`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
            if (customActiveTab == 10) {
              props.closeClinicalAssessmentModal();
            }
            nextStep();
          } else {
            toast.error("Digital assessment update is failed.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        } else {
          if (customActiveTab === 10 && isVersionCreated === false && isVersionCreatedToday === false && !props?.isMeeting ) {
            handleAmendment({
              formId: data._id,
              updateAssesment: data,
              nextStep: nextStep,
              onModalRefresher: modalRefresher,
              setIsVersionCreated: setIsVersionCreated,
              closeClinicalAssessmentModal: closeClinicalAssessmentModal,
            });
          } else {
            const result = await CommonService.updateDigitalAssessmentDetails(data);

            if (result && result.success) {
              toast.success(`Successfully updated digital assessment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
              if (customActiveTab == 10) {
                props.closeClinicalAssessmentModal();
              }
              nextStep();
            } else {
              toast.error("Digital assessment update is failed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          }
        }
      }
    } catch (error) {
      toast.error("Digital assessment update is failed.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return (
    <React.Fragment>
      <div ref={scrollContainerRef} className="page-content mt-0" style={{ padding: 0 }}>
        <Container className="containerOnboarding" style={{ padding: 0 }}>
          <Row className="row-content justify-content-sm-center" style={{ padding: 0 }}>
            <Nav pills style={{ marginTop: "30px", justifyContent: "center", paddingLeft: "12px" }} className="col-12 d-none d-xl-flex">
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 0 })}
                  onClick={() => {
                    toggleCustom(0);
                  }}
                >
                  Page 1
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 1 })}
                  onClick={() => {
                    toggleCustom(1);
                  }}
                >
                  Page 2
                </NavLink>
              </NavItem>
              {/* <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 2 })}
                  onClick={() => {
                    toggleCustom(2);
                  }}
                >
                  Page 3
                </NavLink>
              </NavItem> */}
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 2 })}
                  onClick={() => {
                    toggleCustom(2);
                  }}
                >
                  Page 3
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 3 })}
                  onClick={() => {
                    toggleCustom(3);
                  }}
                >
                  Page 4
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 4 })}
                  onClick={() => {
                    toggleCustom(4);
                  }}
                >
                  Page 5
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 5 })}
                  onClick={() => {
                    toggleCustom(5);
                  }}
                >
                  Page 6
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 6 })}
                  onClick={() => {
                    toggleCustom(6);
                  }}
                >
                  Page 7
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 7 })}
                  onClick={() => {
                    toggleCustom(7);
                  }}
                >
                  Page 8
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 8 })}
                  onClick={() => {
                    toggleCustom(8);
                  }}
                >
                  Page 9
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 9 })}
                  onClick={() => {
                    toggleCustom(9);
                  }}
                >
                  Page 10
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 10 })}
                  onClick={() => {
                    toggleCustom(10);
                  }}
                >
                  Page 11
                </NavLink>
              </NavItem>
            </Nav>

            <Nav pills style={{ marginTop: "30px", justifyContent: "center", paddingLeft: "12px" }} className="col-12 d-xl-none">
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 0 })}
                  onClick={() => {
                    toggleCustom(0);
                  }}
                >
                  Page 1
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 1 })}
                  onClick={() => {
                    toggleCustom(1);
                  }}
                >
                  Page 2
                </NavLink>
              </NavItem>
              {/* <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 2 })}
                  onClick={() => {
                    toggleCustom(2);
                  }}
                >
                  Page 3
                </NavLink>
              </NavItem> */}
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 2 })}
                  onClick={() => {
                    toggleCustom(2);
                  }}
                >
                  Page 3
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 3 })}
                  onClick={() => {
                    toggleCustom(3);
                  }}
                >
                  Page 4
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 4 })}
                  onClick={() => {
                    toggleCustom(4);
                  }}
                >
                  Page 5
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 5 })}
                  onClick={() => {
                    toggleCustom(5);
                  }}
                >
                  Page 6
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 6 })}
                  onClick={() => {
                    toggleCustom(6);
                  }}
                >
                  Page 7
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 7 })}
                  onClick={() => {
                    toggleCustom(7);
                  }}
                >
                  Page 8
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 8 })}
                  onClick={() => {
                    toggleCustom(8);
                  }}
                >
                  Page 9
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 9 })}
                  onClick={() => {
                    toggleCustom(9);
                  }}
                >
                  Page 10
                </NavLink>
              </NavItem>
              <NavItem className="border">
                <NavLink
                  className={classNames("my-pagination", { active: customActiveTab === 10 })}
                  onClick={() => {
                    toggleCustom(10);
                  }}
                >
                  Page 11
                </NavLink>
              </NavItem>
            </Nav>

            <Col>
              <div>
                <h5 className="font-size-25 profileFont mt-4 text-center">COMPREHENSIVE CLINICAL ASSESSMENT</h5>
              </div>
              <StepWizard>
                <TabContent activeTab={customActiveTab} className="text-muted">
                  <TabPane tabId={0}>
                    <NewFormOne
                      isUpdate={isUpdate}
                      nextStep={nextStep}
                      dateOfSessionFrom={dateOfSessionFrom}
                      data={memoizedData}
                      handleBulkFormDataUpdate={handleBulkFormDataUpdate}
                    />
                  </TabPane>

                  <TabPane tabId={1}>
                    <NewFormTwo
                      previousStep={previousStep}
                      nextStep={nextStep}
                      // data={{
                      //   formHeader: formData?.formHeader,
                      //   presentingProblem: formData?.presentingProblem,
                      //   historyOfProblem: formData?.historyOfProblem,
                      //   symptomChicklist: formData?.symptomChicklist,
                      // }}
                      data={memoizedData}
                      handleBulkFormDataUpdate={handleBulkFormDataUpdate}
                    />
                  </TabPane>

                  <TabPane tabId={2}>
                    <NewFormThree previousStep={previousStep} nextStep={nextStep} data={memoizedData} handleBulkFormDataUpdate={handleBulkFormDataUpdate} />
                  </TabPane>
                  <TabPane tabId={3}>
                    <NewFormFour previousStep={previousStep} nextStep={nextStep} data={memoizedData} handleBulkFormDataUpdate={handleBulkFormDataUpdate} />
                  </TabPane>
                  <TabPane tabId={4}>
                    <NewFormFive previousStep={previousStep} nextStep={nextStep} data={memoizedData} handleBulkFormDataUpdate={handleBulkFormDataUpdate} />
                  </TabPane>
                  <TabPane tabId={5}>
                    <NewFormSix previousStep={previousStep} nextStep={nextStep} data={memoizedData} handleBulkFormDataUpdate={handleBulkFormDataUpdate} />
                  </TabPane>
                  <TabPane tabId={6}>
                    <NewFormSeven previousStep={previousStep} nextStep={nextStep} data={memoizedData} handleBulkFormDataUpdate={handleBulkFormDataUpdate} />
                  </TabPane>
                  <TabPane tabId={7}>
                    <NewFormEight previousStep={previousStep} nextStep={nextStep} data={memoizedData} handleBulkFormDataUpdate={handleBulkFormDataUpdate} />
                  </TabPane>
                  <TabPane tabId={8}>
                    <NewFormNine
                      previousStep={previousStep}
                      nextStep={nextStep}
                      // data={{
                      //   formHeader: formData?.formHeader,
                      //   riskToSelfAndOthers: formData?.riskToSelfAndOthers,
                      // }}
                      data={memoizedData}
                      handleBulkFormDataUpdate={handleBulkFormDataUpdate}
                    />
                  </TabPane>
                  <TabPane tabId={9}>
                    <NewFormTen
                      assesmenData={assesmenData}
                      previousStep={previousStep}
                      nextStep={nextStep}
                      data={memoizedData}
                      handleBulkFormDataUpdate={handleBulkFormDataUpdate}
                    />
                  </TabPane>
                  <TabPane tabId={10}>
                    <NewFormEleven
                      assesmenData={assesmenData}
                      previousStep={previousStep}
                      data={memoizedData}
                      handleBulkFormDataUpdate={handleBulkFormDataUpdate}
                    />
                  </TabPane>
                </TabContent>
              </StepWizard>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AssessmentOnboardingPage;
