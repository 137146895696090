import React from "react";
import { Modal, ModalBody } from "reactstrap";

interface ErrorModalProps {
  errorModalText: string;
  isEventTimeValidationViewModal: boolean;
  eventTimeValidationModalToggle: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ errorModalText, isEventTimeValidationViewModal, eventTimeValidationModalToggle }: ErrorModalProps) => {
  return (
    <Modal isOpen={isEventTimeValidationViewModal} centered toggle={eventTimeValidationModalToggle}>
      <ModalBody>
        <div className="modal-val">
          <h5 className="model-error">{errorModalText}</h5>
          <button className="btn btn-sm text-center btn-success mt-3" onClick={() => eventTimeValidationModalToggle()}>
            Got It
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ErrorModal;
