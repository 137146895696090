import { Client } from "./Client";
import { RepeatInfo } from "./subModel/RepeatInfo";
import { BlockedDate, Therapist } from "./Therapist";
import {  UserDetails } from "./User";

export enum AppointmentType {
  VIDEO = "VIDEO",
  TEXT = "TEXT",
  VOICE = "VOICE",
}

export enum Repeat {
  DOES_NOT_REPEAT = "DOES NOT REPEAT",
  WEEKLY = "WEEKLY",
  BI_WEEKLY = "BI_WEEKLY"
}

export enum AppointmentStatus {
  WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  OVERDUE = "OVERDUE",
}

export enum ApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum ModalType {
  EDIT = "EDIT",
  CREATE = "CREATE",
}

export interface AppointmentDetailsResponseModel {
  repeatedSession: Appointment[];
  selectedDate: string;
  allSessionOfWeek: Appointment[];
  sessionTimeOfWeek: number;
  allSession: number;
}

export interface Appointment {
  className?: string;
  color: any;
  _id?: string;
  title: string;
  therapistId: Therapist;
  clientId: Client;
  start: Date;
  end: Date;
  createdAt: Date;
  typeOfMeeting: AppointmentType;
  reminders: number[];
  repeatInfo?: RepeatInfo;
  createdBy?: UserDetails;
  status?: AppointmentStatus;
  approvedStatus?: ApprovalStatus;
  groupId?: string;
  noOfCallingTriesByClient?: number;
  noOfCallingTriesByTherapist?: number;
  meetingStatus?: string;
  meetingId?: string;
  meetingStartedBy?: any;
  GoogleCalendarEventId: string;
  blockedDates: BlockedDate[]
}
