export enum AuthFormType {
  AmeriHealthAuthForm = "AmeriHealthAuthForm",
  WellcareAuthForm = "WellcareAuthForm",
  UnitedHealthCareAuthForm = "UnitedHealthCareAuthForm",
  HealthyBlueAuthForm = "HealthyBlueAuthForm",
  CarolinaCompleteHealthAuthForm = "CarolinaCompleteHealthAuthForm",
  AmbetterAuthForm = "AmbetterAuthForm"
}

export interface AuthorizationForm {
  _id?: string;
  therapistId?: string;
  clientId?: string;
  insuranceCompanyId?: string;
  authFormType?: string
}

interface InsuranceCompanyId {
  _id: string;
  authorizationFormType: string;
  isPrimary: boolean;
}

export interface AuthorizationFormData {
  _id: string;
  insuranceCompanyId: InsuranceCompanyId;
}
  