import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import { RouteName } from "../../RouteName";

const Footer = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      {location.pathname != RouteName.MEETING_SCREEN_NEW && !location.pathname.includes("vonage-session") && !location.pathname.includes("video-room") && !location.pathname.includes("room") &&
        <footer className="footer">
          <Container fluid={true}>
            <Row>
              <Col md={6}>{new Date().getFullYear()}  Lavni.</Col>
              <Col md={6}>
                <div className="text-sm-end d-none d-sm-block">
                  All rights reserved.
                </div>
              </Col>
            </Row>
          </Container>
        </footer>}
    </React.Fragment>
  )
}

export default Footer
