import React, { useContext } from "react";
import UserContext from "../../../context/UserContext";
import { UncontrolledTooltip } from "reactstrap";
import { SaasTherapistService } from "../../../services/SaasTherapistService";
import Swal from "sweetalert2";

const SwitchDashboard = () => {
  const [user] = useContext(UserContext);

  const handleSwitchDashboard = () => {
    if (user) {
      const isNewDashboard = user.isNewDashboard === undefined || user.isNewDashboard === true;
      const version = isNewDashboard ? "previous" : "new";
      const actionType = isNewDashboard ? "restore" : "switch";
      const btnActionType = isNewDashboard ? "Restore" : "Switch";
      Swal.fire({
        icon: "warning",
        title: `Are you sure you want to ${actionType} to the ${version} version?`,
        showCancelButton: true,
        confirmButtonText: btnActionType,
        confirmButtonColor: "#50a5f1",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          SaasTherapistService.switchDashboardVersion().then(res => {
            if (res.success) {
              Swal.fire({
                icon: "success",
                title: "Switching in progress. Please wait!",
                text: "You will be redirected to the requested dashboard version shortly.",
                confirmButtonColor: "#FD7F00",
                didOpen: () => {
                  Swal.showLoading();
                }
              });
              setTimeout(() => {
                window.location.href = "/dashboard";
              }, 2000);
            } else {
              Swal.fire({
                icon: "error",
                title: res.error ? res.error : "Something went wrong. Please try again later.",
                confirmButtonColor: "#FD7F00",
              });
            }
          })
            .catch(() => {
              Swal.fire({
                icon: "error",
                title: "Failed to communicate with the server. Please try again later.",
                confirmButtonColor: "#FD7F00",
              });
            });
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="overflow-hidden-welcome2">
        <div className="text-primary p-3 d-flex align-items-center">
          <a
            className="btn btn-primary switch-between-dashboard-btn d-flex align-items-center"
            onClick={() => handleSwitchDashboard()}
          >
            <i className="bx bx-loader-circle me-2"></i>
            {user?.isNewDashboard === undefined || user?.isNewDashboard === true ? "Restore previous version" : "Switch to new version"}
          </a>
          <i className="bx bx-info-circle clarity-info p-1 ms-2" id="clarity_info"></i>
          <UncontrolledTooltip placement="right" target={"clarity_info"}>
            {user?.isNewDashboard === undefined || user?.isNewDashboard === true ? "Click here to switch back to the previous version of the platform if you encounter any issues with the updated version." : "Click here to switch back to the new version of the platform if you like with the updated version."}
          </UncontrolledTooltip>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SwitchDashboard;