import React, { useEffect, useState, useContext, useMemo } from "react";
import { Card, CardBody, Col, Input, Row, Table, Button } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import UserContext from "../../context/UserContext";

import {
  TreatementDetails,
  DisabilityStatus,
  PhysicianDiagnosedConditions,
  LastVisitPrimaryCarePhysician,
  ReceivingTreatementStatus,
  ReceivingTreatementPainStatus, Sleep
} from "./../../models/AssessmentDigitalForm/AssessmentDigitalFormsSubModels";

import { DigitalForm } from "./../../models/AssessmentDigitalForm/AssessmentDigitalForms";

const NewFormFive = (props: any) => {
  const { handleBulkFormDataUpdate, previousStep, nextStep, data } = props;
  const [edit, setEdit] = useState<boolean>(true);
  const [releventInformationComment, setReleventComment] = useState("");

  const [formData, setFormData] = useState<Partial<DigitalForm>>({
    formHeader: {
      date: "",
      insuranceId: "",
      lastName: "",
      firstname: "",
      mi: "",
      currentAddress:{
        streetAddress: "",
        unit: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },
    releventMedicalInformation: {
      physicianDiagnosedConditions: {},
      cancerType: "",
      otherType: "",
      lastVisitPrimaryCarePhysician: "",
      comment: "",
      anyAllergies: "",
      hypersensitivities: "",
      patientPainLevel: {
        currentLevelOfPhysicalPain: "",
        isCurrentReceivingTreatementPain: "",
        isReceivingTreatement: "",
        treatementDetails: [],
        disablityStatus: {
          hearingImpairment: "",
          sightImpairment: "",
          intellectualDevelopmentalDisability: "",
          other: "",
          none: "",
          comment: "",
        },
        adjustDisability: "",
        requireEquipmentOrServices: "",
      },
    },
  });

  const memoizedUpdateValues = useMemo(() => {
    if (data !== null && data !== undefined) {
      return {
        formHeader: data?.formHeader,
        releventMedicalInformation: data?.releventMedicalInformation,
      };
    }
    return {};
  }, [data]);

  useEffect(() => {
    if (data) {
      // const updateValues = {
      //   formHeader: data?.formHeader,
      //   releventMedicalInformation: data?.releventMedicalInformation,
      // };
      // setFormData(updateValues);

      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        ...memoizedUpdateValues,
      }));

      setReleventComment(memoizedUpdateValues?.releventMedicalInformation?.comment);
    }
  }, [memoizedUpdateValues]);

  useEffect(() => {
    if (
      !formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails ||
      formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails.length === 0
    ) {
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        releventMedicalInformation: {
          ...formData?.releventMedicalInformation,
          patientPainLevel: {
            ...formData?.releventMedicalInformation?.patientPainLevel,
            treatementDetails: [...(formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails ?? []), {}],
          },
        },
      }));
    }
  }, [formData]);

  const addMedicationDetails = () => {
    setFormData((formData: Partial<DigitalForm>) => {
      const currentTreatementDetails = formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails;

      return {
        ...formData,
        releventMedicalInformation: {
          ...formData?.releventMedicalInformation,
          patientPainLevel: {
            ...formData?.releventMedicalInformation?.patientPainLevel,
            treatementDetails: currentTreatementDetails && Array.isArray(currentTreatementDetails) ? [...currentTreatementDetails, {}] : [{}], // Initialize as array if undefined or non-array
          },
        },
      };
    });
  };

  const shouldEnableFields = (disabilityStatus: any={}) => {
    return Object.values(disabilityStatus).some(value => value !== "" && value !== "None");
  };

  const fieldsEnabled = shouldEnableFields(formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus);

  const handleUpdateData = () => {
    const update = {
      formHeader: {
        ...formData?.formHeader,
      },
      releventMedicalInformation: {
        ...formData?.releventMedicalInformation,
        comment: releventInformationComment,
      },
    };
    handleBulkFormDataUpdate(update);
  };
  const updatePhysicianDiagnosedConditions=(field:string,value:boolean|string)=>{
    if (field!=="none"){
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        releventMedicalInformation: {
          ...formData?.releventMedicalInformation,
          physicianDiagnosedConditions: {
            ...formData?.releventMedicalInformation?.physicianDiagnosedConditions,
            none:"",
          },
        },
      }));
    }
    if ((field === "cancer" && formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cancer !== "")||field==="none") {
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        releventMedicalInformation: {
          ...formData?.releventMedicalInformation,
          cancerType: "",
        },
      }));
    }
    if((field === "other" && formData?.releventMedicalInformation?.physicianDiagnosedConditions?.other !== "")||field==="none"){
      setFormData((formData: Partial<DigitalForm>) => ({
        ...formData,
        releventMedicalInformation: {
          ...formData?.releventMedicalInformation,
          otherType: "",
        },
      }));
    }
    setFormData((formData: Partial<DigitalForm>) => ({
      ...formData,
      releventMedicalInformation: {
        ...formData?.releventMedicalInformation,
        physicianDiagnosedConditions: {
          ...formData?.releventMedicalInformation?.physicianDiagnosedConditions,
          allergies:
            field === "allergies"
              ? value
                ? PhysicianDiagnosedConditions.allergies
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.allergies,
          gynecological:
            field === "gynecological"
              ? value
                ? PhysicianDiagnosedConditions.gynecological
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.gynecological,
          pancreatitis:
            field === "pancreatitis"
              ? value
                ? PhysicianDiagnosedConditions.pancreatitis
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.pancreatitis,
          anemia:
            field === "anemia"
              ? value
                ? PhysicianDiagnosedConditions.anemia
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.anemia,
          headInjury:
            field === "headInjury"
              ? value
                ? PhysicianDiagnosedConditions.headInjury
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.headInjury,
          respiratory:
            field === "respiratory"
              ? value
                ? PhysicianDiagnosedConditions.respiratory
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.respiratory,
          arthritis:
            field === "arthritis"
              ? value
                ? PhysicianDiagnosedConditions.arthritis
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.arthritis,
          heartDisease:
            field === "heartDisease"
              ? value
                ? PhysicianDiagnosedConditions.heartDisease
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.heartDisease,
          seizureDisorder:
            field === "seizureDisorder"
              ? value
                ? PhysicianDiagnosedConditions.seizureDisorder
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.seizureDisorder,
          asthma:
            field === "asthma"
              ? value
                ? PhysicianDiagnosedConditions.asthma
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.asthma,
          hepatitis:
            field === "hepatitis"
              ? value
                ? PhysicianDiagnosedConditions.hepatitis
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.hepatitis,
          std: field === "std" ? (value ? PhysicianDiagnosedConditions.std : "") : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.std,
          brainDisorder:
            field === "brainDisorder"
              ? value
                ? PhysicianDiagnosedConditions.brainDisorder
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.brainDisorder,
          highBloodPressure:
            field === "highBloodPressure"
              ? value
                ? PhysicianDiagnosedConditions.highBloodPressure
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.highBloodPressure,
          stroke:
            field === "stroke"
              ? value
                ? PhysicianDiagnosedConditions.stroke
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.stroke,
          cancer:
            field === "cancer"
              ? value
                ? PhysicianDiagnosedConditions.cancer
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cancer,
          lowBloodPressure:
            field === "lowBloodPressure"
              ? value
                ? PhysicianDiagnosedConditions.lowBloodPressure
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.lowBloodPressure,
          thyroidDisease:
            field === "thyroidDisease"
              ? value
                ? PhysicianDiagnosedConditions.thyroidDisease
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.thyroidDisease,
          chronicPain:
            field === "chronicPain"
              ? value
                ? PhysicianDiagnosedConditions.chronicPain
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.chronicPain,
          cirrhosisoftheliver:
            field === "cirrhosisoftheliver"
              ? value
                ? PhysicianDiagnosedConditions.cirrhosisoftheliver
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cirrhosisoftheliver,
          immuneDisease:
            field === "immuneDisease"
              ? value
                ? PhysicianDiagnosedConditions.immuneDisease
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.immuneDisease,
          tuberculosis:
            field === "tuberculosis"
              ? value
                ? PhysicianDiagnosedConditions.tuberculosis
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.tuberculosis,
          diabetes:
            field === "diabetes"
              ? value
                ? PhysicianDiagnosedConditions.diabetes
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.diabetes,
          kidneyDisease:
            field === "kidneyDisease"
              ? value
                ? PhysicianDiagnosedConditions.kidneyDisease
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.kidneyDisease,
          ulcer:
            field === "ulcer" ? (value ? PhysicianDiagnosedConditions.ulcer : "") : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.ulcer,
          eatingDisorder:
            field === "eatingDisorder"
              ? value
                ? PhysicianDiagnosedConditions.eatingDisorder
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.eatingDisorder,
          muscleDisorder:
            field === "muscleDisorder"
              ? value
                ? PhysicianDiagnosedConditions.muscleDisorder
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.muscleDisorder,
          mensHealthProblems:
            field === "mensHealthProblems"
              ? value
                ? PhysicianDiagnosedConditions.mensHealthProblems
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.mensHealthProblems,
          hivAids:
            field === "hivAids"
              ? value
                ? PhysicianDiagnosedConditions.hivAids
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.hivAids,
          other:
            field === "other"
              ? value
                ? PhysicianDiagnosedConditions.other
                : ""
              : formData?.releventMedicalInformation?.physicianDiagnosedConditions?.other,
        },
      },
    }));
  }

  return (
    <>
      <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
        <div className="text-end">
          <button
            className="btn btn-primary mb-2"
            type="button"
            onClick={() => {
              handleUpdateData();
            }}
          >
            {props.totalSteps > 17 ? "Next" : "Save & Continue"}
          </button>
        </div>
        <Row>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Date:
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="date"
                    value={formData?.formHeader?.date ? moment.utc(formData?.formHeader?.date).format("YYYY-MM-DD") : ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Insurance ID :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.insuranceId}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          insuranceId: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Last Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.lastName}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          lastName: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    First Name :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.firstname}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          firstname: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    MI :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.mi}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          mi: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row >
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Street Address :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.streetAddress ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            streetAddress: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Unit :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.unit ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            unit: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    City :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.city ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            city: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    State :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.state ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            state: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-auto">
                  <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                    Zip Code :
                  </h6>
                </div>
                <div className="col">
                  <input
                    style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                    type="text"
                    value={formData?.formHeader?.currentAddress?.zipCode ?? ""}
                    onChange={e => {
                      setFormData((formData: Partial<DigitalForm>) => ({
                        ...formData,
                        formHeader: {
                          ...formData?.formHeader,
                          currentAddress:{
                            ...formData?.formHeader?.currentAddress,
                            zipCode: e.target.value,
                          }
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Row>
          <div className="card-bodyDiv">
            <Card className="match-card-header p-2 mb-2">
              <div style={{ display: "flex" }}>
                <h5 className="fw-500" style={{ margin: "auto" }}>
                  RELEVANT MEDICAL INFORMATION
                </h5>
              </div>
            </Card>
            <Card className="match-card-body p-3">
              <Card className="match-card-body-second p-3">
                <h6>Do you have or have you ever had any of the following conditions diagnosed by a physician:</h6>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.allergies}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.allergies === PhysicianDiagnosedConditions?.allergies}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.allergies === PhysicianDiagnosedConditions?.allergies
                        updatePhysicianDiagnosedConditions("allergies",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Allergies*
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.gynecological}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.gynecological === PhysicianDiagnosedConditions?.gynecological
                      }
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.gynecological === PhysicianDiagnosedConditions?.gynecological
                        updatePhysicianDiagnosedConditions("gynecological",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Gynecological
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.pancreatitis}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.pancreatitis === PhysicianDiagnosedConditions?.pancreatitis}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.pancreatitis === PhysicianDiagnosedConditions?.pancreatitis
                        updatePhysicianDiagnosedConditions("pancreatitis",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Pancreatitis
                    </label>
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.anemia}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.anemia === PhysicianDiagnosedConditions?.anemia}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.anemia === PhysicianDiagnosedConditions?.anemia
                        updatePhysicianDiagnosedConditions("anemia",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Anemia
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.headInjury}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.headInjury === PhysicianDiagnosedConditions?.headInjury}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.headInjury === PhysicianDiagnosedConditions?.headInjury;
                        updatePhysicianDiagnosedConditions("headInjury",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Head Injury
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.respiratory}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.respiratory === PhysicianDiagnosedConditions?.respiratory}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.respiratory === PhysicianDiagnosedConditions?.respiratory;
                        updatePhysicianDiagnosedConditions("respiratory",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Respiratory
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.arthritis}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.arthritis === PhysicianDiagnosedConditions?.arthritis}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.arthritis === PhysicianDiagnosedConditions?.arthritis;
                        updatePhysicianDiagnosedConditions("arthritis",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Arthritis
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.heartDisease}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.heartDisease === PhysicianDiagnosedConditions?.heartDisease}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.heartDisease === PhysicianDiagnosedConditions?.heartDisease;
                        updatePhysicianDiagnosedConditions("heartDisease",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Heart Disease
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.seizureDisorder}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.seizureDisorder === PhysicianDiagnosedConditions?.seizureDisorder
                      }
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.seizureDisorder === PhysicianDiagnosedConditions?.seizureDisorder;
                        updatePhysicianDiagnosedConditions("seizureDisorder",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Seizure Disorder
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.asthma}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.asthma === PhysicianDiagnosedConditions?.asthma}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.asthma === PhysicianDiagnosedConditions?.asthma;
                        updatePhysicianDiagnosedConditions("asthma",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Asthma
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.hepatitis}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.hepatitis === PhysicianDiagnosedConditions?.hepatitis}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.hepatitis === PhysicianDiagnosedConditions?.hepatitis;
                        updatePhysicianDiagnosedConditions("hepatitis",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Hepatitis
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.std}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.std === PhysicianDiagnosedConditions?.std}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.std === PhysicianDiagnosedConditions?.std;
                        updatePhysicianDiagnosedConditions("std",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      STD
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.brainDisorder}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.brainDisorder === PhysicianDiagnosedConditions?.brainDisorder
                      }
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.brainDisorder === PhysicianDiagnosedConditions?.brainDisorder;
                        updatePhysicianDiagnosedConditions("brainDisorder",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Brain Disorder
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.highBloodPressure}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.highBloodPressure ===
                        PhysicianDiagnosedConditions?.highBloodPressure
                      }
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.highBloodPressure === PhysicianDiagnosedConditions?.highBloodPressure;
                        updatePhysicianDiagnosedConditions("highBloodPressure",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      High Blood Pressure
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.stroke}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.stroke === PhysicianDiagnosedConditions?.stroke}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.stroke === PhysicianDiagnosedConditions?.stroke;
                        updatePhysicianDiagnosedConditions("stroke",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Stroke
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{
                        fontSize: "larger",
                        zIndex: 1 /* Make sure it's on top */,
                        position: "relative",
                        minWidth:"1rem",
                        // width: "20px" /* Ensure checkbox is large enough */,
                        // height: "20px",
                      }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions.cancer}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cancer === PhysicianDiagnosedConditions.cancer}
                      onClick={() => {
                        const result = formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cancer === PhysicianDiagnosedConditions.cancer;
                        updatePhysicianDiagnosedConditions("cancer", !result);
                      }}
                    />
                    <div className="col">
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                        <div className="row">
                          <div className="col-auto">
                            <label className="fw-500" style={{ margin: "0.3rem" }}>
                              Cancer <br />
                              (Type):
                            </label>
                          </div>
                          <div className="col">
                            <input
                              style={{
                                width: "100%",
                                border: "none",
                                marginTop: "0.1rem",
                                backgroundColor: "unset",
                              }}
                              disabled={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cancer !== PhysicianDiagnosedConditions.cancer}
                              value={formData?.releventMedicalInformation?.cancerType ?? ""}
                              onChange={e => {
                                setFormData((formData: Partial<DigitalForm>) => ({
                                  ...formData,
                                  releventMedicalInformation: {
                                    ...formData?.releventMedicalInformation,
                                    cancerType: e.target.value,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.lowBloodPressure}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.lowBloodPressure === PhysicianDiagnosedConditions?.lowBloodPressure
                      }
                      onClick={() => {
                        const result = formData?.releventMedicalInformation?.physicianDiagnosedConditions?.lowBloodPressure === PhysicianDiagnosedConditions.lowBloodPressure;
                        updatePhysicianDiagnosedConditions("lowBloodPressure", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Low Blood Pressure
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.thyroidDisease}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.thyroidDisease === PhysicianDiagnosedConditions?.thyroidDisease
                      }
                      onClick={() => {
                        const result = formData?.releventMedicalInformation?.physicianDiagnosedConditions?.thyroidDisease === PhysicianDiagnosedConditions.thyroidDisease;
                        updatePhysicianDiagnosedConditions("thyroidDisease", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Thyroid Disease
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.chronicPain}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.chronicPain === PhysicianDiagnosedConditions?.chronicPain}
                      onClick={() => {
                        const result = formData?.releventMedicalInformation?.physicianDiagnosedConditions?.chronicPain === PhysicianDiagnosedConditions.chronicPain;
                        updatePhysicianDiagnosedConditions("chronicPain", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Chronic Pain
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.cirrhosisoftheliver}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cirrhosisoftheliver ===
                        PhysicianDiagnosedConditions?.cirrhosisoftheliver
                      }
                      onClick={() => {
                        const result = formData?.releventMedicalInformation?.physicianDiagnosedConditions?.cirrhosisoftheliver === PhysicianDiagnosedConditions.cirrhosisoftheliver;
                        updatePhysicianDiagnosedConditions("cirrhosisoftheliver", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Cirrhosis of the liver
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.immuneDisease}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.immuneDisease === PhysicianDiagnosedConditions?.immuneDisease
                      }
                      onClick={() => {
                        const result = formData?.releventMedicalInformation?.physicianDiagnosedConditions?.immuneDisease === PhysicianDiagnosedConditions.immuneDisease;
                        updatePhysicianDiagnosedConditions("immuneDisease", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Immune Disease
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.tuberculosis}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.tuberculosis === PhysicianDiagnosedConditions?.tuberculosis}
                      onClick={() => {
                        const result = formData?.releventMedicalInformation?.physicianDiagnosedConditions?.tuberculosis === PhysicianDiagnosedConditions.tuberculosis;
                        updatePhysicianDiagnosedConditions("tuberculosis", !result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Tuberculosis
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.diabetes}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.diabetes === PhysicianDiagnosedConditions?.diabetes}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.diabetes === PhysicianDiagnosedConditions?.diabetes;
                        updatePhysicianDiagnosedConditions("diabetes",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Diabetes
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.kidneyDisease}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.kidneyDisease === PhysicianDiagnosedConditions?.kidneyDisease
                      }
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.kidneyDisease === PhysicianDiagnosedConditions?.kidneyDisease;
                        updatePhysicianDiagnosedConditions("kidneyDisease",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Kidney Disease
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.ulcer}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.ulcer === PhysicianDiagnosedConditions?.ulcer}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.ulcer === PhysicianDiagnosedConditions?.ulcer;
                        updatePhysicianDiagnosedConditions("ulcer",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Ulcer
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.eatingDisorder}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.eatingDisorder === PhysicianDiagnosedConditions?.eatingDisorder
                      }
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.eatingDisorder === PhysicianDiagnosedConditions?.eatingDisorder;
                        updatePhysicianDiagnosedConditions("eatingDisorder",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Eating Disorder
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.muscleDisorder}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.muscleDisorder === PhysicianDiagnosedConditions?.muscleDisorder
                      }
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.muscleDisorder === PhysicianDiagnosedConditions?.muscleDisorder;
                        updatePhysicianDiagnosedConditions("muscleDisorder",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Muscle Disorder
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.mensHealthProblems}
                      checked={
                        formData?.releventMedicalInformation?.physicianDiagnosedConditions?.mensHealthProblems ===
                        PhysicianDiagnosedConditions?.mensHealthProblems
                      }
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.mensHealthProblems === PhysicianDiagnosedConditions?.mensHealthProblems;
                        updatePhysicianDiagnosedConditions("mensHealthProblems",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Men’s Health Problems
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.hivAids}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.hivAids === PhysicianDiagnosedConditions?.hivAids}
                      onClick={() => {
                        const result=formData?.releventMedicalInformation?.physicianDiagnosedConditions?.hivAids === PhysicianDiagnosedConditions?.hivAids;
                        updatePhysicianDiagnosedConditions("hivAids",!result);
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      HIV/AIDS
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.none}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.none === PhysicianDiagnosedConditions?.none}
                      onClick={() => {
                        updatePhysicianDiagnosedConditions("none",true);
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          releventMedicalInformation: {
                            ...formData?.releventMedicalInformation,
                            physicianDiagnosedConditions: {
                              ...formData?.releventMedicalInformation?.physicianDiagnosedConditions,
                              allergies:"",
                              gynecological:"",
                              pancreatitis:"",
                              anemia:"",
                              headInjury:"",
                              respiratory:"",
                              arthritis:"",
                              heartDisease:"",
                              seizureDisorder:"",
                              asthma:"",
                              hepatitis:"",
                              std:"",
                              brainDisorder:"",
                              highBloodPressure:"",
                              stroke:"",
                              cancer:"",
                              lowBloodPressure:"",
                              thyroidDisease:"",
                              chronicPain:"",
                              cirrhosisoftheliver:"",
                              immuneDisease:"",
                              tuberculosis:"",
                              diabetes:"",
                              kidneyDisease:"",
                              ulcer:"",
                              eatingDisorder:"",
                              muscleDisorder:"",
                              mensHealthProblems:"",
                              hivAids:"",
                              other:"",
                              none:
                                formData?.releventMedicalInformation?.physicianDiagnosedConditions?.none === PhysicianDiagnosedConditions?.none
                                  ? ""
                                  : PhysicianDiagnosedConditions?.none,

                            },
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      None
                    </label>
                  </Col>
                  <Col xl={4} className="d-flex">
                    <input
                      style={{ fontSize: "larger", zIndex: 1 /* Make sure it's on top */, position: "relative",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={PhysicianDiagnosedConditions?.other}
                      checked={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.other === PhysicianDiagnosedConditions?.other}
                      onClick={() => {
                        const result = formData?.releventMedicalInformation?.physicianDiagnosedConditions?.other === PhysicianDiagnosedConditions?.other;
                        updatePhysicianDiagnosedConditions("other",!result);                  
                      }}
                    />
                    <div className="col">
                      <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                        <div className="row">
                          <div className="col-auto">
                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                              Other
                              <br />
                              (specify):
                            </h6>
                          </div>
                          <div className="col">
                            <input
                              style={{
                                width: "100%",
                                border: "none",
                                marginTop: "0.1rem",
                                backgroundColor: "unset",
                              }}
                              disabled={formData?.releventMedicalInformation?.physicianDiagnosedConditions?.other !== PhysicianDiagnosedConditions?.other}
                              value={formData?.releventMedicalInformation?.otherType || ""}
                              type="text"
                              onChange={e => {
                                setFormData((formData: Partial<DigitalForm>) => ({
                                  ...formData,
                                  releventMedicalInformation: {
                                    ...formData?.releventMedicalInformation,
                                    otherType: e.target.value,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem" }}>
                    When was your last visit with your Primary Care Physician?
                  </label>
                </Row>
                <Row>
                  <Col xl={5} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={LastVisitPrimaryCarePhysician?.unableToRecall}
                      checked={formData?.releventMedicalInformation?.lastVisitPrimaryCarePhysician === LastVisitPrimaryCarePhysician?.unableToRecall}
                      onClick={e => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          releventMedicalInformation: {
                            ...formData?.releventMedicalInformation,

                            lastVisitPrimaryCarePhysician:
                              formData?.releventMedicalInformation?.lastVisitPrimaryCarePhysician === LastVisitPrimaryCarePhysician?.unableToRecall
                                ? ""
                                : LastVisitPrimaryCarePhysician?.unableToRecall,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Client unable to recall date of last visit
                    </label>
                  </Col>
                  <Col xl={7} className="d-flex">
                    <input
                      style={{ fontSize: "larger",minWidth:"1rem" }}
                      type="checkbox"
                      className="form-check-input"
                      value={LastVisitPrimaryCarePhysician?.greaterThanYear}
                      checked={formData?.releventMedicalInformation?.lastVisitPrimaryCarePhysician === LastVisitPrimaryCarePhysician?.greaterThanYear}
                      onClick={e => {
                        setFormData((formData: Partial<DigitalForm>) => ({
                          ...formData,
                          releventMedicalInformation: {
                            ...formData?.releventMedicalInformation,

                            lastVisitPrimaryCarePhysician:
                              formData?.releventMedicalInformation?.lastVisitPrimaryCarePhysician === LastVisitPrimaryCarePhysician?.greaterThanYear
                                ? ""
                                : LastVisitPrimaryCarePhysician?.greaterThanYear,
                          },
                        }));
                      }}
                    />
                    <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                      Greater than 1 year,patient advised to see primary care doctor.
                    </label>
                  </Col>
                </Row>
                <Row>
                  <label className="fw-500 mb-2 mt-3" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    COMMENTS
                  </label>
                </Row>
                <Row>
                  <Card className="bg-light-gray" style={{ height: "175px", marginBottom: "0.1rem" }}>
                    <div className="editor-area ck-content" style={{ height: "calc(100% - 1rem)", marginTop: "0", overflowY: "auto" }}>
                      <CKEditor
                        editor={Editor}
                        data={releventInformationComment ?? ""}
                        onChange={(event: any, editor: any) => {
                          const data = editor.getData();
                          // setFormData((formData: Partial<DigitalForm>) => ({
                          //   ...formData,
                          //   releventMedicalInformation: {
                          //     ...formData?.releventMedicalInformation,
                          //     comment: data,
                          //   },
                          // }));
                          setReleventComment(data);
                        }}
                      />
                    </div>
                  </Card>
                </Row>
              </Card>
              <Row>
                <Col>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                          Specify any allergies :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.releventMedicalInformation?.anyAllergies ?? ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              releventMedicalInformation: {
                                ...formData?.releventMedicalInformation,
                                anyAllergies: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.7rem" }}>
                    <div className="row">
                      <div className="col-auto">
                        <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                          Hypersensitivities (Specify) :
                        </h6>
                      </div>
                      <div className="col">
                        <input
                          style={{
                            width: "100%",
                            marginTop: "0.1rem",
                            backgroundColor: "unset",
                            border: "1px dotted  #ccc",
                            padding: "2px",
                            outline: "none",
                            borderRadius: "8px",
                          }}
                          type="text"
                          value={formData?.releventMedicalInformation?.hypersensitivities ?? ""}
                          onChange={e => {
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              releventMedicalInformation: {
                                ...formData?.releventMedicalInformation,
                                hypersensitivities: e.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Card className="match-card-body-second p-3">
                <Row>
                  <label className="fw-500 mb-2" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    <u>PATIENT PAIN LEVEL:</u>
                  </label>
                </Row>
                <Row>
                  <Col>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                            On a scale from 1 to 10, with <b>1=NO PAIN</b> and <b>10=WORST PAIN IMAGINABLE</b>, please rate your current level of physical pain
                            :
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                            type="number"
                            value={formData?.releventMedicalInformation?.patientPainLevel?.currentLevelOfPhysicalPain ?? ""}
                            max={10}
                            min={1}
                            onChange={e => {
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                releventMedicalInformation: {
                                  ...formData?.releventMedicalInformation,
                                  patientPainLevel: {
                                    ...formData?.releventMedicalInformation?.patientPainLevel,
                                    currentLevelOfPhysicalPain: Number(e.target.value),
                                    isReceivingTreatement: formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement ?? "",
                                  },
                                },
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={7}>
                    <h6 className="fw-500" style={{ margin: "0.2rem" }}>
                      If applicable, are you currently receiving treatment for your pain ?
                    </h6>
                  </Col>
                  <Col lg={3} md={3}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem",minWidth:"1rem" }}
                        type="radio"
                        className="form-check-input"
                        value={ReceivingTreatementPainStatus.na}
                        checked={formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement === ReceivingTreatementPainStatus.na}
                        onClick={e => {
                          const result = formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement === ReceivingTreatementPainStatus.na;
                          setFormData((formData: Partial<DigitalForm>) => ({
                            ...formData,
                            releventMedicalInformation: {
                              ...formData?.releventMedicalInformation,
                              patientPainLevel: {
                                ...formData?.releventMedicalInformation?.patientPainLevel,
                                isReceivingTreatement: result ? "" : ReceivingTreatementPainStatus.na,
                              },
                            },
                          }));
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        N/A
                      </label>
                    </div>
                  </Col>
                  <Col lg={2} md={3}>
                    <div className="col-auto d-flex">
                      <input
                        style={{ fontSize: "larger", marginRight: "0.5rem",minWidth:"1rem" }}
                        type="radio"
                        className="form-check-input"
                        value={ReceivingTreatementPainStatus.no}
                        checked={formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement === ReceivingTreatementPainStatus.no}
                        onClick={e => {
                          const result = formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement === ReceivingTreatementPainStatus.no;
                          setFormData((formData: Partial<DigitalForm>) => ({
                            ...formData,
                            releventMedicalInformation: {
                              ...formData?.releventMedicalInformation,
                              patientPainLevel: {
                                ...formData?.releventMedicalInformation?.patientPainLevel,
                                isReceivingTreatement: result ? "" : ReceivingTreatementPainStatus.no,
                              },
                            },
                          }));
                        }}
                      />
                      <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                        No
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={3}>
                    <div className="row">
                      <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                        <input
                          style={{ fontSize: "larger" }}
                          type="radio"
                          className="form-check-input"
                          value={ReceivingTreatementStatus.yes}
                          checked={formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement === ReceivingTreatementStatus.yes}
                          onClick={e => {
                            const result = formData?.releventMedicalInformation?.patientPainLevel?.isReceivingTreatement === ReceivingTreatementStatus.yes;
                            setFormData((formData: Partial<DigitalForm>) => ({
                              ...formData,
                              releventMedicalInformation: {
                                ...formData?.releventMedicalInformation,
                                patientPainLevel: {
                                  ...formData?.releventMedicalInformation?.patientPainLevel,
                                  isReceivingTreatement: result ? "" : ReceivingTreatementStatus.yes,
                                },
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col">
                        <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                          RECEIVING TREATMENT, in treatment with
                        </h6>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="table-responsive">
                  <Table responsive className="clinical-table-bordered table-md ">
                    <thead>
                      <tr>
                        <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                          <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                            <b> Medications </b>
                          </div>
                          <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                            (Including OTC)
                          </div>
                        </th>
                        <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                          <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                            Dose
                          </div>
                        </th>
                        <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                          <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                            Frequency
                          </div>
                        </th>
                        <th style={{ border: "1px solid #B2B2B2", textAlign: "center", verticalAlign: "middle" }}>
                          <div className="d-flex align-items-center justify-content-center" style={{ fontSize: "12px" }}>
                            Last Use
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails) &&
                        formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails.map((trdetails: TreatementDetails, index: number) => (
                          <tr key={index}>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{
                                    fontSize: "0.70rem",
                                    width: "100%",
                                    border: "none",
                                    backgroundColor: "unset",
                                    textAlign: "left",
                                    paddingLeft: "0.2rem",
                                  }}
                                  type="text"
                                  value={formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails?.[index]?.medication ?? ""}
                                  onChange={e => {
                                    const newDetails = [...(formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails ?? [])];
                                    newDetails[index] = { ...newDetails[index], medication: e.target.value };
                                    setFormData({
                                      ...formData,
                                      releventMedicalInformation: {
                                        ...formData?.releventMedicalInformation,
                                        patientPainLevel: {
                                          ...formData?.releventMedicalInformation?.patientPainLevel,
                                          treatementDetails: newDetails,
                                        },
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{
                                    fontSize: "0.70rem",
                                    width: "100%",
                                    border: "none",
                                    backgroundColor: "unset",
                                    textAlign: "left",
                                    paddingLeft: "0.2rem",
                                  }}
                                  type="text"
                                  value={formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails?.[index]?.does ?? ""}
                                  onChange={e => {
                                    const newDetails = [...(formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails ?? [])];
                                    newDetails[index] = { ...newDetails[index], does: e.target.value };
                                    setFormData({
                                      ...formData,
                                      releventMedicalInformation: {
                                        ...formData?.releventMedicalInformation,
                                        patientPainLevel: {
                                          ...formData?.releventMedicalInformation?.patientPainLevel,
                                          treatementDetails: newDetails,
                                        },
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{
                                    fontSize: "0.70rem",
                                    width: "100%",
                                    border: "none",
                                    backgroundColor: "unset",
                                    textAlign: "left",
                                    paddingLeft: "0.2rem",
                                  }}
                                  type="text"
                                  value={formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails?.[index]?.frequency ?? ""}
                                  onChange={e => {
                                    const newDetails = [...(formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails ?? [])];
                                    newDetails[index] = { ...newDetails[index], frequency: e.target.value };
                                    setFormData({
                                      ...formData,
                                      releventMedicalInformation: {
                                        ...formData?.releventMedicalInformation,
                                        patientPainLevel: {
                                          ...formData?.releventMedicalInformation?.patientPainLevel,
                                          treatementDetails: newDetails,
                                        },
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="col-auto">
                                <input
                                  style={{
                                    fontSize: "0.70rem",
                                    width: "100%",
                                    border: "none",
                                    backgroundColor: "unset",
                                    textAlign: "left",
                                    paddingLeft: "0.2rem",
                                  }}
                                  type="date"
                                  value={
                                    formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails?.[index]?.lastUse
                                      ? moment
                                          .utc(formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails?.[index]?.lastUse)
                                          .format("YYYY-MM-DD")
                                      : ""
                                  }
                                  onChange={e => {
                                    const newDetails = [...(formData?.releventMedicalInformation?.patientPainLevel?.treatementDetails ?? [])];
                                    newDetails[index] = { ...newDetails[index], lastUse: e.target.value };
                                    setFormData({
                                      ...formData,
                                      releventMedicalInformation: {
                                        ...formData?.releventMedicalInformation,
                                        patientPainLevel: {
                                          ...formData?.releventMedicalInformation?.patientPainLevel,
                                          treatementDetails: newDetails,
                                        },
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <div className="py-3 d-flex justify-content-start">
                      {edit && (
                        <Button color="primary" onClick={addMedicationDetails}>
                          Add More Details
                        </Button>
                      )}
                    </div>
                  </Table>

                  <Table className="clinical-table-bordered " style={{ textAlign: "left" }}>
                    <tbody>
                      <tr>
                        <td className="fw-500" colSpan={3} style={{ padding: "0.2rem", width: "25%", fontWeight: "bold" }}>
                          Disability Status
                        </td>
                        <td colSpan={3} style={{ padding: "0.2rem", width: "25%" }}>
                          <Table className="tableTwo">
                            <tbody>
                              <tr>
                                <td style={{ width: "25%", padding: "0.1rem" }}>
                                  <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    className="form-check-input"
                                    value={DisabilityStatus.hearingImpairment}
                                    checked={
                                      formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.hearingImpairment ===
                                      DisabilityStatus.hearingImpairment
                                    }
                                    onClick={e => {
                                      setFormData((formData: Partial<DigitalForm>) => ({
                                        ...formData,
                                        releventMedicalInformation: {
                                          ...formData?.releventMedicalInformation,
                                          patientPainLevel: {
                                            ...formData?.releventMedicalInformation?.patientPainLevel,
                                            disablityStatus: {
                                              ...formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus,
                                              hearingImpairment:
                                                formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.hearingImpairment ===
                                                DisabilityStatus.hearingImpairment
                                                  ? ""
                                                  : DisabilityStatus.hearingImpairment,
                                              sightImpairment: "",
                                              intellectualDevelopmentalDisability: "",
                                              other: "",
                                              none: "",
                                              comment: "",
                                            },
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={!edit}
                                  />
                                  <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                    Hearing Impairment
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "25%", padding: "0.1rem" }}>
                                  <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    className="form-check-input"
                                    value={DisabilityStatus.sightImpairment}
                                    checked={
                                      formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.sightImpairment ===
                                      DisabilityStatus.sightImpairment
                                    }
                                    onClick={e => {
                                      setFormData((formData: Partial<DigitalForm>) => ({
                                        ...formData,
                                        releventMedicalInformation: {
                                          ...formData?.releventMedicalInformation,
                                          patientPainLevel: {
                                            ...formData?.releventMedicalInformation?.patientPainLevel,
                                            disablityStatus: {
                                              ...formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus,
                                              sightImpairment:
                                                formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.sightImpairment ===
                                                DisabilityStatus.sightImpairment
                                                  ? ""
                                                  : DisabilityStatus.sightImpairment,
                                              hearingImpairment: "",

                                              intellectualDevelopmentalDisability: "",
                                              other: "",
                                              none: "",
                                              comment: "",
                                            },
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={!edit}
                                  />
                                  <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                    Sight Impairment
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td colSpan={3} style={{ padding: "0.2rem", width: "35%" }}>
                          <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                            <tbody>
                              <tr>
                                <td style={{ width: "25%", padding: "0.1rem" }}>
                                  <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    className="form-check-input"
                                    value={DisabilityStatus.intellectualDevelopmentalDisability}
                                    checked={
                                      formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.intellectualDevelopmentalDisability ===
                                      DisabilityStatus.intellectualDevelopmentalDisability
                                    }
                                    onClick={e => {
                                      setFormData((formData: Partial<DigitalForm>) => ({
                                        ...formData,
                                        releventMedicalInformation: {
                                          ...formData?.releventMedicalInformation,
                                          patientPainLevel: {
                                            ...formData?.releventMedicalInformation?.patientPainLevel,
                                            disablityStatus: {
                                              ...formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus,
                                              intellectualDevelopmentalDisability:
                                                formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.intellectualDevelopmentalDisability ===
                                                DisabilityStatus.intellectualDevelopmentalDisability
                                                  ? ""
                                                  : DisabilityStatus.intellectualDevelopmentalDisability,
                                              hearingImpairment: "",
                                              sightImpairment: "",
                                              other: "",
                                              none: "",
                                              comment: "",
                                            },
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={!edit}
                                  />
                                  <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                    Intellectual/ <br />
                                    Developmental Disability
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "25%", padding: "0.1rem" }}>
                                  <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    className="form-check-input"
                                    value={DisabilityStatus.other}
                                    checked={formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.other === DisabilityStatus.other}
                                    onClick={e => {
                                      setFormData((formData: Partial<DigitalForm>) => ({
                                        ...formData,
                                        releventMedicalInformation: {
                                          ...formData?.releventMedicalInformation,
                                          patientPainLevel: {
                                            ...formData?.releventMedicalInformation?.patientPainLevel,
                                            disablityStatus: {
                                              ...formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus,
                                              other:
                                                formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.other === DisabilityStatus.other
                                                  ? ""
                                                  : DisabilityStatus.other,
                                              hearingImpairment: "",
                                              sightImpairment: "",
                                              intellectualDevelopmentalDisability: "",
                                              none: "",
                                              comment: "",
                                            },
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={!edit}
                                  />
                                  <div className="col">
                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                      <div className="row">
                                        <div className="col-auto">
                                          <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                            Other (specify):
                                          </h6>
                                        </div>
                                        <div className="col">
                                          <input
                                            style={{
                                              width: "100%",
                                              border: "none",
                                              marginTop: "0.1rem",
                                              backgroundColor: "unset",
                                            }}
                                            disabled={formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.other !== DisabilityStatus.other}
                                            value={formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.comment}
                                            type="text"
                                            onChange={e => {
                                              setFormData((formData: Partial<DigitalForm>) => ({
                                                ...formData,
                                                releventMedicalInformation: {
                                                  ...formData?.releventMedicalInformation,
                                                  patientPainLevel: {
                                                    ...formData?.releventMedicalInformation?.patientPainLevel,
                                                    disablityStatus: {
                                                      ...formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus,
                                                      comment: e.target.value,
                                                    },
                                                  },
                                                },
                                              }));
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                        <td colSpan={3} style={{ padding: "0.2rem", width: "15%" }}>
                          <Table className="tableTwo" style={{ width: "100%", borderCollapse: "collapse" }}>
                            <tbody>
                              <tr>
                                <td style={{ width: "25%", padding: "0.1rem" }}>
                                  <input
                                    style={{ fontSize: "larger" }}
                                    type="radio"
                                    className="form-check-input"
                                    value={DisabilityStatus.none}
                                    checked={formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.none === DisabilityStatus.none}
                                    onClick={e => {
                                      setFormData((formData: Partial<DigitalForm>) => ({
                                        ...formData,
                                        releventMedicalInformation: {
                                          ...formData?.releventMedicalInformation,
                                          patientPainLevel: {
                                            ...formData?.releventMedicalInformation?.patientPainLevel,
                                            disablityStatus: {
                                              ...formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus,
                                              none:
                                                formData?.releventMedicalInformation?.patientPainLevel?.disablityStatus?.none === DisabilityStatus.none
                                                  ? ""
                                                  : DisabilityStatus.none,
                                              comment: "",
                                              hearingImpairment: "",
                                              sightImpairment: "",
                                              intellectualDevelopmentalDisability: "",
                                              other: "",
                                            },
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={!edit}
                                  />
                                  <label className="fw-500" style={{ margin: "0.1rem 0.1rem 0rem 0.2rem", marginInlineStart: "0.3rem" }}>
                                    None
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <Row>
                  <label className="fw-500" style={{ margin: "0.1rem", marginInlineStart: "0.3rem", fontWeight: "bold" }}>
                    If a disability exists :
                  </label>
                </Row>
                <Row>
                  <label className="fw-500" style={{ margin: "0.1rem", marginInlineStart: "0.3rem" }}>
                    Describe :
                  </label>
                </Row>
                <Row>
                  <Col>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem", marginInlineStart: "0.3rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                            How are you adjusting to your disability ?
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={!fieldsEnabled}
                            value={formData?.releventMedicalInformation?.patientPainLevel?.adjustDisability ?? ""}
                            onChange={e => {
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                releventMedicalInformation: {
                                  ...formData?.releventMedicalInformation,
                                  patientPainLevel: {
                                    ...formData?.releventMedicalInformation?.patientPainLevel,
                                    adjustDisability: e.target.value,
                                  },
                                },
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.1rem", marginInlineStart: "0.3rem" }}>
                      <div className="row">
                        <div className="col-auto">
                          <h6 className="fw-500" style={{ margin: "0.1rem" }}>
                            Do you require any special services or assistive equipment ?
                          </h6>
                        </div>
                        <div className="col">
                          <input
                            style={{
                              width: "100%",
                              // border: "none",
                              marginTop: "0.1rem",
                              backgroundColor: "unset",
                              border: "1px dotted  #ccc",
                              padding: "2px",
                              outline: "none",
                              borderRadius: "8px",
                            }}
                            type="text"
                            disabled={!fieldsEnabled}
                            value={formData?.releventMedicalInformation?.patientPainLevel?.requireEquipmentOrServices}
                            onChange={e => {
                              setFormData((formData: Partial<DigitalForm>) => ({
                                ...formData,
                                releventMedicalInformation: {
                                  ...formData?.releventMedicalInformation,
                                  patientPainLevel: {
                                    ...formData?.releventMedicalInformation?.patientPainLevel,
                                    requireEquipmentOrServices: e.target.value,
                                  },
                                },
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Card>
          </div>
        </Row>

        <Row>
          <Col xs={4}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
              }}
              onClick={() => {
                previousStep();
              }}
            >
              <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
              Back
            </button>
          </Col>
          <Col xs={8}>
            <button
              type="button"
              className="btn btn-skip  relative mt-3 mr-2 mb-4"
              style={{
                bottom: "0",
                float: "right",
                paddingBottom: "7.5px !important",
              }}
              onClick={() => {
                handleUpdateData();
              }}
            >
              Next
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2 relative mt-3 mb-2"
              style={{
                bottom: "0",
                float: "right",
              }}
              onClick={() => {
                // updateAssesmentDetails();
                handleUpdateData();
                // nextStep();
              }}
            >
              {"Save & Continue"}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>
                Page 5 of 11
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default NewFormFive;
