import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Card, CardBody, Row, Col, NavLink } from "reactstrap";
import Swal from "sweetalert2";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { FeedbackList } from "../../models/Feedback";
import { AdminService } from "../../services/AdminService";
import Spinner from "../../common/spinner/spinner";
import FeedbackContext from "src/context/UserFeedbackCount";

const ViewFeedback: React.FC = () => {
  const [feedback, setFeedback] = useState<FeedbackList[]>([]);
  const [userFeedbackCount, setUserFeedbackCount] = useContext(FeedbackContext);
  const [userCount, setUserCount] = useState(20);
  const [offset, setOffset] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const LIMIT = 20;

  useEffect(() => {
    getAllFeedbacks();
  }, []);

  const getAllFeedbacks = () => {
    setIsLoading(true)

    AdminService.getAllFeedbacks(LIMIT, offset).then(res => {
      if (res.success) {
        setFeedback(res.data.feedbackList);
        setUserCount(res.data.feedbackList.length);
        setIsLoading(false)
      } else {
        setFeedback([]);
        setHasMore(false);
        setIsLoading(false)
      }
    });
  };



  const markAsRead = (feedbackId: string) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to mark as read?",
      showCancelButton: true,
      confirmButtonText: "Mark as Read",
      confirmButtonColor: "#FD7F00",
      cancelButtonColor: "#FD7F00",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.updateFeedbackStatus(feedbackId).then(res => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Successfully Updated!",
              confirmButtonColor: "#FD7F00",
            });
            getAllFeedbacks();
            setUserFeedbackCount(userFeedbackCount - 1);
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed update. Please try again later.",
              confirmButtonColor: "#FD7F00",
            });
          }
        });
      }
    });

  };

  const seeMore = () => {
    const updatedSkip = offset + 1;

    setOffset(updatedSkip);

    setIsLoading(true);

    AdminService.getAllFeedbacks(20, updatedSkip).then(res => {
      if (res.success) {
        res.data.feedbackList.map((feedback: any) => {
          setFeedback(feedbacks => [...feedbacks, feedback]);
        });
        setUserCount(res.data.feedbackList.length);
      }

      setIsLoading(false);
    });
  };

  const navigateToProfile = (userId: string) => {
    history.push(`/profile/${userId}`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Feedback"} breadcrumbItem={"Feedback"} />
          <Card className="m-h">
            <CardBody>
              <Row>
                <Col sm="12" className="mt10">
                  {feedback !== undefined && feedback.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">User</th>
                              <th scope="col">Created Date</th>
                              <th scope="col">Feedback</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {feedback &&
                              feedback.map((feedback: FeedbackList, index: number) => (
                                <tr key={feedback?._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>
                                    <NavLink
                                      className="px-0 py-0"
                                      onClick={() => {
                                        navigateToProfile(feedback?.userId?._id);
                                      }}
                                    >
                                      {feedback?.userId?.firstname} {feedback?.userId?.lastname}
                                    </NavLink>
                                  </td>
                                  <td>{moment(feedback?.createdAt).format("YYYY-MM-DD hh:mm A")}</td>
                                  <td>{feedback?.feedback}</td>
                                  <td>
                                    {feedback?.isRead == false ?
                                      <button
                                        className="btn btn-success btn-sm"
                                        onClick={() => {
                                          markAsRead(feedback?._id);
                                        }}
                                      >
                                        Mark as Read
                                      </button>
                                      :
                                      <span className="inprogress-2">Seen</span>
                                    }
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      {userCount >= 20 && (
                        <>
                          <button className="btn btn-warning text-center mt-4" onClick={seeMore}>
                            See More
                          </button>
                        </>
                      )}
                      {userCount == 0 && <span>Feedbacks not found....</span>}
                    </>
                  ) : (
                    <>
                      {isLoading ? <Spinner /> : <div>Feedbacks not found.</div>}</>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default ViewFeedback;

