import React, { useContext } from "react";
import UserContext from "src/context/UserContext";
import { Util } from "src/Util";
import parse from "html-react-parser";
import { ClientChatMessageModel } from "src/models/AdminClientChat/ClientChatMessage";
import { decryptAdminClientMessage } from "src/pages/Chat/GroupChat/Common/AdminClientChatEncryption";
window.Buffer = window.Buffer || require("buffer").Buffer;

interface Props {
  selectedMessage: ClientChatMessageModel | undefined;
  closeModal: () => void;
}

const ClientChatReplyMessageDisplayComp = ({ selectedMessage, closeModal }: Props) => {
  const [user, setUser] = useContext(UserContext);

  return (
    <div className="content-wrapper1 break-word-show-msg">
      <div className="reply-content">
        <span
          style={{
            backgroundColor: "#FD7F00",
            height: "100%",
            width: "1px",
          }}
        ></span>
        <div className="receiver1">
          {selectedMessage && (selectedMessage.createdBy == user?._id || selectedMessage.createdBy == "SUPER_ADMIN")
            ? "You"
            : selectedMessage && selectedMessage.firstname
            ? selectedMessage.firstname
            : "Client"}
        </div>
        <i
          className="fa fa-times pointer1"
          style={{ padding: "5px 0px 0px 10px" }}
          onClick={() => {
            closeModal();
          }}
        ></i>
      </div>
      <div className="replying-message">
        <p className="m-0">{selectedMessage && selectedMessage.messageText ? parse(decryptAdminClientMessage(selectedMessage.messageText)) : ""}</p>
      </div>
    </div>
  );
};

export default ClientChatReplyMessageDisplayComp;
