import React, { useContext, useEffect, useState } from "react";
import { Modal, Row, Col, CardTitle } from "reactstrap";
import Close from "../../assets/images/icons/u_times-circle.png";
import { Input } from "reactstrap";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import AiIcon from "../../assets/images/icons/AI_animation_logo.gif";
import { TherapistService } from "src/services/TherapistService";
import Spinner from "./../../common/spinner/spinner";
import NewClinicalAssessmentModal from "../Popup/NewClinicalAssessmentModal";
import { DigitalForm } from "src/models/AssessmentDigitalForm/AssessmentDigitalForms";
import UserContext from "src/context/UserContext";


interface AssessmentAiGeneratedProps {
  closeModelFunction: (isCalled: boolean) => void;
  transcribeData: any;
  OpenAiModel: boolean;
  refreshModal32: (isCalled: boolean) => void;
  isInMeeting: boolean;
  clientId?: any;
}

const AIGeneratedAssessment: React.FC<AssessmentAiGeneratedProps> = ({ transcribeData, OpenAiModel, closeModelFunction, refreshModal32, isInMeeting, clientId }: AssessmentAiGeneratedProps) => {

  const [showAiGeneratedPopup, setShowAiGeneratedPopup] = useState(OpenAiModel);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isDataLoadingError, setIsDataLoadingError] = useState<boolean>(false);
  const [modal_center, setModalCenter] = React.useState(false);
  const [addReview, setReview] = useState<any>({} as any);
  const [rating1, setRating1] = useState(0);
  const [generatedAssessmentData, setGeneratedAssessmentData] = useState<Partial<DigitalForm>>();
  const [showNewClinicalAssessmentModal, setShowNewClinicalAssessmentModal] = useState<boolean>(false);
  const [loggedTherapist] = useContext(UserContext);
  const [transcribeClientUserId, setTranscribeClientUserId] = useState("");
  
  useEffect(() => {
    startInitialProcessById();
  }, []);

  useEffect(() => {
    if (generatedAssessmentData && generatedAssessmentData != null) {
      setShowNewClinicalAssessmentModal(true);
    }
  }, [generatedAssessmentData]);

  const startInitialProcessById = () => {
    setShowNewClinicalAssessmentModal(false);
    setIsDataLoading(true);
    setIsDataLoadingError(false);
    // // This part modified for show and generate AI assessment for marcus king therapist.
    let data;
    if(loggedTherapist?._id === "62f17eb1b81f31a68505d9ad") {
      setTranscribeClientUserId(clientId);
      data = {
        meetingId: "62ddf99ef1dff8eded242e82",
        clientId: "61ccb52623949c3c55888e0e"
      }
    } else {
      setTranscribeClientUserId(transcribeData?.clientId);
      data = {
        meetingId: transcribeData?._id,
        clientId: transcribeData?.clientId
      }
    }
    TherapistService.getPreviousAIGeneratedAssessment(data).then(res => {
      if (res && res?.success && res?.data) {
        setShowAiGeneratedPopup(false);
        setGeneratedAssessmentData(res?.data);
        setIsDataLoadingError(false);
        setIsDataLoading(false);
      } else if (res?.data === null || res?.data === undefined) {
        getOpenAIResponse();
      } else {
        setIsDataLoadingError(true);
        setIsDataLoading(false);
      }
    });
  };

  const getOpenAIResponse = async () => {
    // // This part modified for show and generate AI assessment for marcus king therapist.
    let relatedMeetingId;
    if(loggedTherapist?._id === "62f17eb1b81f31a68505d9ad") {
      relatedMeetingId = "Regular Call - 38396";
    } else {
      relatedMeetingId = transcribeData.meetingId;
    }

    await TherapistService.generateOpenAIAssessment(relatedMeetingId).then((res:any) => {
      if (res?.success) {
        setShowAiGeneratedPopup(false);
        setGeneratedAssessmentData(res?.data);

        // TherapistService.getAiGenerateCount().then(res => {
        //   if (res.success) {
        //     const currentCount = res.data || 0
        //     const Testdata = {
        //       aiGenerateCount: currentCount + 1
        //     }

        //     TherapistService.updateAiGenerateCount(Testdata as any).then(res => {
        //       if (res?.data?.aiGenerateCount == 5 && res?.data?.aiReviewSubmitted !== true) {
        //         setModalCenter(true);
        //       } else if (res?.data?.aiGenerateCount == 10 && res?.data?.aiReviewSubmitted !== true) {
        //         setModalCenter(true);
        //       } else if (res?.data?.aiGenerateCount == 15 && res?.data?.aiReviewSubmitted !== true) {
        //         setModalCenter(true);
        //       } else if (res?.data?.aiGenerateCount == 20 && res?.data?.aiReviewSubmitted !== true) {
        //         setModalCenter(true);
        //       }
        //     });
        //   }
        // });

        toast.success("Assessment is generated!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      } else {
        if (res.errorCode == 404) {
          toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error("Unable to generate AI Assessment at this time!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setShowAiGeneratedPopup(false);
      }

      setIsLoading(false);
    });
  };

  function togCenterReview() {
    setModalCenter(!modal_center);
  }

  const AddReviewData = () => {
    const reviewData = { review: addReview.review, noOfStars: rating1 };
    TherapistService.addTherapistReview(reviewData).then(res => {
      if (res.success) {
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
        setReview({ review: "" });
        setRating1(0);
        setModalCenter(false);
      } else {
        toast.error(res.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    });
  };

  const closeNewAssessmentModal = (value: boolean) => {
    refreshModal32(true);
    setShowNewClinicalAssessmentModal(value);
    setTranscribeClientUserId("");
  };

  return (
    <React.Fragment>
      {showNewClinicalAssessmentModal  && <NewClinicalAssessmentModal showModal={showNewClinicalAssessmentModal} identifierId={transcribeClientUserId} setShowModal={closeNewAssessmentModal} isFromMeeting={isInMeeting} isAdmin={false} setModalRefresher={refreshModal32} aiGeneratedAssessmentData={generatedAssessmentData}  isMeeting={false}/>}
      <>
        <Modal isOpen={showAiGeneratedPopup} centered className="modal-lg">
          <div className="modal-body" style={{ zIndex: 9998, textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "15px" }}>
            <div className="call-title flex flex-column justify-content-center align-items-center" style={{ paddingBottom: "15px" }}>
              <div className="image-container2">
                <div className="button-container btn-container-position-top">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAiGeneratedPopup(false);
                      closeModelFunction(false);
                    }}
                    className="bg-transparent-color"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src={Close} alt="Close" className="" />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <img src={AiIcon} className="ai-icon-size-popup" />
              <div className="mb-5"></div>
              <h5 className="font-size-15 ai-titile mb-3 mt-2">{isLoading ? "Please wait for generating your Clinical Assessment using JSI" : "Generating your Digital Assessment using JSI"}</h5>
              <div className="mt-5"></div>
            </div>
            <div className="">
              {isLoading && (
                <div className={`h-100`}
                  style={{
                    minHeight: isLoading ? "300px" : "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={modal_center}
          toggle={() => {
            togCenterReview();
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">How was your AI session notes experience? </h5>
            <button
              type="button"
              onClick={() => {
                setModalCenter(false);
                localStorage.removeItem("review");
              }}
              className="review-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span>&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <Row className="mb-4">
              <Col xl="12" style={{ display: "flex" }}>
                <CardTitle> Share your experience with us</CardTitle>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xl="12" style={{ display: "flex", alignItems: "center" }}>
                <CardTitle className="mr-2">Rate:</CardTitle>
                <Rating initialValue={rating1} onClick={(rate: any) => setRating1(rate)} size={30} transition fillColor={"#F2C94C"} />
              </Col>
            </Row>
            <div className="mb-2">
              <Input
                type="textarea"
                className="form-control"
                id="formrow-firstname-Input"
                style={{ height: "100%", minHeight: "100px" }}
                placeholder="Your review..."
                onChange={e => setReview({ ...addReview, review: e.target.value })}
                value={addReview.review}
                name="articleTitle"
                required
              />
            </div>

            <Row>
              <Col xl="3"></Col>

              <Col xl="6">
                <button type="button" className="btn btn-primary w-45 " onClick={() => AddReviewData()}>
                  Save
                </button>
                <button
                  type="button"
                  className="btn  w-45 ml-3  card_shadow"
                  onClick={() => {
                    setModalCenter(false);
                  }}
                >
                  Cancel
                </button>
              </Col>
              <Col xl="3"></Col>
            </Row>
          </div>
        </Modal>
      </>
    </React.Fragment>
  );
};

export default AIGeneratedAssessment;
