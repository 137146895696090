export enum ReviewStatus {
  BLOCKED = "BLOCKED",
  PENDING = "PENDING",
  UNBLOCKED = "UNBLOCKED",
  APPROVED = "APPROVED",
}

export interface Report {
  _id: string;
  reported: ReportUser;
  reportedBy: ReportUser;
  reason: string;
  status : string;
}

export interface ReportUser {
  _id: string;
  firstname: string;
  lastname: string;
}
