import React, { useEffect, useState } from "react";
const classNames = require('classnames');
import { Row, Col, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import ChildTab from "../ChildTabs/ChildTab";
import { Phrase, TabTypes } from "../../../models/Notes";
import { NoteService } from "../../../services/NoteService";
import Spinner from "../../../common/spinner/spinner";

interface AssessmentTabProps {
  stage: string;
  activeVerticalTab: string;
  toggleVertical: (value: string) => void;
  assessments: string;
  setAssessments: (value: string) => void;
}

const AssessmentTab: React.FC<AssessmentTabProps> = ({ stage, activeVerticalTab, assessments, toggleVertical, setAssessments }: AssessmentTabProps) => {
  const [globalAssessmentPhrases, setGlobalAssessmentPhrases] = useState<Phrase[]>([]);
  const [significantPhrases, setSignificantPhrases] = useState<Phrase[]>([]);
  const [issuesPhrases, setIssuesPhrases] = useState<Phrase[]>([]);
  const [isLoded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    NoteService.getAllPhrases(stage).then(res => {
      if (res.success) {
        setGlobalAssessmentPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.GLOBAL_ASSESSMENT));
        setSignificantPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.SIGNIFICANT_DEVELOPMENTS));
        setIssuesPhrases(res.data.filter((item: Phrase) => item.tabType === TabTypes.OUTSTANDING_ISSUES));
        setIsLoaded(true);
      } else {
        setGlobalAssessmentPhrases([]);
        setSignificantPhrases([]);
        setIssuesPhrases([]);
        setTimeout(() => {
          setIsLoaded(true);
        }, 500);
      }
    });
  }, [stage]);

  return (
    <>
      {!isLoded && <Spinner className="bouncer" />}
      <Row>
        <Col lg={3}>
          <Nav pills className="flex-column">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "1",
                })}
                onClick={() => {
                  toggleVertical("1");
                }}
                to={""}
              >
                Global Assessment
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  "mb-2": true,
                  active: activeVerticalTab === "3",
                })}
                onClick={() => {
                  toggleVertical("3");
                }}
                to={""}
              >
                Significant Developments
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: activeVerticalTab === "4",
                })}
                onClick={() => {
                  toggleVertical("4");
                }}
                to={""}
              >
                Outstanding Issues
              </NavLink>
            </NavItem>
          </Nav>
        </Col>

        <Col lg={9}>
          <TabContent activeTab={activeVerticalTab} className="text-muted">
            {globalAssessmentPhrases && globalAssessmentPhrases.length > 0 && (
              <ChildTab
                tabId={"1"}
                states={globalAssessmentPhrases}
                tabType={TabTypes.GLOBAL_ASSESSMENT}
                message={"The client's estimated global assessment of functioning:"}
                assessments={assessments}
                setAssessments={setAssessments}
              />
            )}

            {significantPhrases && significantPhrases.length > 0 && (
              <ChildTab
                tabId={"3"}
                states={significantPhrases}
                tabType={TabTypes.SIGNIFICANT_DEVELOPMENTS}
                message={""}
                assessments={assessments}
                setAssessments={setAssessments}
              />
            )}

            {issuesPhrases && issuesPhrases.length > 0 && (
              <ChildTab
                tabId={"4"}
                states={issuesPhrases}
                tabType={TabTypes.OUTSTANDING_ISSUES}
                message={""}
                assessments={assessments}
                setAssessments={setAssessments}
              />
            )}
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default AssessmentTab;
