import axios from "axios";
import { ArticleResponseModel, PopularPostResponse, ViewArticleModel } from "../models/Article";
import { ExperienceTag } from "../models/ExperienceTag";
import { HashTag } from "../models/HashTag";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

export class ArticleService {
  public static async getAllExperienceTags(): Promise<AppResponse<ExperienceTag[]>> {
    const url = Util.apiAuthUrl(`getExperienceTags`);
    return await axios.get<void, AppResponse<ExperienceTag[]>>(url);
  }
  public static async getAllPublicExperienceTags(): Promise<AppResponse<ExperienceTag[]>> {
    const url = Util.apiPublicUrl(`getPublicExperienceTags`);
    return await axios.get<void, AppResponse<ExperienceTag[]>>(url);
  }

  public static async getAllHashTags(limit?: number, offset?: number): Promise<AppResponse<HashTag[]>> {
    const url = Util.apiAuthUrl(`getAllHashTags/${limit}/${offset}`);
    return await axios.get<void, AppResponse<HashTag[]>>(url);
  }
  public static async getAllPublicHashTags(limit?: number, offset?: number): Promise<AppResponse<HashTag[]>> {
    const url = Util.apiPublicUrl(`getAllPublicHashTags/${limit}/${offset}`);
    return await axios.get<void, AppResponse<HashTag[]>>(url);
  }

  public static async createArticle(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`addArticle`);
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getAllArticles(limit: number, offset: number): Promise<AppResponse<ArticleResponseModel>> {
    const url = Util.apiAuthUrl("getAllArticles/" + limit + "/" + offset);
    return await axios.get<Partial<ArticleResponseModel>, AppResponse<ArticleResponseModel>>(url);
  }
  public static async getAllArticlesPublic(limit: number, offset: number): Promise<AppResponse<ArticleResponseModel>> {
    const url = Util.apiPublicUrl("getAllPublicArticles/" + limit + "/" + offset);
    return await axios.get<Partial<ArticleResponseModel>, AppResponse<ArticleResponseModel>>(url);
  }

  public static async getArticleById(id: string): Promise<AppResponse<ViewArticleModel>> {
    const url = Util.apiAuthUrl("getArticleById/" + id);
    return await axios.get<Partial<ViewArticleModel>, AppResponse<ViewArticleModel>>(url);
  }
  public static async getArticleByPublicId(id: string): Promise<AppResponse<ViewArticleModel>> {
    const url = Util.apiPublicUrl("getArticleById/" + id);
    return await axios.get<Partial<ViewArticleModel>, AppResponse<ViewArticleModel>>(url);
  }

  public static async deleteArticle(id: string): Promise<AppResponse<ViewArticleModel>> {
    const url = Util.apiAuthUrl("deleteArticle/" + id);
    return await axios.delete<Partial<ViewArticleModel>, AppResponse<ViewArticleModel>>(url);
  }

  public static async updateArticleWithoutFile(id: string, data: any): Promise<AppResponse<ViewArticleModel>> {
    const url = Util.apiAuthUrl(`updateArticleWithoutFile/` + id);
    return await axios.post<Partial<ViewArticleModel>, AppResponse<ViewArticleModel>>(url, data);
  }

  public static async searchArticlesByTags(data: any, limit: number, offset: number): Promise<AppResponse<ArticleResponseModel>> {
    const url = Util.apiAuthUrl("searchArticlesByTagsAndHashTags/" + limit + "/" + offset);
    return await axios.post<Partial<ArticleResponseModel>, AppResponse<ArticleResponseModel>>(url, data);
  }
  public static async searchPublicArticlesByTags(data: any, limit: number, offset: number): Promise<AppResponse<ArticleResponseModel>> {
    const url = Util.apiPublicUrl("searchPublicArticlesByTagsAndHashTags/" + limit + "/" + offset);
    return await axios.post<Partial<ArticleResponseModel>, AppResponse<ArticleResponseModel>>(url, data);
  }

  public static async searchArticlesByTypeAndTags(data: any, limit: number, offset: number): Promise<AppResponse<ArticleResponseModel>> {
    const url = Util.apiAuthUrl("searchArticlesByTags/" + limit + "/" + offset);
    return await axios.post<Partial<ArticleResponseModel>, AppResponse<ArticleResponseModel>>(url, data);
  }

  public static async addLike(id: string): Promise<AppResponse<ViewArticleModel>> {
    const url = Util.apiAuthUrl("likePost/" + id);
    return await axios.post<Partial<ViewArticleModel>, AppResponse<ViewArticleModel>>(url);
  }

  public static async addComment(id: string, data: any): Promise<AppResponse<ViewArticleModel>> {
    const url = Util.apiAuthUrl("addComment/" + id);
    return await axios.post<Partial<ViewArticleModel>, AppResponse<ViewArticleModel>>(url, data);
  }

  public static async addReply(data: any): Promise<AppResponse<ViewArticleModel>> {
    const url = Util.apiAuthUrl("addReply");
    return await axios.post<Partial<ViewArticleModel>, AppResponse<ViewArticleModel>>(url, data);
  }

  public static async getPopularPosts(): Promise<AppResponse<PopularPostResponse[]>> {
    const url = Util.apiAuthUrl("getPopularPosts");
    return await axios.get<Partial<PopularPostResponse>, AppResponse<PopularPostResponse[]>>(url);
  }
  public static async getPopularPublicPosts(): Promise<AppResponse<PopularPostResponse[]>> {
    const url = Util.apiPublicUrl("getPublicPopularPosts");
    return await axios.get<Partial<PopularPostResponse>, AppResponse<PopularPostResponse[]>>(url);
  }

  public static async updateCommentReply(id: string, data: any): Promise<AppResponse<ViewArticleModel>> {
    const url = Util.apiAuthUrl("updateCommentReply/" + id);
    return await axios.post<Partial<ViewArticleModel>, AppResponse<ViewArticleModel>>(url, data);
  }

  public static async getArticleByIdForPublic(id: string): Promise<AppResponse<ViewArticleModel>> {
    const url = Util.apiPublicUrl("getArticleById/" + id);
    return await axios.get<Partial<ViewArticleModel>, AppResponse<ViewArticleModel>>(url);
  }

  public static async getAllInsuranceCompanies(state: string): Promise<AppResponse<any>> {
    const data = {
      state
    }
    const url = Util.apiPublicUrl("getInsuaranceCompanyByState");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getAllSymptoms(): Promise<AppResponse<any>> {
    const url = Util.apiPublicUrl("getAllExperienceTagSymptomsPublic");
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }
}
