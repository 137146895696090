import React, { useEffect, useState } from "react";
import { Modal, Button, ModalBody, Row, Col } from "reactstrap";
import Close from "../../../assets/images/icons/close.png";
import { Client } from "src/models/Client";
import { Therapist } from "src/models/Therapist";
import { AdminService } from "src/services/AdminService";
import { Util } from "../../../Util";
import Swal from "sweetalert2";

type ModalComponentProps = {
  isOpen: boolean;
  toggle: () => void;
  therapistData: Therapist;
};

const UnmatchModal: React.FC<ModalComponentProps> = ({ isOpen, toggle, therapistData }) => {
  const [sentClients, setSentClients] = useState([] as Client[]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchableString, setIsSearchableString] = useState({searchableString: '',} as any);
  const [offset, setOffSet] = useState(1);
  const LIMIT = 4;

  useEffect(() => {
    setIsSearchableString({ ...isSearchableString, searchableString: '' })
    getClients();
  }, [therapistData, isOpen]);

  const confirmUnmatch = (client: Client) => {
    Swal.fire({
      title: "Confirmation",
      text: `Do you want to unmatch the client with this therapist? (Please note that, this will remove all scheduled appointments with this client.) Do you want to proceed?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes, unmatch",
      cancelButtonText: "No, cancel",
      confirmButtonColor: "#34C38F",
      cancelButtonColor: "#F24C4C",
    }).then(result => {
      if (result.isConfirmed) {
        AdminService.unmatchMatchedClientOrTherapist(therapistData?._id, client?._id).then((res: any) => {
          if (res.success) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "You have successfully unmatched the client with this therapist.",
            });
            {
              toggle();
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "failed!",
              text: "There is a issue when unmatching the client with this therapist.",
            });
          }
        });
      }
    });
  };

  const searchClients = () => {
    const discoverObject = {
      therapistId: therapistData?._id,
      searchableString: isSearchableString?.searchableString
    };

    setIsLoading(true);
    AdminService.searchMatchClientsByTherapistId(discoverObject, LIMIT, 1).then(res => {
      if (res.success) {
        if (res.data.userSet?.length > 0) {
          setSentClients(res.data.userSet);

        }
        setOffSet(1)
      }
      setIsLoading(false);
    });
  };

  const getClients = () => {
    const discoverObject = {
      therapistId: therapistData?._id,
      searchableString: isSearchableString?.searchableString
    };

    setIsLoading(true);
    AdminService.searchMatchClientsByTherapistId(discoverObject, LIMIT, offset).then(res => {
      if (res.success) {
        setSentClients(res.data.userSet);
        setOffSet(1)
      }
      setIsLoading(false);
    });
  };

  // const seeMoreFunc = () => {
  //   setIsLoading(true);
  //   const updatedSkip = offset + 1;

  //   setOffSet(updatedSkip);

  //   const discoverObject = {
  //     clientId: therapistData?._id,
  //     searchableString: isSearchableString?.searchableString
  //   };

  //   AdminService.searchMatchClientsByTherapistId(discoverObject, LIMIT, updatedSkip).then(res => {
  //     if (res.success) {
  //       if (res.data.userSet?.length > 0) {
  //         setSentClients([...sentClients, ...res.data.userSet]);
  //       }
  //     }
  //     setIsLoading(false);
  //   });
  // };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="modal-lg">
      <div style={{ textAlign: "center", paddingRight: "0px" }}>
        <span></span>
        <Button
          close
          onClick={toggle}
          style={{
            position: "absolute",
            right: "15px",
            background: "none",
            border: "none",
            padding: "0",
            zIndex: "10",
          }}
        >
          <img src={Close} alt="Close" className="me-2 mt-2" />
        </Button>
      </div>
      <ModalBody className="ps-4 pe-4">
        <Row>
          <span className="font-size-18 profileFont mb-2 cursor-pointer pt-4 text-center">Matched clients with <b>{therapistData?.firstname} {therapistData?.lastname}</b> therapist</span>
        </Row>
        <Row className="mb-2">
          <Col className="d-flex admin-filter">
            <div className="d-flex justify-content-center pr-2 ">
              <div className="d-flex justify-content-center align-items-center pr-2">
                <span className="text-center mb-0 single-line-text mr-2">Search by client : </span>
              </div>
              <div onClick={(event) => event.stopPropagation()}>
                <input className="search-input-stat form-control me-2" onChange={(e: any) => setIsSearchableString({ ...isSearchableString, searchableString: e.target.value })} />
              </div>

            </div>
            <span className="btn btn-warning  btn-sm  mx-2 d-flex justify-content-center align-items-center"
              onClick={() => {
                searchClients()
              }}
            >
              <i className="bx bx-search"></i>
            </span>
          </Col>
        </Row>
        {sentClients &&
          sentClients?.map((client, index) => (
            <Row key={client?._id} className="border-top border-bottom p-2">
              <div className="d-flex justify-content-between border-top border-bottom p-2">
                <div className="d-flex">
                  <div
                    className="img-thumbnail imageFit rounded-circle imageFit avatar-md items-center  profileImageShow"
                    style={{
                      backgroundImage: `url(${Util.fileURL(client.photoId?._id)} )`,
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  ></div>

                  <div className="d-flex flex-column ps-4 justify-content-center">
                    <h5 className="font-size-15 mb-1 text-left">
                      {client?.firstname} {client?.lastname}
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <button type="button" className="btn-match btn btn-primary mb-3 pt-2 pb-2" onClick={() => confirmUnmatch(client)}>
                    Unmatch
                  </button>
                </div>
              </div>
            </Row>
          ))}
        {/* {sentClients?.length > 1 && (
          <>
            <button className="btn btn-warning text-center mt-4" onClick={seeMoreFunc}>
              See More
            </button>
          </>
        )} */}

        {sentClients?.length == 0 && !isLoading && <div>No clients available.</div>}
      </ModalBody>
    </Modal>
  );
};

export default UnmatchModal;
