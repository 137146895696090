import React, { useState, useEffect } from "react";
import { UncontrolledTooltip } from "reactstrap";
import Spinner from "src/common/spinner/spinner";
import SessionHistoryModal from "src/pages/Popup/SessionHistoryModal";

interface PendingSessionNotesProps {
  pendingSessionNotes: any[];
}

const PendingSessionNotes: React.FC<PendingSessionNotesProps> = ({ pendingSessionNotes }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [clientDetails, setClientDetails] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState('asc');
  const [isLoading, setIsLoading] = useState(true);
  const [showSessionHistoryModal, setShowSessionHistoryModal] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<any>();

  useEffect(() => {
    setIsLoading(true);
    setClientDetails(pendingSessionNotes);
    setIsLoading(false);
  }, [pendingSessionNotes]);

  const handleNoteClick = (index: any) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleSessionNoteView = (clientId: string) => {
    setSelectedClientId(clientId);
    setShowSessionHistoryModal(true);
  };

  const toggleSorting = () => {
    if (sortType === 'asc') {
      sortingAZ();
      setSortType('desc');
    } else {
      sortingZA();
      setSortType('asc');
    }
  };

  const sortingAZ = () => {
    const sortedData = [...clientDetails].sort((a: any, b: any) => {
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setClientDetails(sortedData);
  };

  const sortingZA = () => {
    const sortedData = [...clientDetails].sort((a: any, b: any) => {
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setClientDetails(sortedData);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredClientDetails = clientDetails.filter((client) => {
    const fullName = `${client.clientDetails?.firstname} ${client.clientDetails?.lastname}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  return (
    <React.Fragment>
      {showSessionHistoryModal && (
        <SessionHistoryModal setShowModal={setShowSessionHistoryModal} showModal={showSessionHistoryModal} userId={selectedClientId} />
      )}
      <div className="clinical-notes-container">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="clinical-notes-header-container d-flex flex-lg-row flex-md-row flex-sm-column flex-column justify-content-between align-items-center">
              <div className="d-flex flex-row align-items-center fs-5 fw-bold">
                Pending Patient Session Notes: Clients List
                <span className="ms-2">
                  <i onClick={toggleSorting} className="bx bx-sort"></i>
                </span>
              </div>
              <div className="search-container d-flex ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search clients..."
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ width: '250px', height: "35px" }}
                />
              </div>
            </div>
            <div className="clinical-notes-list">
              {filteredClientDetails.length > 0 ? (
                filteredClientDetails.map((client, index) => (
                  <div
                    key={index}
                    className={`clinical-note-item ${index === activeIndex ? "active" : ""}`}
                    onClick={() => handleNoteClick(index)}
                  >
                    <div className="clinical-note-icon"></div>
                    <span className="clinical-note-name">
                      {client.clientDetails?.firstname} {client.clientDetails?.lastname}
                      {client.clientDetails?.pendingCount > 0 && (
                        <>
                          <span
                            className="pending-count-circle"
                            id={`pendingCountTooltip-${client.clientDetails?._id}`} 
                          >
                            {client.clientDetails?.pendingCount}
                          </span>
                          <UncontrolledTooltip
                            placement="bottom" 
                            target={`pendingCountTooltip-${client.clientDetails?._id}`}
                          >
                            Pending session notes count: {client.clientDetails?.pendingCount}
                          </UncontrolledTooltip>
                        </>
                      )}
                    </span>
                    <button
                      className="clinical-note-button"
                      onClick={() => {
                        console.log("clientDetails._id:", client.clientDetails?._id);
                        handleSessionNoteView(client.clientDetails?._id);
                      }}
                    >
                      View
                    </button>
                  </div>
                ))
              ) : (
                <p>No pending patient session notes available</p>
              )}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default PendingSessionNotes;
