import moment from "moment";
import React from "react";
import userImage from "../../assets/images/avatar.jpeg";

interface ReplyProps {
  firstname: string;
  lastname: string;
  reply: string;
  date: string;
  imageUrl: any;
}

const ReplySection: React.FC<ReplyProps> = ({
  firstname,
  lastname,
  reply,
  date,
  imageUrl,
}: ReplyProps) => {
  return (
    <div className="d-flex pt-3">
      <div className="avatar-xs me-3">
        <div className="avatar-title rounded-circle bg-light text-primary">
          {imageUrl == null ? (
            <img
              src={userImage}
              className="img-fluid d-block rounded-circle"
              style={{ height: "2rem" }}
            />
          ) : (
            <img
              src={imageUrl}
              className="img-fluid d-block rounded-circle"
              style={{ height: "2rem" }}
            />
          )}
        </div>
      </div>
      <div className="flex-grow-1">
        <h5 className="font-size-14 mb-1">
          {firstname == "CLIENT" || firstname == "THERAPIST"
            ? "#" + firstname
            : firstname}
          {lastname == undefined ? "" : lastname}&nbsp;
          <small className="text-muted float-end">
            {moment(date).format("YYYY/MM/DD")}
          </small>
        </h5>
        <p className="text-muted">{reply}</p>
      </div>
    </div>
  );
};

export default ReplySection;