import React, { useState, useEffect } from "react";
import { Col, Container, Row} from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepWizard from "react-step-wizard";
import AssessmentForm from "./AssessmentForm";
import { CommonService } from "src/services/CommonService";
import { ClinicalAssessmentGeneratePdf } from "src/models/clinicalAssesment/clinicalAssessmentGeneratePdf";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import html2PDF from "jspdf-html2canvas";
import Swal from "sweetalert2";
import { AdminService } from "src/services/AdminService";
import moment from "moment";
import { TPType } from "src/models/therapyPlan/therapyPlan";
import { FormVersionService } from "src/services/FormVersionService";

toast.configure();

const PdfGeneratePage: React.FC<{ identifierId: any, isAdmin: boolean, onPdfComplete: () => void; clinicalAssessmentType: TPType; }> = props => {
    const [buttonText, setButtonText] = useState('Download Clinical Assessment');
    const [isDisabled, setIsDisabled] = useState(false);
    const [assesmenData, setAssesmenData] = useState<ClinicalAssessmentGeneratePdf>();
    const identifierId = props.identifierId;
    const isAdmin = props.isAdmin;
    const clinicalAssessmentType = props.clinicalAssessmentType;

    useEffect(() => {
        getAssesmentData();
    }, []);

    const getAssesmentDataOld = async () => {
        try {
            if (isAdmin) {
                const data = {
                    assesmentId: identifierId
                }

                const res = await AdminService.getClinicalAssestmentDetailsForDownloadByAdmin(data);

                if (res && res?.success && res?.data && res?.data?.asessmentData) {
                    const updatedData = res?.data?.asessmentData;

                    if(updatedData?.assesmentHeader){
                        if(res?.data?.dateOfSession){
                            const formattedDate = moment.utc(res?.data?.dateOfSession).format("YYYY-MM-DD");
                            updatedData.assesmentHeader.dateOfSession = updatedData?.assesmentHeader?.dateOfSession ?? formattedDate;
                        }

                        if(res?.data?.dateOfBirth){
                            const formattedDate = moment.utc(res?.data?.dateOfBirth).toDate();
                            updatedData.assesmentHeader.dateOfBirth = updatedData?.assesmentHeader?.dateOfBirth ? moment.utc(updatedData?.assesmentHeader?.dateOfBirth).toDate() : formattedDate;
                        }
                        
                    }

                    if(updatedData?.generalInfor){
                        if(res?.data?.dateOfBirth){
                            updatedData.generalInfor.consumerDob = updatedData?.generalInfor?.consumerDob ? moment.utc(updatedData?.generalInfor?.consumerDob).format("YYYY-MM-DD") : moment.utc(res?.data?.dateOfBirth).format("YYYY-MM-DD");
                            updatedData.generalInfor.age = updatedData?.generalInfor?.age ?? moment().diff(moment.utc(res?.data?.dateOfBirth), 'years'); 
                        }
                        
                    }

                    setAssesmenData(updatedData);

                } else {
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }

            } else {
                const data = {
                    clientId: identifierId
                }

                const res = await CommonService.getClinicalAssestmentDetailsForDownload(data);
                
                if (res && res?.success && res?.data && res?.data?.asessmentData) {
                    const updatedData = res?.data?.asessmentData;

                    if(updatedData?.assesmentHeader){
                        if(res?.data?.dateOfSession){
                            const formattedDate = moment.utc(res?.data?.dateOfSession).format("YYYY-MM-DD");
                            updatedData.assesmentHeader.dateOfSession = updatedData?.assesmentHeader?.dateOfSession ?? formattedDate;
                        }

                        if(res?.data?.dateOfBirth){
                            const formattedDate = moment.utc(res?.data?.dateOfBirth).toDate();
                            updatedData.assesmentHeader.dateOfBirth = updatedData?.assesmentHeader?.dateOfBirth ? moment.utc(updatedData?.assesmentHeader?.dateOfBirth).toDate() : formattedDate;
                        }
                        
                    }

                    if(updatedData?.generalInfor){
                        if(res?.data?.dateOfBirth){
                            updatedData.generalInfor.consumerDob = updatedData?.generalInfor?.consumerDob ? moment.utc(updatedData?.generalInfor?.consumerDob).format("YYYY-MM-DD") : moment.utc(res?.data?.dateOfBirth).format("YYYY-MM-DD");
                            updatedData.generalInfor.age = updatedData?.generalInfor?.age ?? moment().diff(moment.utc(res?.data?.dateOfBirth), 'years'); 
                        }
                        
                    }

                    setAssesmenData(updatedData);
                } else {
                    toast.error("Please complete the assessment before downloading!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            }
        } catch (error) {
            toast.error("Something went wrong!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const getAssesmentData = async () => {
        try {
            const data = {
                assesmentId: identifierId
            }

            if (clinicalAssessmentType === TPType.ORIGINAL) {
                const res = await CommonService.getClinicalAssestmentDetailsForDownload(data);
                
                if (res && res?.success && res?.data && res?.data?.asessmentData) {
                    const updatedData = res?.data?.asessmentData;

                    if(updatedData?.assesmentHeader){
                        if(res?.data?.dateOfSession){
                            const formattedDate = moment.utc(res?.data?.dateOfSession).format("YYYY-MM-DD");
                            updatedData.assesmentHeader.dateOfSession = updatedData?.assesmentHeader?.dateOfSession ?? formattedDate;
                        }

                        if(res?.data?.dateOfBirth){
                            const formattedDate = moment.utc(res?.data?.dateOfBirth).toDate();
                            updatedData.assesmentHeader.dateOfBirth = updatedData?.assesmentHeader?.dateOfBirth ? moment.utc(updatedData?.assesmentHeader?.dateOfBirth).toDate() : formattedDate;
                        }
                        
                    }

                    if(updatedData?.generalInfor){
                        if(res?.data?.dateOfBirth){
                            updatedData.generalInfor.consumerDob = updatedData?.generalInfor?.consumerDob ? moment.utc(updatedData?.generalInfor?.consumerDob).format("YYYY-MM-DD") : moment.utc(res?.data?.dateOfBirth).format("YYYY-MM-DD");
                            updatedData.generalInfor.age = updatedData?.generalInfor?.age ?? moment().diff(moment.utc(res?.data?.dateOfBirth), 'years'); 
                        }
                        
                    }

                    setAssesmenData(updatedData);
                } else {
                    if(isAdmin) {
                        toast.error("Something went wrong!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    } else {
                        toast.error("Please complete the assessment before downloading!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                }
            } else {
                const res = await FormVersionService.getClinicalAssessmentVersion(identifierId);
                if (res && res?.success && res?.data) {
                    setAssesmenData(res?.data);
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            }
        } catch (error) {
            toast.error("Something went wrong!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const replaceTextareasWithDivs = () => {
        const textareas = document.querySelectorAll('textarea');
        textareas.forEach((textarea) => {
            const text = textarea.value;
            const div = document.createElement('div');
            
            div.style.whiteSpace = 'pre-wrap';
            div.style.wordWrap = 'break-word';
            div.style.border = textarea.style.border;
            div.style.padding = textarea.style.padding;
            div.style.width = textarea.style.width;
            div.style.height = textarea.style.height;
            div.textContent = text;
    
            const parent = textarea.parentNode;
            if (parent) {
                parent.insertBefore(div, textarea);
                textarea.style.display = 'none';
            }
        });
    };
    
    // // This function allows printing all content on a single page, with the option to define the page height. 
    // // It uses the jspdf and html2canvas packages separately. 
    // // The issue with this approach is that if the page size changes based on its content, it may not work properly, leading to potential content loss.
    // const generatePDF = async () => {
    //     replaceTextareasWithDivs();
    
    //     Swal.fire({
    //         icon: "info",
    //         title: "PDF Preparation In Progress!",
    //         html: `
    //         <p>Your Clinical Assessment is being prepared. Please wait while we generate it.</p>
    //         <p>Once the preparation is complete, it will be automatically downloaded to your device.</p>
    //         `,
    //         showConfirmButton: false,
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         didOpen: () => {
    //             Swal.showLoading();
    //         },
    //     });
    
    //     setButtonText('PDF preparation in progress. Please wait...');
    //     setIsDisabled(true);
    //     const pdf = new jsPDF('p', 'mm', [210, 600]);
    //     const pages = document.querySelectorAll('.form-page');
      
    //     try {
    //         for (let i = 0; i < pages.length; i++) {
    //             const page = pages[i] as HTMLElement;
              
    //             if (!page) {
    //                 throw new Error("Page element not found");
    //             }
    
    //             const canvas = await html2canvas(page);
    //             const imgData = canvas.toDataURL('image/jpeg');
    //             const imgWidth = 210;
    //             const imgHeight = (canvas.height * imgWidth) / canvas.width;
                
    //             if (i > 0) pdf.addPage();
    //             pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
    //         }
    
    //         const now = new Date();
    //         const formattedDate = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}`;
    //         const formattedTime = `${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;
    //         const fileName = `CA_${formattedDate}_${formattedTime}_${props.clientId}.pdf`;
    //         pdf.save(fileName);
    //         toast.success("The clinical assessment has been successfully downloaded! Check your download folder.", {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //             className: "foo-bar",
    //         });
    //     } catch (error) {
    //         toast.error("Something went wrong! Try again later.", {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //             className: "foo-bar",
    //         });
    //     } finally {
    //         setButtonText('Download Clinical Assessment');
    //         setIsDisabled(false);
    //         props.onPdfComplete();
    //         Swal.close();
    //     }
    // };

    // // This function can break pages without losing content, using the jspdf-html2canvas package. 
    // // If the content cannot fit on a single page, the remaining content automatically moves to the next page. 
    // // However, we cannot set optional width or height for the pages, if we use this package.
    const generatePDF = async () => {
        replaceTextareasWithDivs();
    
        Swal.fire({
            icon: "info",
            title: "PDF Preparation In Progress!",
            html: `
            <p>The clinical assessment is being prepared. Please wait while we generate it.</p>
            <p>Once the preparation is complete, it will be automatically downloaded to your device.</p>
            `,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    
        setButtonText('PDF preparation in progress. Please wait...');
        setIsDisabled(true);
    
        try {
            const pagesCollection = document.getElementsByClassName('form-page');
            const pages = Array.from(pagesCollection).map(page => page as HTMLElement);
    
            const now = new Date();
            const formattedDate = `${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}`;
            const formattedTime = `${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`;
            const fileName = `ClinicalAssessment_${formattedDate}_${formattedTime}.pdf`;
    
            await html2PDF(pages, {
                jsPDF: {
                    unit: 'pt',
                    format: 'a4',
                },
                margin: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
                autoResize: true,
                imageType: 'image/jpeg',
                output: fileName,
                success: function(pdf) {
                    pdf.save(this.output);
                },
            });
    
            toast.success("The clinical assessment has been successfully downloaded! Check your download folder.", {
                position: toast.POSITION.TOP_RIGHT,
                className: "foo-bar",
            });
        } catch (error) {
            toast.error("Something went wrong! Try again later.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } finally {
            setButtonText('Download Clinical Assessment');
            setIsDisabled(false);
            props.onPdfComplete();
            Swal.close();
        }
    };

    return (
        <React.Fragment>
            <div className="page-content mt-0" style={{ padding: 0 }}>
                <Container className="containerOnboarding" style={{ padding: 0 }}>
                    <Row className="row-content justify-content-sm-center" style={{ padding: 0 }}>
                        <Col>
                            <div>
                                <h5 className="font-size-25 profileFont mt-4 text-center">
                                    COMPREHENSIVE CLINICAL ASSESSMENT
                                </h5>
                            </div>
                            <div className="d-flex justify-content-center">
                                <span>Click the button below to create and download the PDF to your device.</span>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-primary mb-2" onClick={generatePDF} disabled={isDisabled}>
                                    {buttonText}
                                </button>
                            </div>
                            <StepWizard>
                                <AssessmentForm assesmenData={assesmenData} />
                            </StepWizard>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PdfGeneratePage;