import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "reactstrap";
import ReactPlayer from "react-player";
import DiscoverIcon from "../../assets/images/icons/discover_icon.png";
import play from "../../assets/images/icons/fi_play-circle.png";
import { Article, ViewArticleModel } from "src/models/Article";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Util } from "src/Util";
import images from "../../assets/images";
import SkeltonDiscoverArticles from "src/common/skeleton/SkeltonDiscoverArticles";

interface articlesProps {
  articles: ViewArticleModel[];
  isLoading: boolean;
}
const DiscoverArticle: React.FC<articlesProps> = ({ articles , isLoading}) => {
  const [articleList, setArticleList] = useState([] as ViewArticleModel[]);
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);
  const nav = useHistory();
  const Listview = "list";

  useEffect(() => {
    if (Array.isArray(articles)){
        setArticleList(articles);
    }
  }, [articles]);
  const navi = (articleId: any) => {
    if (articleId) {
      nav.push({ pathname: "/article-details/" + articleId + "/" + Listview });
    }
  };
  return (
    <React.Fragment>
      <Card className="w-100">
        <CardBody className="m-p-zero">
          <div className="flex mb-3">
            <span className="rounded-icon-bg-n d-flex justify-content-center align-items-center">
              <img className="icon-h-20" src={DiscoverIcon} />
            </span>
            <h5 className="mb-0 d-flex justify-content-center align-items-center ms-3 fc-black">Discover Articles</h5>
          </div>
          <div className="custom-vertical-scroll">
            {articleList?.map((article: ViewArticleModel, index: number) => (
              <Card className="bg-light-orange" key={index}>
                <CardBody>
                  <div className="row">
                    <div className="col-8">
                      <h5 className="fs-13">{article?.articleTitle}</h5>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">{moment(article?.createdAt).format("YYYY-MM-DD h:mm:ss A")}</p>
                        <Link to={`/article-details/${article?._id}/list`} className="btn btn-primary bg-light-orange2 btn-sm">
                          Read Article...
                        </Link>
                      </div>
                    </div>
                    <div className="col-4 left-border-discover">
                      {article?.uploadId && article.uploadId.type.indexOf("image") !== -1 ? (
                        <div className="position-relative d-flex justify-content-center align-items-center">
                          <div
                            style={{
                              backgroundImage: `url(${Util.fileURL(article.uploadId._id)})`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              height: "75px",
                              padding: "10px",
                              cursor: "pointer",
                              borderRadius: "6px",
                              maxWidth: "115px",
                              minWidth: "115px",
                            }}
                            onClick={() => navi(article?._id as any)}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <i className="fa fa-picture-o video-icon"></i>
                          </div>
                        </div>
                      ) : (
                        <div className="position-relative d-flex justify-content-center align-items-center">
                          <div
                            style={{
                              backgroundImage: `url(https://vumbnail.com/${article?.vimeoId}.jpg)`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              height: "75px",
                              padding: "10px",
                              cursor: "pointer",
                              borderRadius: "6px",
                              maxWidth: "115px",
                              minWidth: "115px",
                            }}
                            className=" d-flex justify-content-center align-items-center"
                            onClick={() => navi(article?._id as any)}
                          >
                            <img src={play} style={{ width: 53 }} />
                          </div>
                        </div>
                      )}
                      {/* <div className="position-relative d-flex justify-content-center align-items-center">
                        <div
                          style={{
                            backgroundImage: `url(https://picsum.photos/200)`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "75px",
                            padding: "10px",
                            cursor: "pointer",
                            borderRadius: "6px",
                            maxWidth: "115px",
                            minWidth: "115px",
                          }}
                          className="video-item"
                        >
                          <img src={play} style={{ width: 53 }} />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>
          {articleList?.length == 0 && !isLoading && <div className="text-upcoming">No Articles</div>}
          {isLoading && (
            <SkeltonDiscoverArticles/>
          )}
          {articleList?.length == 3 && (
            <div className="d-flex justify-content-end mt-3">
              <Link to={`/articles`} className="btn btn-primary">
                View More <i className="bx bx-right-arrow-alt ms-2"></i>
              </Link>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DiscoverArticle;
