import axios from "axios";
import { Util } from "src/Util";
import { DiagnosisNoteFromResponse, DiagnosisNoteVersionIdentifier } from "src/models/DiagnosisNote";
import { AppResponse } from "src/models/Response";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { ClinicalAssetmentVersion } from "src/models/clinicalAssesment/ClinicalAssesmentVersion";
import { TherapyPlanVersion } from "src/models/therapyPlan/TherapyPlanVersion";
import { TherapyPlan, TherapyPlanSinglePage } from "src/models/therapyPlan/therapyPlan";
import { DigitalForm, DigitalFormVersion } from "src/models/AssessmentDigitalForm/AssessmentDigitalForms";

export class FormVersionService {
    public static async getCurrentTherapyPlan(id: any): Promise<AppResponse<TherapyPlanSinglePage>> {
        const url = Util.apiAuthUrl(`getCurrentTherapyPlanById/${id}`);
        return await axios.get<Partial<TherapyPlanSinglePage>, AppResponse<TherapyPlanSinglePage>>(url);
    }
    public static async createTherapyPlanVersion(data: Partial<TherapyPlanVersion>): Promise<AppResponse<TherapyPlanVersion>> {
        const url = Util.apiAuthUrl("createTherapyPlanVersion");
        return await axios.post<Partial<TherapyPlanVersion>, AppResponse<TherapyPlanVersion>>(url, data);
    }
    public static async getTherapyPlansWithAllVersions(clientId?: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`getTherapyPlansWithAllVersions/${clientId}`);
        return await axios.get<Partial<any>, AppResponse<any>>(url);
    }
    public static async getTherapyPlanVersion(id: string): Promise<AppResponse<TherapyPlanVersion>> {
        const url = Util.apiAuthUrl(`getTherapyPlanVersion/${id}`);
        return await axios.get<Partial<TherapyPlanVersion>, AppResponse<TherapyPlanVersion>>(url);
    }
    public static async getDiagnosisNoteVersion(id: string): Promise<AppResponse<DiagnosisNoteFromResponse>> {
        const url = Util.apiAuthUrl(`getDiagnosisNoteVersionById/${id}`);
        return await axios.get<Partial<DiagnosisNoteFromResponse>, AppResponse<DiagnosisNoteFromResponse>>(url);
    }
    public static async downloadDiagnosisPIENoteVersionDetails(id: string, data: DiagnosisNoteVersionIdentifier): Promise<AppResponse<DiagnosisNoteFromResponse>> {
        const url = Util.apiAuthUrl(`downloadDiagnosisPIENoteById/${id}`);
        return await axios.post<Partial<DiagnosisNoteFromResponse>, AppResponse<DiagnosisNoteFromResponse>>(url, data);
    }
    public static async downloadDiagnosisNoteVersionDetails(id: string, data: DiagnosisNoteVersionIdentifier): Promise<AppResponse<DiagnosisNoteFromResponse>> {
        const url = Util.apiAuthUrl(`downloadDiagnosisNoteById/${id}`);
        return await axios.post<Partial<DiagnosisNoteFromResponse>, AppResponse<DiagnosisNoteFromResponse>>(url, data);
    }
    public static async getCurrentClinicalAssessment(id: any): Promise<AppResponse<ClinicalAssetment>> {
        const url = Util.apiAuthUrl(`getCurrentClinicalAssessmentById/${id}`);
        return await axios.get<Partial<ClinicalAssetment>, AppResponse<ClinicalAssetment>>(url);
    }
    public static async createClinicalAssessmentVersion(data: Partial<ClinicalAssetmentVersion>): Promise<AppResponse<ClinicalAssetmentVersion>> {
        const url = Util.apiAuthUrl("createClinicalAssessmentVersion");
        return await axios.post<Partial<ClinicalAssetmentVersion>, AppResponse<ClinicalAssetmentVersion>>(url, data);
    }
    public static async getClinicalAssessmentsWithAllVersions(clientId?: any): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`getClinicalAssessmentsWithAllVersions/${clientId}`);
        return await axios.get<Partial<any>, AppResponse<any>>(url);
    }
    public static async getClinicalAssessmentVersion(id: string): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`getClinicalAssessmentVersion/${id}`);
        return await axios.get<Partial<any>, AppResponse<any>>(url);
    }
    // public static async getNewClinicalAssessmentWithAllVersions(clientId?: any): Promise<AppResponse<any>> {
    //     const url = Util.apiAuthUrl(`getNewClinicalAssessmentWithAllVersions/${clientId}`);
    //     return await axios.get<Partial<any>, AppResponse<any>>(url);
    // }
    public static async getCurrentNewTypeOfClinicalAssessment(id: any): Promise<AppResponse<DigitalForm>> {
        const url = Util.apiAuthUrl(`getCurrentNewTypeOfClinicalAssessment/${id}`);
        return await axios.get<Partial<DigitalForm>, AppResponse<DigitalForm>>(url);
    }
    public static async createNewTypeOfClinicalAssessmentVersion(data: Partial<DigitalFormVersion>): Promise<AppResponse<DigitalFormVersion>> {
        const url = Util.apiAuthUrl("createNewTypeOfClinicalAssessmentVersion");
        return await axios.post<Partial<DigitalFormVersion>, AppResponse<DigitalFormVersion>>(url, data);
    }
    public static async getNewTypeOfClinicalAssessmentVersionForDownload(id: string): Promise<AppResponse<any>> {
        const url = Util.apiAuthUrl(`getNewTypeOfClinicalAssessmentVersionForDownload/${id}`);
        return await axios.get<Partial<any>, AppResponse<any>>(url);
    }
}