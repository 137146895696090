import React, { useContext, useEffect, useState } from "react";
import { CardType } from "../../models/CardTypes";
import { Button, Card, Col, Row, TabPane } from "reactstrap";
import visa from "../../assets/images/cardtypes/visa.svg";
import mastercard from "../../assets/images/cardtypes/mastercard.svg";
import amex from "../../assets/images/cardtypes/amex.svg";
import discover from "../../assets/images/cardtypes/discover.svg";
import jcb from "../../assets/images/cardtypes/jcb.svg";
import maestro from "../../assets/images/cardtypes/maestro.svg";
import unionpay from "../../assets/images/cardtypes/unionpay.svg";
import anyCard from "../../assets/images/cardtypes/any_card.png";
import { VideoChatService } from "../../services/VideoChatService";
import UserContext from "../../context/UserContext";
import { TreatmentHistoryData } from "../../models/TreatmentHistory";
import { MeetingData } from "../../models/MeetingData";
import MeetingHistoryTabChild from "./MeetingHistoryTabChild";
import images from "../../assets/images";
import Spinner from "../../common/spinner/spinner";

const MeetingHistoryTab: React.FC = props => {
  const [meetingData, setMeetingData] = useState<MeetingData[]>([] as MeetingData[]);
  const [isMeetingDataLoading, setMeetingDataLoading] = useState(true);
  const [isMeetingError, setMeetingError] = useState(false);
  const [isInitial, setInitial] = useState(true);
  const limit = 10;
  const [meatingCount, setMeatingCount] = useState(10);
  const [offset, setOffset] = useState(1);
  useEffect(() => {
    if (isInitial) {
      getAllMeetings();
      setInitial(false);
    }
  }, []);

  const getAllMeetings = () => {
    setMeetingDataLoading(true);
    setMeetingError(false);

    VideoChatService.getAllMeetingsForTherapistForTranscribe(limit, 1).then(res => {
      if (res.success) {
        setMeetingDataLoading(false);
        setMeetingError(false);
        setMeetingData(res.data);
        setMeatingCount(res.data?.length);
      } else {
        setMeetingDataLoading(false);
        setMeetingError(true);
      }
    });
  };

  const seeMore = () => {
    const updatedSkip = offset + 1;

    setOffset(updatedSkip);
    setMeetingDataLoading(true);
    VideoChatService.getAllMeetingsForTherapistForTranscribe(10, updatedSkip).then(res => {
      if (res.success) {
        res.data.map((meating: any) => {
          setMeetingData(meetingData => [...meetingData, meating]);
        });
        setMeatingCount(res.data?.length);
      }
      setMeetingDataLoading(false);
    });
  };

  return (
    <>
      {!isMeetingDataLoading && isMeetingError && <h1>Server error occurred</h1>}

      {!isMeetingDataLoading && !isMeetingError ? (
        <Row>
          <Col sm="12" className="mt10">
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Meeting Id</th>
                    <th scope="col">Client</th>
                    <th scope="col">Type</th>
                    {/* <th scope="col">Spent Duration</th>
                    <th scope="col">Transcribe Status</th> */}
                    {/* <th scope="col">Share recording with client</th> */}
                  </tr>
                </thead>
                {meetingData?.map((meet: any, index) => {
                  return <MeetingHistoryTabChild key={index} meeting={meet}></MeetingHistoryTabChild>;
                })}
              </table>
            </div>
          </Col>

          {meatingCount >= 10 && (
            <>
              <button className="btn btn-warning text-center mt-4 button_center" style={{ width: "unset", margin: "auto" }} onClick={seeMore}>
                See More
              </button>
            </>
          )}
        </Row>
      ) : (
        <>{isMeetingDataLoading && <Spinner className="bouncer2" />}</>
      )}
    </>
  );
};

export default MeetingHistoryTab;
