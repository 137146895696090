import React from 'react';

import Videocam from '@material-ui/icons/Videocam';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

const VideoSettings = React.memo(({ hasVideo, onVideoChange, className }) => {
  return (
    <div className={className}>
      <Videocam />
      <div>Video</div>
      <Switch checked={hasVideo} onChange={onVideoChange} name="VideoToggle" />
    </div>
  );
});
VideoSettings.displayName = 'VideoSettings';

VideoSettings.propTypes = {
  hasVideo: PropTypes.bool,
  onVideoChange: PropTypes.func,
  className: PropTypes.string,
};

export default VideoSettings;
