import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "../../assets/css/admin.css";
const classNames = require("classnames");
import { AdminService } from "../../services/AdminService";
import { ApproveReject, ApproveRejectReview } from "../../models/User";
import Swal from "sweetalert2";
import { CustomerReviewData } from "src/models/Review";
import Spinner from "../../common/spinner/spinner";
import { LavniReview, LavniReviewStatus } from "src/models/LavniReview";
import ReviewContext from "src/context/ReviewContext";

const ViewLavniReviews: React.FC = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [pendingReviews, setPendingReviews] = useState([] as LavniReview[]);
  const [approvedReviews, setApprovedReviews] = useState([] as LavniReview[]);
  const [isLoading, setIsLoading] = useState(true);
  const [unreadReviewsCount, setUnreadReviewsCount] = useContext(ReviewContext);
  
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const LIMIT_P_C = 10;
  const LIMIT_A_C = 10;

  const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
  const [seeMore_p_c, setSeeMore_p_c] = useState(false);

  const [isOffset_a_c, setOffset_a_c] = useState<number>(0);
  const [seeMore_a_c, setSeeMore_a_c] = useState(false);

  useEffect(() => {
    AdminService.getAllLavniPendingReviews(LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        setPendingReviews(res.data);
        setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
      } else {
        setSeeMore_p_c(false);
      }
    });

    AdminService.getAllLavniApprovedReviews(LIMIT_A_C, isOffset_a_c).then(res => {
      if (res.success) {
        setApprovedReviews(res.data);
        setSeeMore_a_c(res.data.length > 0 && res.data.length == LIMIT_A_C);
      } else {
        setSeeMore_a_c(false);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  const approveRejectLavniReviewFunc = (e: any) => {
    const reviewId = e.currentTarget.getAttribute("data-value1");
    const status = e.currentTarget.getAttribute("data-value2");

    if (status == "true") {
      Swal.fire({
        icon: "info",
        title: "Are you sure want to approve this review?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          const data: ApproveRejectReview = {
            reviewId: reviewId,
            status: LavniReviewStatus.APPROVED
          };

          AdminService.approveRejectLavniReview(data).then(res => {
            const approvedClient = pendingReviews.filter(c => c._id === reviewId)[0];

            setApprovedReviews(approvedClients => [...approvedClients, approvedClient]);

            const updatedPendingClientList = pendingReviews.filter(c => c._id !== reviewId);

            setPendingReviews(updatedPendingClientList);

            setUnreadReviewsCount(updatedPendingClientList.length);
          });
        }
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Are you sure want to remove this review?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#50a5f1",
        cancelButtonText: "No",
        cancelButtonColor: "#f46a6a",
      }).then(result => {
        if (result.isConfirmed) {
          const data: ApproveRejectReview = {
            reviewId: reviewId,
            status: "REJECT"
          };

          AdminService.approveRejectLavniReview(data).then(res => {
            const updatedApprovedClientList = approvedReviews.filter(c => c._id !== reviewId);

            setApprovedReviews(updatedApprovedClientList);

            const updatedPendingClientList = pendingReviews.filter(c => c._id !== reviewId);

            setPendingReviews(updatedPendingClientList);

            setUnreadReviewsCount(updatedPendingClientList.length);
          });
        }
      });
    }
  };

  const approvedSeeMore = () => {
    const updatedSkip = isOffset_a_c + LIMIT_A_C;

    setOffset_a_c(updatedSkip);

    AdminService.getAllLavniApprovedReviews(LIMIT_A_C, updatedSkip).then(res => {
      if (res.success) {
        setApprovedReviews((prev: any) => {
          return [...new Set([...prev, ...res.data])];
        });
        setSeeMore_a_c(res.data.length > 0 && res.data.length == LIMIT_A_C);
      } else {
        setSeeMore_a_c(false);
      }
    });
  };

  const pendingSeeMore = () => {
    const updatedSkip = isOffset_p_c + LIMIT_P_C;

    setOffset_p_c(updatedSkip);

    AdminService.getAllLavniPendingReviews(LIMIT_P_C, updatedSkip).then(res => {
      if (res.success) {
        setPendingReviews((prev: any) => {
          return [...new Set([...prev, ...res.data])];
        });

        setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
      } else {
        setSeeMore_p_c(false);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Manage Reviews"} breadcrumbItem={"Manage Reviews"} />

          <Card>
            <CardBody>
              <Row>
                <Col xl="12">
                  <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: customActiveTab === "1" })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-view-grid-outline"></i>
                        </span>

                        <span className="d-none d-sm-block">Pending Reviews</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classNames({ active: customActiveTab === "2" })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-format-list-bulleted"></i>
                        </span>

                        <span className="d-none d-sm-block">Approved Reviews</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <div className="table-responsive">
                  {isLoading ? (
                      <Spinner />
                    ) : (
                    <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12" className="mt10">
                            <div className="table-responsive">
                              {pendingReviews.length > 0 ? (
                                <table className="table">
                                  <thead className="thead-dark">
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Email</th>
                                      <th scope="col">Review Message</th>
                                      <th scope="col">Star Rating</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pendingReviews &&
                                      pendingReviews.map((review, index) => (
                                        <tr key={review?._id}>
                                          <th scope="row">{index + 1}</th>
                                          <td>
                                            {review?.name}
                                          </td>
                                          <td>
                                            {review?.email}
                                          </td>
                                          <td>
                                            {review?.reviewMessage}
                                          </td>       
                                          <td>
                                            {review?.ratingValue}
                                          </td>                                    
                                          <td>
                                            <span className="btn btn-warning status">Pending</span>
                                          </td>
                                          <td>
                                            <button className="btn btn-success" onClick={approveRejectLavniReviewFunc} data-value1={review._id} data-value2={true}>
                                              Approve
                                            </button>

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <button className="btn btn-danger" onClick={approveRejectLavniReviewFunc} data-value1={review._id} data-value2={false}>
                                              Reject
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              ) : (
                                <div>No Pending Reviews</div>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12 mt30 text-center">
                            {seeMore_p_c && (
                              <button className="btn btn-warning" onClick={() => pendingSeeMore()}>
                                See More
                              </button>
                            )}
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12" className="mt10">
                            <div className="table-responsive">
                              {approvedReviews.length > 0 ? (
                                <table className="table">
                                  <thead className="thead-dark">
                                    <tr>
                                    <th scope="col">#</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Email</th>
                                      <th scope="col">Role</th>
                                      <th scope="col">Review Message</th>
                                      <th scope="col">Star Rating</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {approvedReviews &&
                                      approvedReviews.map((review, index) => (
                                        <tr key={review._id}>
                                          <th scope="row">{index + 1}</th>
                                          <td>
                                            {review.name}
                                          </td>
                                          <td>
                                            {review.email}
                                          </td>
                                          <td>
                                            {review?.clientId ? "CLIENT" : review?.therapistId ? "THERAPIST" : "-"}
                                          </td>
                                          <td>
                                            {review.reviewMessage}
                                          </td>
                                          <td>
                                            {review.ratingValue}
                                          </td>
                                          <td>
                                            <span className="btn btn-success status">Approved</span>
                                          </td>
                                          <td>
                                            <button className="btn btn-danger" onClick={approveRejectLavniReviewFunc} data-value1={review._id} data-value2={false}>
                                              Reject
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              ) : (
                                <div>No Approved Reviews</div>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12 mt30 text-center">
                            {seeMore_a_c && (
                              <button className="btn btn-warning" onClick={() => approvedSeeMore()}>
                                See More
                              </button>
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                     )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewLavniReviews;
