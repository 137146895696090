import React, { useContext, useState, useEffect, useRef } from "react";
import { Card, CardBody, Modal, ModalBody } from "reactstrap";
import CheckIcon from "../../assets/images/icons/fi_check.png";
import arrowIcon from "../../assets/images/icons/fi_arrow-right-circle.svg";
import editIcon from "../../assets/images/icons/fi_edit.png";
import AppointmentIcon from "../../assets/images/icons/no_upcomming.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { Role } from "../../models/Role";
import { AppointmentService } from "src/services/AppointmentService";
import { toast } from "react-toastify";
import { CommonService } from "src/services/CommonService";
import { Util } from "src/Util";
import { ClientService } from "src/services/ClientService";
import Modal4 from "../Popup/Modal4";
import { Appointment, ApprovalStatus, ModalType, Repeat } from "../../models/Appointment";
import { Therapist } from "src/models/Therapist";
import { Client } from "src/models/Client";
import dayjs, { Dayjs } from "dayjs";
import SkeltonNewAppointment from "src/common/skeleton/SkeltonNewAppointment";
import AppointmentModal from "../Popup/AppointmentModal";
import MessageModal from "../Popup/MessageModal";
import { TherapistService } from "src/services/TherapistService";

interface newAppointmentsProps {
  newAppointments: Appointment[];
  isLoading: boolean;
}

const NewAppointmentsNew: React.FC<newAppointmentsProps> = ({ newAppointments, isLoading }) => {
  const [user, setUser] = useContext(UserContext);
  const [pendingAppointments, setPendingAppointments] = useState([] as Appointment[]);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [isEventModal, setIsEventModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [appointment, setAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: moment().format("YYYY/MM/DD"),
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    meetingType: "",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
    selectedDate: ""
  });

  useEffect(() => {
    setPendingAppointments(newAppointments);
  }, [newAppointments]);

  useEffect(() => {
    reloadPendingAppintment();
  }, [isEventModal]);

  const reloadPendingAppintment = () => {
    if (user?.role == Role.CLIENT) {
      ClientService.getClientDashboardStats().then(res => {
        setPendingAppointments(res.data?.pendingAppointmentStats);
      });
    } else {
      const data = {
        filterValue: "YEAR",
      };

      TherapistService.getDashboardStats(data).then(res => {
        setPendingAppointments(res.data?.pendingAppointmentStats);
      });
    }
  };

  // const updateApprovalStatus = async (appointmentId: string | undefined, approvedStatus: string, clientId: string, googleCalendardata: any) => {
  //   if (appointmentId) {
  //     const data = {
  //       appointmentId: appointmentId,
  //       approvedStatus: approvedStatus
  //     };
      
  //     AppointmentService.updateApprovalStatus(data).then((res: any) => {
  //       if (res) {
  //         setPendingAppointments(pendingAppointments.filter((appointment: Appointment) => appointment._id !== appointmentId));
  //         return toast.success("Appointment status is changed successfully.", {
  //           position: toast.POSITION.BOTTOM_RIGHT,
  //           className: "foo-bar",
  //         });
  //       } else {
  //         return toast.error(res.error ? res.error : "Failed to change appointment status.", {
  //           position: toast.POSITION.BOTTOM_RIGHT,
  //           className: "foo-bar",
  //         });
  //       }
  //     });

  //     if (approvedStatus == "APPROVED" && user && user.googleCalendarAccess == true) {
  //       try {
  //         if (googleCalendardata.GoogleCalendarEventId) {
  //           CommonService.updateGoogleCalendarEvent(googleCalendardata).then((res: any) => {
  //             if (res) {
  //               const googleCalendarEventId = res.data;
  
  //               const data = {
  //                 appointmentId: appointmentId,
  //                 approvedStatus: approvedStatus,
  //                 GoogleCalendarEventId: googleCalendarEventId
  //               };
  
  //               AppointmentService.updateApprovalStatus(data);
  //             }
  //           });
  //         } else {
  //           CommonService.createGoogleCalendarEvent(googleCalendardata).then((res: any) => {
  //             if (res.success) {
  //               const googleCalendarEventId = res.data;
  
  //               const data = {
  //                 appointmentId: appointmentId,
  //                 approvedStatus: approvedStatus,
  //                 GoogleCalendarEventId: googleCalendarEventId
  //               };
  
  //               AppointmentService.updateApprovalStatus(data);
  //             }
  //           });
  //         }
  //       } catch (error) {
  //         console.log('Google Calendar Sync Error', error);
  //       }
  //     }

  //     if (user && user.role == Role.CLIENT) {
  //       if (Util.skipCheckingForPremiumUsers(user)) {
  //         if ((user.subscriptionId == null || user.subscriptionStatus != "active") && user.testSubscriptionStatus != "active") {
  //           return setShowModalForTherapist(true);
  //         }
  //       }
  //     }

  //     if (approvedStatus == ApprovalStatus.APPROVED) {
  //       if (user && user.role == Role.THERAPIST) {
  //         try {
  //           const res = await ClientService.getClientByClientId(clientId);
  //           if (res.success) {
  //             if (
  //               res.data.premiumStatus != "active" &&
  //               (res.data.subscriptionId == null || res.data.subscriptionStatus != "active") &&
  //               res.data.testSubscriptionStatus != "active"
  //             ) {
  //               return setShowModalForTherapist(true);
  //             }
  //           } else {
  //             return toast.error("Invalid client id." + clientId, {
  //               position: toast.POSITION.BOTTOM_RIGHT,
  //               className: "foo-bar",
  //             });
  //           }
  //         } catch (error: any) {
  //           return toast.error(error.toString(), {
  //             position: toast.POSITION.BOTTOM_RIGHT,
  //             className: "foo-bar",
  //           });
  //         }
  //       }
  //     }
  //   }
  // };
  
  const updateApprovalStatus = async (appointmentIdFrom: string | undefined, approvedStatusFrom: string, clientId: string, googleCalendardataFrom: any) => {
    try{
      if (appointmentIdFrom) {
        const finalGoogleCalendardata = googleCalendardataFrom;
        const finalAppointmentId = appointmentIdFrom;
        const finalApprovedStatus = approvedStatusFrom;
        let finalGoogleCalendarEventId;

        if (user?.googleCalendarAccess == true && finalApprovedStatus == "APPROVED") {
          if (finalGoogleCalendardata?.GoogleCalendarEventId) {
            const res = await CommonService.updateGoogleCalendarEvent(finalGoogleCalendardata);

            if(res?.success && res?.data){

              finalGoogleCalendarEventId = res?.data;

            } else {
              toast.error("Unable to update google calendar event", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
            
          } else {

            const res = await CommonService.createGoogleCalendarEvent(finalGoogleCalendardata);

            if(res?.success && res?.data){

              finalGoogleCalendarEventId = res?.data;

            } else {
              toast.error("Unable to create google calendar event", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          }
        }

        const finalDataForSend: any = {
          appointmentId: finalAppointmentId,
          approvedStatus: finalApprovedStatus,
        };

        if(finalGoogleCalendarEventId){

          finalDataForSend.GoogleCalendarEventId = finalGoogleCalendarEventId;

        }

        const res = await AppointmentService.updateApprovalStatus(finalDataForSend);

        if(res?.success){

          setPendingAppointments(pendingAppointments.filter((appointment: Appointment) => appointment._id !== finalAppointmentId));
          
          toast.success("Appointment status is changed successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error("Failed to change appointment status.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        if (user && user.role == Role.CLIENT) {
          if (Util.skipCheckingForPremiumUsers(user)) {
            if ((user.subscriptionId == null || user.subscriptionStatus != "active") && user.testSubscriptionStatus != "active") {
              return setShowModalForTherapist(true);
            }
          }
        }
      
        if (finalApprovedStatus == ApprovalStatus.APPROVED) {
          if (user && user.role == Role.THERAPIST) {
            try {
              const res = await ClientService.getClientByClientId(clientId);
              if (res.success) {
                if (
                  res.data.premiumStatus != "active" &&
                  (res.data.subscriptionId == null || res.data.subscriptionStatus != "active") &&
                  res.data.testSubscriptionStatus != "active"
                ) {
                  return setShowModalForTherapist(true);
                }
              } else {
                return toast.error("Invalid client id." + clientId, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  className: "foo-bar",
                });
              }
            } catch (error: any) {
              return toast.error(error.toString(), {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
          }
        }

      } else {
        return toast.error("Invalid appointment id !", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      return toast.error("Something went wrong !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const getWorkingDaysOfWeek = (workingHours: any) => {
    const workingDays: string[] = [];

    workingHours?.map((session: any) => {
      if (!workingDays.includes(session.day)) workingDays.push(session.day);
    });
  };

  const editAppointment = async (data: Appointment) => {
    if (user && user.role == Role.CLIENT) {
      if (Util.skipCheckingForPremiumUsers(user)) {
        if ((user.subscriptionId == null || user.subscriptionStatus != "active") && user.testSubscriptionStatus != "active") {
          return setShowModalForTherapist(true);
        }
      }
    }

    if (user && user.role == Role.THERAPIST) {
      const res = await ClientService.getClientByClientId(data.clientId._id!);

      if (res.success) {
        if (
          res.data.premiumStatus != "active" &&
          (res.data.subscriptionId == null || res.data.subscriptionStatus != "active") &&
          res.data.testSubscriptionStatus != "active"
        ) {
          return setShowModalForTherapist(true);
        }
      } else {
        return toast.error("Invalid client id." + data.clientId._id!, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    }

    if (data) {
      getWorkingDaysOfWeek(data?.therapistId.workingHours);

      setAppointment({
        ...appointment,
        id: data._id || "",
        title: data.title,
        startTime: moment(data.start).format("H:mm A"),
        endTime: moment(data.end).format("H:mm A"),
        date: moment(data.start).format("YYYY-MM-DD HH:mm"),
        repeat: (data.repeatInfo?.repeatType as Repeat) || Repeat.DOES_NOT_REPEAT,
        color: data.color,
        meetingType: data.typeOfMeeting,
        therapist: data?.therapistId,
        client: data.clientId,
        selectedDate: moment(data.start).toISOString()
      });

      setTimeValue(data.start.toString());
      setHourValue(moment(data.start).hour());
      setMinuteValue(moment(data.start).minute());

      setIsEventModal(!isEventModal);
    }
  };

  const eventTimeValidationModalToggle = () => {
    setEventTimeValidationViewModal(!isEventTimeValidationViewModal);
    setErrorModalText("");
  };

  return (
    <React.Fragment>
      {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
      
      {showErrorModal && (
        <MessageModal showModal={showErrorModal} setShowModal={setShowErrorModal} message={"Please subscribe to a provided plan to access this feature."} />
      )}

      <AppointmentModal
        clientId={appointment.client._id}
        appointment={appointment}
        modalType={ModalType.EDIT}
        showModal={isEventModal}
        setShowModal={setIsEventModal}
        setAppointment={setAppointment}
        errorModalText={errorModalText}
        setErrorModalText={setErrorModalText}
        setShowErrorModal={setShowErrorModal}
        setShowEventTimeValidationViewModal={setEventTimeValidationViewModal}
        setShowModalForTherapist={setShowModalForTherapist}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        updateMatchedClient={() => {}}
        hourValue={hourValue}
        setHourValue={setHourValue}
        minuteValue={minuteValue}
        setMinuteValue={setMinuteValue}
        updateAppointmentObject={() => {}}
      />

      <Modal isOpen={isEventTimeValidationViewModal} centered toggle={eventTimeValidationModalToggle}>
        <ModalBody>
          <div className="modal-val">
            <h5 className="model-error">{errorModalText}</h5>
            <button className="btn btn-sm text-center btn-success mt-3" onClick={() => eventTimeValidationModalToggle()}>
              Got It
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Card className="p-2">
        <CardBody className="p-7-dashboard">
          <h5 className="mb-0 fs-13 fc-black mb-4">New Appointment</h5>

          {pendingAppointments?.map((newAppointment: Appointment, index: number) => (
            <Card className="appointment-card1 mb-2" key={index}>
              <CardBody className="p-10-dashboard">
                <div className="d-flex justify-content-between  row">
                  <div className="flex col-7 col-sm-6 col-xl-7">
                    <span className="d-sm-none"></span>

                    <span className="d-none d-sm-flex justify-content-center align-items-center">
                      <img className="icon-h-24" src={arrowIcon} alt="Arrow Icon" />
                    </span>

                    <div className="d-flex justify-content-center align-items-center flex-column min-width-100">
                      <p className="mb-0 fc-black">
                        {
                          newAppointment.therapistId._id === user?._id ? (
                            newAppointment.clientId?.firstname + " " + newAppointment.clientId?.lastname
                          ) : (
                            newAppointment.therapistId?.firstname + " " + newAppointment.therapistId?.lastname
                          )
                        }
                      </p>
                      <p className="mb-0 fc-black fs-sm-sizes">
                        {`${moment(newAppointment.start).format("hh:mm A")}`} - {`${moment(newAppointment.start).format("YYYY-MM-DD")}`}
                      </p>
                    </div>
                  </div>
                  <div className="left-border-discover  col-5 col-sm-6 col-xl-5 d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-around min-width-100">
                      {/* <button
                        className="btn btn-primary btn-sm accept-btn-dashboard d-flex justify-content-center align-items-center"
                        onClick={() => {
                          updateApprovalStatus(newAppointment?._id, ApprovalStatus.APPROVED, newAppointment.clientId._id!, newAppointment);
                        }}
                      >
                        <span className="d-none d-sm-block">Accept </span><img src={CheckIcon} className="mt-1 mt-sm-0 ms-sm-1" />
                      </button> */}
                      <button
                        className="btn btn-primary btn-sm Edit-btn-dashboard"
                        onClick={() => {
                          editAppointment(newAppointment);
                        }}
                      >
                        <img src={editIcon} className="" />
                      </button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}

          {pendingAppointments?.length == 0 && !isLoading && (
            <div className="">
              <div className="container no-upcoming-card d-flex justify-content-center align-items-center">
                <div className="overlay-upcoming"></div>
                <img className="image-upcoming" src={AppointmentIcon} alt="Overlay Image" />
                <div className="text-upcoming">No New Appointments</div>
              </div>
            </div>
          )}

          {isLoading && (
            <SkeltonNewAppointment />
          )}
          
          {pendingAppointments?.length == 3 && (
            <div className="d-flex justify-content-end mt-4">
              <Link to="/appointments">
                <button className="btn btn-primary btn-sm">
                  View More <i className="bx bx-right-arrow-alt ms-2"></i>
                </button>
              </Link>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default NewAppointmentsNew;