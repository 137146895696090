import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { withTranslation } from "react-i18next";
import UserContext from "../../context/UserContext";
import { Role } from "../../models/Role";
import logo from "../../assets/images/logo.svg";
import logoDark from "../../assets/images/logo-dark.png";
import { RouteName } from "../../RouteName";
import ChatContext from "../../context/ChatContext";
import { PremiumStatus } from "../../models/Client";
import Star from "../../assets/images/icons/fi_star2.png";
import SwitchDashboard from "../CommonForBoth/TopbarDropdown/SwitchDashboard";

const Header = props => {
  const [user] = useContext(UserContext);
  const [isSearch] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [unreadChatCount, setUnreadChatCount] = useContext(ChatContext);
  return (
    <React.Fragment>
      {location.pathname != RouteName.MEETING_SCREEN_NEW && !location.pathname.includes("vonage-session") && !location.pathname.includes("video-room") && !location.pathname.includes("room") && <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex logo-sec">
            <div className="navbar-brand-box">
              <Link to={location.pathname != RouteName.ONBOARDING ? "/" : "#"} className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} height="22" style={{ height: "22px" }} />
                </span>

                <span className="logo-lg">
                  <img src={logoDark} height="50" style={{ height: "50px" }} />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item "
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              {unreadChatCount > 0 ? (
                <div className="dot">
                  <i
                    className="mdi mdi-circle text-danger font-size-10" style={{ position: "absolute", right: "32px", top: "20px" }}
                  />
                </div>
              ) : null}
              <i className="fa fa-fw fa-bars" />

            </button>
            <span className="px-3 font-size-16 d-lg-none d-md-none header-item d-flex">
              <h5 className="wlcm-header">Welcome Back!</h5>
            </span>
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <div
                className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"}
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder={props.t("Search") + "..."} aria-label="Recipient's username" />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1 upgrade-section">
              {user.role == Role.CLIENT && location.pathname != RouteName.ONBOARDING && (
                <>
                  {user?.premiumStatus == PremiumStatus.ACTIVE ? (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        history.push(`/payment-history`);
                      }}
                    >
                      {/* <span className="subscription-status-premium">Premium</span> */}
                      <span className="dashboard-premium-status pt-2 fs-premium pb-2 ps-sm-4 pe-sm-2 ps-2 pe-2 d-flex justify-content-center align-items-center">
                        <span className="d-none d-sm-block font-prm">Premium</span> <img className="dashboard-prrmium-start ms-sm-1" src={Star} />
                      </span>

                    </div>
                  ) : user?.subscriptionId || user?.testSubscriptionStatus == "active" ? (
                    <div
                      onClick={() => {
                        history.push(`/subscription`);
                      }}
                      className="subscription-div"
                    >
                      {user?.subscriptionStatus == "active" || user?.testSubscriptionStatus == "active" ? (
                        <>
                          {/* <span className="subscription-status">Active</span> */}
                          <span className="dashboard-active-status pt-2 fs-premium pb-2 ps-sm-5 pe-sm-5 ps-2 pe-2 d-flex justify-content-center align-items-center"><span className="d-none d-sm-block">Active</span> <img className="dashboard-prrmium-start ms-sm-1 d-sm-none d-block" src={Star} /></span>
                        </>
                      ) : user?.subscriptionStatus == "past_due" ? (
                        // <span className="subscription-status-past-due">Past Due</span>
                        <span className="dashboard-to-pro-status pt-2 fs-premium pb-2 ps-sm-5 pe-sm-5 ps-2 pe-2 d-flex justify-content-center align-items-center"><span className="d-none d-sm-block">Past Due</span> <img className="dashboard-prrmium-start ms-sm-1 d-sm-none d-block" src={Star} /></span>
                      ) : user?.subscriptionStatus == "canceled" ? (
                        // <span className="subscription-status-canceled">Cancelled</span>
                        <span className="dashboard-to-pro-status pt-2 fs-premium pb-2 ps-sm-5 pe-sm-5 ps-2 pe-2 d-flex justify-content-center align-items-center"><span className="d-none d-sm-block">Cancelled Due</span> <img className="dashboard-prrmium-start ms-sm-1 d-sm-none d-block" src={Star} /></span>
                      ) : user?.subscriptionStatus == "incomplete" ? (
                        // <span className="subscription-status-canceled">Incomplete</span>
                        <span className="dashboard-to-pro-status pt-2 fs-premium pb-2 ps-sm-5 pe-sm-5 ps-2 pe-2 d-flex justify-content-center align-items-center"><span className="d-none d-sm-block">Incomplete</span> <img className="dashboard-prrmium-start ms-sm-1 d-sm-none d-block" src={Star} /></span>
                      ) : user?.subscriptionStatus == "incomplete_expired" ? (
                        // <span className="subscription-status-canceled">Expired</span>
                        <span className="dashboard-to-pro-status pt-2 fs-premium pb-2 ps-sm-5 pe-sm-5 ps-2 pe-2 d-flex justify-content-center align-items-center"><span className="d-none d-sm-block">Expired</span> <img className="dashboard-prrmium-start ms-sm-1 d-sm-none d-block" src={Star} /></span>
                      ) : (
                        // <button
                        //   className="btn btn-info btn-sm"
                        //   onClick={() => {
                        //     history.push(`/subscription`);
                        //   }}
                        // >
                        //   Upgrade To Pro&nbsp;&nbsp;<i className="fa fa-star"></i>
                        // </button>
                        <span
                          onClick={() => {
                            history.push(`/subscription`);
                          }}
                          className="cursor-p dashboard-to-pro-status pt-2 fs-premium pb-2 ps-sm-5 pe-sm-5 ps-2 pe-2 d-flex justify-content-center align-items-center"><span className="d-none d-sm-block">Upgrade To Pro</span> <img className="dashboard-prrmium-start ms-sm-1" src={Star} />
                        </span>
                      )}
                    </div>
                  ) : (
                    // <button
                    //   className="btn btn-info btn-sm"
                    //   onClick={() => {
                    //     history.push(`/subscription`);
                    //   }}
                    // >
                    //   Upgrade To Pro&nbsp;&nbsp;<i className="fa fa-star"></i>
                    // </button>
                    <span
                      onClick={() => {
                        history.push(`/subscription`);
                      }}
                      className="cursor-p dashboard-to-pro-status pt-2 fs-premium pb-2 ps-sm-5 pe-sm-5 ps-2 pe-2 d-flex justify-content-center align-items-center"><span className="d-none d-sm-block">Upgrade To Pro</span> <img className="dashboard-prrmium-start ms-sm-1" src={Star} />
                    </span>
                  )}
                </>
              )}
            </div>

            {user.role == Role.THERAPIST && <SwitchDashboard />}
            {location.pathname != RouteName.ONBOARDING && location.pathname != RouteName.THERAPISTPERSONALINFORMATIONONBOARDINGPAGE && location.pathname != RouteName.THERAPISTPROFESSIONALEXPERIENCEONBOARDINGPAGE && location.pathname != RouteName.THERAPISTWORKINGDAYSONBOARDINGPAGE && location.pathname != RouteName.THERAPISTINTRODUCTORYVIDEOONBOARDINGPAGE && <NotificationDropdown />}
            <ProfileMenu />
          </div>
        </div>
      </header>}
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, toggleLeftmenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, toggleLeftmenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
