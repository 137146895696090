import React, { useEffect, useState } from "react";
import { AdminService } from "src/services/AdminService";
import ClientRewardContext from "src/context/ClientRewardCount";

const ClientRewardUtils: React.FC<{ children: any }> = ({ children }): any => {
  const [unreadClientRewardCount, setUnreadClientRewardCount] = useState<number>(0);
  const [offset] = useState(0);

  const LIMIT = 0;

  useEffect(() => {
    AdminService.getAllClientRewards(LIMIT, offset).then(res => {
      if (res.success) {
        setUnreadClientRewardCount(res.data.count)
      }
    });
  }, []);

  return <ClientRewardContext.Provider value={[unreadClientRewardCount, setUnreadClientRewardCount]}>{children}</ClientRewardContext.Provider>;
};

export default ClientRewardUtils;