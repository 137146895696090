const classNames = require('classnames');
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Input, Modal, ModalBody, Button, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Spinner from "../../common/spinner/spinner";
import { AdminService } from "../../services/AdminService";
import Select from "react-select";
import diagnosisCodeList from "../Chat/DiagnosisNote/Dataset/options";
import Close from "../../assets/images/icons/close.png";
import {
  DiagnosisCode,
  ServiceLine,
  Claim,
  DependentSubmitModel,
  SubscribersSubmitModel,
  RenderingProviderSubmitModel,
  SecondaryDiagnosisCode,
} from "../../models/Insurance";
import { InsuranceCompanyTags, SelectedInsuranceCompanyTag } from "src/models/InsuranceCompany";
import { InsuranceCompanyService } from "src/services/InsuranceCompanyService";
import CustomDropdown from "src/components/Common/CustomDropdown";
import { spawn } from "child_process";
import { number } from "prop-types";

interface InsuranceClaimModalProps {
  diagnosisNoteId: string;
  insuranceId: string;
  showModal: boolean;
  toggleClaimModal: any
}

const InsuranceClaimModal: React.FC<InsuranceClaimModalProps> = ({ diagnosisNoteId, insuranceId, showModal, toggleClaimModal }: InsuranceClaimModalProps) => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [diagnosisCodes, setDiagnosisCodes] = useState<DiagnosisCode[]>([]);
  const [secondaryDiagnosisCodes, setSecondaryDiagnosisCodes] = useState<SecondaryDiagnosisCode[]>([]);
  const [id, setId] = useState(1);
  const [coPayment, setCoPayment] = useState();
  const [sessionDate, setSessionDate] = useState({ serviceDate: "" });
  const [selectedProcedureCode, setSelectedProcedureCode] = useState("");
  const [procedureCode, setProcedureCode] = useState({ cptCode: "" });
  const [submitter, setSubmitter] = useState({
    organizationName: "Lavni Inc",
    phoneNumber: "9199397137",
  });
  const [subscriber, setSubscriber] = useState<SubscribersSubmitModel>({} as SubscribersSubmitModel);
  const [renderingProvider, setRenderingProvider] = useState<RenderingProviderSubmitModel>({} as RenderingProviderSubmitModel);
  const [selecteInsuranceCompanyTag, setSelectedInsuranceCompanyTag] = useState<SelectedInsuranceCompanyTag>({} as SelectedInsuranceCompanyTag);
  const [searchInsuranceCompanyTag, setSearchInsuranceCompanyTag] = useState<InsuranceCompanyTags[]>([]);
  const [showModal1, setModalCenter] = React.useState(false);

  const [claim, setClaim] = useState({
    claimChargeAmount: "175",
    diagnosisCode: "",
    secondaryDiagnosisCodes: ""
  });

  const [serviceLines, setServiceLines] = useState({
    serviceDate: sessionDate?.serviceDate,
    lineItemChargeAmount: "175",
    procedureCode: "",

  });
  const [subscriberErrors, setSubscriberErrors] = useState({
    memberId: false,
    firstName: false,
    lastName: false,
    gender: false,
    dateOfBirth: false,
  });

  const [serviceLinesErrors, setServiceLinesErrors] = useState({
    serviceDate: false,
    lineItemChargeAmount: false,
    procedureCode: false,
  });
  const [renderingProviderErrors, setRenderingProviderErrors] = useState({
    providerType: false,
    npi: false,
    firstName: false,
    middleName: false,
    lastName: false,
  });
  const [claimErrors, setClaimErrors] = useState({
    claimChargeAmount: false,
    diagnosisCode: false,
  });

  const [organizationErrors, setOrganizationErrors] = useState({
    organizationName: false,
  });

  const [cptCodeList] = useState([
    { cptCode: "-1", cptCodeDescription: "Select" },
    { cptCode: "90791", cptCodeDescription: "Psychiatric diagnostic evaluation" },
    { cptCode: "90792", cptCodeDescription: "Psychiatric diagnostic evaluation with medical services" },
    { cptCode: "90832", cptCodeDescription: "Psychotherapy, 16-37 minutes" },
    { cptCode: "90833", cptCodeDescription: "Psychotherapy, 16-37 minutes with E/M service, listed seperately" },
    { cptCode: "90834", cptCodeDescription: "Psychotherapy, 38-52 minutes" },
    { cptCode: "90836", cptCodeDescription: "Psychotherapy, 38-52 minutes with E/M service, listed seperately" },
    { cptCode: "90837", cptCodeDescription: "Psychotherapy, 53+ minutes" },
    { cptCode: "90838", cptCodeDescription: "Psychotherapy, 53+ minutes with E/M service, listed seperately" },
    { cptCode: "90839", cptCodeDescription: "Psychotherapy for crisis, 30-74 minutes" },
    { cptCode: "90840", cptCodeDescription: "Psychotherapy for crisis, each additional 30 minutes beyond initial 74min, upto two add-ons per 90839" },
    { cptCode: "90846", cptCodeDescription: "Family psytx w/o patient 50 minutes" },
    { cptCode: "90847", cptCodeDescription: "Family psytx w/patient 50 minutes" },
    { cptCode: "90853", cptCodeDescription: "Group psychotherapy" },
    { cptCode: "00001", cptCodeDescription: "Non Billable" },
  ]);


  useEffect(() => {
    AdminService.getClaimDetails(diagnosisNoteId).then(res => {
      if (res.success) {
        console.log(res.data[0].encounterDate)
        setSubscriber({
          memberId: res.data[0]?.clientInsuranceDetails?.subscriber?.memberId,
          copaymentAmount: res.data[0]?.clientDetails?.copaymentAmount,
          claimEligibilityDetails: res.data[0]?.clientDetails?.claimEligibilityDetails,
          paymentResponsibilityLevelCode: res.data[0]?.clientInsuranceDetails?.subscriber?.paymentResponsibilityLevelCode,
          firstName: res.data[0]?.clientInsuranceDetails?.subscriber?.firstName,
          lastName: res.data[0]?.clientInsuranceDetails?.subscriber?.lastName,
          gender: res.data[0]?.clientDetails?.gender,
          dateOfBirth: res.data[0]?.clientDetails?.dateOfBirth,
          policyNumber: res.data[0]?.clientInsuranceDetails?.subscriber?.policyNumber,
          address1: res.data[0]?.clientInsuranceDetails?.subscriber?.address?.address1,
          city: res.data[0]?.clientInsuranceDetails?.subscriber?.address?.city,
          state: res.data[0]?.clientInsuranceDetails?.subscriber?.address?.state,
          postalCode: res.data[0]?.clientInsuranceDetails?.subscriber?.address?.postalCode,
        });

        setRenderingProvider({
          providerType: "RenderingProvider",
          firstName: res.data[0]?.therapistDetails?.firstname,
          lastName: res.data[0]?.therapistDetails?.lastname,
          middleName: res.data[0]?.therapistDetails?.middlename,
          npi: res.data[0]?.therapistDetails?.nPI1,
          socialSecurity: res.data[0]?.therapistDetails?.socialSecurity,
          taxonomyCode: res.data[0]?.therapistDetails?.taxonomyCode,
        });

        setSessionDate({
          serviceDate: res.data[0]?.encounterDate,
        })
        setDiagnosisCodes(res.data[0]?.diagnosisICDcodes);
        setSecondaryDiagnosisCodes(res.data[0]?.secondaryDiagnosisICDcodes);
        setSelectedInsuranceCompanyTag({ ...selecteInsuranceCompanyTag, label: res.data[0]?.clientInsuranceCompanyDetails?.insuranceCompany });

        setProcedureCode({
          cptCode: res.data[0]?.cptCode,
        })

      }
    });
    getAllInsuranceCompanies();
  }, [diagnosisNoteId]);

  function toggleClaimModal1() {
    setModalCenter(!showModal1);
  }


  const handleChange = (event: any) => {
    event.preventDefault();

    const element = event?.target?.name;
    const value: string = event?.target?.value;

    switch (element) {
      case "serviceDateProvider":
        if (value == "" || value == undefined || value == null) {
          setServiceLinesErrors({ ...serviceLinesErrors, serviceDate: true });
        } else {
          setServiceLinesErrors({ ...serviceLinesErrors, serviceDate: false });
        }
        setServiceLines({ ...serviceLines, serviceDate: value });
        break;

      case "serviceAmount":
        if (value == "" || value == undefined || value == null) {
          setServiceLinesErrors({ ...serviceLinesErrors, lineItemChargeAmount: true });
        } else {
          setServiceLinesErrors({ ...serviceLinesErrors, lineItemChargeAmount: false });
        }
        setServiceLines({ ...serviceLines, lineItemChargeAmount: value });
        break;

      case "subscriberMemberId":
        if (value == "" || value == undefined || value == null) {
          setSubscriberErrors({ ...subscriberErrors, memberId: true });
        } else {
          setSubscriberErrors({ ...subscriberErrors, memberId: false });
        }
        setSubscriber({ ...subscriber, memberId: value });
        break;

      case "subscriberFirstname":
        if (value == "" || value == undefined || value == null) {
          setSubscriberErrors({ ...subscriberErrors, firstName: true });
        } else {
          setSubscriberErrors({ ...subscriberErrors, firstName: false });
        }
        setSubscriber({ ...subscriber, firstName: value });
        break;

      case "subscriberLastname":
        if (value == "" || value == undefined || value == null) {
          setSubscriberErrors({ ...subscriberErrors, lastName: true });
        } else {
          setSubscriberErrors({ ...subscriberErrors, lastName: false });
        }
        setSubscriber({ ...subscriber, lastName: value });
        break;

      case "subscriberGender":
        if (value == "-1" || value == undefined || value == null) {
          setSubscriberErrors({ ...subscriberErrors, gender: true });
        } else {
          setSubscriberErrors({ ...subscriberErrors, gender: false });
        }
        setSubscriber({ ...subscriber, gender: value });
        break;

      case "subscriberDateOfBirth":
        if (value == "-1" || value == undefined || value == null) {
          setSubscriberErrors({ ...subscriberErrors, dateOfBirth: true });
        } else {
          setSubscriberErrors({ ...subscriberErrors, dateOfBirth: false });
        }
        setSubscriber({ ...subscriber, dateOfBirth: value });
        break;

      case "rederingProviderType":
        if (value == "" || value == undefined || value == null) {
          setRenderingProviderErrors({ ...renderingProviderErrors, providerType: true });
        } else {
          setRenderingProviderErrors({ ...renderingProviderErrors, providerType: false });
        }
        setRenderingProvider({ ...renderingProvider, providerType: value });
        break;

      case "renderingProviderNpi":
        if (value == "" || value == undefined || value == null) {
          setRenderingProviderErrors({ ...renderingProviderErrors, npi: true });
        } else {
          setRenderingProviderErrors({ ...renderingProviderErrors, npi: false });
        }
        setRenderingProvider({ ...renderingProvider, npi: value });
        break;

      case "renderingProviderFirstName":
        if (value == "" || value == undefined || value == null) {
          setRenderingProviderErrors({ ...renderingProviderErrors, firstName: true });
        } else {
          setRenderingProviderErrors({ ...renderingProviderErrors, firstName: false });
        }
        setRenderingProvider({ ...renderingProvider, firstName: value });
        break;


      case "renderingProviderLastName":
        if (value == "" || value == undefined || value == null) {
          setRenderingProviderErrors({ ...renderingProviderErrors, lastName: true });
        } else {
          setRenderingProviderErrors({ ...renderingProviderErrors, lastName: false });
        }
        setRenderingProvider({ ...renderingProvider, lastName: value });
        break;


      case "claimChargeAmount":
        if (value == "" || value == undefined || value == null) {
          setClaimErrors({ ...claimErrors, claimChargeAmount: true });
        } else {
          setClaimErrors({ ...claimErrors, claimChargeAmount: false });
        }
        setClaim({ ...claim, claimChargeAmount: value });
        break;

      default:
        break;
    }
  };

  const handleDiagnosisCodeChange = (newValue: any) => {
    if (newValue.length == 0) {
      setClaimErrors({ ...claimErrors, diagnosisCode: true });
    } else {
      setClaimErrors({ ...claimErrors, diagnosisCode: false });
    }
    const finalArray = [...newValue];
    setDiagnosisCodes(finalArray);
    setClaim({ ...claim, diagnosisCode: finalArray[0].value });
  };



  const handleInsuranceCompanyChange = (newValue: any) => {
    if (newValue.label.length == 0) {
      setOrganizationErrors({ ...organizationErrors, organizationName: true });
    } else {
      setOrganizationErrors({ ...organizationErrors, organizationName: false });
    }
    setSelectedInsuranceCompanyTag({ ...selecteInsuranceCompanyTag, label: newValue.label });
  };


  const handleSecondaryDiagnosisChange = (newValue: any) => {
    const finalArray = [...newValue];
    setSecondaryDiagnosisCodes(finalArray);
    setClaim({ ...claim, secondaryDiagnosisCodes: finalArray[0].value });
  };


  const customStyleOrganization = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      maxWidth: "40vw",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      borderColor: organizationErrors.organizationName ? "red" : "#ced4da",
    }),
  };


  const customStyle = {
    multiValueLabel: (styles: any) => ({
      ...styles,
      maxWidth: "40vw",
    }),
    control: (styles: any, state: any) => ({
      ...styles,
      borderColor: claimErrors.diagnosisCode ? "red" : "#ced4da",
    }),
  };


  const validateEntries = () => {
    let isValidated = true;

    if (subscriber.memberId == "" || subscriber.memberId == undefined || subscriber.memberId == null) {
      subscriberErrors.memberId = true;
      isValidated = false;
    }

    if (!isFilled(serviceLines.lineItemChargeAmount)) {
      serviceLinesErrors.lineItemChargeAmount = true;
      isValidated = false;
    }
    if (!isFilled(subscriber.firstName)) {
      subscriberErrors.firstName = true;
      isValidated = false;
    }

    if (!isFilled(subscriber.lastName)) {
      subscriberErrors.lastName = true;
      isValidated = false;
    }

    if (!isSelected(subscriber.gender)) {
      subscriberErrors.gender = true;
      isValidated = false;
    }

    if (!isFilled(renderingProvider.npi)) {
      renderingProviderErrors.npi = true;
      isValidated = false;
    }


    if (!isFilled(claim.claimChargeAmount)) {
      claimErrors.claimChargeAmount = true;
      isValidated = false;
    }


    if (!selecteInsuranceCompanyTag.label) {
      organizationErrors.organizationName = true;
      isValidated = false;
    }

    if (diagnosisCodes?.length == 0) {
      claimErrors.diagnosisCode = true;
      isValidated = false;
    }

    return isValidated;
  };

  const checkEligibilty = (event: any) => {
    event.preventDefault();

    const isValidated = validateEntries();
    if (isValidated) {
      const services = {
        serviceDate: serviceLines.serviceDate ? serviceLines.serviceDate : sessionDate?.serviceDate,
        professionalService: {
          lineItemChargeAmount: serviceLines.lineItemChargeAmount,
          procedureCode: selectedProcedureCode ? selectedProcedureCode : procedureCode?.cptCode,
        },
      };

      let claimData;
      if (secondaryDiagnosisCodes?.length > 0 || (claim?.secondaryDiagnosisCodes && claim?.secondaryDiagnosisCodes.length > 0)) {
        claimData = {
          claimChargeAmount: claim?.claimChargeAmount,
          diagnosisCode: claim?.diagnosisCode ? claim?.diagnosisCode : diagnosisCodes[0]?.value,
          secondaryDiagnosisCodes: claim?.secondaryDiagnosisCodes ? claim?.secondaryDiagnosisCodes : secondaryDiagnosisCodes[0]?.value,
        }
      } else {
        if (diagnosisCodes?.length > 0 || claim?.diagnosisCode && claim?.diagnosisCode.length > 0) {
          claimData = {
            claimChargeAmount: claim?.claimChargeAmount,
            diagnosisCode: claim?.diagnosisCode ? claim?.diagnosisCode : diagnosisCodes[0]?.value,
          }
        }
      }

      const claims: Claim = {
        diagnosisNoteId: diagnosisNoteId,
        organizationName: selecteInsuranceCompanyTag.label,
        submitter: submitter,
        subscriber: subscriber,
        renderingProvider: renderingProvider,
        claim: claimData,
        serviceLines: services,
      };

      setisLoading(true);

      if (subscriber?.claimEligibilityDetails) {
        setModalCenter(true)
        setCoPayment(subscriber?.copaymentAmount as any)
        setisLoading(false);
      } else {
        AdminService.checkEligibiltyAdmin(claims, insuranceId).then(res => {
          if (res.success) {
            if (res.data?.eligibilityType == "claimMD") {
              toggleClaimModal(diagnosisNoteId, "ACTIVEMD", "", res?.data?.coPaymentValue, res?.data?.copaymentStatus);
              setisLoading(false);
              return toast.success(`Claim MD submitted successfully!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            } else {
              setModalCenter(true)
              setCoPayment(res.data?.coPaymentValue)
              setisLoading(false);
              return toast.success(`Claim Eligibility Checking Successfully!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

          } else {
            setisLoading(false);
            setModalCenter(false);
            toggleClaimModal(diagnosisNoteId, "INACTIVE", res.error, 0, "NOTSUBMITTED");
            return toast.error(res.error, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        });
      }
    } else {
      return toast.error(`Please fill in all the entries before submitting!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };



  const submitClaim = (event: any) => {
    event.preventDefault();

    const isValidated = validateEntries();
    if (isValidated) {
      const services = {
        serviceDate: serviceLines.serviceDate ? serviceLines.serviceDate : sessionDate?.serviceDate,
        professionalService: {
          lineItemChargeAmount: serviceLines.lineItemChargeAmount,
          procedureCode: selectedProcedureCode ? selectedProcedureCode : procedureCode?.cptCode,
        },
      };

      let claimData;
      if (secondaryDiagnosisCodes?.length > 0 || (claim?.secondaryDiagnosisCodes && claim?.secondaryDiagnosisCodes.length > 0)) {
        claimData = {
          claimChargeAmount: claim?.claimChargeAmount,
          diagnosisCode: claim?.diagnosisCode ? claim?.diagnosisCode : diagnosisCodes[0]?.value,
          secondaryDiagnosisCodes: claim?.secondaryDiagnosisCodes ? claim?.secondaryDiagnosisCodes : secondaryDiagnosisCodes[0]?.value,
        }
      } else {
        if (diagnosisCodes?.length > 0 || claim?.diagnosisCode && claim?.diagnosisCode.length > 0) {
          claimData = {
            claimChargeAmount: claim?.claimChargeAmount,
            diagnosisCode: claim?.diagnosisCode ? claim?.diagnosisCode : diagnosisCodes[0]?.value,
          }
        }
      }

      const claims: Claim = {
        diagnosisNoteId: diagnosisNoteId,
        organizationName: selecteInsuranceCompanyTag.label,
        submitter: submitter,
        subscriber: subscriber,
        renderingProvider: renderingProvider,
        claim: claimData,
        serviceLines: services,
      };

      setisLoading(true);

      AdminService.submitClaim(claims, insuranceId).then(res => {
        if (res.success) {
          toggleClaimModal(diagnosisNoteId, "ACTIVE", "", res?.data?.copayment?.amount, res?.data?.copayment?.status, res?.data?.copayment?.details);
          setModalCenter(false);
          setId(1);
          setSubscriber({} as SubscribersSubmitModel);
          setRenderingProvider({} as RenderingProviderSubmitModel);
          setClaim({
            claimChargeAmount: "175",
            diagnosisCode: "",
            secondaryDiagnosisCodes: "",
          });
          setServiceLines({
            lineItemChargeAmount: "175",
            procedureCode: "",
            serviceDate: "",
          });
          setDiagnosisCodes([]);
          setisLoading(false);
          return toast.success(`Claim submitted successfully!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        } else {
          toggleClaimModal(diagnosisNoteId, "INACTIVE", res.error, 0, "NOTSUBMITTED");
          setModalCenter(false);
          setisLoading(false);
          return toast.error(res.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      });
    } else {
      return toast.error(`Please fill in all the entries before submitting!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const getAllInsuranceCompanies = () => {
    InsuranceCompanyService.getAllInsuranceCompany().then((res: any) => {
      if (res.success) {
        const tagList = res.data.map((tag: any) => {
          return { value: tag._id, label: tag.insuranceCompany };
        });
        setSearchInsuranceCompanyTag(tagList);
      } else {
        setSearchInsuranceCompanyTag([]);
      }
    });
  };


  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };


  const isFilled = (value: string) => {
    if (value == "" || value == undefined || value == null) {
      return false;
    }
    return true;
  };

  const isSelected = (value: string) => {
    if (value == "-1" || value == "" || value == undefined || value == null) {
      return false;
    }
    return true;
  };


  const db = moment.utc(subscriber.dateOfBirth).format("YYYY-MM-DD");

  return showModal ? (
    <React.Fragment>
      <Modal isOpen={showModal} toggle={toggleClaimModal} centered scrollable fullscreen className="p-5">
        {isLoading && <Spinner className="bouncer" />}

        {/* <ModalHeader> */}
        <button type="button" onClick={toggleClaimModal} className="close" data-dismiss="modal" aria-label="Close">
          <span>&times;</span>
        </button>
        {/* </ModalHeader> */}

        <ModalBody className="px-5">
          <h2 className="mb-3 text-center text-muted">Claim Submission</h2>

          <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({ active: customActiveTab === "1" })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="mdi mdi-view-grid-outline"></i>
                </span>

                <span className="d-none d-sm-block">Involved Parties</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTab} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row className="mb-3">
                <Row className="mb-3">
                  <div className="flex flew-wrap flex-start">
                    <h5 className="text-muted font-size-20">Subscriber Details</h5>
                  </div>
                </Row>

                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Member Id</h5>
                  <Input
                    type="text"
                    name="subscriberMemberId"
                    className={subscriberErrors.memberId ? "form-control is-invalid" : "form-control"}
                    value={subscriber.memberId}
                    onChange={event => handleChange(event)}
                    placeholder="0000000000"
                  ></Input>
                  {subscriberErrors.memberId && <div className="invalid-feedback">This field is required!</div>}
                </Col>

                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Firstname</h5>
                  <Input
                    type="text"
                    name="subscriberFirstname"
                    className={subscriberErrors.firstName ? "form-control is-invalid" : "form-control"}
                    onChange={event => handleChange(event)}
                    value={subscriber.firstName}
                    placeholder="johnone"
                  ></Input>
                  {subscriberErrors.firstName && <div className="invalid-feedback">This field is required!</div>}
                </Col>

                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Lastname</h5>
                  <Input
                    type="text"
                    name="subscriberLastname"
                    className={subscriberErrors.lastName ? "form-control is-invalid" : "form-control"}
                    onChange={event => handleChange(event)}
                    value={subscriber.lastName}
                    placeholder="doeone"
                  ></Input>
                  {subscriberErrors.lastName && <div className="invalid-feedback">This field is required!</div>}
                </Col>

                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Date of Birth</h5>
                  <Input
                    type="date"
                    name="subscriberDateOfBirth"
                    className={subscriberErrors.dateOfBirth ? "form-control is-invalid" : "form-control"}
                    value={db || ""}
                    onChange={event => handleChange(event)}
                    disabled
                  ></Input>
                  {subscriberErrors.dateOfBirth && <div className="invalid-feedback">This field is required!</div>}
                </Col>

                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Gender</h5>

                  <select
                    className={subscriberErrors.gender ? "form-control is-invalid" : "form-control"}
                    name="subscriberGender"
                    onChange={event => handleChange(event)}
                    value={subscriber.gender}
                  >
                    <option value="-1">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>

                  {subscriberErrors.gender && <div className="invalid-feedback">This field is required!</div>}
                </Col>
                <Col lg={3} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Organization Name</h5>
                  <Select
                    placeholder="Choose or create company"
                    onChange={handleInsuranceCompanyChange}
                    value={selecteInsuranceCompanyTag}
                    options={searchInsuranceCompanyTag}
                    styles={customStyleOrganization}
                    className="creatableEdit mb-4"
                    required
                  />
                </Col>
                <div className="mt-3 border-bottom"></div>
              </Row>
              <Row>

                <Col lg={6}>
                  <Row className="mb-3">
                    <div className="flex flew-wrap flex-start">
                      <h5 className="text-muted font-size-20">Provider Details</h5>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="flex flew-wrap flex-start">
                      <h5 className="text-muted font-size-16">Service Rendering Provider</h5>
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <Col lg={4} className="mb-3">
                      <h5 className="text-muted font-size-16 mb-2">Provider Type</h5>
                      <Input
                        type="text"
                        name="rederingProviderType"
                        className={renderingProviderErrors.providerType ? "form-control is-invalid" : "form-control"}
                        value={"Rendering Provider"}
                        onChange={event => handleChange(event)}
                        disabled
                      ></Input>
                      {renderingProviderErrors.providerType && <div className="invalid-feedback">This field is required!</div>}
                    </Col>

                    <Col lg={4} className="mb-3">
                      <h5 className="text-muted font-size-16 mb-2">NPI</h5>
                      <Input
                        type="text"
                        name="renderingProviderNpi"
                        className={renderingProviderErrors.npi ? "form-control is-invalid" : "form-control"}
                        value={renderingProvider.npi}
                        onChange={event => handleChange(event)}
                        placeholder="1760854442"
                      ></Input>
                      {renderingProviderErrors.npi && <div className="invalid-feedback">This field is required!</div>}
                    </Col>

                    <Col lg={4} className="mb-3">
                      <h5 className="text-muted font-size-16 mb-2">Firstname</h5>
                      <Input
                        type="text"
                        name="renderingProviderFirstName"
                        className={renderingProviderErrors.firstName ? "form-control is-invalid" : "form-control"}
                        value={renderingProvider.firstName}
                        onChange={event => handleChange(event)}
                        placeholder="janetwo"
                      ></Input>
                      {renderingProviderErrors.firstName && <div className="invalid-feedback">This field is required!</div>}
                    </Col>

                    <Col lg={4} className="mb-3">
                      <h5 className="text-muted font-size-16 mb-2">Lastname</h5>
                      <Input
                        type="text"
                        name="renderingProviderLastName"
                        className={renderingProviderErrors.lastName ? "form-control is-invalid" : "form-control"}
                        value={renderingProvider.lastName}
                        onChange={event => handleChange(event)}
                        placeholder="doetwo"
                      ></Input>
                      {renderingProviderErrors.lastName && <div className="invalid-feedback">This field is required!</div>}
                    </Col>
                  </Row>
                </Col>


                <Col lg={6}>
                  <Row className="mb-3">
                    <div className="flex flew-wrap flex-start">
                      <h5 className="text-muted font-size-20">Claim Details</h5>
                    </div>
                  </Row>

                  <Col lg={6} className="mb-3">
                    <h5 className="text-muted font-size-16 mb-2">Claim Charge Amount</h5>
                    <Input
                      type="text"
                      name="claimChargeAmount"
                      className={claimErrors.claimChargeAmount ? "form-control is-invalid" : "form-control"}
                      onChange={event => handleChange(event)}
                      value={claim.claimChargeAmount}
                    ></Input>
                    {claimErrors.claimChargeAmount && <div className="invalid-feedback">This field is required!</div>}
                  </Col>
                  <Row className="mb-3">
                    <Col lg={6} className="mb-3">
                      <h5 className="text-muted font-size-16 mb-2">Diagnosis Codes</h5>
                      <Select
                        closeMenuOnSelect={false}
                        value={diagnosisCodes}
                        isMulti
                        options={diagnosisCodeList}
                        onChange={handleDiagnosisCodeChange}
                        styles={customStyle}
                      />
                    </Col>
                    <Col lg={6} className="mb-3">
                      <h5 className="text-muted font-size-16 mb-2">Secondary Diagnosis Codes</h5>
                      <Select
                        closeMenuOnSelect={false}
                        value={secondaryDiagnosisCodes}
                        isMulti
                        options={diagnosisCodeList}
                        onChange={handleSecondaryDiagnosisChange}
                      />
                    </Col>
                  </Row>
                </Col>
                <div className="mt-3 mb-3 border-bottom"></div>
              </Row>



              <Row className="mb-3">
                <Row className="mb-3">
                  <div className="flex flew-wrap flex-start">
                    <h5 className="text-muted font-size-20">Service Line Details</h5>
                  </div>
                </Row>
              </Row>

              <Row>
                <Row className="mb-3">
                  <div className="flex flew-wrap flex-start">
                    <h5 className="text-muted font-size-16">Service Rendering Provider</h5>
                  </div>
                </Row>
                <Col lg={4} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Service Date</h5>
                  <Input
                    type="date"
                    name="serviceDateProvider"
                    className={serviceLinesErrors.serviceDate ? "form-control is-invalid" : "form-control"}
                    value={moment.utc(sessionDate?.serviceDate?.slice(0, 10)).utcOffset(-5 * 60).format("YYYY-MM-DD")}
                    onChange={event => handleChange(event)}
                  ></Input>
                </Col>


                <Col lg={4} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Line Item Charge Amount</h5>
                  <Input
                    type="text"
                    name="serviceAmount"
                    className={serviceLinesErrors.lineItemChargeAmount ? "form-control is-invalid" : "form-control"}
                    value={serviceLines.lineItemChargeAmount}
                    onChange={event => handleChange(event)}
                    placeholder="doetwo"
                  ></Input>
                  {serviceLinesErrors.lineItemChargeAmount && <div className="invalid-feedback">This field is required!</div>}
                </Col>


                <Col lg={4} className="mb-3">
                  <h5 className="text-muted font-size-16 mb-2">Procedure Code</h5>
                  <select className="form-control" onChange={e => setSelectedProcedureCode(e.target.value)} value={selectedProcedureCode || procedureCode?.cptCode}>
                    {cptCodeList &&
                      cptCodeList.map(opt => (
                        <option value={opt.cptCode} key={opt.cptCode}>
                          {opt.cptCodeDescription}
                        </option>
                      ))}
                  </select>
                </Col>
              </Row>

              {/* <Row className="mb-3">
                <Col>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <BootstrapTable keyField="id" data={serviceLines} columns={columns} cellEdit={cellEditFactory({ mode: "click", blurToSave: true })} />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}

              <Row className="mb-3">
                <div className="flex flex-wrap flex-row-reverse">
                  <button className="btn btn-sm btn-success btn-style4" style={{ height: "30px" }} disabled={isLoading ? true : false} onClick={event => checkEligibilty(event)}>
                    Submit
                  </button>
                  {/* <button className="btn btn-sm btn-info btn-style4" style={{ height: "30px" }} onClick={event => addMoreFields(event)}>
                    Add More
                  </button> */}
                </div>
              </Row>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>


      <Modal isOpen={showModal1} centered className="modal-lg">
        {isLoading && <Spinner className="bouncer" />}
        <div style={{ textAlign: "center", paddingRight: "0px" }}>
          <span></span>
          <Button
            close
            onClick={toggleClaimModal1}
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              padding: "0",
              zIndex: "10",
            }}
          >
            <img src={Close} alt="Close" className="me-2 mt-2" />
          </Button>
        </div>
        <ModalBody className="ps-4 pe-4">
          <Row>
            <span className="font-size-18 profileFont mb-2 cursor-pointer pt-4 text-center">Claim Eligibility Checking Successful</span>
          </Row>

          <Row className="p-2">
            <div className="flex flew-wrap flex-start mt-3">
              {coPayment ?
                <h5 className="text-muted font-size-20">This claim has a ${coPayment} copayment amount.</h5>
                :
                <h5 className="text-muted font-size-20">This claim has no copayment.</h5>
              }
            </div>
          </Row>

          <Row className="mt-3 mb-3">
            <div className="flex flex-wrap flex-row-reverse">
              <button className="btn btn-sm btn-success btn-style4" style={{ height: "30px" }} disabled={isLoading ? true : false} onClick={event => submitClaim(event)}>
                Submit Claim
              </button>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  ) : null;
};

export default InsuranceClaimModal;
