
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "../../assets/css/admin.css";
const classNames = require('classnames');
import { Therapist } from "../../models/Therapist";
import { AdminService } from "../../services/AdminService";
import { ApproveReject } from "../../models/User";
import Swal from "sweetalert2";
import moment from "moment";
import { Link } from "react-router-dom";
import Spinner from "../../common/spinner/spinner";
import { FaLastfmSquare } from "react-icons/fa";
import { toast } from "react-toastify";

const TherapistRequests: React.FC = () => {
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [pendingTherapists, setPendingTherapists] = useState([] as Therapist[]);
    const [approvedTherapists, setApprovedTherapists] = useState([] as Therapist[]);
    const [rejectedTherapists, setRejectedTherapists] = useState([] as Therapist[]);
    const [isLoading, setIsLoading] = useState(true);
    const [inProgress, setInProgress] = useState(true);
    const [isLazyLoading, setIsLazyLoading] = useState(false);
    
    const LIMIT_P_T = 10;
    const LIMIT_A_T = 10;
    const LIMIT_R_T = 10;

    const [seeMore_p_t, setSeeMore_p_t] = useState(false);

    const [seeMore_a_t, setSeeMore_a_t] = useState(false);

    const [seeMore_r_t, setSeeMore_r_t] = useState(false);


    useEffect(() => {
        loadInitialData();
    }, []);

    const loadInitialData = async () => {
        try {
            setInProgress(true);
            const res = await AdminService.getAllPendingTherapistRequests(LIMIT_P_T, 0);

            if (res && res.success) {
                setPendingTherapists(res.data);
                setSeeMore_p_t(res.data.length >= LIMIT_P_T);
                setIsLoading(false);
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
            setInProgress(false);
        } catch (error) {
            setInProgress(false);
            toast.error("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const seeMorePendingTherapist = async () => {
        try {
            if(!inProgress){
                setInProgress(true);
                setIsLazyLoading(true);

                const finalOffset = pendingTherapists?.length ?? 0;

                const res = await AdminService.getAllPendingTherapistRequests(LIMIT_P_T, finalOffset);
                if (res.success) {
                    setPendingTherapists(prev => {
                        return [...new Set([...prev, ...res.data])];
                    });
                    setSeeMore_p_t(res.data.length >= LIMIT_P_T);
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
                setInProgress(false);
                setIsLazyLoading(false);

            } else {
                toast.error("Please wait!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        } catch (error) {
            setIsLazyLoading(false);
            setInProgress(false);
            toast.error("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const seeMoreApprovedTherapist = async () => {
        try {
            if(!inProgress){
                setInProgress(true);
                setIsLazyLoading(true);

                const finalOffset = approvedTherapists?.length ?? 0;

                const res = await AdminService.getAllApprovedTherapistRequests(LIMIT_A_T, finalOffset);

                if (res.success) {
                    setApprovedTherapists(prev => {
                        return [...new Set([...prev, ...res.data])];
                    });
                    setSeeMore_a_t(res.data.length >= LIMIT_A_T);
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }

                setInProgress(false);
                setIsLazyLoading(false);
            } else {
                toast.error("Please wait!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }

        } catch (error) {
            setInProgress(false);
            setIsLazyLoading(false);
            toast.error("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const seeMoreRejectedTherapist = async () => {
        try {
            if(!inProgress){
                setInProgress(true);
                setIsLazyLoading(true);

                const finalOffset = rejectedTherapists?.length ?? 0;

                const res = await AdminService.getAllRejectedTherapistRequests(LIMIT_R_T, finalOffset);
                
                if (res.success) {
                    setRejectedTherapists(prev => {
                        return [...new Set([...prev, ...res.data])];
                    });
                    setSeeMore_r_t(res.data.length >= LIMIT_R_T);
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
                setInProgress(false);
                setIsLazyLoading(false);
            } else {
                toast.error("Please wait!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        } catch (error) {
            setInProgress(false);
            setIsLazyLoading(false);
            toast.error("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const toggleCustom = async (tab: any) => {
        try {
            if(!inProgress){
                setInProgress(true);
                if (customActiveTab !== tab) {
                    setIsLoading(true);
                    setPendingTherapists([]);
                    setApprovedTherapists([]);
                    setRejectedTherapists([]);
                    setSeeMore_p_t(false);
                    setSeeMore_a_t(false);
                    setSeeMore_r_t(false);
                    setIsLazyLoading(false);
                    setcustomActiveTab(tab);
                    
                    if(tab == "1"){

                        const res = await AdminService.getAllPendingTherapistRequests(LIMIT_P_T, 0);

                        if (res && res.success) {
                            setPendingTherapists(res.data);
                            setSeeMore_p_t(res.data.length >= LIMIT_P_T);
                            setIsLoading(false);
                        } else {
                            toast.error(res.error, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar",
                            });
                        }
                
                    }else if( tab == "2"){

                        const res = await AdminService.getAllApprovedTherapistRequests(LIMIT_A_T, 0);

                        if (res && res.success) {
                            setApprovedTherapists(res.data);
                            setSeeMore_a_t(res.data.length >= LIMIT_A_T);
                            setIsLoading(false);
                        } else {
                            toast.error(res.error, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar",
                            });
                        }

                    }else{

                        const res = await AdminService.getAllRejectedTherapistRequests(LIMIT_R_T, 0);

                        if (res && res.success) {
                            setRejectedTherapists(res.data);
                            setSeeMore_r_t(res.data.length >= LIMIT_R_T);
                            setIsLoading(false);
                        } else {
                            toast.error(res.error, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: "foo-bar",
                            });
                        }
                    }
                }
                setInProgress(false);
            } else {
                toast.error("Please wait!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        } catch (error) {
            setInProgress(false);
            toast.error("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    };

    const approveTherapistRequest = async (e: any) => {
        try {
            if(!inProgress){
                setInProgress(true);
                const therapistId = e.currentTarget.getAttribute("data-value1");
                const status = e.currentTarget.getAttribute("data-value2");

                const result = await Swal.fire({
                    icon: "info",
                    title: "Are you sure want to approve this request?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#50a5f1",
                    cancelButtonText: "No",
                    cancelButtonColor: "#f46a6a",
                });

                if (result.isConfirmed) {

                    const data: ApproveReject = {
                        userId: therapistId,
                        status: true
                    }

                    const res = await AdminService.approveTherapistRequest(data);

                    if(res && res.success){
                        if(status == "true"){
                            const updatedPendingTherapistList = pendingTherapists.filter((c) => c._id !== therapistId);
        
                            setPendingTherapists(updatedPendingTherapistList);
                        }else{
                            const updatedRejectedTherapistList = rejectedTherapists.filter((c) => c._id !== therapistId);
        
                            setRejectedTherapists(updatedRejectedTherapistList);
                        }

                    }else{
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                }
                setInProgress(false);
            } else {
                toast.error("Please wait!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        } catch (error) {
            setInProgress(false);
            toast.error("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }
    const rejectTherapistRequest = async (e: any) => {
        try {
            if(!inProgress){
                setInProgress(true);
                const therapistId = e.currentTarget.getAttribute("data-value1");
                const status = e.currentTarget.getAttribute("data-value2");
    
                const result = await Swal.fire({
                    icon: "info",
                    title: "Are you sure want to reject this request?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#50a5f1",
                    cancelButtonText: "No",
                    cancelButtonColor: "#f46a6a",
                });
    
                if (result.isConfirmed) {
                    const data: ApproveReject = {
                        userId: therapistId,
                        status: true
                    }
    
                    const res = await AdminService.rejectTherapistRequest(data);
    
                    if(res && res.success){
                        if(status == "true"){
                            const updatedPendingTherapistList = pendingTherapists.filter((c) => c._id !== therapistId);
        
                            setPendingTherapists(updatedPendingTherapistList);
                        }else{
                            const updatedApprovedTherapistList = approvedTherapists.filter((c) => c._id !== therapistId);
        
                            setApprovedTherapists(updatedApprovedTherapistList);
                        }
    
                    }else{
                        toast.error(res.error, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: "foo-bar",
                        });
                    }
                }
                setInProgress(false);
            } else {
                toast.error("Please wait!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        } catch (error) {
            setInProgress(false);
            toast.error("Something went wrong", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Therapist Requests"} breadcrumbItem={"Therapist Requests"} />

                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl="12">
                                    <Nav tabs className="nav-tabs-custom nav-justified discover-nav-items">
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classNames({ active: customActiveTab === "1", })} onClick={() => { toggleCustom("1"); }} >
                                                <span className="d-block d-sm-none">
                                                    <i className="mdi mdi-view-grid-outline"></i>
                                                </span>

                                                <span className="d-none d-sm-block">Pending Requests</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classNames({ active: customActiveTab === "2", })} onClick={() => { toggleCustom("2"); }} >
                                                <span className="d-block d-sm-none">
                                                    <i className="mdi mdi-format-list-bulleted"></i>
                                                </span>

                                                <span className="d-none d-sm-block">Approved Requests</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classNames({ active: customActiveTab === "3", })} onClick={() => { toggleCustom("3"); }} >
                                                <span className="d-block d-sm-none">
                                                    <i className="mdi mdi-format-list-bulleted"></i>
                                                </span>

                                                <span className="d-none d-sm-block">Rejected Requests</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <div className="table-responsive">
                                    {isLoading ? (
                                        <div style={{height: 500}}>
                                            <Spinner />
                                        </div>
                                            
                                        ) : (
                                        <TabContent activeTab={customActiveTab} className="p-3 text-muted" >
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col sm="12" className="mt10">
                                                        <div className="table-responsive">
                                                            {
                                                                pendingTherapists.length > 0 ? (
                                                                    <table className="table">
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Name</th>
                                                                                <th scope="col">Email</th>
                                                                                <th scope="col">Phone</th>
                                                                                {/* <th scope="col">Profile</th> */}
                                                                                <th scope="col">Created Date</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                pendingTherapists && pendingTherapists.map((therapist, index) => (
                                                                                    <tr key={therapist._id}>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td>{therapist.firstname ?? ""} {therapist.lastname ?? ""}</td>
                                                                                        <td>{therapist.email ?? ""}</td>
                                                                                        <td>{therapist.primaryPhone ?? ""}</td>
                                                                                        {/* <td>
                                                                                            <Link to={"/profile/" + therapist._id} className="btn btn-info mr-2" title="View Profile"> <i className="fa fa-user"></i> &nbsp;Profile</Link>
                                                                                        </td> */}
                                                                                        <td>{therapist?.createdAt ? moment(therapist?.createdAt).format("YYYY-MM-DD hh:mm:ss A") : <span>-</span>}</td>
                                                                                        <td><span className="btn btn-warning status">Pending</span></td>
                                                                                        <td>
                                                                                            <button className="btn btn-success mr-2" onClick={approveTherapistRequest} data-value1={therapist._id} data-value2={true}>Approve</button>
                                                                                            <button className="btn btn-danger" onClick={rejectTherapistRequest} data-value1={therapist._id} data-value2={true}>Reject</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <div>No Pending Therapists</div>
                                                                )
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12 mt30 text-center">
                                                        {seeMore_p_t && (
                                                            <button className={isLazyLoading ? "btn btn-secondary" : "btn btn-warning"} onClick={() => seeMorePendingTherapist()}>
                                                                {isLazyLoading ? "Loading..." : "See More"}
                                                            </button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </TabPane>

                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col sm="12" className="mt10">
                                                        <div className="table-responsive">
                                                            {
                                                                approvedTherapists.length > 0 ? (
                                                                    <table className="table">
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Name</th>
                                                                                <th scope="col">Email</th>
                                                                                <th scope="col">Phone</th>
                                                                                {/* <th scope="col">Profile</th> */}
                                                                                <th scope="col">Created Date</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                approvedTherapists && approvedTherapists.map((therapist, index) => (
                                                                                    <tr key={therapist._id}>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td>{therapist.firstname ?? ""} {therapist.lastname ?? ""}</td>
                                                                                        <td>{therapist.email ?? ""}</td>
                                                                                        <td>{therapist.primaryPhone ?? ""}</td>
                                                                                        {/* <td>
                                                                                            <Link to={"/profile/" + therapist._id} className="btn btn-info mr-2" title="View Profile"> <i className="fa fa-user"></i> &nbsp;Profile</Link>
                                                                                        </td> */}
                                                                                        <td>{therapist?.createdAt ? moment(therapist?.createdAt).format("YYYY-MM-DD hh:mm:ss A") : <span>-</span>}</td>
                                                                                        <td><span className="btn btn-success status">Approved</span></td>
                                                                                        <td>
                                                                                            <button className="btn btn-danger" onClick={rejectTherapistRequest} data-value1={therapist._id} data-value2={false}>Reject</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <div>No Approved Therapists</div>
                                                                )
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12 mt30 text-center">
                                                        {seeMore_a_t && (
                                                            <button className={isLazyLoading ? "btn btn-secondary" : "btn btn-warning"} onClick={() => seeMoreApprovedTherapist()}>
                                                                {isLazyLoading ? "Loading..." : "See More"}
                                                            </button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </TabPane>

                                            <TabPane tabId="3">
                                                <Row>
                                                    <Col sm="12" className="mt10">
                                                        <div className="table-responsive">
                                                            {
                                                                rejectedTherapists.length > 0 ? (
                                                                    <table className="table">
                                                                        <thead className="thead-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Name</th>
                                                                                <th scope="col">Email</th>
                                                                                <th scope="col">Phone</th>
                                                                                {/* <th scope="col">Profile</th> */}
                                                                                <th scope="col">Created Date</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                rejectedTherapists && rejectedTherapists.map((therapist, index) => (
                                                                                    <tr key={therapist._id}>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td>{therapist.firstname ?? ""} {therapist.lastname ?? ""}</td>
                                                                                        <td>{therapist.email ?? ""}</td>
                                                                                        <td>{therapist.primaryPhone ?? ""}</td>
                                                                                        {/* <td>
                                                                                            <Link to={"/profile/" + therapist._id} className="btn btn-info mr-2" title="View Profile"> <i className="fa fa-user"></i> &nbsp;Profile</Link>
                                                                                        </td> */}
                                                                                        <td>{therapist?.createdAt ? moment(therapist?.createdAt).format("YYYY-MM-DD hh:mm:ss A") : <span>-</span>}</td>
                                                                                        <td><span className="btn btn-danger status">Rejected</span></td>
                                                                                        <td>
                                                                                            <button className="btn btn-success" onClick={approveTherapistRequest} data-value1={therapist._id} data-value2={false}>Approve</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <div>No Rejected Therapists</div>
                                                                )
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12 mt30 text-center">
                                                        {seeMore_r_t && (
                                                            <button className={isLazyLoading ? "btn btn-secondary" : "btn btn-warning"} onClick={() => seeMoreRejectedTherapist()}>
                                                                {isLazyLoading ? "Loading..." : "See More"}
                                                            </button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                         )}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TherapistRequests;

