import { auto } from "@popperjs/core";
import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { ColumnScore, PatientHealthQuestionaireModel } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";

interface SelectedCell {
    [key: number]: number | undefined;
}

export interface score {
    [key: string]: boolean | undefined;
    notAtAll?: boolean;
    severalDays?: boolean;
    moreThanHalfTheDays?: boolean;
    NearlyEveryDay?: boolean;
}

const FormFourteen = (props: any) => {

    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    })
    const { formId, assesmenData,customActiveTab } = props;
    const [edit, setEdit] = useState<boolean>(true);

    const [selectedCell, setSelectedCell] = useState<SelectedCell>({});

    const handleCellClick = (row: number, id: number) => {

        setSelectedCell(prevSelectedCell => ({ ...prevSelectedCell, [row]: id }));

    };

    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                _id: formId,
                stepCount: 12,
                leisureActivity: [],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                patientHealthQuestionaire: assesmenData.patientHealthQuestionaire
            }
            setFormData(updateAssesment);

        }
    }, [assesmenData])

    useEffect(() => {
        if (formData && formData.patientHealthQuestionaire) {
            const selectedCells: SelectedCell = {};
            Object.entries(formData.patientHealthQuestionaire).forEach(([key, value]) => {
                const score = value as score; // Cast value to 'score' type
                if (score && Object.values(score).some(val => val === true)) {
                    // Find the index of the true value in 'score'
                    const selectedId = Object.keys(score as { [key: string]: boolean }).findIndex(scoreKey => (score as { [key: string]: boolean })[scoreKey]) + 1;
                    selectedCells[getRowNumber(key)] = selectedId;
                }
            });
            setSelectedCell(selectedCells);
        }
    }, [formData]);
    
    const getRowNumber = (fieldName: string): number => {
        switch (fieldName) {
            case 'littleInteres':
                return 1;
            case 'feelingDown':
                return 2;
            case 'troubleFalling':
                return 3;
            case 'feelingTired':
                return 4;
            case 'poorAppetite':
                return 5;
            case 'feelingBad':
                return 6;
            case 'troubleConcentrating':
                return 7;
            case 'speakingSlowly':
                return 8;
            case 'thoughtsDead':
                return 9;
            default:
                return 0;
        }
    };

    const getRowFieldName = (row: number) => {
        switch (row) {
            case 1: return 'littleInteres';
            case 2: return 'feelingDown';
            case 3: return 'troubleFalling';
            case 4: return 'feelingTired';
            case 5: return 'poorAppetite';
            case 6: return 'feelingBad';
            case 7: return 'troubleConcentrating';
            case 8: return 'speakingSlowly';
            case 9: return 'thoughtsDead';
            default: return '';
        }
    };

    const setCellValue = (row: number, id: number) => {
        setFormData(prevFormData => {
            const fieldName = getRowFieldName(row);
            if (!fieldName) return prevFormData;


            const updatedScore: score = {
                notAtAll: false,
                severalDays: false,
                moreThanHalfTheDays: false,
                NearlyEveryDay: false,
            };


            switch (id) {
                case 1:
                    updatedScore.notAtAll = true;
                    break;
                case 2:
                    updatedScore.severalDays = true;
                    break;
                case 3:
                    updatedScore.moreThanHalfTheDays = true;
                    break;
                case 4:
                    updatedScore.NearlyEveryDay = true;
                    break;
                default:
                    break;
            }

            //submodal
            const updatedpatientHealthQuestionaire: PatientHealthQuestionaireModel = {
                ...prevFormData.patientHealthQuestionaire,
                [fieldName]: updatedScore
            };

            const columnTotalsCopy = [...columnTotals];
            const columnIndex = id - 1;
            columnTotalsCopy[columnIndex] += columnIndex;

            const updatedColumnCal: ColumnScore = {
                column1: columnTotalsCopy[0],
                column2: columnTotalsCopy[1],
                column3: columnTotalsCopy[2],
                column4: columnTotalsCopy[3],
                total: columnTotalsCopy.reduce((acc, curr) => acc + curr, 0)
            };


            return {
                ...prevFormData,
                patientHealthQuestionaire: { ...updatedpatientHealthQuestionaire, columnCal: updatedColumnCal }
            };
        });
    };

    const cellStyle = (row: number, id: number) => {
        return selectedCell[row] === id ? { backgroundColor: 'rgb(255, 236, 220)' } : {};
    };

    const columnTotals = Array.from({ length: 4 }, () => 0);

    Object.values(selectedCell).forEach((id) => {
        if (id !== undefined) {
            const columnIndex = id - 1;
            columnTotals[columnIndex] += columnIndex;
        }
    });


    const updateAssesmentDetails = () => {
        const updatedAssesment: ClinicalAssetment = {
            _id: formId,
            stepCount: 14,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            patientHealthQuestionaire: formData.patientHealthQuestionaire
        }

        CommonService.updateClinicalAssesment(updatedAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });

    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);



    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header-secondary p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Patient Health Questionnaire (PHQ -9)</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-md" style={{ minWidth: "920px" }} >
                                    <thead style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" style={{ width: "40%", textAlign: "left", paddingLeft: "0.5rem" }}>Over the last two weeks, how often have you  been bothered by the following problems?</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Not at all</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Several days</td>
                                            <td className="fw-500" style={{ width: "15%" }}>More than half the days</td>
                                            <td className="fw-500" style={{ width: "15%" }}>Nearly every day</td>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-500" style={{ verticalAlign: "middle" }}>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    1. Little interest or pleasure in doing things
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(1, 1); setCellValue(1, 1) }} style={cellStyle(1, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(1, 2); setCellValue(1, 2) }} style={cellStyle(1, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(1, 3); setCellValue(1, 3) }} style={cellStyle(1, 3)}>
                                                <div className="col-auto ">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(1, 4); setCellValue(1, 4) }} style={cellStyle(1, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    2. Feeling down, depressed, or hopeless
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(2, 1); setCellValue(2, 1) }} style={cellStyle(2, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(2, 2); setCellValue(2, 2) }} style={cellStyle(2, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(2, 3); setCellValue(2, 3) }} style={cellStyle(2, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(2, 4); setCellValue(2, 4) }} style={cellStyle(2, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    3. Trouble falling or staying asleep, or sleeping too much
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(3, 1); setCellValue(3, 1) }} style={cellStyle(3, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(3, 2); setCellValue(3, 2) }} style={cellStyle(3, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(3, 3); setCellValue(3, 3) }} style={cellStyle(3, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(3, 4); setCellValue(3, 4) }} style={cellStyle(3, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    4. Feeling tired or having little energy
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(4, 1); setCellValue(4, 1) }} style={cellStyle(4, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(4, 2); setCellValue(4, 2) }} style={cellStyle(4, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(4, 3); setCellValue(4, 3) }} style={cellStyle(4, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(4, 4); setCellValue(4, 4) }} style={cellStyle(4, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    5. Poor appetite or overeating
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(5, 1); setCellValue(5, 1) }} style={cellStyle(5, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(5, 2); setCellValue(5, 2) }} style={cellStyle(5, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(5, 3); setCellValue(5, 3) }} style={cellStyle(5, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(5, 4); setCellValue(5, 4) }} style={cellStyle(5, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    6. Feeling bad about yourself   or that you are a failure or  have let yourself or your family down
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(6, 1); setCellValue(6, 1) }} style={cellStyle(6, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(6, 2); setCellValue(6, 2) }} style={cellStyle(6, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(6, 3); setCellValue(6, 3) }} style={cellStyle(6, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(6, 4); setCellValue(6, 4) }} style={cellStyle(6, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    7. Trouble concentrating on things, such as reading the newspaper or watching television
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(7, 1); setCellValue(7, 1) }} style={cellStyle(7, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(7, 2); setCellValue(7, 2) }} style={cellStyle(7, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(7, 3); setCellValue(7, 3) }} style={cellStyle(7, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(7, 4); setCellValue(7, 4) }} style={cellStyle(7, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    8. Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or  restless that you have been moving around a lot more  than usual
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(8, 1); setCellValue(8, 1) }} style={cellStyle(8, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(8, 2); setCellValue(8, 2) }} style={cellStyle(8, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(8, 3); setCellValue(8, 3) }} style={cellStyle(8, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(8, 4); setCellValue(8, 4) }} style={cellStyle(8, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ height: "2rem" }}>
                                            <td style={{ textAlign: "left" }}>
                                                <div className="col-auto m-1">
                                                    9. Thoughts that you would be better off dead, or of hurting yourself
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(9, 1); setCellValue(9, 1) }} style={cellStyle(9, 1)}>
                                                <div className="col-auto">
                                                    0
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(9, 2); setCellValue(9, 2) }} style={cellStyle(9, 2)}>
                                                <div className="col-auto">
                                                    1
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(9, 3); setCellValue(9, 3) }} style={cellStyle(9, 3)}>
                                                <div className="col-auto">
                                                    2
                                                </div>
                                            </td>
                                            <td onClick={() => { handleCellClick(9, 4); setCellValue(9, 4) }} style={cellStyle(9, 4)}>
                                                <div className="col-auto">
                                                    3
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <Row style={{ justifyContent: "end", margin: "0.5rem" }}>
                                <Col lg={8} style={{ paddingInline: "inherit" }}>
                                    <div className="row">
                                        <div className="col-auto fw-500">
                                            Column totals
                                        </div>

                                        {[1, 2, 3, 4].map((index) => (
                                            <React.Fragment key={index}>
                                                <div className="col" style={{ paddingInline: "inherit" }}>
                                                    <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <input
                                                                    style={{
                                                                        width: "100%",
                                                                        border: "none",
                                                                        marginTop: "0.1rem",
                                                                        backgroundColor: "unset",
                                                                    }}
                                                                    type="text"
                                                                    id={`value${index}`}
                                                                    value={columnTotals[index - 1]}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                                {index !== 4 && (
                                                    <div className="col-auto fw-500" style={{ paddingInline: "inherit" }}>
                                                        +
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                        <div className="col-auto fw-500">
                                            =
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ justifyContent: "space-between", margin: "0.5rem" }}>
                                <Col md={auto}>
                                    <div className="col-auto fw-500" style={{ fontSize: "smaller" }}><i>
                                        (Healthcare professional : For interpretation of TOTAL,  please refer to accompanying scoring card).
                                    </i></div>
                                </Col>
                                <Col md={auto} style={{ paddingInline: "inherit" }}>
                                    <div className="row">
                                        <div className="col-auto fw-500">
                                            Total :
                                        </div>
                                        <div className="col" style={{ paddingInline: "inherit" }}>
                                            <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col">
                                                        <input
                                                            style={{
                                                                width: "100%",
                                                                border: "none",
                                                                marginTop: "0.1rem",
                                                                backgroundColor: "unset",
                                                            }}
                                                            type="text"
                                                            id="total"
                                                            value={columnTotals.reduce((acc, curr) => acc + (curr || 0), 0)}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-auto">
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Card className="match-card-body-second p-3">
                                <Row>
                                    <h6 className="fw-500 mb-2">If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?</h6>
                                </Row>
                                <Row>
                                    <Col xl={3} className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel12"
                                            checked={formData.patientHealthQuestionaire?.difficultLevel?.notDifficult || false}
                                            onClick={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    patientHealthQuestionaire: {
                                                        ...formData.patientHealthQuestionaire,
                                                        difficultLevel: { notDifficult: true }
                                                    }
                                                })
                                            }
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Not difficult at all</label>
                                    </Col>
                                    <Col xl={3} className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel12"
                                            checked={formData.patientHealthQuestionaire?.difficultLevel?.someWhatDifficult || false}
                                            onClick={e => setFormData({
                                                ...formData, patientHealthQuestionaire: {
                                                    ...formData.patientHealthQuestionaire, difficultLevel: {
                                                        someWhatDifficult: true
                                                    }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Somewhat difficult</label>
                                    </Col>
                                    <Col xl={3} className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel12"
                                            checked={formData.patientHealthQuestionaire?.difficultLevel?.veryDifficult || false}
                                            onClick={e => setFormData({
                                                ...formData, patientHealthQuestionaire: {
                                                    ...formData.patientHealthQuestionaire, difficultLevel: {
                                                        veryDifficult: true
                                                    }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Very difficult</label>
                                    </Col>
                                    <Col xl={3} className="d-flex">
                                        <input style={{ fontSize: "larger" }}
                                            type="radio"
                                            className="form-check-input"
                                            name="difficultLevel12"
                                            checked={formData.patientHealthQuestionaire?.difficultLevel?.extremlyDifficult || false}
                                            onClick={e => setFormData({
                                                ...formData, patientHealthQuestionaire: {
                                                    ...formData.patientHealthQuestionaire, difficultLevel: {
                                                        extremlyDifficult: true
                                                    }
                                                }
                                            })}
                                        />
                                        <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Extremely difficult</label>
                                    </Col>
                                </Row>
                            </Card>

                            <Row style={{ justifyContent: "center", marginTop: "8rem" }}>
                                <div className="col-auto fw-500" style={{ fontSize: "smaller", textAlign: "center" }}><i>
                                    Copyright © 1999 Pfizer Inc. All rights reserved. Reproduced with permission. PRIME-MD© is a trademark of Pfizer Inc.  A2663B 10-04-2005
                                </i></div>
                            </Row>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>
                        {edit &&

                            <button
                                type="button"
                                className="btn btn-primary mr-2 relative mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();
                                }}
                            >
                                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                            </button>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 14 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default FormFourteen;