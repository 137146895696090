import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalHeader, FormGroup, Input, ModalBody, Row, Col, Label } from "reactstrap";
import { SocketContext } from "../../context/ScoketContext";
import UserContext from "../../context/UserContext";
import { Repeat } from "../../models/Appointment";
import { Client } from "../../models/Client";
import { NotificationModel, NotificationEvent, NotificationVarient } from "../../models/Notification";
import { Therapist } from "../../models/Therapist";
import { WorkingHour } from "../../models/WorkingHour";
import { AppointmentService } from "../../services/AppointmentService";
import { ClientService } from "../../services/ClientService";
import { NotificationService } from "../../services/NotificationService";
import { Util } from "../../Util";
import Modal14 from "./Modal14";
import Swal from "sweetalert2";
import { sendNotificationSocketData } from "../../models/sendNotificationCallSocketData";
import DatePicker from "react-date-picker";
import ShowPopupSheduleContext from "src/context/ShowSchedulePopupContext";
import CustomTimePicker from "src/components/CustomTimePicker";
import LocalCallContext from "src/context/LocalCallContext";
import { TherapistService } from "src/services/TherapistService";
import Spinner from "src/common/spinner/spinner";

interface AppointmentModalInMeetingProps {
  showModal: boolean;
  setShowModal: (created: boolean) => void;
  setShowModalForTherapist: (value: boolean) => void;
}

const AppointmentModalInMeeting: React.FC<AppointmentModalInMeetingProps> = ({
  setShowModal,
  showModal,
  setShowModalForTherapist,
}: AppointmentModalInMeetingProps) => {
  const [user] = useContext(UserContext);
  const [localUserOnCall] = useContext(LocalCallContext);
  const [slots, setSlots] = useState<string[]>([]);
  const [haveRead, setHaveRead] = useState(false);
  const [haveAgreed, setHaveAgreed] = useState(false);
  const [haveAgreedPolicy, setHaveAgreedPolicy] = useState(false);
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [disabledCreateBtn, setDisabledCreateBtn] = useState(false);
  const [appointmentCreated, setAppointmentCreated] = useState(false);
  const [appointment, setAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: moment().format("YYYY/MM/DD"),
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    meetingType: "",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
    selectedDate: "",
  });
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const [errorModalText, setErrorModalText] = useState("");
  const [therapistDetailsFrom, setTherapistDetailsFrom] = useState<Therapist>({} as Therapist);
  const [clientDetailsFrom, setClientDetailsFrom] = useState<Client>({} as Client);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState<boolean>(true);

  const socket = useContext(SocketContext);
  const LOCALE = "en";
  useEffect(() => {
    if (user?.role == "THERAPIST") {
      setTherapistDetailsFrom(user as Therapist);
      getInitialData();
    }

    if (user?.role == "CLIENT") {
      setClientDetailsFrom(user as Client);
      getInitialData();
    }

    createHoursForTimeSlots();
  }, []);

  const getInitialData = async () => {
    const otherUserId = localUserOnCall.recieversUserId;

    if (user?.role == "THERAPIST") {
      const res = await ClientService.getClientByClientId(otherUserId);

      if (
        res &&
        res.success &&
        res.data &&
        res.data._id &&
        res.data.role &&
        user?._id &&
        user?.role &&
        res.data._id != user?._id &&
        res.data.role == "CLIENT"
      ) {
        setClientDetailsFrom(res.data);
        setIsLoadingInitialData(false);
      } else {
        return toast.error(`Error getting client data`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    }

    if (user?.role == "CLIENT") {
      const res = await TherapistService.getTherapistDetailsbyId(otherUserId);

      if (
        res &&
        res.success &&
        res.data &&
        res.data._id &&
        res.data.role &&
        user?._id &&
        user?.role &&
        res.data._id != user?._id &&
        res.data.role == "THERAPIST"
      ) {
        setTherapistDetailsFrom(res.data);
        setIsLoadingInitialData(false);
      } else {
        return toast.error(`Error getting therapist data`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    }
  };

  const checkAvailableTime = (workingHoursOfTherapist: WorkingHour[] | undefined) => {
    const availableDays: { startTime: any; endTime: any; daysOfWeek: any[] }[] = [];

    workingHoursOfTherapist?.length &&
      workingHoursOfTherapist?.map((obj: any) => {
        const dayAsNumber = dayOfWeekAsNumber(obj.day);

        if (Util.convertUTCDateToLocalDate(obj.endTime) > Util.convertUTCDateToLocalDate(obj.startTime)) {
          return availableDays.push({
            startTime: Util.convertUTCDateToLocalDate(obj.startTime),
            endTime: Util.convertUTCDateToLocalDate(obj.endTime),
            daysOfWeek: [dayAsNumber],
          });
        } else {
          availableDays.push({
            startTime: Util.convertUTCDateToLocalDate(obj.startTime),
            endTime: "24:00 AM",
            daysOfWeek: [dayAsNumber - 1],
          });

          return availableDays.push({
            startTime: "00:00 AM",
            endTime: Util.convertUTCDateToLocalDate(obj.endTime),
            daysOfWeek: [dayAsNumber],
          });
        }
      });

    return availableDays;
  };

  const getAvailableTimeBySelectedDay = (dayNumber: number, isAvailableHours?: any) => {
    const availableHours: string[] = [];

    isAvailableHours
      .filter((obj: any) => obj.daysOfWeek[0] === dayNumber)
      .map((obj: any) => {
        for (let hour = parseInt(obj.startTime); hour <= parseInt(obj.endTime); hour++) {
          if (hour == parseInt(obj.startTime)) {
            const mST = moment(obj.startTime, "HH:mm").minute();

            if (mST == 0) {
              availableHours.push(moment({ hour }).format("H:mm A"));
            }
          } else {
            availableHours.push(moment({ hour }).format("H:mm A"));
          }

          if (hour != parseInt(obj.endTime)) {
            availableHours.push(
              moment({
                hour,
                minute: 30,
              }).format("H:mm A")
            );
          } else {
            const mET = moment(obj.endTime, "HH:mm").minute();

            if (mET == 30) {
              availableHours.push(
                moment({
                  hour,
                  minute: 30,
                }).format("H:mm A")
              );
            }
          }
        }
      });

    return availableHours;
  };

  const createHoursForTimeSlots = () => {
    moment.locale(LOCALE);
    for (let hour = 0; hour < 24; hour++) {
      slots.push(moment({ hour }).format("H:mm A"));
      slots.push(
        moment({
          hour,
          minute: 30,
        }).format("H:mm A")
      );
    }

    setSlots(slots);
  };

  const handleChangeComplete = (color: any) => {
    appointment.color = color.hex;
  };

  const eventModalToggle = () => {
    setShowModal(appointmentCreated);
  };

  const closeAfterAppointmentCreate = (value: boolean) => {
    setShowModal(appointmentCreated);
  };

  const eventTimeValidationModalToggle = () => {
    setEventTimeValidationViewModal(!isEventTimeValidationViewModal);
    setErrorModalText("");
  };

  const createAppointment = () => {
    if (
      clientDetailsFrom.premiumStatus != "active" &&
      (clientDetailsFrom.subscriptionId == null || clientDetailsFrom.subscriptionStatus != "active") &&
      clientDetailsFrom.testSubscriptionStatus != "active"
    ) {
      return setShowModalForTherapist(true);
    }

    const selectedDate = moment(appointment.selectedDate);

    if (selectedDate.minutes() != 0 && selectedDate.minutes() != 30) {
      setErrorModalText("You have selected invalid time.");

      return setEventTimeValidationViewModal(true);
    }

    const blockedDates = therapistDetailsFrom.blockedDates;

    if (blockedDates) {
      const isInRange = blockedDates.some((range: any) => {
        return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate.toISOString()));
      });

      if (isInRange) {
        setErrorModalText("Sorry! Therapist `" + therapistDetailsFrom.firstname + " " + therapistDetailsFrom.lastname + "` has blocked this date.");

        return setEventTimeValidationViewModal(true);
      }
    }

    AppointmentService.getAllAppointmentAndDetailsByUserId(
      Util.calculateWeekNumberAndDates(appointment.date, clientDetailsFrom._id!, therapistDetailsFrom._id)
    ).then(res2 => {
      if (res2 && res2.success) {
        if (Util.skipCheckingForPremiumUsers(clientDetailsFrom)) {
          if (res2.data.sessionTimeOfWeek != 0 && res2.data.sessionTimeOfWeek != 60) {
            setErrorModalText(`Sorry! Client's weekly session time has exceeded.`);

            return setEventTimeValidationViewModal(true);
          }

          if (res2.data.sessionTimeOfWeek != 0 && res2.data.sessionTimeOfWeek == 60) {
            setErrorModalText(`Sorry! Client's weekly session time has exceeded.`);

            return setEventTimeValidationViewModal(true);
          }
        }

        if (!appointment.startTime) {
          return toast.error("Please select valid start time.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        const mST = moment(appointment.startTime, "HH:mm").minute();

        if (mST != 0 && mST != 30) {
          return toast.error("Please select valid start time.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        if (!appointment.date) {
          return toast.error("Please select valid date.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }

        const dayNumber = dayOfWeekAsNumber(moment(appointment.date).format("dddd"));

        const isAvailableHours = checkAvailableTime(therapistDetailsFrom.workingHours);

        const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, isAvailableHours);

        if (!hoursSlots.includes(appointment.startTime) || !hoursSlots.includes(appointment.endTime)) {
          if (user?.role == "THERAPIST") {
            Swal.fire({
              icon: "warning",
              title: "Your working hours are not within selected time. Do you still want to proceed?",
              showCancelButton: true,
              confirmButtonText: "Yes",
              confirmButtonColor: "#50a5f1",
              cancelButtonText: "No",
              cancelButtonColor: "#f46a6a",
            }).then(result => {
              if (result.isConfirmed) {
                addAppointment(true);
              }
            });
          } else {
            return toast.error("Therapist working hours are not within selected time.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        } else {
          addAppointment(false);
        }
      } else {
        setErrorModalText(`No appointments found for this Therapist.`);
        return setEventTimeValidationViewModal(true);
      }
    });
  };

  const addAppointment = (holidayEvent: boolean) => {
    if (
      !moment(new Date()).isBefore(
        moment(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
      )
    ) {
      return toast.error(`Sorry! You can't create appointment in a past date!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!appointment.title) {
      return toast.error(`Please add title.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!appointment.repeat) {
      return toast.error(`Please select repeat type.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (user?.role != "THERAPIST" && appointment.repeat != Repeat.DOES_NOT_REPEAT) {
      return toast.error(`You can't schedule repeating appointments.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveRead && therapistDetailsFrom.disclosureStatementId?.url) {
      return toast.error(`You must read disclosure statement & accept before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreed) {
      return toast.error(`You must agree to the terms & conditions of Lavni  before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreedPolicy) {
      return toast.error(`You must agree to the No Show and Cancellation Policy of Lavni Inc. before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    setDisabledCreateBtn(true);

    const appointmentObj = {
      therapistId: therapistDetailsFrom._id,
      clientId: clientDetailsFrom._id,
      start: new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0)),
      end: new Date(new Date(appointment.date).setHours(parseInt(appointment.endTime.split(":")[0]), parseInt(appointment.endTime.split(":")[1]), 0, 0)),
      reminders: [30],
      title: appointment.title,
      repeatInfo: {
        repeatType: appointment.repeat,
        interval: "",
        repeatDays: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
        endingDate: Util.monthsNextFrom(new Date(appointment.date), 1),
        endingAfter: 10,
        endingType: "",
      },
      color: appointment.color,
      groupId:
        user?._id +
        "_" +
        Util.dateConvertToMilisecond(
          new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
        ),
      eventOnHolidays: holidayEvent,
    };

    if (user?.role == "THERAPIST") {
      AppointmentService.createAppointmentByTherapist(appointmentObj).then(res => {
        if (res && res.success) {
          const recieverFrom = user?.role == "THERAPIST" ? clientDetailsFrom : therapistDetailsFrom;

          const appointmentNotification: NotificationModel = {
            senderId: user?._id,
            receiverId: recieverFrom._id,
            event: NotificationEvent.APPOINMENT_CREATED,
            link: "/appointments",
            content: "Appointment is created by " + user?.firstname + " " + user?.lastname,
            variant: NotificationVarient.INFO,
          };

          NotificationService.sendNotification(appointmentNotification).then(res => {
            const socketData: sendNotificationSocketData = {
              socketId: res.data.socketId,
              notifyData: appointmentNotification,
              senderId: user?._id,
              receiverId: recieverFrom._id,
            };

            socket.emit("send-notification", socketData);
          });
          setAppointmentCreated(true);
        } else {
          if (res && res.error) {
            setErrorModalText(res.error.toString());
          } else {
            setErrorModalText("Something went wrong !");
          }

          setDisabledCreateBtn(false);
          return setEventTimeValidationViewModal(true);
        }
      });
    } else {
      AppointmentService.createAppointmentByClient(appointmentObj).then(res => {
        if (res && res.success) {
          const recieverFrom = user?.role == "THERAPIST" ? clientDetailsFrom : therapistDetailsFrom;

          const appointmentNotification: NotificationModel = {
            senderId: user?._id,
            receiverId: recieverFrom._id,
            event: NotificationEvent.APPOINMENT_CREATED,
            link: "/appointments",
            content: "Appointment is created by " + user?.firstname + " " + user?.lastname,
            variant: NotificationVarient.INFO,
          };

          NotificationService.sendNotification(appointmentNotification).then(res => {
            const socketData: sendNotificationSocketData = {
              socketId: res.data.socketId,
              notifyData: appointmentNotification,
              senderId: user?._id,
              receiverId: recieverFrom._id,
            };

            socket.emit("send-notification", socketData);
          });
          setAppointmentCreated(true);
        } else {
          if (res && res.error) {
            setErrorModalText(res.error.toString());
          } else {
            setErrorModalText("Something went wrong !");
          }

          setDisabledCreateBtn(false);
          return setEventTimeValidationViewModal(true);
        }
      });
    }
  };

  const dayOfWeekAsNumber = (day: string) => {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
  };

  return (
    <React.Fragment>
      {!appointmentCreated && (
        <Modal isOpen={showModal} centered toggle={eventModalToggle} unmountOnClose={true} backdrop={"static"}>
          <ModalHeader toggle={eventModalToggle}>
            {!isLoadingInitialData && (
              <div className="title-input">
                <FormGroup>
                  <Input
                    className="modal-title new-input-v"
                    name="title"
                    type="text"
                    placeholder="Add title - Optional"
                    onChange={e => setAppointment({ ...appointment, title: e.target.value })}
                    value={appointment.title}
                  />
                  <span className="awsome_input_border" />
                </FormGroup>
              </div>
            )}
          </ModalHeader>

          {!isLoadingInitialData ? (
            <ModalBody className="awsome-area">
              <Row className="mb-4">
                <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                  <i className="bx bx-time-five"></i>
                </Col>
                <Col lg={11} md={11} sm={11} xs={12}>
                  <Row>
                    <Col lg={4} md={4} sm={4} xs={6} className="sm-mb5">
                      <DatePicker
                        minDate={moment().toDate()}
                        value={moment(appointment?.date).toDate()}
                        onChange={(date: Date) => {
                          const selectedTime = moment(date);

                          selectedTime.hour(hourValue).minute(minuteValue);

                          const stateT = moment(selectedTime).format("H:mm A");
                          const endT = moment(selectedTime).add(60, "minutes").format("H:mm A");
                          const sDate = moment(selectedTime).toISOString();

                          setAppointment({ ...appointment, startTime: stateT, endTime: endT, selectedDate: sDate, date: selectedTime.format("YYYY/MM/DD") });
                        }}
                      />
                    </Col>

                    <Col lg={8} md={8} sm={8} xs={6}>
                      <CustomTimePicker
                        timeValue={timeValue}
                        setTimeValue={setTimeValue}
                        appointment={appointment}
                        setAppointment={setAppointment}
                        setHourValue={setHourValue}
                        setMinuteValue={setMinuteValue}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                  <i className="bx bx-video"></i>
                </Col>
                <Col lg={11} md={11} sm={11} xs={12}>
                  Video - 1 Hour Session
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                  <i className={Util.meetingRepeatTypeIcon(appointment.repeat)}></i>
                </Col>
                <Col lg={11} md={11} sm={11} xs={12}>
                  <Input
                    type="select"
                    name="repeat"
                    onChange={e => setAppointment({ ...appointment, repeat: e.target.value as Repeat })}
                    value={appointment.repeat}
                  >
                    <option defaultChecked value={Repeat.DOES_NOT_REPEAT}>
                      Does Not Repeat
                    </option>

                    {user?.role == "THERAPIST" && <option value={Repeat.WEEKLY}>Weekly</option>}
                    <option value={Repeat.BI_WEEKLY}>Bi Weekly</option>
                  </Input>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
                  <i className="bx bx-palette"></i>
                </Col>
                <Col lg={11} md={11} sm={11} xs={12}>
                  <TwitterPicker width="100%" onChangeComplete={handleChangeComplete} color={appointment.color} />
                </Col>
              </Row>

              {user?.role == "CLIENT" && therapistDetailsFrom.disclosureStatementId?.url && (
                <Row className="mb-4">
                  <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
                  <Col className="" lg={11} md={11} sm={11} xs={12}>
                    <FormGroup check inline>
                      <Input type="checkbox" onChange={(e: any) => setHaveRead(e.target.checked)} />
                      <Label check>
                        I have read the&nbsp;
                        <Link to={{ pathname: Util.fileURL(therapistDetailsFrom.disclosureStatementId?._id) }} target="_blank">
                          disclosure statement&nbsp;
                        </Link>
                        & Accept.
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {user?.role == "THERAPIST" && therapistDetailsFrom.disclosureStatementId?.url && (
                <Row className="mb-4">
                  <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
                  <Col className="" lg={11} md={11} sm={11} xs={12}>
                    <FormGroup check inline>
                      <Input type="checkbox" onChange={(e: any) => setHaveRead(e.target.checked)} />
                      <Label check>
                        I have read the&nbsp;
                        <Link to={{ pathname: Util.fileURL(therapistDetailsFrom.disclosureStatementId?._id) }} target="_blank">
                          disclosure statement&nbsp;
                        </Link>
                        & Accept.
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              )}

              <Row className="mb-4">
                <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
                <Col className="" lg={11} md={11} sm={11} xs={12}>
                  <FormGroup check inline>
                    <Input type="checkbox" onChange={(e: any) => setHaveAgreedPolicy(e.target.checked)} />
                    <Label check>
                      I agree to&nbsp;
                      <a href="/noshow-policy" className="hover" target="_blank">
                        No Show and Cancellation Policy&nbsp;
                      </a>
                      of Lavni Inc.
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
                <Col className="" lg={11} md={11} sm={11} xs={12}>
                  <FormGroup check inline>
                    <Input type="checkbox" onChange={(e: any) => setHaveAgreed(e.target.checked)} />
                    <Label check>
                      I agree to&nbsp;
                      <a href="/consent-document" className="hover" target="_blank">
                        Consent Document&nbsp;
                      </a>
                      of Lavni Inc.
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col className="cont-center" lg={1}></Col>
                <Col className="appoint-btn">
                  <button className={disabledCreateBtn ? "session-btn updating" : "session-btn"} disabled={disabledCreateBtn} onClick={createAppointment}>
                    Create
                  </button>
                </Col>
              </Row>
            </ModalBody>
          ) : (
            <ModalBody className="awsome-area">
              <div
                style={{
                  height: 150,
                }}
              >
                <Spinner />
              </div>
            </ModalBody>
          )}
        </Modal>
      )}

      {!isLoadingInitialData && !appointmentCreated && (
        <Modal isOpen={isEventTimeValidationViewModal} centered toggle={eventTimeValidationModalToggle}>
          <ModalBody>
            <div className="modal-val">
              <h5 className="model-error">{errorModalText}</h5>

              <button className="btn btn-sm text-center btn-success mt-3" onClick={() => eventTimeValidationModalToggle()}>
                Got It
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}

      {!isLoadingInitialData && appointmentCreated && <Modal14 setShowModal={closeAfterAppointmentCreate} showModal={appointmentCreated} />}
    </React.Fragment>
  );
};

export default AppointmentModalInMeeting;
