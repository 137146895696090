import React, { useState, useEffect } from "react";
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepWizard from "react-step-wizard";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";
import FormFour from "./FormFour";
import FormFive from "./FormFive";
import FormSix from "./FormSix";
import FormSeven from "./FormSeven";
import FormEight from "./FormEight";
import FormNine from "./FormNine";
import FormTen from "./FormTen";
import FormEleven from "./FormEleven";
import FormTwelve from "./FormTwelve";
import FormThirteen from "./FormThirteen";
import FormFourteen from "./FormFourteen";
import FormFifteen from "./FormFifteen";
import FormSixteen from "./FormSixteen";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { CommonService } from "src/services/CommonService";
import classNames from "classnames";
import { Client } from './../../models/Client';
import { ClientService } from './../../services/ClientService';
import { AdminService } from "src/services/AdminService";
import moment from "moment";

toast.configure();

const AssessmentOnboardingPage: React.FC<{ identifierId: any; isAdmin: boolean; closePopup: ()=>void; setModalRefresher: ((value: boolean) => void) | null; }> = props => {
    const [customActiveTab, setcustomActiveTab] = useState(0);
    const [formId, setFormId] = useState<string>();
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [assesmenData, setAssesmenData] = useState<ClinicalAssetment>();
    const [client, setClient] = useState<Client>();
    const identifierId = props.identifierId;
    const isAdmin = props.isAdmin;
    const [dateOfSessionFrom, setDateOfSessionFrom] = useState<string>("");
    const [isVersionCreated, setIsVersionCreated] = useState(false);
    const [isVersionCreatedToday, setIsVersionCreatedToday] = useState(false);

    const todayDate = moment.utc().startOf('day').toDate();


    useEffect(() => {
       getClient();
    },[])

    const getClient = async () => {
        try {
            if (isAdmin) {
                const data = {
                    assesmentId: identifierId
                }
                const res = await AdminService.getClientUsingClinicalAssessmentId(data);
                if (res && res.success) {
                    setClient(res.data);
                } else {
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            } else {
                const res = await ClientService.getClientByClientId(identifierId);
                if (res && res.success) {
                    setClient(res.data)
                } else {
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            }
        } catch (error) {
            toast.error("Something went wrong!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    const toggleCustom = (tab: number) => {
        setcustomActiveTab(tab);
    }

    function getUpdateFormId(formId: string) {
        setFormId(formId);
    }

    useEffect(() => {
        getAssessmentData();
    }, []);

    useEffect(() => {
        setFormId(assesmenData?._id);
    }, [assesmenData]);

    const previousStep = () => {
        setcustomActiveTab(customActiveTab - 1);
    }

    const nextStep = () => {
        setcustomActiveTab(customActiveTab + 1);
    }

    const getAssessmentData = async () => {
        try {
            if (isAdmin) {
                const data = {
                    assesmentId: identifierId
                }

                const res = await AdminService.getClinicalAssestmentDetailsWithSessionDataByAdmin(data);

                if (res && res?.success && res?.data && res?.data?.asessmentData) {
                    const updatedData = res?.data?.asessmentData;

                    if(updatedData?.assesmentHeader){
                        if(res?.data?.dateOfSession){
                            const formattedDate = moment.utc(res?.data?.dateOfSession).format("YYYY-MM-DD");
                            updatedData.assesmentHeader.dateOfSession = updatedData?.assesmentHeader?.dateOfSession ?? formattedDate;
                        } else {
                            const formattedTodayDate = moment.utc(todayDate).format("YYYY-MM-DD");
                            updatedData.assesmentHeader.dateOfSession = updatedData?.assesmentHeader?.dateOfSession ?? formattedTodayDate;
                        }
                    }

                    updatedData.dateOfAssesment = updatedData?.dateOfAssesment ?? todayDate;
                    
                    setIsVersionCreatedToday(res?.data?.isVersionCreatedToday);
                    setIsUpdate(true);
                    setAssesmenData(updatedData);
                    setFormId(updatedData?._id);

                } else {
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }

            } else {
                const data = {
                    clientId: identifierId
                }
                
                const res = await CommonService.getClinicalAssestmentDetailsWithSessionData(data);

                if (res && res?.success && res?.data) {
    
                    if(res?.data?.asessmentData){
                        const updatedData = res?.data?.asessmentData;

                        if(updatedData?.assesmentHeader){
                            if(res?.data?.dateOfSession){
                                const formattedDate = moment.utc(res?.data?.dateOfSession).format("YYYY-MM-DD");
                                updatedData.assesmentHeader.dateOfSession = updatedData?.assesmentHeader?.dateOfSession ?? formattedDate;
                            } else {
                                const formattedTodayDate = moment.utc(todayDate).format("YYYY-MM-DD");
                                updatedData.assesmentHeader.dateOfSession = updatedData?.assesmentHeader?.dateOfSession ?? formattedTodayDate;
                            }
                        }

                        updatedData.dateOfAssesment = updatedData?.dateOfAssesment ?? todayDate;
                        
                        setIsVersionCreatedToday(res?.data?.isVersionCreatedToday);
                        setIsUpdate(true);
                        setAssesmenData(updatedData);
                        setFormId(updatedData?._id);
                    }else{
                        setIsUpdate(false);
                        if(res?.data?.dateOfSession){
                            setDateOfSessionFrom(res?.data?.dateOfSession);
                        } else {
                            setDateOfSessionFrom(todayDate.toString());
                        }
                    }

                } else {
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            }
        } catch (error) {
            toast.error("Something went wrong!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }
    }

    function modalRefresher() {
        props.setModalRefresher?.(true);
    }

    return (
        <React.Fragment>
            <div className="page-content mt-0" style={{ padding: 0 }}>
                <Container className="containerOnboarding" style={{ padding: 0 }}>
                    <Row className="row-content justify-content-sm-center" style={{ padding: 0 }}>
                            <Nav pills style={{ marginTop: "30px", justifyContent: "center", paddingLeft:"12px" }} className="col-12 d-none d-xl-flex">
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination', { active: customActiveTab === 0 })}
                                        onClick={() => { toggleCustom(0); }}>
                                        Page 1
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 1 })}
                                        onClick={() => { toggleCustom(1); }}>
                                        Page 2
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 2 })}
                                        onClick={() => { toggleCustom(2); }}>
                                        Page 3
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 3 })}
                                        onClick={() => { toggleCustom(3); }}>
                                        Page 4
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 4 })}
                                        onClick={() => { toggleCustom(4); }}>
                                        Page 5
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 5 })}
                                        onClick={() => { toggleCustom(5); }}>
                                        Page 6
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 6 })}
                                        onClick={() => { toggleCustom(6); }}>
                                        Page 7
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 7 })}
                                        onClick={() => { toggleCustom(7); }}>
                                        Page 8
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 8 })}
                                        onClick={() => { toggleCustom(8); }}>
                                        Page 9
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 9 })}
                                        onClick={() => { toggleCustom(9); }}>
                                        Page 10
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 10 })}
                                        onClick={() => { toggleCustom(10); }}>
                                        Page 11
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 11 })}
                                        onClick={() => { toggleCustom(11); }}>
                                        Page 12
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 12 })}
                                        onClick={() => { toggleCustom(12); }}>
                                        Page 13
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 13 })}
                                        onClick={() => { toggleCustom(13); }}>
                                        Page 14
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 14 })}
                                        onClick={() => { toggleCustom(14); }}>
                                        Page 15
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 15 })}
                                        onClick={() => { toggleCustom(15); }}>
                                        Page 16
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <Nav pills style={{ marginTop: "30px", justifyContent: "center", paddingLeft:"12px" }} className="col-12 d-xl-none">
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination', { active: customActiveTab === 0 })}
                                        onClick={() => { toggleCustom(0); }}>
                                        1
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 1 })}
                                        onClick={() => { toggleCustom(1); }}>
                                        2
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 2 })}
                                        onClick={() => { toggleCustom(2); }}>
                                        3
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 3 })}
                                        onClick={() => { toggleCustom(3); }}>
                                        4
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 4 })}
                                        onClick={() => { toggleCustom(4); }}>
                                        5
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 5 })}
                                        onClick={() => { toggleCustom(5); }}>
                                        6
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 6 })}
                                        onClick={() => { toggleCustom(6); }}>
                                        7
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 7 })}
                                        onClick={() => { toggleCustom(7); }}>
                                        8
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 8 })}
                                        onClick={() => { toggleCustom(8); }}>
                                        9
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 9 })}
                                        onClick={() => { toggleCustom(9); }}>
                                        10
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 10 })}
                                        onClick={() => { toggleCustom(10); }}>
                                        11
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 11 })}
                                        onClick={() => { toggleCustom(11); }}>
                                        12
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 12 })}
                                        onClick={() => { toggleCustom(12); }}>
                                        13
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 13 })}
                                        onClick={() => { toggleCustom(13); }}>
                                        14
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 14 })}
                                        onClick={() => { toggleCustom(14); }}>
                                        15
                                    </NavLink>
                                </NavItem>
                                <NavItem className="border">
                                    <NavLink
                                        className={classNames('my-pagination',{ active: customActiveTab === 15 })}
                                        onClick={() => { toggleCustom(15); }}>
                                        16
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        {/* </Col> */}
                        <Col>
                            <div>
                                <h5 className="font-size-25 profileFont mt-4 text-center">
                                    COMPREHENSIVE CLINICAL ASSESSMENT
                                </h5>
                            </div>
                            <StepWizard>
                                <TabContent activeTab={customActiveTab} className="text-muted">
                                    <TabPane tabId={0}>
                                        <FormOne formId={formId} getUpdateFormId={getUpdateFormId} clientId={client?._id} isUpdate={isUpdate} assesmenData={assesmenData} nextStep={nextStep} client={client} dateOfSessionFrom= {dateOfSessionFrom} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={1}>
                                        <FormTwo formId={formId} assesmenData={assesmenData} previousStep={previousStep} nextStep={nextStep} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <FormThree formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={3}>
                                        <FormFour formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={4}>
                                        <FormFive formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={5}>
                                        <FormSix formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={6}>
                                        <FormSeven formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={7}>
                                        <FormEight formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={8}>
                                        <FormNine formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={9}>
                                        <FormTen formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={10}>
                                        <FormEleven formId={formId} assesmenData={assesmenData} clientId={client?._id} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={11}>
                                        <FormTwelve formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={12}>
                                        <FormThirteen formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={13}>
                                        <FormFourteen formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={14}>
                                        <FormFifteen formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} nextStep={nextStep} customActiveTab={customActiveTab} onModalRefresher={modalRefresher} />
                                    </TabPane>
                                    <TabPane tabId={15}>
                                        <FormSixteen formId={formId} assesmenData={assesmenData} getAssesmentByClientId={getAssessmentData} previousStep={previousStep} isVersionCreated={isVersionCreated} setIsVersionCreated={setIsVersionCreated} closePopup={() => props.closePopup()} onModalRefresher={modalRefresher} isVersionCreatedToday={isVersionCreatedToday} />
                                    </TabPane>
                                </TabContent>
                            </StepWizard>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AssessmentOnboardingPage;