import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Container, Row, Col, Card, CardBody, Input } from "reactstrap";
import moment from "moment";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { PasswordDetails, UserDetails } from "src/models/User";
import { ClientService } from "src/services/ClientService";
import { Role } from "src/models/Role";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Therapist } from "src/models/Therapist";
import { AppointmentService } from "src/services/AppointmentService";
import { environment } from "../../environment/environment";
import { AuthService } from "src/services/AuthService";
import { CommonService } from "src/services/CommonService";
import { Ethinicity } from "src/models/Ethnicity";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import MainDetailsContext from "src/context/MainDetailsContext";
import { useHistory } from "react-router-dom";
import FooterMain from "./FooterMain";
import HeaderMain from "./HeaderMain";

const PublicSignup: React.FC = (props: any) => {
  const state = localStorage.getItem("state");
  const [userdata, setUserdata] = useState<UserDetails | undefined>({} as UserDetails);
  const [isLoading, setIsLoading] = useState(true);
  const [dateValue, setDateValue] = useState("");
  const [isDisable, setDisable] = useState(false);
  const [response, setResponse] = useState(false);
  function handleDateUpdate(e: any) {
    const dateValue = e.target.value;
    setDateValue(dateValue);
  }
  const [changePassword, setChangePassword] = useState<PasswordDetails>({} as PasswordDetails);
  const db = moment.utc(dateValue).format("YYYY-MM-DD");
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [ethnicityList, setEthnicityList] = useState<Ethinicity[]>([]);
  const [dataResponse, setDataResponse] = useState();
  const [insuranceCard, selectInsuranceCard] = useState({ accessToken: "", facebookId: "" });
  const textBoxRef = useRef<HTMLInputElement | null>(null);
  const storedTherapist = localStorage.getItem('therapist');
  const storedappointmentObj = localStorage.getItem('appointmentObj');
  const storedPersonalizeMatchObj = localStorage.getItem('PersonalizeMatchData');
  const skipBtn = localStorage.getItem('skip');
  const clientReferralCode = localStorage.getItem('referralCode') || '';
  const storedTherapistData: Therapist = JSON.parse(storedTherapist as any);
  const storedAppointmentObjData: any = JSON.parse(storedappointmentObj as any);
  const storedPersonalizeMatchObjData: any = JSON.parse(storedPersonalizeMatchObj as any);
  const [clickSkipButton, setClickSkipButton] = useContext(MainDetailsContext);

  const history = useHistory();

  useEffect(() => {
    CommonService.getEthinicityList().then(res => {
      setEthnicityList(res.data);
    });
  }, []);

  const createClient = () => {
    const phoneno = /^\+1[0-9]{10}$/;

    if (!userdata?.firstname) {
      toast.error("First name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.lastname) {
      toast.error("Last name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.email) {
      toast.error("Email is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.username) {
      toast.error("Username is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!dateValue) {
      toast.error("Date of birth is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else {
      const inputValue = dateValue;
      const numericValue = inputValue.replace(/[^0-9]/g, "");

      if (numericValue.length === 8) {
        const mm = parseInt(numericValue.substring(0, 2));
        const dd = parseInt(numericValue.substring(2, 4));
        const yyyy = parseInt(numericValue.substring(4, 8));

        if (mm > 12 || dd > 31 || yyyy > 2024) {
          toast.error("Valid birthday is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      }
    }

    if (!userdata?.primaryPhone) {
      toast.error("Primary Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.primaryPhone.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.gender) {
      toast.error("Gender is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!selectedEthnicity) {
      toast.error("Ethnicity is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (userdata?.password !== changePassword?.confirmPassword) {
      toast.error("Password does not match.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    setDisable(true);

    const updatedUser: any = {
      firstname: userdata?.firstname,
      lastname: userdata?.lastname,
      gender: userdata?.gender,
      dateOfBirth: (dateValue as any) || userdata?.dateOfBirth,
      ethnicityId: selectedEthnicity,
      email: userdata?.email,
      zipCode: userdata?.zipCode,
      username: userdata?.username,
      state: state as any,
      primaryPhone: userdata?.primaryPhone,
      role: Role.CLIENT,
      password: userdata?.password,
      skip: skipBtn == 'true' ? true : false,
      PersonalizeMatchData: storedPersonalizeMatchObjData
    };

    const likeTherapist: any = {
      therapistId: storedTherapistData?._id,
    }

    const appointmentObj: any = {
      therapistId: storedAppointmentObjData?.therapistId,
      start: storedAppointmentObjData?.start,
      end: storedAppointmentObjData?.end,
      reminders: storedAppointmentObjData?.reminders,
      title: storedAppointmentObjData?.title,
      repeatInfo: storedAppointmentObjData?.repeatInfo,
      color: storedAppointmentObjData?.color,
      groupId: storedAppointmentObjData?.groupId,
      eventOnHolidays: storedAppointmentObjData?.eventOnHolidays,
    }

    const referralInfo: any = {
      referralCode: clientReferralCode,
    }

    ClientService.signupClient(updatedUser, likeTherapist, appointmentObj, referralInfo).then(res => {
      if (res.success) {
        localStorage.setItem('client', JSON.stringify(res.data));
        localStorage.setItem('password', JSON.stringify(userdata?.password));

        setDisable(true);
        toast.success(<div dangerouslySetInnerHTML={{ __html: res?.message }} />, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          autoClose: 10000,
        })
        history.push("/public-verify-signup");

        setIsLoading(false);
      } else {
        toast.error(res?.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setIsLoading(false);
        setDisable(false);
      }
    });
  };

  const responseGoogle = (response: any) => {
    setDataResponse(response);

    if (!response.error) {
      setResponse(true);
    }
  };

  const confirmSubmission = () => {
    const phoneno = /^\+1[0-9]{10}$/;

    if (!userdata?.firstname) {
      toast.error("First name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.lastname) {
      toast.error("Last name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.username) {
      toast.error("Username is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!dateValue) {
      toast.error("Date of birth is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else {
      const inputValue = dateValue;
      const numericValue = inputValue.replace(/[^0-9]/g, "");

      if (numericValue.length === 8) {
        const mm = parseInt(numericValue.substring(0, 2));
        const dd = parseInt(numericValue.substring(2, 4));
        const yyyy = parseInt(numericValue.substring(4, 8));

        if (mm > 12 || dd > 31 || yyyy > 2024) {
          toast.error("Valid birthday is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      }
    }

    if (!userdata?.primaryPhone) {
      toast.error("Primary Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.primaryPhone.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.gender) {
      toast.error("Gender is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!selectedEthnicity) {
      toast.error("Ethnicity is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    setDisable(true);

    const updatedUser: any = {
      firstname: userdata?.firstname,
      lastname: userdata?.lastname,
      gender: userdata?.gender,
      dateOfBirth: (dateValue as any) || userdata?.dateOfBirth,
      ethnicityId: selectedEthnicity,
      username: userdata?.username,
      zipCode: userdata?.zipCode,
      state: state as any,
      primaryPhone: userdata?.primaryPhone,
      idToken: dataResponse,
      clientId: environment.google_client_Id,
      role: "CLIENT",
      skip: skipBtn == 'true' ? true : false,
      PersonalizeMatchData: storedPersonalizeMatchObjData
    };

    const likeTherapist: any = {
      therapistId: storedTherapistData?._id,
    }

    const appointmentObj: any = {
      therapistId: storedAppointmentObjData?.therapistId,
      start: storedAppointmentObjData?.start,
      end: storedAppointmentObjData?.end,
      reminders: storedAppointmentObjData?.reminders,
      title: storedAppointmentObjData?.title,
      repeatInfo: storedAppointmentObjData?.repeatInfo,
      color: storedAppointmentObjData?.color,
      groupId: storedAppointmentObjData?.groupId,
      eventOnHolidays: storedAppointmentObjData?.eventOnHolidays,
    }

    const referralInfo: any = {
      referralCode: clientReferralCode,
    }

    AuthService.signUpWithGoogle(updatedUser, likeTherapist, appointmentObj, referralInfo).then(res => {
      if (res.success) {
        setDisable(true);
        toast.success(res?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
          autoClose: 10000,
        })
        history.push("/dashboard");

        setIsLoading(false);
      } else {
        toast.error(res?.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setIsLoading(false);
        setDisable(false);
      }
    });
  };

  const responseFacebook = (response: any) => {
    selectInsuranceCard({ ...insuranceCard, accessToken: response?.accessToken, facebookId: response?.userID });
    if (response?.accessToken) {
      setResponse(true);
    }
  };

  const confirmFbSubmission = () => {
    const phoneno = /^\+1[0-9]{10}$/;

    if (!userdata?.firstname) {
      toast.error("First name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.lastname) {
      toast.error("Last name is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.username) {
      toast.error("Username is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!dateValue) {
      toast.error("Date of birth is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    } else {
      const inputValue = dateValue;
      const numericValue = inputValue.replace(/[^0-9]/g, "");

      if (numericValue.length === 8) {
        const mm = parseInt(numericValue.substring(0, 2));
        const dd = parseInt(numericValue.substring(2, 4));
        const yyyy = parseInt(numericValue.substring(4, 8));

        if (mm > 12 || dd > 31 || yyyy > 2024) {
          toast.error("Valid birthday is required", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });

          return false;
        }
      }
    }

    if (!userdata?.primaryPhone) {
      toast.error("Primary Phone is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.primaryPhone.match(phoneno)) {
      toast.error("Please enter valid primary phone number with country code! Ex:+1xxxxxxxxx", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!userdata?.gender) {
      toast.error("Gender is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    if (!selectedEthnicity) {
      toast.error("Ethnicity is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });

      return false;
    }

    setDisable(true);

    const updatedUser: any = {
      firstname: userdata?.firstname,
      lastname: userdata?.lastname,
      gender: userdata?.gender,
      dateOfBirth: (dateValue as any) || userdata?.dateOfBirth,
      ethnicityId: selectedEthnicity,
      username: userdata?.username,
      zipCode: userdata?.zipCode,
      state: state as any,
      primaryPhone: userdata?.primaryPhone,
      accessToken: insuranceCard?.accessToken,
      facebookId: insuranceCard?.facebookId,
      role: "CLIENT",
      medium: "FACEBOOK",
      skip: skipBtn == 'true' ? true : false,
      PersonalizeMatchData: storedPersonalizeMatchObjData
    };

    const likeTherapist: any = {
      therapistId: storedTherapistData?._id,
    }

    const appointmentObj: any = {
      therapistId: storedAppointmentObjData?.therapistId,
      start: storedAppointmentObjData?.start,
      end: storedAppointmentObjData?.end,
      reminders: storedAppointmentObjData?.reminders,
      title: storedAppointmentObjData?.title,
      repeatInfo: storedAppointmentObjData?.repeatInfo,
      color: storedAppointmentObjData?.color,
      groupId: storedAppointmentObjData?.groupId,
      eventOnHolidays: storedAppointmentObjData?.eventOnHolidays,
    }

    const referralInfo: any = {
      referralCode: clientReferralCode,
    }

    AuthService.signUpWithFacebook(updatedUser, likeTherapist, appointmentObj, referralInfo).then(res => {
      if (res.success) {
        setDisable(true);

        history.push("/dashboard");

        setIsLoading(false);
      } else {
        toast.error(res?.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });

        setIsLoading(false);
        setDisable(false);
      }
    });
  };


  const handleDateChange = (e: any) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    const truncatedValue = numericValue.substring(0, 10);
    let formattedDate = "";

    for (let i = 0; i < truncatedValue.length; i++) {
      if (i === 2 || i === 4) {
        formattedDate += "/";
      }
      formattedDate += truncatedValue[i];
    }

    setDateValue(formattedDate);
  };

  return (
    <>
      <div className="content-wrapper ">
        <HeaderMain />
        <section
          className="bg-image-set wrapper image-wrapper bg-image bg-overlays bg-overlay-light-600 text-white"
          data-image-src="./../static/assets/img/photos/bg3.jpg"
        >
          <div className="container pt-17 pb-20 pt-md-19 pb-md-21 text-center"></div>
        </section>

        <section className="wrapper bg-light2 angled upper-end" id="recoverform">
          <div className="container1 pb-14 pb-md-16">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto mt-n20 mt-n222">
                {response ? (
                  <div id="targetSection" className="displaycenter">
                    <div className="card cardChange">
                      <div className="card-body  text-center">
                        <div className="row">
                          <div className="col-xl-12 mb-3 d-flex float-left jestifyStart">
                            <span>
                              <button className="btn btn-skip float-left" onClick={() => {
                                if (skipBtn == 'true') {
                                  localStorage.removeItem('skip');
                                  history.push('/main')
                                } else {
                                  localStorage.removeItem('skip');
                                  history.push(`/public-appoinments/${storedTherapistData?._id}`)
                                }
                              }}>
                                <i className="bx bx-left-arrow-alt"></i> &nbsp; Back
                              </button>
                            </span>
                          </div>
                        </div>
                        <Row>
                          <Col xl="7" sm="12" className="pading-signup2">
                            <form className="text-start mb-3">
                              <Row>
                                <Col xl="12">
                                  <div className="card-heading mb-3  flex">
                                    <Input
                                      type="text"
                                      className="form-control public-input"
                                      id="formrow-firstname-Input"
                                      placeholder="First Name"
                                      value={userdata?.firstname || ""}
                                      onChange={e => setUserdata({ ...userdata, firstname: e.target.value.trim()})}
                                      name="articleTitle"
                                      required
                                    />

                                    <Input
                                      type="text"
                                      className="form-control public-input ml-3"
                                      id="formrow-firstname-Input"
                                      placeholder="Last name"
                                      name="articleTitle"
                                      value={userdata?.lastname || ""}
                                      onChange={e => setUserdata({...userdata, lastname: e.target.value.trim()})}
                                      required
                                    />
                                  </div>
                                </Col>

                                <Col xl="12">
                                  <div className="card-heading mb-3 flex">
                                    <Input
                                      type="text"
                                      className="form-control public-input"
                                      id="formrow-firstname-Input"
                                      placeholder="Username"
                                      name="username"
                                      value={userdata?.username || ""}
                                      onChange={e => setUserdata({ ...userdata, username: e.target.value.trim() })}
                                      required
                                    />
                                  </div>
                                </Col>


                                <Col xl="12">
                                  <div className="card-heading mb-3">
                                    <PhoneInput
                                      placeholder="Primary Phone "
                                      defaultCountry="US"
                                      onChange={(e: string) => setUserdata({ ...userdata, primaryPhone: e })}
                                      inputClass="my-input-class"
                                      containerStyle={{ backgroundColor: "black" }}
                                    />
                                  </div>
                                </Col>

                                <Col xl="12">
                                  <div className="card-heading ">
                                    <h5 className="font-size-14 profileFont ">
                                      Your Birthday<span style={{ color: "#990000" }}></span>
                                    </h5>
                                  </div>
                                  <div className="card-heading mb-3 flex">
                                    <input
                                      type="text"
                                      className="form-control public-input"
                                      id="formrow-firstname-Input"
                                      placeholder="mm/dd/yyyy"
                                      value={dateValue}
                                      onChange={handleDateChange}
                                      maxLength={10}
                                    />

                                    <Input
                                      type="text"
                                      className="form-control public-input ml-3"
                                      id="formrow-firstname-Input"
                                      placeholder="Zipcode"
                                      name="zipcode"
                                      value={userdata?.zipCode || ""}
                                      onChange={e => setUserdata({ ...userdata, zipCode: e.target.value })}
                                      required
                                    />
                                  </div>
                                </Col>

                                <Col xl="12">
                                  <div className="card-heading mb-2">
                                    <h5 className="font-size-14 profileFont mb-2">
                                      Gender<span style={{ color: "#990000" }}></span>
                                    </h5>
                                  </div>

                                  <div className="card-heading mb-2">
                                    <Row>
                                      <Col style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exampleRadioss"
                                            id="exampleRadios1"
                                            value="Female"
                                            defaultChecked={userdata?.gender == "Female"}
                                            onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                          />

                                          <label className="form-check-label" htmlFor="exampleRadios1">
                                            Female
                                          </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exampleRadioss"
                                            id="exampleRadios2"
                                            value="Male"
                                            defaultChecked={userdata?.gender == "Male"}
                                            onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                          />

                                          <label className="form-check-label" htmlFor="exampleRadios2">
                                            Male
                                          </label>
                                        </div>

                                        <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exampleRadioss"
                                            id="exampleRadios3"
                                            value="NonBinary"
                                            defaultChecked={userdata?.gender == "NonBinary"}
                                            onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                          />

                                          <label className="form-check-label" htmlFor="exampleRadios3">
                                            Non Binary
                                          </label>
                                        </div>

                                        <div className="form-check mb-3">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="exampleRadioss"
                                            id="exampleRadios4"
                                            value="Other"
                                            defaultChecked={userdata?.gender == "Other"}
                                            onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                          />

                                          <label className="form-check-label" htmlFor="exampleRadios4">
                                            Other
                                          </label>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>



                                <Col xl="12">
                                  <div className="card-heading mb-4">
                                    <select className="form-control public-input" onChange={e => setSelectedEthnicity(e.target.value)} value={selectedEthnicity}>
                                      <option value="">Ethnicity</option>
                                      {ethnicityList &&
                                        ethnicityList.map(opt => (
                                          <option value={opt._id} key={opt._id}>
                                            {opt.ethnicity}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </Col>
                              </Row>


                              <div className="col-12 text-center">
                                <input
                                  className="btn btn-primary btn-send mb-3 px-20 py-3 br-10 fsize-1"
                                  type="button"
                                  value="Confirm"
                                  disabled={isDisable}
                                  onClick={dataResponse == undefined ? confirmFbSubmission : confirmSubmission}
                                />
                              </div>
                            </form>
                          </Col>
                          <Col xl="5" className="bg-public"></Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="displaycenter">
                    <div className="card cardChange">
                      <div className="card-body  text-center">
                        <div className="row">
                          <div className="col-xl-12 mb-3 d-flex float-left jestifyStart">
                            <span>
                              <button
                                className="btn btn-skip float-left"
                                onClick={() => {
                                  if (skipBtn == 'true') {
                                    localStorage.removeItem('skip');
                                    history.push('/main')
                                  } else {
                                    localStorage.removeItem('skip');
                                    history.push(`/public-appoinments/${storedTherapistData?._id}`)
                                  }
                                }}
                              >
                                <i className="bx bx-left-arrow-alt"></i> &nbsp; Back
                              </button>
                            </span>
                          </div>
                        </div>
                        <Row>
                          <Col xl="7" sm="12" className="pading-signup2">
                            <h1 className="mb-3 text-start  text-center">
                              Signup to confirm your appointment with
                              <h1 className="mb-3 text-start  text-center primaryColor">
                                {storedTherapistData?.firstname || "-"} {storedTherapistData?.lastname}
                              </h1>
                            </h1>
                            <div className="google-login-container">
                              <GoogleLogin
                                className="google-login-button"
                                clientId={environment.google_client_Id}
                                buttonText="Sign up with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={"single_host_origin"}
                              />
                            </div>
                            <p className="lead mb-6 text-start  text-center">Registration takes less than a minute.</p>
                            <form className="text-start mb-1">
                              <Row>
                                <Col xl="12">
                                  <div className="card-heading mb-3  flex">
                                    <Input
                                      type="text"
                                      className="form-control public-input"
                                      id="formrow-firstname-Input"
                                      placeholder="First name"
                                      value={userdata?.firstname || ""}
                                      onChange={e => setUserdata({ ...userdata, firstname: e.target.value })}
                                      name="articleTitle"
                                      required
                                    />

                                    <Input
                                      type="text"
                                      className="form-control public-input ml-3"
                                      id="formrow-Lastname-Input"
                                      placeholder="Last name"
                                      name="articleTitle"
                                      value={userdata?.lastname || ""}
                                      onChange={e => setUserdata({ ...userdata, lastname: e.target.value })}
                                      required
                                    />
                                  </div>
                                </Col>
                                <Col xl="12">
                                  <div className="card-heading mb-3 flex">
                                    <Input
                                      type="email"
                                      className="form-control public-input"
                                      id="email"
                                      placeholder="Email"
                                      name="email"
                                      value={userdata?.email || ""}
                                      onChange={e => setUserdata({ ...userdata, email: e.target.value })}
                                      required
                                    />
                                    <Input
                                      type="text"
                                      className="form-control public-input ml-3"
                                      id="username"
                                      placeholder="Username"
                                      name="username"
                                      value={userdata?.username || ""}
                                      onChange={e => setUserdata({ ...userdata, username: e.target.value })}
                                      required
                                    />
                                  </div>
                                </Col>


                                <Col xl="12">
                                  <div className="flex mb-3">
                                    <div className="w-50 mrn-6">
                                      <PhoneInput
                                        placeholder="Primary Phone "
                                        defaultCountry="US"
                                        onChange={(e: string) => setUserdata({ ...userdata, primaryPhone: e })}
                                        inputClass="my-input-class"
                                        containerStyle={{ backgroundColor: "black" }}
                                      />
                                    </div>
                                    <div className="w-50 mln-6">
                                      <select className="form-control public-input " onChange={e => setSelectedEthnicity(e.target.value)} value={selectedEthnicity}>
                                        <option value="">Ethnicity</option>
                                        {ethnicityList &&
                                          ethnicityList.map(opt => (
                                            <option value={opt._id} key={opt._id}>
                                              {opt.ethnicity}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>
                                </Col>

                                <Col xl="12">
                                  <div className="card-heading ">
                                    <h5 className="font-size-14 profileFont ">
                                      Your Birthday<span style={{ color: "#990000" }}></span>
                                    </h5>
                                  </div>
                                  <div className="card-heading mb-3 flex">
                                    <input
                                      type="text"
                                      className="form-control public-input"
                                      id="formrow-firstname-Input"
                                      placeholder="mm/dd/yyyy"
                                      value={dateValue}
                                      onChange={handleDateChange}
                                      maxLength={10}
                                    />

                                    <Input
                                      type="text"
                                      className="form-control public-input ml-3"
                                      id="formrow-firstname-Input"
                                      placeholder="Zipcode"
                                      name="zipcode"
                                      value={userdata?.zipCode || ""}
                                      onChange={e => setUserdata({ ...userdata, zipCode: e.target.value })}
                                      required
                                    />
                                  </div>
                                </Col>



                                <Col xl="12">
                                  <div style={{ display: "flex" }}>
                                    <div className="card-heading mb-3">
                                      <h5 className="font-size-14 profileFont mb-2">
                                        Gender<span style={{ color: "#990000" }}></span>
                                      </h5>
                                    </div>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                      <div className="form-check mb-2 ml-3" style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadioss"
                                          id="exampleRadios1"
                                          value="Female"
                                          defaultChecked={userdata?.gender == "Female"}
                                          onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                          Female
                                        </label>
                                      </div>

                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadioss"
                                          id="exampleRadios2"
                                          value="Male"
                                          defaultChecked={userdata?.gender == "Male"}
                                          onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                          Male
                                        </label>
                                      </div>

                                      <div className="form-check mb-3 " style={{ marginRight: "1.2rem" }}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadioss"
                                          id="exampleRadios3"
                                          value="NonBinary"
                                          defaultChecked={userdata?.gender == "NonBinary"}
                                          onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios3">
                                          Non Binary
                                        </label>
                                      </div>

                                      <div className="form-check mb-3">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="exampleRadioss"
                                          id="exampleRadios4"
                                          value="Other"
                                          defaultChecked={userdata?.gender == "Other"}
                                          onChange={e => setUserdata({ ...userdata, gender: e.target.value })}
                                        />

                                        <label className="form-check-label" htmlFor="exampleRadios4">
                                          Other
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </Col>




                                <Col xl="12">
                                  <div className="card-heading mb-3 flex">
                                    <Input
                                      type="password"
                                      className="form-control public-input"
                                      id="formrow-firstname-Input"
                                      placeholder="Password"
                                      value={userdata?.password || ""}
                                      onChange={e => setUserdata({ ...userdata, password: e.target.value })}
                                      name="articleTitle"
                                      required
                                    />

                                    <Input
                                      type="password"
                                      className="form-control public-input ml-3"
                                      id="formrow-firstname-Input "
                                      placeholder="Confirm Password"
                                      onChange={e => setChangePassword({ ...changePassword, confirmPassword: e.target.value })}
                                      value={changePassword.confirmPassword || ""}
                                      name="articleTitle"
                                      required
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <div className="col-12 text-center">
                                <input
                                  className="btn btn-primary btn-send mb-3 px-20 py-3 br-10 fsize-1"
                                  type="button"
                                  value="Sign Up"
                                  disabled={isDisable}
                                  onClick={createClient}
                                />
                              </div>
                            </form>

                            <p className="mb-0">
                              Already have an account?
                              <a href="/signin" className="hover d-block d-md-inline-block">
                                Sign in
                              </a>
                            </p>

                            <div className="divider1">
                              <span className="divider1-line"></span>
                              <span className="divider1-text">or</span>
                              <span className="divider1-line"></span>
                            </div>

                            <nav className="social justify-content-center text-center">
                              <div className="row">
                                <div className="facebookContainer">
                                  <FacebookLogin
                                    cssClass="btnFacebook"
                                    icon={<span className="iconify" data-icon="uil:facebook-f" style={{ marginRight: "5px" }}></span>}
                                    appId={environment.facebook_app_id}
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                  />
                                </div>
                              </div>
                            </nav>
                          </Col>
                          <Col xl="5" className="bg-public"></Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <div className="overflow-hidden">
          <div className="divider text-navy mx-n2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" style={{ color: "#343f52" }}>
              <path fill="currentColor"
                d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z" />
            </svg>
          </div>
        </div>
        <FooterMain />
      </div>
    </>
  );
};
export default PublicSignup;