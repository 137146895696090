import React, { useEffect, useState, useContext } from "react";
import { Card, Col, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { SearchQuery, ViewArticleModel } from "../../../models/Article";
import { ArticleService } from "../../../services/ArticleService";
import ArticleGridPane from "./ArticleGridPane";
import InfiniteScroll from "react-infinite-scroll-component";
import SketetonGroup2 from "../../../common/skeleton/SkeletonGroup2";
import PublicArticleGridPane from "./PublicArticleGridPane";
import UserContext from "src/context/UserContext";

interface PublicArticleGrid {
  searchQuery?: SearchQuery;
}

const PublicArticleGrid: React.FC<PublicArticleGrid> = ({ searchQuery }: PublicArticleGrid) => {
  const [activeTab] = useState("1");
  const [articleDetails, setArticleDetails] = useState<ViewArticleModel[]>([]);
  const [searchedArticleDetails, setSearchedArticleDetails] = useState<ViewArticleModel[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(1);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [userDetails] = useContext(UserContext);

  const LIMIT = 4;

  useEffect(() => {
    setSearchedArticleDetails([]);
    setOffset(1);
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery?.role == "CLIENT") {
      const query = {
        experienceTags: searchQuery.experienceTags,
        hashTags: searchQuery.hashTags,
      };

      searchPublicArticlesByTags(query, LIMIT, offset);
    }

    if (searchQuery?.role == "THERAPIST") {
      const query = {
        searchTags: searchQuery?.searchTags,
        type: searchQuery.type,
      };

      searchArticlesByTypeAndTags(query, LIMIT, offset);
    }

    getAllArticlesPublic(LIMIT, offset);
  }, [offset, searchQuery]);

  const getAllArticlesPublic = (limit: number, offset: number) => {
    setShowSkeleton(true);
    ArticleService.getAllArticlesPublic(limit, offset).then(res => {
      if (res.success) {
        setArticleDetails(previousDetails => {
          return [...new Set([...previousDetails, ...res.data.articleSet])];
        });
        setHasMore(res.data.count > 0);
      } else {
        setArticleDetails([]);
        setHasMore(false);
      }
      setShowSkeleton(false);
    });
  };

  const searchPublicArticlesByTags = (searchQuery: any, limit: number, offset: number) => {
    setShowSkeleton(true);

    ArticleService.searchPublicArticlesByTags(searchQuery, LIMIT, offset).then(res => {
      if (res.success) {
        setSearchedArticleDetails(prevDetails => {
          return [...new Set([...prevDetails, ...res.data.articleSet])];
        });

        setHasMore(res.data.count > 0);
      } else {
        setSearchedArticleDetails([]);

        setHasMore(false);
      }

      setShowSkeleton(false);
    });
  };

  const searchArticlesByTypeAndTags = (searchQuery: any, limit: number, offset: number) => {
    setShowSkeleton(false);
    ArticleService.searchArticlesByTypeAndTags(searchQuery, limit, offset).then(res => {
      if (res.success) {
        setSearchedArticleDetails(prevDetails => {
          return [...new Set([...prevDetails, ...res.data.articleSet])];
        });
        setHasMore(res.data.count > 0);
      } else {
        setSearchedArticleDetails([]);
        setHasMore(false);
      }
      setShowSkeleton(false);
    });
  };

  return (
    <React.Fragment>
      <Col xl={9} lg={8}>
        <Card>
          <TabContent className="p-4" activeTab={activeTab}>
            <TabPane tabId="1">
              <div>
                <Row className="justify-content-center">
                  <Col xl={8}>
                    <div>
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div>
                            <h1 className="mb-0 f-18">Article Grid</h1>
                          </div>
                        </div>

                        <Col xs={8}>
                          <div className="float-end">
                            <ul className="nav nav-pills">
                              <NavItem>
                                <NavLink className="disabled" to="#" tabIndex={-1}>
                                  View :
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <Link to="/blogs/" className="nav-link">
                                  <i className="mdi mdi-format-list-bulleted"></i>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link to="articles-grid" className="nav-link active">
                                  <i className="mdi mdi-view-grid-outline"></i>
                                </Link>
                              </NavItem>
                            </ul>
                          </div>
                        </Col>
                      </div>

                      {searchedArticleDetails.length > 0 ? (
                        <InfiniteScroll
                          dataLength={searchedArticleDetails.length}
                          next={() => {
                            setOffset(offset + 1);
                          }}
                          hasMore={hasMore}
                          loader={null}
                        >
                          <PublicArticleGridPane articleDetails={searchedArticleDetails} />
                          {showSkeleton ? (
                            <div className="mt-5">
                              <SketetonGroup2 />
                            </div>
                          ) : (
                            <></>
                          )}
                        </InfiniteScroll>
                      ) : searchedArticleDetails.length == 0 && searchQuery ? (
                        showSkeleton ? (
                          <div className="mt-5">
                            <SketetonGroup2 />
                          </div>
                        ) : (
                          <div>No articles to display</div>
                        )
                      ) : articleDetails.length > 0 ? (
                        <InfiniteScroll
                          dataLength={articleDetails.length}
                          next={() => {
                            setOffset(offset + 1);
                          }}
                          hasMore={hasMore}
                          loader={null}
                        >
                          <PublicArticleGridPane articleDetails={articleDetails} />
                          {showSkeleton ? (
                            <div className="mt-5">
                              <SketetonGroup2 />
                            </div>
                          ) : (
                            <></>
                          )}
                        </InfiniteScroll>
                      ) : (
                        <>
                          {showSkeleton ? (
                            <div className="mt-5">
                              <SketetonGroup2 />
                            </div>
                          ) : (
                            <div>No articles created yet!</div>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tabId="2">
              <div>
                <Row className="justify-content-center">
                  <Col xl={8}>
                    <h5>Archive</h5>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="ms-2">
                          <h4>2020</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">03</span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i> What is mental health?
                        </Link>

                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i> Risk factors for mental health conditions
                        </Link>

                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i>
                          Schizophrenia disorders
                        </Link>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="ms-2">
                          <h4>2019</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">06</span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i>&nbsp; Psychotherapy, or talking therapies
                        </Link>

                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i> Make social connection a priority
                        </Link>

                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i> How medication help your mental health
                        </Link>

                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i> Suicide prevention
                        </Link>

                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i>
                          Understanding good mental health
                        </Link>

                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i> How to boost your mental health
                        </Link>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="ms-2">
                          <h4>2018</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">03</span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i> What is mental health?
                        </Link>

                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i> Suicide prevention
                        </Link>

                        <Link to="blog-details" className="list-group-item text-muted">
                          <i className="mdi mdi-circle-medium ms-1"></i>
                          Psychotherapy, or talking therapies
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default PublicArticleGrid;
