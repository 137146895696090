import axios from "axios";
import { DiagnosisNoteForUpdate, DiagnosisNoteFromResponse, DiagnosisNoteVersionIdentifier } from "../models/DiagnosisNote";
import { NearestAppointment } from "../models/NearestAppointment";
import { AppResponse } from "../models/Response";
import { TranscribeDetails } from "../models/TranscribeDetails";
import { TreatmentHistory, TreatmentHistoryData } from "../models/TreatmentHistory";
import { AvatarDetails } from "../models/videoCall/AvatarDetails";
import { VideoSdkToken } from "../models/videoCall/VideoSdkToken";
import { Util } from "../Util";

export class VideoChatService {
  public static async getVideoSdkToken(): Promise<string> {
    const url = Util.apiAuthUrl("get/video/sdk/token");

    const response = await axios.get<Partial<VideoSdkToken>, AppResponse<VideoSdkToken>>(url);

    return response.success && response.data.token ? response.data.token : "";
  }

  public static async getAvatarDetails(): Promise<AppResponse<AvatarDetails>> {
    const url = Util.apiAuthUrl("get/avatar/details");

    const response = await axios.get<Partial<AvatarDetails>, AppResponse<AvatarDetails>>(url);

    return response;
  }

  public static async saveNewAvatar(avatarUrl: string,
    // backgroundId: string
    ): Promise<AppResponse<AvatarDetails>> {
    const url = Util.apiAuthUrl("save/avatar/by/url");

    const data = {
      avatarUrl: avatarUrl,
      // backgroundId: backgroundId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async saveBackground(backgroundId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("change/avatar/background");

    const data = {
      backgroundId: backgroundId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async saveDefaultAvatar(avatarId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("save/default/avatar");

    const data = {
      defaultAvatarId: avatarId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async createTranscribe(meetingId: string, isZoonMeeting: boolean): Promise<AppResponse<any>> {
    let url;
    if (isZoonMeeting) {
      url = Util.apiAuthUrl("finalise/zoom/meeting");
    } else {
      url = Util.apiAuthUrl("finalise/meetingWithLink");
    }

    const data = {
      meetingId: meetingId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async recordingShareMeetingWithLink(meetingId: string, mode: boolean): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("recordingShare/meetingWithLink");

    const data = {
      meetingId: meetingId,
      mode: mode,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async updateSpeakerHistoryOnMeeting(meetingId: string, speakerArray: string[]): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update/speaker/history");

    const data = {
      meetingId: meetingId,
      speakerArray: speakerArray,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getTranscribeDetails(id: string): Promise<AppResponse<TranscribeDetails>> {
    const url = Util.apiAuthUrl(`get/transcribe/by/id/${id}`);

    const response = await axios.get<Partial<TranscribeDetails>, AppResponse<TranscribeDetails>>(url);

    return response;
  }

  public static async mergeMeetings(mergeMeetings: string[], clientId: string): Promise<AppResponse<TranscribeDetails>> {
    const url = Util.apiAuthUrl(`mergeTreatmentHistory`);

    const data = {
      treatmentHistories: mergeMeetings,
      clientId: clientId
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getAllMeetingsForTherapistForTranscribe(limit?: number, offset?: number): Promise<any> {
    const url = Util.apiAuthUrl("getAllMeetingsForTherapistForTranscribe" + "/" + limit + "/" + offset);

    const response = await axios.get<Partial<any>, AppResponse<any>>(url);

    return response;
  }

  public static async deleteTreatmentHistoryByTherapist(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`deleteTreatmentHistory`);
    return await axios.post<void, AppResponse<any>>(url, data);
  }


  public static async getDiagnosisNoteDetails(id: string): Promise<AppResponse<DiagnosisNoteFromResponse>> {
    const url = Util.apiAuthUrl(`getDiagnosisNoteById/${id}`);

    const response = await axios.get<Partial<DiagnosisNoteFromResponse>, AppResponse<DiagnosisNoteFromResponse>>(url);

    return response;
  }

  public static async downloadDiagnosisNoteDetails(id: string, data: DiagnosisNoteVersionIdentifier): Promise<AppResponse<DiagnosisNoteFromResponse>> {
    const url = Util.apiAuthUrl(`downloadDiagnosisNoteById/${id}`);

    const response = await axios.post<Partial<DiagnosisNoteFromResponse>, AppResponse<DiagnosisNoteFromResponse>>(url, data);

    return response;
  }

  public static async updateDiagnosisNote(data: DiagnosisNoteForUpdate): Promise<AppResponse<DiagnosisNoteFromResponse>> {
    const ep = Util.apiAuthUrl("updateDiagnosisNote");

    const formData: FormData = new FormData();

    formData.append("noteDetails", JSON.stringify(data) as any);

    for (const file of data.historyOfPresentIllnessAttachments) {
      formData.append("historyOfPresentIllnessAttachments", file);
    }

    for (const file of data.mentalBehavioralStatusAttachments) {
      formData.append("mentalBehavioralStatusAttachments", file);
    }
    for (const file of data.carePlanAttachments) {
      formData.append("carePlanAttachments", file);
    }

    for (const file of data.assessmentAttachments) {
      formData.append("assessmentAttachments", file);
    }

    return await axios.post<FormData, AppResponse<DiagnosisNoteFromResponse>>(ep, formData);
  }

  //start
 public static async updateDiagnosisPIENote(data: DiagnosisNoteForUpdate): Promise<AppResponse<DiagnosisNoteFromResponse>> {
    const ep = Util.apiAuthUrl("updateDiagnosisPIENote");

    const formData: FormData = new FormData();

    formData.append("noteDetails", JSON.stringify(data) as any);

    // for (const file of data.historyOfPresentIllnessAttachments) {
    //   formData.append("historyOfPresentIllnessAttachments", file);
    // }

    for (const file of data.mentalBehavioralStatusAttachments) {
      formData.append("mentalBehavioralStatusAttachments", file);
    }
    for (const file of data.carePlanAttachments) {
      formData.append("carePlanAttachments", file);
    }

    // for (const file of data.assessmentAttachments) {
    //   formData.append("assessmentAttachments", file);
    // }

    return await axios.post<FormData, AppResponse<DiagnosisNoteFromResponse>>(ep, formData);
  }
  //end

  //start
 public static async downloadDiagnosisPIENoteDetails(id: string, data: DiagnosisNoteVersionIdentifier): Promise<AppResponse<DiagnosisNoteFromResponse>> {
    const url = Util.apiAuthUrl(`downloadDiagnosisPIENoteById/${id}`);

    const response = await axios.post<Partial<DiagnosisNoteFromResponse>, AppResponse<DiagnosisNoteFromResponse>>(url, data);

    return response;
  }

  //end

  public static async getTreatmentHistoryForClientByAdmin(clientId: string): Promise<AppResponse<TreatmentHistoryData[]>> {
    const url = Util.apiAuthUrl("getDiagnosisNoteByClientId/" + clientId);

    return await axios.get<Partial<TreatmentHistory>, AppResponse<TreatmentHistoryData[]>>(url);
  }

  public static async checkForNearestAppointment(): Promise<AppResponse<NearestAppointment[]>> {
    const url = Util.apiAuthUrl("checkForNearestUpcomingAppointmentAndIncompletedCalls");

    return await axios.get<Partial<NearestAppointment>, AppResponse<NearestAppointment[]>>(url);
  }

  public static async startCallForAppointment(
    recieverId: string,
    appointmentId: string | undefined,
    callDuration: number,
    isTranscribeAllowed: boolean,
    isAppointmentBased: boolean
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("startCallWithMeetingLink");

    const data = {
      recieverId: recieverId,
      appointmentId: appointmentId,
      callDuration: callDuration,
      isTranscribeAllowed: isTranscribeAllowed,
      isAppointmentBased: isAppointmentBased,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  // public static async acceptCall(meetingId: string): Promise<AppResponse<any>> {
  //   const url = Util.apiAuthUrl("acceptCall");

  //   const data = {
  //     meetingId: meetingId,
  //   };

  //   return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  // }

  public static async createVideoCallNotification(receiverId: any, event: string, link: string, content: string, variant: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("createVideoCallNotification");

    const data = {
      recieverId: receiverId,
      event: event,
      link: link,
      content: content,
      variant: variant,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getRecieverSocketId(recieverId: string | undefined): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("getRecieverSocketId");

    const data = {
      recieverId: recieverId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async checkRemainingTime(recieverId: string, extendingDuration: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("zoom/checkRemainingTime");

    const data = {
      recieverId: recieverId,
      extendingDuration: extendingDuration,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getAvatarDetailsOfOwnUser(): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("getAvatarDetailsOfOwnUser");

    const res = await axios.get<void, AppResponse<any>>(ep);

    return res;
  }

  public static async updateMeetingTimeWhenExtend(meetingId: string, extendingDuration: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("zoom/updateMeetingTimeWhenExtend");

    const data = {
      meetingId: meetingId,
      extendingDuration: extendingDuration,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  // public static async joinMeeting(
  //   meetingId: string | undefined,
  //   recieverId: string | undefined,
  //   callDuration: number,
  //   isTranscribeAllowed: boolean,
  //   isAppointmentBased: boolean,
  //   appointmentId: string | undefined
  // ): Promise<AppResponse<any>> {
  //   const url = Util.apiAuthUrl("joinMeeting");

  //   const data = {
  //     meetingId: meetingId,
  //     recieverId: recieverId,
  //     callDuration: callDuration,
  //     isTranscribeAllowed: isTranscribeAllowed,
  //     isAppointmentBased: isAppointmentBased,
  //     appointmentId: appointmentId,
  //   };

  //   return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  // }

  public static async acceptCallWithMeetingLink(meetingId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("acceptCallWithMeetingLink");

    const data = {
      meetingId: meetingId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async validateMeetingForAccept(meetingId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("validateMeetingForAccept");

    const data = {
      meetingId: meetingId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async createTranscriptText(meetingId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("createTranscribeTextManuallyWithZoom");

    const data = {
      meetingId: meetingId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async initializeZoomCall(
    recieverId: string,
    appointmentId: string | undefined,
    callDuration: number,
    isTranscribeAllowed: boolean,
    isAppointmentBased: boolean,
    isAudio: boolean,
    transcribeAllowedForLoggedUser: boolean | undefined
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("initialize/zoom/call");

    const data = {
      recieverId: recieverId,
      appointmentId: appointmentId,
      callDuration: callDuration,
      isTranscribeAllowed: isTranscribeAllowed,
      isAppointmentBased: isAppointmentBased,
      isAudioCall: isAudio,
      transcribeAllowedForLoggedUser
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async createRegularMeeting(formData: any ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("createRegularMeeting");
    return await axios.post<Partial<any>, AppResponse<any>>(url, formData);
  }

  public static async startZoomMeeting(
    sessionId: string,
    meetingId: string,
    recieverId: string,
    appointmentId: string | undefined,
    callDuration: number,
    isTranscribeAllowed: boolean,
    isAppointmentBased: boolean
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("start/zoom/call");

    const data = isAppointmentBased
      ? {
          sessionId: sessionId,
          meetingId: meetingId,
          recieverId: recieverId,
          appointmentId: appointmentId,
          callDuration: callDuration,
          isTranscribeAllowed: isTranscribeAllowed,
          isAppointmentBased: isAppointmentBased,
        }
      : {
          sessionId: sessionId,
          meetingId: meetingId,
          recieverId: recieverId,
          callDuration: callDuration,
          isTranscribeAllowed: isTranscribeAllowed,
          isAppointmentBased: isAppointmentBased,
        };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async joinZoomMeeting(meetingId: string, isAppointmentBased: boolean, appointmentId: any, transcribeAllowedForUser :boolean | undefined): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("join/zoom/call");

    const data = {
      meetingId: meetingId,
      isAppointmentBased: isAppointmentBased,
      appointmentId: appointmentId,
      transcribeAllowedForUser
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async acceptZoomCall(meetingId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("accept/zoom/call");

    const data = {
      meetingId: meetingId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async cancellCall(meetingId: any, isAppointmentBased: boolean, appointmentId: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("cancel/zoom/call");

    const data = {
      meetingId: meetingId,
      isAppointmentBased: isAppointmentBased,
      appointmentId: appointmentId,
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async createTranscribeNew(meetingId: string, conversation: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("createTranscribeNew");

    const data = {
      meetingId: meetingId,
      conversation
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async generateOpenAIResponse(meetingId: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("generateSoapWithOpenAI");

    const data = {
      meetingId: meetingId,

    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async generateOpenAISoapPieResponse(meetingId: string, noteType: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("generateSopePieAINotes");

    const data = {
      meetingId: meetingId,
      noteType:noteType
    };

    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }


}
