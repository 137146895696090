import React from 'react';
import { StepEnum } from './types';

interface StepProgressProps {
  currentStep: number;
}

const StepProgress: React.FC<StepProgressProps> = ({ currentStep }) => {
  const steps = [
    { step: 0, label: 'Patient Info' },
    { step: 1, label: 'Assessments' },
    { step: 2, label: 'Documents' },
    { step: 3, label: 'Session Notes' },
    { step: 4, label: 'Preview' },
    { step: 5, label: 'Send Fax' },
    { step: 6, label: 'Completed' },
  ];

  const getStepClass = (step: number) => {
    if (step === currentStep) return 'active';
    if (step < currentStep) return 'completed';
    return '';
  };

  return (
    <div className="step-progress mb-4">
      <div className="d-flex justify-content-between">
        {steps.map(({ step, label }) => (
          <div
            key={step}
            className={`step-item ${getStepClass(step)}`}
          >
            <div className="step-circle">{step}</div>
            <div className="step-label">{label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepProgress;
