import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Card, Col, Row, Input } from "reactstrap";
import PhoneInput from "react-phone-number-input";
import { AmbetterAuthForm } from "src/models/AuthorizationForm/ambetter-auth-form-model";
import { AuthorizationFormService } from "src/services/AuthorizationFormService";
import { AuthFormType } from "src/models/AuthorizationFormData";
import SignaturePad from "react-signature-canvas";
import moment from "moment";

const AmbetterForm = (props: any) => {
    const { clientId, insuranceCompanyId, formId, getUpdateFormId, ambetterData } = props;
    const [isUpdate, setIsUpdate] = useState(props.isUpdate);
    const [formData, setFormData] = useState<AmbetterAuthForm>({});
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState({
        memberName: '',
        medicaidId: '',
        dateOfBirth: '',
        requestingNpi: '',
        requestingTin: '',
        faxNumber: '',
        servicingNpi: '',
        servicingTin: '',
        primaryProcedureCode: '',
        primaryProcedureCodeModifier: '',
        additionalProcedureCode: '',
        additionalProcedureCodeModifier: '',
        totalUnit: '',
        diagnosisCode: ''
    });
    const [clinicianSign, setClinicianSign] = useState<boolean>(false);

    useEffect(() => {
        setIsUpdate(props.isUpdate);
    }, [props.isUpdate]);

    useEffect(() => {
        const updateAmbetterData: AmbetterAuthForm = {
            basicInformation: ambetterData?.basicInformation,
            memberInformation: ambetterData?.memberInformation,
            providerInformation: ambetterData?.providerInformation,
            facilityInformation: ambetterData?.facilityInformation,
            authorizationRequest: ambetterData?.authorizationRequest,
        };
        setFormData(updateAmbetterData);
    }, [ambetterData]);


    const validateForm = () => {
        const newErrors: any = {};

        if (!formData?.memberInformation?.memberName) {
            newErrors.memberName = 'Member Name is required';
        }

        if (!formData?.memberInformation?.medicaidId) {
            newErrors.medicaidId = 'Medicaid ID is required';
        }

        if (!formData?.memberInformation?.dateOfBirth) {
            newErrors.dateOfBirth = 'Date of Birth is required';
        }

        if (!formData?.providerInformation?.requestingNpi) {
            newErrors.requestingNpi = 'Requesting NPI is required';
        }

        if (!formData?.providerInformation?.requestingTin) {
            newErrors.requestingTin = 'Requesting TIN is required';
        }

        if (!formData?.providerInformation?.faxNumber) {
            newErrors.faxNumber = 'Fax Number is required';
        }

        if (!formData?.facilityInformation?.servicingTin) {
            newErrors.servicingTin = 'Servicing TIN is required';
        }

        if (!formData?.facilityInformation?.servicingNpi) {
            newErrors.servicingNpi = 'Servicing NPI is required';
        }

        if (!formData?.authorizationRequest?.additionalProcedureCode) {
            newErrors.additionalProcedureCode = 'Code is required';
        }
        if (!formData?.authorizationRequest?.additionalProcedureCodeModifier) {
            newErrors.additionalProcedureCodeModifier = 'Modifier is required';
        }
        if (!formData?.authorizationRequest?.primaryProcedureCode) {
            newErrors.primaryProcedureCode = 'Code is required';
        }
        if (!formData?.authorizationRequest?.primaryProcedureCodeModifier) {
            newErrors.primaryProcedureCodeModifier = 'Modifier is required';
        }
        if (!formData?.authorizationRequest?.totalUnit) {
            newErrors.totalUnit = 'Total Unit is required';
        }
        if (!formData?.authorizationRequest?.diagnosisCode) {
            newErrors.diagnosisCode = 'Diagnosis Code is required';
        }

        setErrors(newErrors);

        // Return true if no errors
        return Object.keys(newErrors).length === 0;
    }

    function autoResizeTextarea(e: any) {
        const textarea = e.target;
        textarea.style.height = 'auto';

        const newHeight = Math.min(textarea.scrollHeight, 3 * 16);
        textarea.style.height = `${newHeight}px`;

        textarea.style.overflowY = textarea.scrollHeight > newHeight ? 'scroll' : 'hidden';
    }

    // const formatDate = (date: any) => {
    //     if (!date) return '';
    //     const d = new Date(date);
    //     const year = d.getFullYear();
    //     const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    //     const day = String(d.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };

    const formatDate = (date: any) => {
        if (!date) return '';
        return moment.utc(date).format('YYYY-MM-DD');
    };

    const saveAmbetterAuthForm = async () => {
        if (!validateForm()) {
            toast.error("Please fill in all required fields.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            return;
        }
        setIsSaving(true);
        const ambetterAuthFormDetails: AmbetterAuthForm = {
            _id: formId,
            clientId: clientId,
            insuranceCompanyId: insuranceCompanyId,
            authFormType: AuthFormType.AmbetterAuthForm,
            basicInformation: formData?.basicInformation,
            memberInformation: formData?.memberInformation,
            providerInformation: formData?.providerInformation,
            facilityInformation: formData?.facilityInformation,
            authorizationRequest: formData?.authorizationRequest,
        }
        try {
            let res;
            if (isUpdate === false) {
                res = await AuthorizationFormService.createAmbetterAuthForm(ambetterAuthFormDetails);
                if (res && res.success && res.data) {
                    getUpdateFormId(res.data._id);
                    setIsUpdate(true);
                    toast.success("Ambetter form data has been successfully saved!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    props.closePopup();
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            } else {
                res = await AuthorizationFormService.updateAuthorizationForm(AuthFormType.AmbetterAuthForm, ambetterAuthFormDetails);
                if (res && res.success && res.data) {
                    getUpdateFormId(res.data._id);
                    toast.success("Ambetter form has been successfully updated!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                    props.closePopup();
                } else {
                    toast.error(res.error, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                    });
                }
            }
        } catch (error) {
            toast.error(`Error occurred! Error details: ${error}.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        } finally {
            setIsSaving(false);
        }
    }

    useEffect(() => {
        const therapySign = formData?.basicInformation?.signature;
        if (therapySign === undefined || !therapySign || therapySign === "") {
            setClinicianSign(false);
        } else {
            setClinicianSign(true);
        }
    }, [ambetterData, formData]);

    const signPad = useRef<any>({
        minDistance: 0,
        throttle: 9,
        velocityFilterWeight: 0.7,
    });

    function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
        let timeout: ReturnType<typeof setTimeout>;
        return function (...args: Parameters<T>) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    }

    const resizeCanvas = () => {
        if (signPad?.current) {
            const canvas = signPad.current.getCanvas();
            if (canvas) {
                const container = canvas.parentNode as HTMLElement;
                if (container) {
                    const ratio = Math.max(window.devicePixelRatio || 1, 1);
                    canvas.width = container.offsetWidth * ratio;
                    canvas.height = container.offsetHeight * ratio;
                    const context = canvas.getContext("2d");
                    if (context) {
                        context.scale(ratio, ratio);
                    }
                    signPad.current.clear();
                }
            }
        }
    };

    useEffect(() => {
        try {
            if (signPad?.current) {
                setTimeout(() => {
                    resizeCanvas();
                }, 0);
            }

            const debouncedResize = debounce(resizeCanvas, 200);
            window.addEventListener("resize", debouncedResize);

            // Using ResizeObserver to handle changes in container size
            const observer = new ResizeObserver(() => {
                resizeCanvas();
            });

            if (signPad?.current) {
                const container = signPad.current.getCanvas().parentNode as HTMLElement;
                if (container) {
                    observer.observe(container);
                }
            }

            return () => {
                window.removeEventListener("resize", debouncedResize);
                observer.disconnect();
            };
        } catch (error) {

        }

    }, []);

    function clearsign(): void {
        if (signPad?.current) {
            console.log("clear!");
            signPad.current.clear();
            const signatureData = null;
        }
    }

    function saveSign() {
        if (signPad?.current) {
            const signatureData = signPad.current.getTrimmedCanvas().toDataURL("image/png");
            setFormData({
                ...formData,
                basicInformation: {
                    ...formData?.basicInformation,
                    signature: signatureData,
                }
            });
        }
    }

    function editSign() {
        setClinicianSign(false);
    }


    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pt-1 pb-1">
                <Card className="match-card-header-secondary p-2 mb-2" style={{ marginTop: "0.75rem" }}>
                    <Row className="align-items-center">
                        <Col xs="12" sm="5" className="text-center text-sm-start">
                            <div>
                                <img
                                    src="/static/assets/img/authorization/ambetter_nc.png"
                                    alt="Ambetter Insurance Company Logo"
                                    className="img-fluid"
                                    style={{ maxWidth: '200px' }}
                                />
                            </div>
                        </Col>
                        <Col xs="12" sm="7" className="text-center text-sm-end">
                            <p className="p-3" style={{ margin: "0", fontSize: "12px", color: "#333", lineHeight: "1.6" }}>
                                <span style={{ fontSize: "20px", color: "#333", fontWeight: "500", marginBottom: "0.75rem" }}>Outpatient Authorization Form</span><br />
                                Complete and Fax to <strong> 1-844-536-2412</strong><br />
                                Transplant Request Fax to <strong> 1-833-783-0877</strong><br />
                                Buy & Bill Drugs <strong> 833-893-1490</strong>
                            </p>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "1px solid #333", borderRadius: "8px", padding: "0.7rem 0.7rem 0rem 0.7rem" }}>
                    <Row>
                        <div className="card-bodyDiv" style={{ width: '100%' }}>
                            <div style={{ fontSize: "12px", color: "#333", lineHeight: "1.6", padding: "1px 1px 1px 1px" }}>
                                <Row>
                                    <Col lg={5}>
                                        <div className="row">
                                            <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                                <input
                                                    style={{ fontSize: "larger" }}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={formData?.basicInformation?.additionalRequest}
                                                    onClick={() => {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            basicInformation: {
                                                                ...prevState.basicInformation,
                                                                additionalRequest: !prevState.basicInformation?.additionalRequest,
                                                            },
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Request for additional units.</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={5}>
                                        <Card style={{ marginBottom: "0.5rem" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Existing Authorization :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                        type="text"
                                                        id="name"
                                                        value={formData?.basicInformation?.existingAuthorization || ''}
                                                        onChange={(e) =>
                                                            setFormData({
                                                                ...formData,
                                                                basicInformation: {
                                                                    ...formData?.basicInformation,
                                                                    existingAuthorization: e.target.value,
                                                                }
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col lg={2}>
                                        <Card style={{ marginBottom: "0.5rem" }}>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}>Units :</h6>
                                                </div>
                                                <div className="col">
                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                        type="text"
                                                        value={formData?.basicInformation?.unit || ''}
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            basicInformation: {
                                                                ...formData?.basicInformation,
                                                                unit: e.target.value
                                                            }
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row></div>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="row">
                                <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                    <input
                                        style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData?.basicInformation?.standardRequest}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                basicInformation: {
                                                    ...prevState.basicInformation,
                                                    standardRequest: !prevState.basicInformation?.standardRequest
                                                }
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}><strong>Standard requests</strong> - Determination within 3 business days of receiving all necessary information.</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="row">
                                <div className="col-auto" style={{ paddingInlineEnd: "inherit" }}>
                                    <input
                                        style={{ fontSize: "larger" }}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={formData?.basicInformation?.urgentRequest}
                                        onClick={() => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                basicInformation: {
                                                    ...prevState.basicInformation,
                                                    urgentRequest: !prevState.basicInformation?.urgentRequest
                                                }
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-auto">
                                            <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px", marginBottom: "0.7rem" }}><strong> Urgent requests</strong> - I certify this request is urgent and medically necessary to treat an injury, illness or condition (not life threatening) within 24 hours to
                                                avoid complications and unnecessary suffering or severe pain.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </Card>
                <Row className="mt-2">
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>MEMBER INFORMATION</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Member Name :</label>
                                        </Col>
                                        <Col xs="12" sm="10">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="text"
                                                    style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    required
                                                    value={formData?.memberInformation?.memberName || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            memberInformation: {
                                                                memberName: e.target.value,
                                                                medicaidId: formData?.memberInformation?.medicaidId !== undefined
                                                                    ? formData.memberInformation.medicaidId
                                                                    : '',
                                                                dateOfBirth: formData?.memberInformation?.dateOfBirth !== undefined
                                                                    ? formData.memberInformation.dateOfBirth
                                                                    : new Date(),
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            memberName: ''
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            {errors.memberName && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.memberName} </p>}
                                        </Col>

                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Date of birth :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="date"
                                                    style={{ fontSize: "0.70rem", lineHeight: "0.75" }}
                                                    required
                                                    value={formatDate(formData?.memberInformation?.dateOfBirth)}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            memberInformation: {
                                                                dateOfBirth: new Date(e.target.value),
                                                                medicaidId: formData?.memberInformation?.medicaidId !== undefined
                                                                    ? formData.memberInformation.medicaidId
                                                                    : '',
                                                                memberName: formData?.memberInformation?.memberName !== undefined
                                                                    ? formData.memberInformation.memberName
                                                                    : '',
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            dateOfBirth: ''
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            {errors.dateOfBirth && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.dateOfBirth} </p>}
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Medicaid ID :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="text"
                                                    style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    required
                                                    value={formData?.memberInformation?.medicaidId || ''}
                                                    onInput={(e) => autoResizeTextarea(e)}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            memberInformation: {
                                                                medicaidId: e.target.value,
                                                                memberName: formData?.memberInformation?.memberName !== undefined
                                                                    ? formData.memberInformation.memberName
                                                                    : '',
                                                                dateOfBirth: formData?.memberInformation?.dateOfBirth !== undefined
                                                                    ? formData.memberInformation.dateOfBirth
                                                                    : new Date(),
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            medicaidId: ''
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            {errors.medicaidId && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.medicaidId} </p>}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>REQUESTING PROVIDER INFORMATION</h5>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Requesting Provider Contact Name :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.requestingProviderContactName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            requestingProviderContactName: e.target.value,
                                                            requestingNpi: formData?.providerInformation?.requestingNpi !== undefined ? formData.providerInformation.requestingNpi : '',
                                                            requestingTin: formData?.providerInformation?.requestingTin !== undefined ? formData.providerInformation.requestingTin : '',
                                                            requestingProviderName: formData?.providerInformation?.requestingProviderName !== undefined ? formData.providerInformation.requestingProviderName : '',
                                                            phone: formData?.providerInformation?.phone !== undefined ? formData.providerInformation.phone : '',
                                                            faxNumber: formData?.providerInformation?.faxNumber !== undefined ? formData.providerInformation.faxNumber : '',
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Requesting Provider Name :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.providerInformation?.requestingProviderName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            requestingProviderName: e.target.value,
                                                            requestingNpi: formData?.providerInformation?.requestingNpi || '',
                                                            requestingTin: formData?.providerInformation?.requestingTin || '',
                                                            requestingProviderContactName: formData?.providerInformation?.requestingProviderContactName || '',
                                                            phone: formData?.providerInformation?.phone || '',
                                                            faxNumber: formData?.providerInformation?.faxNumber || '',
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Requesting NPI :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="text"
                                                    style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    required
                                                    value={formData?.providerInformation?.requestingNpi || ''}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            providerInformation: {
                                                                ...formData?.providerInformation,
                                                                requestingNpi: e.target.value,
                                                                requestingProviderContactName: formData?.providerInformation?.requestingProviderContactName !== undefined ? formData.providerInformation.requestingProviderContactName : '',
                                                                requestingTin: formData?.providerInformation?.requestingTin !== undefined ? formData.providerInformation.requestingTin : '',
                                                                requestingProviderName: formData?.providerInformation?.requestingProviderName !== undefined ? formData.providerInformation.requestingProviderName : '',
                                                                phone: formData?.providerInformation?.phone !== undefined ? formData.providerInformation.phone : '',
                                                                faxNumber: formData?.providerInformation?.faxNumber !== undefined ? formData.providerInformation.faxNumber : '',
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            requestingNpi: ''
                                                        }));
                                                    }} />
                                            </div>
                                            {errors.requestingNpi && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.requestingNpi} </p>}
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Requesting TIN :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="text"
                                                    style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    required
                                                    value={formData?.providerInformation?.requestingTin || ''}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            providerInformation: {
                                                                ...formData?.providerInformation,
                                                                requestingTin: e.target.value,
                                                                requestingProviderContactName: formData?.providerInformation?.requestingProviderContactName !== undefined ? formData.providerInformation.requestingProviderContactName : '',
                                                                requestingNpi: formData?.providerInformation?.requestingNpi !== undefined ? formData.providerInformation.requestingNpi : '',
                                                                requestingProviderName: formData?.providerInformation?.requestingProviderName !== undefined ? formData.providerInformation.requestingProviderName : '',
                                                                phone: formData?.providerInformation?.phone !== undefined ? formData.providerInformation.phone : '',
                                                                faxNumber: formData?.providerInformation?.faxNumber !== undefined ? formData.providerInformation.faxNumber : '',
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            requestingTin: ''
                                                        }));
                                                    }} />
                                            </div>
                                            {errors.requestingTin && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.requestingTin} </p>}
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Phone :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.providerInformation?.phone || ''}
                                                    onChange={(value) => setFormData({
                                                        ...formData,
                                                        providerInformation: {
                                                            ...formData?.providerInformation,
                                                            phone: value,
                                                            requestingProviderContactName: formData?.providerInformation?.requestingProviderContactName !== undefined ? formData.providerInformation.requestingProviderContactName : '',
                                                            requestingNpi: formData?.providerInformation?.requestingNpi !== undefined ? formData.providerInformation.requestingNpi : '',
                                                            requestingTin: formData?.providerInformation?.requestingTin !== undefined ? formData.providerInformation.requestingTin : '',
                                                            requestingProviderName: formData?.providerInformation?.requestingProviderName !== undefined ? formData.providerInformation.requestingProviderName : '',
                                                            faxNumber: formData?.providerInformation?.faxNumber !== undefined ? formData.providerInformation.faxNumber : '',
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Fax Number :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    required
                                                    value={formData?.providerInformation?.faxNumber || ''}
                                                    onChange={(value) => {
                                                        setFormData({
                                                            ...formData,
                                                            providerInformation: {
                                                                ...formData?.providerInformation,
                                                                faxNumber: value || '',
                                                                requestingProviderContactName: formData?.providerInformation?.requestingProviderContactName !== undefined ? formData.providerInformation.requestingProviderContactName : '',
                                                                requestingNpi: formData?.providerInformation?.requestingNpi !== undefined ? formData.providerInformation.requestingNpi : '',
                                                                requestingTin: formData?.providerInformation?.requestingTin !== undefined ? formData.providerInformation.requestingTin : '',
                                                                requestingProviderName: formData?.providerInformation?.requestingProviderName !== undefined ? formData.providerInformation.requestingProviderName : '',
                                                                phone: formData?.providerInformation?.phone !== undefined ? formData.providerInformation.phone : '',
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            faxNumber: ''
                                                        })
                                                        );
                                                    }} />
                                            </div>
                                            {errors.faxNumber && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.faxNumber} </p>}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <div style={{ display: 'flex', }}>
                                <h5 className="fw-500" style={{ margin: 'auto', marginRight: '30%' }}>SERVICING PROVIDER / FACILITY INFORMATION</h5>
                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        style={{ fontSize: "larger" }}
                                    />
                                    <label className="form-check-label" style={{ fontStyle: "italic", marginTop: "0.1rem", fontSize: "12px" }}>
                                        Same as Requesting Provider
                                    </label>
                                </div>
                            </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Servicing Provider Contact Name :</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.servicingProviderContactName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            servicingProviderContactName: e.target.value,
                                                            servicingNpi: formData?.facilityInformation?.servicingNpi !== undefined ? formData.facilityInformation.servicingNpi : '',
                                                            servicingTin: formData?.facilityInformation?.servicingTin !== undefined ? formData.facilityInformation.servicingTin : '',
                                                            servicingFacilityName: formData?.facilityInformation?.servicingFacilityName !== undefined ? formData.facilityInformation.servicingFacilityName : '',
                                                            phone: formData?.facilityInformation?.phone !== undefined ? formData.facilityInformation.phone : '',
                                                            faxNumber: formData?.facilityInformation?.faxNumber !== undefined ? formData.facilityInformation.faxNumber : '',
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="3">
                                            <label style={{ fontSize: "12px" }}>Servicing Provider / Facility Name:</label>
                                        </Col>
                                        <Col xs="12" sm="9">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.servicingFacilityName || ''}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            servicingFacilityName: e.target.value,
                                                            servicingNpi: formData?.facilityInformation?.servicingNpi !== undefined ? formData.facilityInformation.servicingNpi : '',
                                                            servicingTin: formData?.facilityInformation?.servicingTin !== undefined ? formData.facilityInformation.servicingTin : '',
                                                            servicingProviderContactName: formData?.facilityInformation?.servicingProviderContactName !== undefined ? formData.facilityInformation.servicingProviderContactName : '',
                                                            phone: formData?.facilityInformation?.phone !== undefined ? formData.facilityInformation.phone : '',
                                                            faxNumber: formData?.facilityInformation?.faxNumber !== undefined ? formData.facilityInformation.faxNumber : '',
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Servicing NPI :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="text"
                                                    style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    value={formData?.facilityInformation?.servicingNpi || ''}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            facilityInformation: {
                                                                ...formData?.facilityInformation,
                                                                servicingNpi: e.target.value,
                                                                servicingProviderContactName: formData?.facilityInformation?.servicingProviderContactName !== undefined ? formData.facilityInformation.servicingProviderContactName : '',
                                                                servicingTin: formData?.facilityInformation?.servicingTin !== undefined ? formData.facilityInformation.servicingTin : '',
                                                                servicingFacilityName: formData?.facilityInformation?.servicingFacilityName !== undefined ? formData.facilityInformation.servicingFacilityName : '',
                                                                phone: formData?.facilityInformation?.phone !== undefined ? formData.facilityInformation.phone : '',
                                                                faxNumber: formData?.facilityInformation?.faxNumber !== undefined ? formData.facilityInformation.faxNumber : '',
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            servicingNpi: ''
                                                        }));
                                                    }} />
                                            </div>
                                            {errors.servicingNpi && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.servicingNpi} </p>}
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Servicing TIN :</label>
                                        </Col>
                                        <Col xs="12" sm="4">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="text"
                                                    style={{ fontSize: "0.70rem", lineHeight: "1" }}
                                                    required
                                                    value={formData?.facilityInformation?.servicingTin || ''}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            facilityInformation: {
                                                                ...formData?.facilityInformation,
                                                                servicingTin: e.target.value,
                                                                servicingProviderContactName: formData?.facilityInformation?.servicingProviderContactName !== undefined ? formData.facilityInformation.servicingProviderContactName : '',
                                                                servicingNpi: formData?.facilityInformation?.servicingNpi !== undefined ? formData.facilityInformation.servicingNpi : '',
                                                                servicingFacilityName: formData?.facilityInformation?.servicingFacilityName !== undefined ? formData.facilityInformation.servicingFacilityName : '',
                                                                phone: formData?.facilityInformation?.phone !== undefined ? formData.facilityInformation.phone : '',
                                                                faxNumber: formData?.facilityInformation?.faxNumber !== undefined ? formData.facilityInformation.faxNumber : '',
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            servicingTin: ''
                                                        }));
                                                    }} />
                                            </div>
                                            {errors.servicingTin && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.servicingTin} </p>}
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Phone :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.facilityInformation?.phone || ''}
                                                    onChange={(value) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            phone: value,
                                                            servicingProviderContactName: formData?.facilityInformation?.servicingProviderContactName !== undefined ? formData.facilityInformation.servicingProviderContactName : '',
                                                            servicingNpi: formData?.facilityInformation?.servicingNpi !== undefined ? formData.facilityInformation.servicingNpi : '',
                                                            servicingTin: formData?.facilityInformation?.servicingTin !== undefined ? formData.facilityInformation.servicingTin : '',
                                                            servicingFacilityName: formData?.facilityInformation?.servicingFacilityName !== undefined ? formData.facilityInformation.servicingFacilityName : '',
                                                            faxNumber: formData?.facilityInformation?.faxNumber !== undefined ? formData.facilityInformation.faxNumber : '',
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Fax Number :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <PhoneInput
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", width: '100%' }}
                                                    defaultCountry="US"
                                                    value={formData?.facilityInformation?.faxNumber || ''}
                                                    onChange={(value) => setFormData({
                                                        ...formData,
                                                        facilityInformation: {
                                                            ...formData?.facilityInformation,
                                                            faxNumber: value,
                                                            servicingProviderContactName: formData?.facilityInformation?.servicingProviderContactName !== undefined ? formData.facilityInformation.servicingProviderContactName : '',
                                                            servicingNpi: formData?.facilityInformation?.servicingNpi !== undefined ? formData.facilityInformation.servicingNpi : '',
                                                            servicingTin: formData?.facilityInformation?.servicingTin !== undefined ? formData.facilityInformation.servicingTin : '',
                                                            servicingFacilityName: formData?.facilityInformation?.servicingFacilityName !== undefined ? formData.facilityInformation.servicingFacilityName : '',
                                                            phone: formData?.facilityInformation?.phone !== undefined ? formData.facilityInformation.phone : '',
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ textAlign: "center", marginBottom: "0" }}>AUTHORIZATION REQUEST</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="align-items-center mb-2">
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}><strong>Primary</strong> Procedure Code :</label>
                                        </Col>
                                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="text"
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }}
                                                    placeholder="(CPT/HCPCS)"
                                                    required
                                                    value={formData?.authorizationRequest?.primaryProcedureCode || ''}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        if (value.length > 5) {
                                                            value = value.slice(0, 5);
                                                            setErrors(prevErrors => ({
                                                                ...prevErrors,
                                                                primaryProcedureCode: 'Primary procedure code contains 5 characters only.'
                                                            }));
                                                        } else {
                                                            setErrors(prevErrors => ({
                                                                ...prevErrors,
                                                                primaryProcedureCode: ''
                                                            }));
                                                        }
                                                        setFormData({
                                                            ...formData,
                                                            authorizationRequest: {
                                                                ...formData?.authorizationRequest,
                                                                primaryProcedureCode: value,
                                                                additionalProcedureCode: formData?.authorizationRequest?.additionalProcedureCode || '',
                                                                primaryProcedureCodeModifier: formData?.authorizationRequest?.primaryProcedureCodeModifier || '',
                                                                additionalProcedureCodeModifier: formData?.authorizationRequest?.additionalProcedureCodeModifier || '',
                                                                startDate: formData?.authorizationRequest?.startDate || new Date(),
                                                                endDate: formData?.authorizationRequest?.endDate || new Date(),
                                                                diagnosisCode: formData?.authorizationRequest?.diagnosisCode || '',
                                                                totalUnit: formData?.authorizationRequest?.totalUnit || '',
                                                                serviceTypeNumber: formData?.authorizationRequest?.serviceTypeNumber || '',
                                                                purchasePrice: formData?.authorizationRequest?.purchasePrice || '',
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {errors.primaryProcedureCode && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.primaryProcedureCode} </p>}
                                        </Col>
                                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text"
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }}
                                                    placeholder="(Modifier)"
                                                    required
                                                    value={formData?.authorizationRequest?.primaryProcedureCodeModifier || ''}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        if (value.length > 2) {
                                                            value = value.slice(0, 2);
                                                            setErrors(prevErrors => ({
                                                                ...prevErrors,
                                                                primaryProcedureCodeModifier: 'Modifier contains 2 characters only.'
                                                            }));
                                                        } else {
                                                            setErrors(prevErrors => ({
                                                                ...prevErrors,
                                                                primaryProcedureCodeModifier: ''
                                                            }));
                                                        }
                                                        setFormData({
                                                            ...formData,
                                                            authorizationRequest: {
                                                                ...formData?.authorizationRequest,
                                                                primaryProcedureCodeModifier: value,
                                                                additionalProcedureCode: formData?.authorizationRequest?.additionalProcedureCode !== undefined ? formData.authorizationRequest.additionalProcedureCode : '',
                                                                primaryProcedureCode: formData?.authorizationRequest?.primaryProcedureCode !== undefined ? formData.authorizationRequest.primaryProcedureCode : '',
                                                                additionalProcedureCodeModifier: formData?.authorizationRequest?.additionalProcedureCodeModifier !== undefined ? formData.authorizationRequest.additionalProcedureCodeModifier : '',
                                                                startDate: formData?.authorizationRequest?.startDate !== undefined ? formData.authorizationRequest.startDate : new Date(),
                                                                endDate: formData?.authorizationRequest?.endDate !== undefined ? formData.authorizationRequest.endDate : new Date(),
                                                                diagnosisCode: formData?.authorizationRequest?.diagnosisCode !== undefined ? formData.authorizationRequest.diagnosisCode : '',
                                                                totalUnit: formData?.authorizationRequest?.totalUnit !== undefined ? formData.authorizationRequest.totalUnit : '',
                                                                serviceTypeNumber: formData?.authorizationRequest?.serviceTypeNumber !== undefined ? formData.authorizationRequest.serviceTypeNumber : '',
                                                                purchasePrice: formData?.authorizationRequest?.purchasePrice !== undefined ? formData.authorizationRequest.purchasePrice : '',
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {errors.primaryProcedureCodeModifier && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.primaryProcedureCodeModifier} </p>}
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}><strong>Additional</strong> Procedure Code :</label>
                                        </Col>
                                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="text"
                                                    style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }}
                                                    placeholder="(CPT/HCPCS)"
                                                    required
                                                    value={formData?.authorizationRequest?.additionalProcedureCode || ''}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        if (value.length > 5) {
                                                            value = value.slice(0, 5);
                                                            setErrors(prevErrors => ({
                                                                ...prevErrors,
                                                                additionalProcedureCode: 'Additional procedure code contains 5 characters only.'
                                                            }));
                                                        } else {
                                                            setErrors(prevErrors => ({
                                                                ...prevErrors,
                                                                additionalProcedureCode: ''
                                                            }));
                                                        }
                                                        setFormData({
                                                            ...formData,
                                                            authorizationRequest: {
                                                                ...formData?.authorizationRequest,
                                                                additionalProcedureCode: value,
                                                                primaryProcedureCode: formData?.authorizationRequest?.primaryProcedureCode !== undefined ? formData.authorizationRequest.primaryProcedureCode : '',
                                                                primaryProcedureCodeModifier: formData?.authorizationRequest?.primaryProcedureCodeModifier !== undefined ? formData.authorizationRequest.primaryProcedureCodeModifier : '',
                                                                additionalProcedureCodeModifier: formData?.authorizationRequest?.additionalProcedureCodeModifier !== undefined ? formData.authorizationRequest.additionalProcedureCodeModifier : '',
                                                                startDate: formData?.authorizationRequest?.startDate !== undefined ? formData.authorizationRequest.startDate : new Date(),
                                                                endDate: formData?.authorizationRequest?.endDate !== undefined ? formData.authorizationRequest.endDate : new Date(),
                                                                diagnosisCode: formData?.authorizationRequest?.diagnosisCode !== undefined ? formData.authorizationRequest.diagnosisCode : '',
                                                                totalUnit: formData?.authorizationRequest?.totalUnit !== undefined ? formData.authorizationRequest.totalUnit : '',
                                                                serviceTypeNumber: formData?.authorizationRequest?.serviceTypeNumber !== undefined ? formData.authorizationRequest.serviceTypeNumber : '',
                                                                purchasePrice: formData?.authorizationRequest?.purchasePrice !== undefined ? formData.authorizationRequest.purchasePrice : '',
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {errors.additionalProcedureCode && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.additionalProcedureCode} </p>}
                                        </Col>
                                        <Col xs="12" sm="2" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }}
                                                    placeholder="(Modifier)"
                                                    value={formData?.authorizationRequest?.additionalProcedureCodeModifier || ''}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        if (value.length > 2) {
                                                            value = value.slice(0, 2);
                                                            setErrors(prevErrors => ({
                                                                ...prevErrors,
                                                                additionalProcedureCodeModifier: 'Modifier contains 2 characters only.'
                                                            }));
                                                        } else {
                                                            setErrors(prevErrors => ({
                                                                ...prevErrors,
                                                                additionalProcedureCodeModifier: ''
                                                            }));
                                                        }
                                                        setFormData({
                                                            ...formData,
                                                            authorizationRequest: {
                                                                ...formData?.authorizationRequest,
                                                                additionalProcedureCodeModifier: value,
                                                                primaryProcedureCode: formData?.authorizationRequest?.primaryProcedureCode !== undefined ? formData.authorizationRequest.primaryProcedureCode : '',
                                                                primaryProcedureCodeModifier: formData?.authorizationRequest?.primaryProcedureCodeModifier !== undefined ? formData.authorizationRequest.primaryProcedureCodeModifier : '',
                                                                additionalProcedureCode: formData?.authorizationRequest?.additionalProcedureCode !== undefined ? formData.authorizationRequest.additionalProcedureCode : '',
                                                                startDate: formData?.authorizationRequest?.startDate !== undefined ? formData.authorizationRequest.startDate : new Date(),
                                                                endDate: formData?.authorizationRequest?.endDate !== undefined ? formData.authorizationRequest.endDate : new Date(),
                                                                diagnosisCode: formData?.authorizationRequest?.diagnosisCode !== undefined ? formData.authorizationRequest.diagnosisCode : '',
                                                                totalUnit: formData?.authorizationRequest?.totalUnit !== undefined ? formData.authorizationRequest.totalUnit : '',
                                                                serviceTypeNumber: formData?.authorizationRequest?.serviceTypeNumber !== undefined ? formData.authorizationRequest.serviceTypeNumber : '',
                                                                purchasePrice: formData?.authorizationRequest?.purchasePrice !== undefined ? formData.authorizationRequest.purchasePrice : '',
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {errors.additionalProcedureCodeModifier && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.additionalProcedureCodeModifier} </p>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}><strong>Start Date</strong> OR Admission Date :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "0.75" }}
                                                    value={formatDate(formData?.authorizationRequest?.startDate)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        authorizationRequest: {
                                                            ...formData?.authorizationRequest,
                                                            startDate: e.target.value ? new Date(e.target.value) : formData?.authorizationRequest?.startDate || new Date(),
                                                            endDate: formData?.authorizationRequest?.endDate !== undefined ? formData.authorizationRequest.endDate : new Date(),
                                                            diagnosisCode: formData?.authorizationRequest?.diagnosisCode !== undefined ? formData.authorizationRequest.diagnosisCode : '',
                                                            totalUnit: formData?.authorizationRequest?.totalUnit !== undefined ? formData.authorizationRequest.totalUnit : '',
                                                            serviceTypeNumber: formData?.authorizationRequest?.serviceTypeNumber !== undefined ? formData.authorizationRequest.serviceTypeNumber : '',
                                                            primaryProcedureCode: formData?.authorizationRequest?.primaryProcedureCode !== undefined ? formData.authorizationRequest.primaryProcedureCode : '',
                                                            primaryProcedureCodeModifier: formData?.authorizationRequest?.primaryProcedureCodeModifier !== undefined ? formData.authorizationRequest.primaryProcedureCodeModifier : '',
                                                            additionalProcedureCode: formData?.authorizationRequest?.additionalProcedureCode !== undefined ? formData.authorizationRequest.additionalProcedureCode : '',
                                                            additionalProcedureCodeModifier: formData?.authorizationRequest?.additionalProcedureCodeModifier !== undefined ? formData.authorizationRequest.additionalProcedureCodeModifier : '',
                                                            purchasePrice: formData?.authorizationRequest?.purchasePrice !== undefined ? formData.authorizationRequest.purchasePrice : '',
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}><strong>End Date</strong> OR Discharge Date :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="date" style={{ fontSize: "0.70rem", lineHeight: "0.75" }}
                                                    value={formatDate(formData?.authorizationRequest?.endDate)}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        authorizationRequest: {
                                                            ...formData?.authorizationRequest,
                                                            endDate: e.target.value ? new Date(e.target.value) : formData?.authorizationRequest?.startDate || new Date(),
                                                            startDate: formData?.authorizationRequest?.endDate !== undefined ? formData.authorizationRequest.endDate : new Date(),
                                                            diagnosisCode: formData?.authorizationRequest?.diagnosisCode !== undefined ? formData.authorizationRequest.diagnosisCode : '',
                                                            totalUnit: formData?.authorizationRequest?.totalUnit !== undefined ? formData.authorizationRequest.totalUnit : '',
                                                            serviceTypeNumber: formData?.authorizationRequest?.serviceTypeNumber !== undefined ? formData.authorizationRequest.serviceTypeNumber : '',
                                                            primaryProcedureCode: formData?.authorizationRequest?.primaryProcedureCode !== undefined ? formData.authorizationRequest.primaryProcedureCode : '',
                                                            primaryProcedureCodeModifier: formData?.authorizationRequest?.primaryProcedureCodeModifier !== undefined ? formData.authorizationRequest.primaryProcedureCodeModifier : '',
                                                            additionalProcedureCode: formData?.authorizationRequest?.additionalProcedureCode !== undefined ? formData.authorizationRequest.additionalProcedureCode : '',
                                                            additionalProcedureCodeModifier: formData?.authorizationRequest?.additionalProcedureCodeModifier !== undefined ? formData.authorizationRequest.additionalProcedureCodeModifier : '',
                                                            purchasePrice: formData?.authorizationRequest?.purchasePrice !== undefined ? formData.authorizationRequest.purchasePrice : '',
                                                        }
                                                    })} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col xs="12" sm="2" >
                                            <label style={{ fontSize: "12px" }}>Total Units/Visits/Days :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "0.75" }}
                                                    required
                                                    value={formData?.authorizationRequest?.totalUnit || ''}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            authorizationRequest: {
                                                                ...formData?.authorizationRequest,
                                                                totalUnit: e.target.value,
                                                                startDate: formData?.authorizationRequest?.startDate !== undefined ? formData.authorizationRequest.startDate : new Date(),
                                                                endDate: formData?.authorizationRequest?.endDate !== undefined ? formData.authorizationRequest.endDate : new Date(),
                                                                diagnosisCode: formData?.authorizationRequest?.diagnosisCode !== undefined ? formData.authorizationRequest.diagnosisCode : '',
                                                                serviceTypeNumber: formData?.authorizationRequest?.serviceTypeNumber !== undefined ? formData.authorizationRequest.serviceTypeNumber : '',
                                                                primaryProcedureCode: formData?.authorizationRequest?.primaryProcedureCode !== undefined ? formData.authorizationRequest.primaryProcedureCode : '',
                                                                primaryProcedureCodeModifier: formData?.authorizationRequest?.primaryProcedureCodeModifier !== undefined ? formData.authorizationRequest.primaryProcedureCodeModifier : '',
                                                                additionalProcedureCode: formData?.authorizationRequest?.additionalProcedureCode !== undefined ? formData.authorizationRequest.additionalProcedureCode : '',
                                                                additionalProcedureCodeModifier: formData?.authorizationRequest?.additionalProcedureCodeModifier !== undefined ? formData.authorizationRequest.additionalProcedureCodeModifier : '',
                                                                purchasePrice: formData?.authorizationRequest?.purchasePrice !== undefined ? formData.authorizationRequest.purchasePrice : '',
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            totalUnit: ''
                                                        }));
                                                    }} />
                                            </div>
                                            {errors.totalUnit && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.totalUnit} </p>}
                                        </Col>
                                        <Col xs="12" sm="2">
                                            <label style={{ fontSize: "12px" }}>Diagnosis Code :</label>
                                        </Col>
                                        <Col xs="12" sm="4" className="mb-2 mb-sm-0">
                                            <div className="d-flex align-items-center">
                                                <Input type="text" style={{ fontSize: "0.70rem", lineHeight: "1", textAlign: "center" }} placeholder="(ICD-10)"
                                                    required
                                                    value={formData?.authorizationRequest?.diagnosisCode || ''}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            authorizationRequest: {
                                                                ...formData?.authorizationRequest,
                                                                diagnosisCode: e.target.value,
                                                                startDate: formData?.authorizationRequest?.startDate !== undefined ? formData.authorizationRequest.startDate : new Date(),
                                                                endDate: formData?.authorizationRequest?.endDate !== undefined ? formData.authorizationRequest.endDate : new Date(),
                                                                totalUnit: formData?.authorizationRequest?.totalUnit !== undefined ? formData.authorizationRequest.totalUnit : '',
                                                                serviceTypeNumber: formData?.authorizationRequest?.serviceTypeNumber !== undefined ? formData.authorizationRequest.serviceTypeNumber : '',
                                                                primaryProcedureCode: formData?.authorizationRequest?.primaryProcedureCode !== undefined ? formData.authorizationRequest.primaryProcedureCode : '',
                                                                primaryProcedureCodeModifier: formData?.authorizationRequest?.primaryProcedureCodeModifier !== undefined ? formData.authorizationRequest.primaryProcedureCodeModifier : '',
                                                                additionalProcedureCode: formData?.authorizationRequest?.additionalProcedureCode !== undefined ? formData.authorizationRequest.additionalProcedureCode : '',
                                                                additionalProcedureCodeModifier: formData?.authorizationRequest?.additionalProcedureCodeModifier !== undefined ? formData.authorizationRequest.additionalProcedureCodeModifier : '',
                                                                purchasePrice: formData?.authorizationRequest?.purchasePrice !== undefined ? formData.authorizationRequest.purchasePrice : '',
                                                            }
                                                        });
                                                        setErrors(prevErrors => ({
                                                            ...prevErrors,
                                                            diagnosisCode: ''
                                                        }));
                                                    }} />
                                            </div>
                                            {errors.diagnosisCode && <p style={{ color: 'red', fontSize: '0.60rem', marginBottom: '0.30rem', marginTop: '0.2rem' }}>* {errors.diagnosisCode} </p>}
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Card>
                    </div>
                </Row>
                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>OUTPATIENT SERVICE TYPE
                            </h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div className="table-responsive">
                                <div className="clinical-form">
                                    <Row className="mb-2">
                                        <Col xl={5} className="d-flex">
                                            <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Enter the Service type number in the boxes : </label></Col>
                                        <Col xl={7} className="d-flex">
                                            <div className="col">
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                maxLength={3}
                                                                type="text"
                                                                name="serviceTypeNumber"
                                                                value={formData?.authorizationRequest?.serviceTypeNumber || ''}
                                                                onChange={(e) => {
                                                                    const inputValue = e.target.value;
                                                                    if (/^\d*$/.test(inputValue)) {
                                                                        setFormData({
                                                                            ...formData,
                                                                            authorizationRequest: {
                                                                                ...formData?.authorizationRequest,
                                                                                serviceTypeNumber: inputValue,
                                                                                startDate: formData?.authorizationRequest?.startDate !== undefined ? formData.authorizationRequest.startDate : new Date(),
                                                                                endDate: formData?.authorizationRequest?.endDate !== undefined ? formData.authorizationRequest.endDate : new Date(),
                                                                                totalUnit: formData?.authorizationRequest?.totalUnit !== undefined ? formData.authorizationRequest.totalUnit : '',
                                                                                diagnosisCode: formData?.authorizationRequest?.diagnosisCode !== undefined ? formData.authorizationRequest.diagnosisCode : '',
                                                                                primaryProcedureCode: formData?.authorizationRequest?.primaryProcedureCode !== undefined ? formData.authorizationRequest.primaryProcedureCode : '',
                                                                                primaryProcedureCodeModifier: formData?.authorizationRequest?.primaryProcedureCodeModifier !== undefined ? formData.authorizationRequest.primaryProcedureCodeModifier : '',
                                                                                additionalProcedureCode: formData?.authorizationRequest?.additionalProcedureCode !== undefined ? formData.authorizationRequest.additionalProcedureCode : '',
                                                                                purchasePrice: formData?.authorizationRequest?.purchasePrice !== undefined ? formData.authorizationRequest.purchasePrice : '',
                                                                                additionalProcedureCodeModifier: formData?.authorizationRequest?.additionalProcedureCodeModifier !== undefined ? formData.authorizationRequest.additionalProcedureCodeModifier : '',
                                                                            },
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>  
                                                    </div>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Card className="p-2 mb-2" style={{ border: "1px solid #B2B2B2" }}>
                                        <Row className="mt-2">
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>422</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Biopharmacy</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>997</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Office Visit/Consult</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>712</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Cochlear Implants & Surgery</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>210</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Orthotics</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>299</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Drug Testing</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>794</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Outpatient Services</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>205</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Genetic Testing & Counseling</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>249</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Home Health</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>147</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Prosthetics</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>410</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Observation</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>171</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Outpatient Surgery</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>202</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Pain Management</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>993</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Transplant Evaluation</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>290</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> Hyperbaric Oxygen Therapy</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>201</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Sleep Study</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>390</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> Hospice Services</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>209 </strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Transplant Surgery</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>211</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>OB Ultrasound</label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>922</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Experimental and Investigational
                                                    Services</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>724 </strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Transportation</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <h6><u><strong>Behavioral Health</strong></u></h6>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>533</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Applied Behavioral Analysis</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>512</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>BH Community Based Services</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>515</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Electroconvulsive Therapy</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>516</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Intensive Outpatient Therapy</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>510</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>BH Medical Management</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>520</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>  BH Professional Fees</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>519</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Outpatient Therapy</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>530</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH PHP</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>522</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>  BH Psychiatric Evaluation
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>518</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>  BH Mental Health /Chemical Dependency Observation</label>
                                            </Col>
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>521</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> BH Psychological Testing</label>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <h6><u><strong>DMA</strong></u></h6>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col xl={4} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>417</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}>Rental</label>
                                            </Col>
                                            <Col xl={8} className="d-flex">
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}><strong>120</strong></label>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "12px" }}> Purchase</label>
                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem", marginLeft: "0.5rem" }}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <input
                                                                style={{
                                                                    width: "100%",
                                                                    border: "none",
                                                                    marginTop: "0.1rem",
                                                                    backgroundColor: "unset",
                                                                }}
                                                                type="text"
                                                                value={formData?.authorizationRequest?.purchasePrice || ''}
                                                                onChange={(e) => setFormData({
                                                                    ...formData,
                                                                    authorizationRequest: {
                                                                        ...formData?.authorizationRequest,
                                                                        purchasePrice: e.target.value,
                                                                        startDate: formData?.authorizationRequest?.startDate !== undefined ? formData.authorizationRequest.startDate : new Date(),
                                                                        endDate: formData?.authorizationRequest?.endDate !== undefined ? formData.authorizationRequest.endDate : new Date(),
                                                                        totalUnit: formData?.authorizationRequest?.totalUnit !== undefined ? formData.authorizationRequest.totalUnit : '',
                                                                        diagnosisCode: formData?.authorizationRequest?.diagnosisCode !== undefined ? formData.authorizationRequest.diagnosisCode : '',
                                                                        primaryProcedureCode: formData?.authorizationRequest?.primaryProcedureCode !== undefined ? formData.authorizationRequest.primaryProcedureCode : '',
                                                                        primaryProcedureCodeModifier: formData?.authorizationRequest?.primaryProcedureCodeModifier !== undefined ? formData.authorizationRequest.primaryProcedureCodeModifier : '',
                                                                        additionalProcedureCode: formData?.authorizationRequest?.additionalProcedureCode !== undefined ? formData.authorizationRequest.additionalProcedureCode : '',
                                                                        serviceTypeNumber: formData?.authorizationRequest?.serviceTypeNumber !== undefined ? formData.authorizationRequest.serviceTypeNumber : '',
                                                                        additionalProcedureCodeModifier: formData?.authorizationRequest?.additionalProcedureCodeModifier !== undefined ? formData.authorizationRequest.additionalProcedureCodeModifier : '',
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem", fontSize: "11px", marginLeft: "0.5rem" }}>
                                                    <i>(Purchase Price)</i></label>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            </div>
                        </Card>
                    </div >
                </Row >
                <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "1px solid #333", borderRadius: "8px", padding: "0.7rem 0.7rem 0rem 0.7rem" }}>
                    <Row>
                        <Col lg={7}>
                            <div className="row">
                                <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px" }}><strong>URGENT REQUESTS MUST BE SIGNED BY THE
                                        REQUESTING PHYSICIAN TO RECEIVE PRIORITY</strong></h6>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="row justify-content-between" style={{ textAlign: "center" }}>
                                <div className="col-lg-5 col-md-12 m-2">
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="sign" style={{ width: '100%', maxWidth: '350px' }}>
                                            <div
                                                className='sigCanvasNewDiv'
                                            >
                                                {clinicianSign ? (
                                                    <img className="sigCanvasNew" src={formData?.basicInformation?.signature} style={{
                                                        width: "100%",
                                                        height: "auto",
                                                        maxWidth: "500px",
                                                    }} />
                                                ) : (
                                                    <SignaturePad
                                                        backgroundColor="rgb(245,242,241)"
                                                        ref={signPad}
                                                        penColor="black"
                                                        canvasProps={{ className: "sigCanvasNew", style: { width: "100%", height: "auto", maxWidth: "350px" } }}
                                                    ></SignaturePad>
                                                )}
                                            </div>
                                        </div>
                                        {!clinicianSign ? (
                                            <div className="d-flex justify-content-between mt-3" style={{ width: "100%", maxWidth: "350px" }}>
                                                <div>
                                                    <a onClick={() => { clearsign(); }} className="btn btn-primary btn-sm" style={{ margin: "0 0.5rem" }}>Clear</a>
                                                </div>
                                                <div>
                                                    <a onClick={() => { saveSign(); }} className="btn btn-primary btn-sm" style={{ margin: "0 0.7rem 0 1.5rem" }}>Save</a>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <a onClick={() => editSign()} className="btn btn-primary btn-sm mt-2" style={{ marginTop: "10px" }}>Edit</a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* </Card> */}
                        </Col>
                    </Row>
                </Card>
                <Card style={{ marginBottom: "0.5rem", backgroundColor: "#F1F1F1", border: "1px solid #333", borderRadius: "8px", padding: "0.7rem 0.7rem 0rem 0.7rem" }}>
                    <Row className="mb-2">
                        <Col lg={12}>
                            <div className="row">
                                <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "12px", textAlign: "center" }}><strong>ALL REQUIRED FIELDS MUST BE FILLED IN AS INCOMPLETE FORMS WILL BE REJECTED.
                                        COPIES OF ALL SUPPORTING CLINICAL INFORMATION ARE REQUIRED. LACK OF CLINICAL INFORMATION MAY RESULT IN DELAYED DETERMINATION</strong></h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="row">
                                <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "10px" }}><strong>Disclaimer:</strong> An authorization is not a guarantee of payment. Member must be eligible at the time services are rendered. Services must be a covered Health Plan Benefit and medically necessary with prior
                                        authorization as per Plan policy and procedures.</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col lg={12}>
                            <div className="row">
                                <div className="col-auto">
                                    <h6 className="fw-500" style={{ margin: "0.3rem", fontSize: "10px" }}><strong>Confidentiality:</strong> The information contained in this transmission is confidential and may be protected under the Health Insurance Portability and Accountability Act of 1996. If you are not the
                                        intended recipient any use, distribution, or copying is strictly prohibited. If you have received this facsimile in error, please notify us immediately and destroy this document.</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <Row>
                    <Col xl={6}></Col>
                    <Col xl={6}>
                        <button
                            type="button"
                            className="btn btn-primary relative mt-3 mb-2"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={saveAmbetterAuthForm}
                            disabled={isSaving}
                        >
                            {isSaving ? "Saving..." : "Save"}
                        </button>
                    </Col>
                </Row>
            </Card >
        </>
    );

};

export default AmbetterForm;