export enum TabTypes {
  RELATIONAL = "relational/01",
  TRAUMA = "trauma/02",
  IDENTITY = "identity/03",
  PROCESS = "process/04",
  MOURNING_LOSS = "mourning_loss/05",
  ADJUSTMENT = "adjustment/06",
  DAILY_LIFE = "daily_life/07",
  SPECIFIC_DIFFICULTIES = "specific_difficulties/08",
  AFFECTIVE_STATE = "affective_state/09",
  MENTAL_STATE = "mental_state/10",
  GLOBAL_ASSESSMENT = "global_assessment/11",
  LEVEL_OF_FUNCTIONING = "level_of_functioning/12",
  SIGNIFICANT_DEVELOPMENTS = "significant_developments/13",
  OUTSTANDING_ISSUES = "outstanding_issues/14",
  SUPPORTIVE = "supportive/15",
  COGNITIVE_BEHAVIORAL = "cognitive_behavioral/16",
  PSYCHOANALYTIC = "psychoanalytic/17",
  OTHER_INTERVENTIONS = "other_interventions/18",
  ONGOING_PLAN = "ongoing_plan/19",
}

export enum Stages {
  SUBJECTIVE = "subjective",
  OBJECTIVE = "objective",
  ASSESSMENT = "assessment",
  PLAN = "plan",
}

export enum RefNames {
  HISTORY_OF_PRESENT_ILLNESS = "history_of_present_illness",
  CHIEF_COMPLAINT = "chief_complaint",
  PROCEDURE_NOTES = "procedure_notes",
  CARE_PLAN = "care_plan",
}

export interface Phrase {
  _id?: string;
  value: string;
  priority: string;
  tabType: string;
  stage: string;
  systemGenerated: boolean;
  createdBy?: string;
}

// export interface PhraseModel {
//   value: string;
//   priority: string;
// }

export interface ContentModel {
  content: string;
  tabType: string;
  message: string;
  priority: string;
}

// export interface TabData {
//   stage: string;
//   selectedStates: ContentModel[];
// }

// export interface Note {
//   clientId: string;
//   sessionDate: Date;
//   startTime: string;
//   endTime: string;
//   fee: number;
//   intrductoryComment: string;
//   closingComment: string;
//   sessionNote: string;
// }

// export interface ContentState {
//   value: string;
//   priority: string;
// }
