import React from "react";
import LogoDark from '../../assets/images/logo-dark.png'

const FooterMain: React.FC = () => {
    return(
        <div>
            <footer className="bg-dark text-inverse mt-0 mt-md-16">
                <div className="container py-13 py-md-15">
                <div className="row gy-6 gy-lg-0">
                    <div className="col-md-4 col-lg-3 mb-2">
                    <div className="widget">
                        <img
                        className="mb-4"
                        src={LogoDark}
                        style={{ height: "60px" }}
                        alt="Lavni helps you find a mental health professional
                    that understands your unique experience"
                        />
                        <p className="mb-2 footer-texts">
                            &copy; {new Date().getFullYear()} Lavni. <br className="d-none d-lg-block" />
                            All rights reserved.
                        </p>
                        <nav className="nav social social-white ">
                        <a href="https://www.instagram.com/mylavni/" className="footer-texts">
                            <span className="iconify" data-icon="uil:instagram"></span>
                        </a>
                        <a href="https://www.facebook.com/lavnihealth" className="footer-texts">
                            <span className="iconify" data-icon="uil:facebook-f"></span>
                        </a>
                        <a href="https://www.linkedin.com/company/lavni/" className="footer-texts">
                            <span className="iconify" data-icon="uil:linkedin"></span>
                        </a>
                        </nav>
                    </div>
                    </div>

                    <div className="col-md-4 col-lg-3">
                    <div className="widget">
                        <h4 className="widget-title text-white mb-3">Get in Touch</h4>
                        <address className="pe-xl-15 pe-xxl-17">4030 Wake Forest Road, STE 349, Raleigh, NC, 27609, USA.</address>
                        <div className="row">
                            <div className="col-12 mb-2">
                                <a aria-label="Call Lavni at +1 (980) 890 7522" href="tel:+19808907522" className="footer-texts">+1 (980) 890 7522</a>
                            </div>
                            <div className="col-12 mb-2">
                                <a aria-label="Email Lavni at info@mylavni.com" href="mailto:info@mylavni.com" className="footer-texts">info@mylavni.com</a>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="col-md-4 col-lg-3">
                    <div className="widget">
                        <h4 className="widget-title text-white mb-3">Learn More</h4>
                        <ul className="list-unstyled mb-2">
                        <li>
                            <a href="/about" className="footer-texts">
                            About Us
                            </a>
                        </li>
                        <li>
                            <a href="/service" className="footer-texts">Service</a>
                        </li>
                        <li>
                            <a href="/terms" className="footer-texts">Terms of Use</a>
                        </li>
                        <li>
                            <a href="/privacy" className="footer-texts">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/faq" className="footer-texts">FAQ</a>
                        </li>
                        <li>
                            <a href="/blogs/" className="footer-texts">Blogs</a>
                        </li>
                        <li>
                            <a href="/contact" className="footer-texts">Contact</a>
                        </li>
                        </ul>
                    </div>
                    </div>

                    <div className="col-md-12 col-lg-3">
                    <div className="widget">
                        <h4 className="widget-title text-white mb-3">Our Newsletter</h4>
                        <p className="mb-5 footer-texts">Subscribe to our newsletter to get our news & deals delivered to you.</p>
                        <div className="newsletter-wrapper">
                        <div id="mc_embed_signup2">
                            <form className="validate dark-fields">
                            <div id="mc_embed_signup_scroll2">
                                <div className="mc-field-group input-group form-floating">
                                <input id="form_email" type="email" name="email" className="form-control" placeholder="jane.doe@example.com" required />
                                <label>Email Address</label>
                                <input type="submit" className="btn btn-primary" value="Submit" id="newsletter" />
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </footer>
        </div>
    )
}

export default FooterMain