import React from "react";
import { CardTitle, Col, Row, Modal, CardBody } from "reactstrap";

const Modal13: React.FC<{
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}> = props => {
  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <div className="notify-icon">
              <i className="fa fa-info-circle text-info"></i>
            </div>

            <CardTitle className="mb-4 text-center font-size-20">Appointment is already completed.</CardTitle>
            <div className="d-flex justify-content-center flexColumn">
              <button type="button" onClick={() => props.setShowModal(false)} className="btn btn-primary btnMargin">
                Close
              </button>
            </div>
          </CardBody>
          <Row>
            <Col xl="3"></Col>
            <Col xl="6"> </Col>
            <Col xl="3"></Col>
          </Row>
        </div>
      </Modal>
    </>
  ) : null;
};

export default Modal13;
