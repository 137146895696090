import React, { useContext, useEffect, useState } from "react";
import "../../../assets/css/chat_screen.css";
import { Card, CardBody, CardTitle, Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";

import GroupChatContext from "src/context/GroupChatContext";

import images from "src/assets/images";
import { GroupChatService } from "src/services/GroupChatService";
import { toast } from "react-toastify";
import Spinner from "src/common/spinner/spinner";
import UserContext from "src/context/UserContext";
import { MemberInSelectedChatGroupModel } from "src/models/GroupChat/MemberInSelectedChatGroup";
import Swal from "sweetalert2";

import { Util } from "src/Util";
import { RequestsInSelectedPublicChatGroupModel } from "src/models/GroupChat/RequestsInSelectedPublicChatGroup";
import { NotificationEvent, NotificationModel, NotificationVarient } from "src/models/Notification";
import { NotificationService } from "src/services/NotificationService";
import { sendNotificationSocketData } from "src/models/sendNotificationCallSocketData";
import { SocketContext } from "src/context/ScoketContext";

const PublicGroupRequestView: React.FC = (props: any) => {
  const [user, setUser] = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [
    selectedGroupData,
    setSelectedGroupData,
    selectedGroupIdForShowMembers,
    setSelectedGroupIdForShowMembers,
    selectedGroupIdForShowRequests,
    setSelectedGroupIdForShowRequests,
  ] = useContext(GroupChatContext);
  const [requestsInSelectedChatGroup, setRequestsInSelectedChatGroup] = useState([] as RequestsInSelectedPublicChatGroupModel[]);

  const [isLoading, setIsLoading] = useState(true);
  const [isGettingMembersError, setIsGettingMembersError] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [changingRequestId, setChangingRequestId] = useState("");
  const SCREEN_SIZE = 991;
  const SCREEN_SIZE_FOR_TOP_BAR = user?.role == "THERAPIST" ? 670 : 485;
  const SCREEN_SIZE_FOR_TOP_BAR_MINI = user?.role == "THERAPIST" ? 565 : 520;
  const SCREEN_SIZE_FOR_TOP_BAR_XS = 455;
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [totalDataCountInDb, setTotalDataCountInDb] = useState(0);
  const [isSearchableString, setIsSearchableString] = useState("");
  const [finalSearchableString, setFinalSearchableString] = useState("");

  useEffect(() => {
    window.addEventListener("resize", getScreenSize);
    return () => {
      window.removeEventListener("resize", getScreenSize);
    };
  }, []);

  const getScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    if (
      selectedGroupData != null &&
      selectedGroupData._id != null &&
      selectedGroupData._id != "" &&
      selectedGroupIdForShowRequests != null &&
      selectedGroupIdForShowRequests != "" &&
      selectedGroupIdForShowRequests == selectedGroupData._id
    ) {
      setOffset(0);
      setTotalDataCountInDb(0);
      setIsSearchableString("");
      setFinalSearchableString("");
      setRequestsInSelectedChatGroup([]);
      initialLoading(true, false, false, true, false);
    }
  }, []);

  const initialLoading = async (next: boolean, isSearch: boolean, isRemove: boolean, isInitial: boolean, isAdd: boolean) => {
    console.log("initialLoading join requests");
    setIsLoading(true);
    await getAllJoinRequests(next, isSearch, isRemove, isInitial, isAdd);
    setIsLoading(false);
  };

  const getAllJoinRequests = async (next: boolean, isSearch: boolean, isRemove: boolean, isInitial: boolean, isAdd: boolean) => {
    try {
      let previousCount = 0;
      let finalOffset = offset;
      if (isInitial || isSearch) {
        finalOffset = 0;
      } else if (isRemove) {
        previousCount = requestsInSelectedChatGroup.length;
        if (isRemove && previousCount > 1) {
          if (finalOffset - 1 >= 0) {
            finalOffset = finalOffset - 1;
          }
        } else {
          if (finalOffset - 2 >= 0) {
            finalOffset = finalOffset - 2;
          }
        }
      } else if (isAdd) {
        if (finalOffset - 1 >= 0) {
          finalOffset = finalOffset - 1;
        }
      } else {
        if (!next) {
          if (finalOffset - 2 >= 0) {
            finalOffset = finalOffset - 2;
          }
        }
      }
      const dataObject = {
        groupId: selectedGroupData._id,
        searchableString: isSearch ? isSearchableString : finalSearchableString,
        limit: limit,
        offset: finalOffset * limit,
      };
      console.log(offset);
      const res = await GroupChatService.getAllJoinRequestForPublicChatGroup(dataObject);
      if (res.success && res.data) {
        if (res.data != null && res.data.requests != null && res.data.count != null) {
          setTotalDataCountInDb(res.data.count);
          setRequestsInSelectedChatGroup(res?.data.requests);

          if (isSearch) {
            setFinalSearchableString(isSearchableString);
          }
          if (isSearch || isInitial) {
            setOffset(1);
          } else {
            if (isRemove) {
              if (isRemove && previousCount == 1) {
                if (offset - 1 > 0) {
                  setOffset(offset - 1);
                }
              }
            } else if (isAdd) {
            } else {
              if (next) {
                setOffset(offset + 1);
              } else {
                if (offset - 1 > 0) {
                  setOffset(offset - 1);
                }
              }
            }
          }
        }
      } else {
        setIsGettingMembersError(true);
        toast.error("Retrieving chat groups requests failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsGettingMembersError(true);
      toast.error("Retrieving chat groups requests failed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const backToGroup = () => {
    setRequestsInSelectedChatGroup([]);
    setSelectedGroupIdForShowMembers("");
    setSelectedGroupIdForShowRequests("");
  };

  const acceptRequest = async (requestId: string, request: RequestsInSelectedPublicChatGroupModel) => {
    try {
      if (!isChanging && user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy) {
        setChangingRequestId(requestId);
        setIsChanging(true);
        const userData = {
          requestId: requestId,
        };
        const res = await GroupChatService.acceptJoinRequestForPublicGroup(userData);
        if (res && res.success) {
          try {
            const grpChatventNotification: NotificationModel = {
              senderId: user?._id,
              receiverId: request.userId,
              event: NotificationEvent.GRP_CHAT_JOIN_REQUEST_ACCEPTED,
              link: `/group-chat/${selectedGroupData._id}`,
              content: `Join request to ${selectedGroupData.title ?? ""} ${selectedGroupData.type == "PUBLIC" ? "peer support" : "private"} chat group accepted.`,
              variant: NotificationVarient.INFO,
            };

            NotificationService.sendNotification(grpChatventNotification).then(res => {
              if (res && res.success && res.data && res.data.socketId) {
                const socketData: sendNotificationSocketData = {
                  socketId: res.data.socketId,
                  notifyData: grpChatventNotification,
                  receiverId: request.userId,
                  senderId: user?._id,
                };

                socket.emit("send-notification", socketData);
              }
            });
          } catch (error) {}

          setIsChanging(false);

          toast.success("Request accepted successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          initialLoading(true, false, true, false, false);
        } else {
          setIsChanging(false);
          toast.error("Failed to accept request!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsChanging(false);
      } else {
        toast.error("Please wait, accepting request in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsChanging(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const rejectRequest = async (requestId: string, request: RequestsInSelectedPublicChatGroupModel) => {
    try {
      if (!isChanging && user?.role == "THERAPIST" && user._id == selectedGroupData.createdBy) {
        setChangingRequestId(requestId);
        setIsChanging(true);
        const userData = {
          requestId: requestId,
        };
        const res = await GroupChatService.rejectJoinRequestForPublicGroup(userData);
        if (res && res.success) {
          try {
            const grpChatventNotification: NotificationModel = {
              senderId: user?._id,
              receiverId: request.userId,
              event: NotificationEvent.GRP_CHAT_JOIN_REQUEST_REJECTED,
              link: `/group-chat`,
              content: `Join request to ${selectedGroupData.title ?? ""} ${selectedGroupData.type == "PUBLIC" ? "peer support" : "private"} chat group rejected.`,
              variant: NotificationVarient.INFO,
            };

            NotificationService.sendNotification(grpChatventNotification).then(res => {
              if (res && res.success && res.data && res.data.socketId) {
                const socketData: sendNotificationSocketData = {
                  socketId: res.data.socketId,
                  notifyData: grpChatventNotification,
                  receiverId: request.userId,
                  senderId: user?._id,
                };

                socket.emit("send-notification", socketData);
              }
            });
          } catch (error) {}

          setIsChanging(false);

          toast.success("Request rejected successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
          initialLoading(true, false, true, false, false);
        } else {
          setIsChanging(false);
          toast.error("Failed to reject request!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
        setIsChanging(false);
      } else {
        toast.error("Please wait, rejecting request in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsChanging(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="flex flex-wrap mb-4 mt-1 ">
            <div
              className="flex d-inline-block cursor-pointer align-items-center justify-content-center"
              onClick={() => {
                backToGroup();
              }}
            >
              <i className="fas fa-regular fa-arrow-left "></i>
              <CardTitle className="align-items-center justify-content-center m-0">&nbsp; Back to Group</CardTitle>
            </div>
          </div>

          <Row>
            <Col lg={12} className="">
              <Card style={{ minHeight: "67vh" }}>
                {screenSize > SCREEN_SIZE_FOR_TOP_BAR_MINI ? (
                  <div className="pt-2 pb-2  chat-header-bg" style={{ paddingLeft: "6vw", paddingRight: "6vw" }}>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center" style={{ paddingRight: "0px", width: "100%" }}>
                          <h5 className="font-size-15 mb-0 text-truncate d-inline-block align-items-center justify-content-center fc-black me-4">
                            Join Requests
                          </h5>
                          <div className="d-flex flex-column ms-4">
                            <div className="d-flex justify-content-center">
                              <div onClick={event => event.stopPropagation()}>
                                <input className="search-input-stat form-control me-2" onChange={(e: any) => setIsSearchableString(e.target.value)} />
                              </div>
                              <span
                                className="btn btn-warning  btn-sm  mx-2 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  if (!isLoading) {
                                    initialLoading(true, true, false, false, false);
                                  } else {
                                    toast.error("Please wait", {
                                      position: toast.POSITION.BOTTOM_RIGHT,
                                      className: "foo-bar",
                                    });
                                  }
                                }}
                              >
                                <i className="bx bx-search"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pt-2 pb-2 ps-3 pe-3 chat-header-bg">
                    <div className="d-flex flex-column align-items-start justify-content-between">
                      <div className="d-flex align-items-center" style={{ paddingRight: "0px", width: "100%" }}>
                        <h5 className="font-size-15 mb-0 text-truncate d-inline-block align-items-center justify-content-center fc-black">Join Requests</h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-start" style={{ paddingRight: "0px", width: "100%" }}>
                        <div className="d-flex flex-row align-items-center justify-content-between mt-2" style={{ paddingRight: "0px", width: "100%" }}>
                          <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-center">
                              <div onClick={event => event.stopPropagation()}>
                                <input className="search-input-stat form-control me-2" onChange={(e: any) => setIsSearchableString(e.target.value)} />
                              </div>
                              <span
                                className="btn btn-warning  btn-sm  mx-2 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  if (!isLoading) {
                                    initialLoading(true, true, false, false, false);
                                  } else {
                                    toast.error("Please wait", {
                                      position: toast.POSITION.BOTTOM_RIGHT,
                                      className: "foo-bar",
                                    });
                                  }
                                }}
                              >
                                <i className="bx bx-search"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <CardBody className="p-0 pt-3">
                  <div
                    className={screenSize > SCREEN_SIZE_FOR_TOP_BAR_MINI ? "container " : "container ps-3 pe-3"}
                    style={{ maxWidth: 1400, paddingLeft: "6vw", paddingRight: "6vw" }}
                  >
                    <div className="row ">
                      {!isLoading &&
                        selectedGroupData != null &&
                        selectedGroupData._id != null &&
                        selectedGroupData._id != "" &&
                        selectedGroupIdForShowRequests != null &&
                        selectedGroupIdForShowRequests != "" &&
                        selectedGroupIdForShowRequests == selectedGroupData._id &&
                        requestsInSelectedChatGroup != null &&
                        requestsInSelectedChatGroup.length != null &&
                        requestsInSelectedChatGroup.length > 0 && (
                          <div className="col-12 d-flex justify-content-start align-items-center mb-3">
                            <h5 className="mb-0 d-flex justify-content-start fs-14 align-items-start fc-black">{`Requests (${totalDataCountInDb})`}</h5>
                          </div>
                        )}
                      <div
                        className="mb-4"
                        style={
                          !isLoading &&
                          selectedGroupData != null &&
                          selectedGroupData._id != null &&
                          selectedGroupData._id != "" &&
                          selectedGroupIdForShowRequests != null &&
                          selectedGroupIdForShowRequests != "" &&
                          selectedGroupIdForShowRequests == selectedGroupData._id &&
                          requestsInSelectedChatGroup != null &&
                          requestsInSelectedChatGroup.length != null &&
                          requestsInSelectedChatGroup.length > 0
                            ? { maxHeight: "50vh", overflowY: "auto" }
                            : {}
                        }
                      >
                        <div className="">
                          {isLoading ? (
                            <div className="group-chat-empty-view" style={{ minHeight: "67vh" }}>
                              <Spinner />
                            </div>
                          ) : !isLoading &&
                            isGettingMembersError &&
                            (requestsInSelectedChatGroup == null || requestsInSelectedChatGroup.length == null || requestsInSelectedChatGroup.length <= 0) ? (
                            <div className="group-chat-empty-view" style={{ minHeight: "67vh" }}>
                              <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
                              <div className="mt-1">Error getting data</div>
                            </div>
                          ) : !isLoading &&
                            selectedGroupData != null &&
                            selectedGroupData._id != null &&
                            selectedGroupData._id != "" &&
                            selectedGroupIdForShowRequests != null &&
                            selectedGroupIdForShowRequests != "" &&
                            selectedGroupIdForShowRequests == selectedGroupData._id &&
                            requestsInSelectedChatGroup != null &&
                            requestsInSelectedChatGroup.length != null &&
                            requestsInSelectedChatGroup.length > 0 ? (
                            requestsInSelectedChatGroup?.map((request, index: number) => (
                              <div key={index}>
                                <Col lg={8} md={10}>
                                  <li
                                    className={
                                      isChanging && changingRequestId == request._id
                                        ? "d-flex d-inline-block mb-1 p-2 rounded bg-light-gray"
                                        : "d-flex d-inline-block mb-1 p-2 rounded"
                                    }
                                  >
                                    <div className="d-flex justify-content-between align-items-center flex-wrap" style={{ width: "100%" }}>
                                      <Col lg={9} md={8} sm={8} xs={12}>
                                        <div className="d-flex flex-row justify-content-start align-items-center me-1 mb-2">
                                          {request != null && request.photoId != null && request.photoId._id != null ? (
                                            <div className="d-flex d-inline-block align-self-center me-3">
                                              <img src={Util.fileURL(request.photoId._id)} className="rounded-circle avatar-sm imageFit" />
                                            </div>
                                          ) : (
                                            <div className="d-flex d-inline-block align-self-center me-3">
                                              <img src={images.defaultGroupIcon} className="rounded-circle avatar-sm imageFit" />
                                            </div>
                                          )}
                                          <h5 className="text-truncate mb-0 ">{`${request.firstname ?? ""} ${request.lastname ?? ""}`}</h5>
                                        </div>
                                      </Col>
                                      <Col lg={3} md={4} sm={4} xs={12}>
                                        <div className="d-flex flex-row justify-content-start align-items-center mb-2">
                                          {!(isChanging && changingRequestId == request._id) &&
                                            user?.role == "THERAPIST" &&
                                            user?._id == selectedGroupData.createdBy && (
                                              <button
                                                className="btn btn-primary "
                                                onClick={() => {
                                                  Swal.fire({
                                                    icon: "warning",
                                                    title: "Are you sure you want to accept this request",
                                                    showCancelButton: true,
                                                    confirmButtonText: "Accept",
                                                    confirmButtonColor: "#FD7F00",
                                                    cancelButtonColor: "#FD7F00",
                                                  }).then(result => {
                                                    if (result.isConfirmed) {
                                                      acceptRequest(request._id, request);
                                                    }
                                                  });
                                                }}
                                              >
                                                Accept
                                              </button>
                                            )}
                                          {!(isChanging && changingRequestId == request._id) &&
                                            user?.role == "THERAPIST" &&
                                            user?._id == selectedGroupData.createdBy && (
                                              <button
                                                className="btn btn-danger  ms-2"
                                                onClick={() => {
                                                  Swal.fire({
                                                    icon: "warning",
                                                    title: "Are you sure you want to reject this request",
                                                    showCancelButton: true,
                                                    confirmButtonText: "Reject",
                                                    confirmButtonColor: "#FD7F00",
                                                    cancelButtonColor: "#FD7F00",
                                                  }).then(result => {
                                                    if (result.isConfirmed) {
                                                      rejectRequest(request._id, request);
                                                    }
                                                  });
                                                }}
                                              >
                                                Reject
                                              </button>
                                            )}
                                          {isChanging && changingRequestId == request._id && <button className="btn btn-secondary disabled">Loading...</button>}
                                        </div>
                                      </Col>
                                    </div>
                                  </li>
                                </Col>
                              </div>
                            ))
                          ) : (
                            <div className="group-chat-empty-view" style={{ minHeight: "67vh" }}>
                              <img className="img-fluid" src={images.usersIcon} alt="Empty Image" style={{ width: "40px" }} />
                              <div className="mt-1">No available requests</div>
                            </div>
                          )}
                        </div>
                      </div>
                      {!isLoading &&
                        selectedGroupData != null &&
                        selectedGroupData._id != null &&
                        selectedGroupData._id != "" &&
                        selectedGroupIdForShowRequests != null &&
                        selectedGroupIdForShowRequests != "" &&
                        selectedGroupIdForShowRequests == selectedGroupData._id &&
                        requestsInSelectedChatGroup != null &&
                        requestsInSelectedChatGroup.length != null &&
                        requestsInSelectedChatGroup.length > 0 && (
                          <Col lg={8} md={10}>
                            <Row className=" mt-2 mb-4">
                              <div className={offset > 1 ? "d-flex justify-content-between " : "d-flex justify-content-end "}>
                                <div>
                                  {offset > 1 && (
                                    <button
                                      className="btn btn-secondary btn-sm"
                                      onClick={() => {
                                        if (!isLoading) {
                                          initialLoading(false, false, false, false, false);
                                        } else {
                                          toast.error("Please wait", {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                            className: "foo-bar",
                                          });
                                        }
                                      }}
                                    >
                                      <i className="bx bx-left-arrow-alt me-2"></i> Back
                                    </button>
                                  )}
                                </div>
                                <div>
                                  {offset > 0 && totalDataCountInDb > limit * offset && (
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        if (!isLoading) {
                                          initialLoading(true, false, false, false, false);
                                        } else {
                                          toast.error("Please wait", {
                                            position: toast.POSITION.BOTTOM_RIGHT,
                                            className: "foo-bar",
                                          });
                                        }
                                      }}
                                    >
                                      Next <i className="bx bx-right-arrow-alt ms-2"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Row>
                          </Col>
                        )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default PublicGroupRequestView;
