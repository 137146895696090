import React, { useState, useEffect } from "react";
import Spinner from "src/common/spinner/spinner";
import { FormVersionService } from "src/services/FormVersionService";
import { toast } from "react-toastify";
import { TPType } from "src/models/therapyPlan/therapyPlan";
import Modal31 from "src/pages/Popup/Modal31";
import TherapyPlanModal from "src/pages/Popup/TherapyPlanModal";
import TherapyPlanPdfModal from "src/pages/Popup/TherapyPlanPdfModal";

interface PendingTherapyPlansProps {
  pendingTherapyPlans: any[];
}

const PendingTherapyPlans: React.FC<PendingTherapyPlansProps> = ({ pendingTherapyPlans }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [therapyPlans, setTherapyPlans] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClientId, setSelectedClientId] = useState<any>();
  const [clientIdForForm, setClientIdForForm] = useState("");
  const [therapyPlanInfo, setTherapyPlanInfo] = useState("");
  const [showTherapyPlanWithVersionModal, setShowTherapyPlanWithVersionModal] = useState(false);
  const [therapyPlanId, setTherapyPlanId] = useState("");
  const [showTherapyPlanModal, setShowTherapyPlanModal] = useState<boolean>(false);
  const [isCreatingNewForm, setIsCreatingNewForm] = useState<boolean>(false);
  const [therapyPlanType, setTherapyPlanType] = useState("");
  const [showTherapyPlanPdfModal, setShowTherapyPlanPdfModal] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const eventType = "DOWNLOAD";

  useEffect(() => {
    setIsLoading(true);
    setTherapyPlans(pendingTherapyPlans);
    setIsLoading(false);
  }, [pendingTherapyPlans]);

  const handleNoteClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const handleTherapyPlansView = async (clientId: string) => {
    setSelectedClientId(clientId);
    if (clientId != undefined) {
      setIsLoading(true);
      setClientIdForForm(clientId);

      const result = await fetchTherapyPlanWithVersions(clientId);
      if (result.success) {
        setTherapyPlanInfo(result.data);
      }
      setIsLoading(false);
      setShowTherapyPlanWithVersionModal(true);
    }
  };

  const fetchTherapyPlanWithVersions = async (clientIdForForm: string) => {
    try {
      const res = await FormVersionService.getTherapyPlansWithAllVersions(clientIdForForm);
      if (res && res?.success && res?.data) {
        return { success: true, data: res.data };
      } else {
        toast.error("Encountered an error while retrieving the therapy plan data with versions!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
        return { success: false };
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      return { success: false };
    }
  };

  const createNewTherapyPlan = () => {
    setTherapyPlanId(selectedClientId);
    setShowTherapyPlanModal(true);
    setIsCreatingNewForm(true);
  };

  const therapyPlan = (planId: string) => {
    setTherapyPlanId(planId);
    setShowTherapyPlanModal(true);
    setIsCreatingNewForm(false);
  };

  const generateTherapyPlanPdf = (planId: string, tpType: TPType) => {
    setTherapyPlanId(planId);
    setTherapyPlanType(tpType);
    setShowTherapyPlanPdfModal(true);
  };
  const refreshModal31 = async (value: boolean) => {
    if (value) {
      const result = await fetchTherapyPlanWithVersions(clientIdForForm);
      if (result.success) {
        setTherapyPlanInfo(result.data);
      }
    }
  };
  const toggleSorting = () => {
    if (sortType === "asc") {
      sortingAZ();
      setSortType("desc");
    } else {
      sortingZA();
      setSortType("asc");
    }
  };

  const sortingAZ = () => {
    const sortedData = [...therapyPlans].sort((a: any, b: any) => {
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setTherapyPlans(sortedData);
  };

  const sortingZA = () => {
    const sortedData = [...therapyPlans].sort((a: any, b: any) => {
      if (a.clientDetails.firstname > b.clientDetails.firstname) {
        return -1;
      }
      if (a.clientDetails.firstname < b.clientDetails.firstname) {
        return 1;
      }
      return 0;
    });
    setTherapyPlans(sortedData);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredTherapyPlans = therapyPlans.filter((client) => {
    const fullName = `${client.clientDetails?.firstname} ${client.clientDetails?.lastname}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  return (
    <React.Fragment>
      {showTherapyPlanWithVersionModal && (
        <Modal31
          setShowModal={setShowTherapyPlanWithVersionModal}
          showModal={showTherapyPlanWithVersionModal}
          therapyPlanInfo={therapyPlanInfo}
          createNewTherapyPlan={createNewTherapyPlan}
          openTherapyPlan={therapyPlan}
          downloadTherapyPlan={generateTherapyPlanPdf}
          isMobile={isMobile}
          isAdmin={false}
        />
      )}
      {showTherapyPlanModal && (
        <TherapyPlanModal
          setShowModal={setShowTherapyPlanModal}
          showModal={showTherapyPlanModal}
          identifierId={therapyPlanId}
          isAdmin={false}
          isFromMeeting={false}
          isCreatingNewForm={isCreatingNewForm}
          setModalRefresher={refreshModal31}
        />
      )}
      {showTherapyPlanPdfModal && (
        <TherapyPlanPdfModal
          setShowModal={setShowTherapyPlanPdfModal}
          showModal={showTherapyPlanPdfModal}
          identifierId={therapyPlanId}
          isAdmin={false}
          eventType={eventType}
          insuranceDocApprovalId={null}
          setModalRefresher={null}
          therapyPlanType={therapyPlanType as TPType}
        />
      )}
      <div className="clinical-notes-container">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="clinical-notes-header-container d-flex flex-lg-row flex-md-row flex-sm-column flex-column justify-content-between align-items-center">
              <div className={"d-flex flex-row align-items-center fs-5 fw-bold"}>
                Pending Therapy Plans: Clients List
                <span className={"ms-2"}>
                  <i onClick={toggleSorting} className={"bx bx-sort"}></i>
                </span>
              </div>
              <div className="search-container d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search clients..."
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ width: "250px", height: "35px" }}
                />
              </div>
            </div>
            <div className="clinical-notes-list">
              {filteredTherapyPlans.length > 0 ? (
                filteredTherapyPlans.map((client, index) => {
                  return (
                    <div
                      key={index}
                      className={`clinical-note-item ${index === activeIndex ? "active" : ""}`}
                      onClick={() => handleNoteClick(index)}
                    >
                      <div className="clinical-note-icon"></div>
                      <span className="clinical-note-name">
                        {client.clientDetails?.firstname} {client.clientDetails?.lastname}
                      </span>
                      <button
                        className="clinical-note-button"
                        onClick={() => {
                          console.log("clientDetails._id:", client.clientDetails?._id); // Log clientDetails._id
                          handleTherapyPlansView(client.clientDetails?._id); // Use _id for client ID
                        }}
                      >
                        View
                      </button>
                    </div>
                  );
                })
              ) : (
                <p>No therapy plans available</p>
              )}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
export default PendingTherapyPlans;
