import React from "react";
import { Modal } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Close from "../../assets/images/icons/u_times-circle.png";
import CarolinaCompleteHealthPage from "../AuthorizationForm/CarolinaCompleteHealthPage";

const CarolinaCompleteHealthModal: React.FC<{ showModal: boolean; identifierId: any; setShowModal: (value: boolean) => void; isFromMeeting: boolean; insuranceCompanyId: any; isAdmin: boolean; }> = props => {

    function closeCarolinaCompletePlan() {
        props.setShowModal(false);
    }
    
    const isAdmin = props.isAdmin;
    const identifierId = props.identifierId;
    const insuranceCompanyId = props.insuranceCompanyId;

    // Determine the class name based on the source prop
    const modalClassName = props.isFromMeeting === true ? "assesment-modal2" : "assesment-modal";

    return props.showModal ? (
        <>
            <Modal isOpen={props.showModal}
                className={modalClassName}
                centered
                fullscreen
                scrollable
            >
                <div
                    className="modal-head"
                    style={{
                        zIndex: 9998,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px"
                    }}
                >
                    <div className="assessment-header"
                        style={{ margin: "5px"}}>
                        <button
                            type="button"
                            onClick={closeCarolinaCompletePlan}
                            className="bg-transparent-color"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <img src={Close} alt="Close" className="close-icon-d" />
                        </button>
                    </div>
                </div>
                <div
                    className="modal-body scrollbar-container ps--active-y"
                    style={{ backgroundColor: "#fff", borderRadius: "5px" }}>
                    <CarolinaCompleteHealthPage identifierId={identifierId} closePopup={closeCarolinaCompletePlan} insuranceCompanyId={insuranceCompanyId} isAdmin={isAdmin} />
                </div>

                <div className="assessment-footer"
                    style={{ margin: "5px"}}>
                </div>
            </Modal>
        </>
    ) : null;
};

export default CarolinaCompleteHealthModal;