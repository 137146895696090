import React from "react";
import { CardTitle, Modal, CardBody, Row, Col } from "reactstrap";
import './Modal6.scss'
import { AINotesType } from "src/models/Therapist";

const NoteTypeSelectModal: React.FC<{
  showModal: boolean;
  setShowModal: (type: string, showModal: boolean) => void;
}> = props => {

  return props.showModal ? (
    <>
      <Modal isOpen={props.showModal} centered>
        <div className="modal-body">
          <button type="button" onClick={() => props.setShowModal("", false)} style={{fontSize: '12px',width: '20px',height: '20px',padding: '2px',margin: '2px'}} className="close" data-dismiss="modal" aria-label="Close">
            <span>&times;</span>
          </button>
          <CardBody>
            <CardTitle className="mb-4 text-center font-size-20">Select Note Type</CardTitle>
            
            <div className="d-flex justify-content-center flexColumn">
              <button type="button" onClick={() => props.setShowModal(AINotesType.SOAP, false)} className="btn btn-primary mr-20 btnMargin callBtn">
                SOAP Notes
              </button>
              
              <button type="button" onClick={() => props.setShowModal(AINotesType.PIE, false)} className="btn btn-primary btnMargin ml-5 callBtn">
                PIE Notes
              </button>
            </div>
          </CardBody>
        </div>
      </Modal>
    </>
  ) : null;
};

export default NoteTypeSelectModal;

