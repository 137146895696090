import React, { useEffect, useState, useRef, useContext } from 'react';
import OT from '@opentok/client';
import { useHistory } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// import './waitingRoom.css';
import { useParams } from 'react-router-dom';
import UserContext from 'src/context/UserContext';
import TextField from "@mui/material/TextField";
import { VonageNativeSdkService } from 'src/services/VonageNativeSdkService';
import { useLocation } from 'react-router-dom';
import { decodeString } from '../hooks/useJoinCall';
import { toast } from 'react-toastify';
import styles from './waitingRoomCss.module.css';
import CallInitializationContext from "src/context/CallInitializationContext";

interface Device {
  deviceId: string;
  label: string;
  kind: string;
}

interface AudioLevelUpdatedEvent {
  audioLevel: number;
}

interface PublisherCycleVideoResponse {
  deviceId: string;
}

const OpenTokDevicePublisher: React.FC = () => {
  const [audioDevices, setAudioDevices] = useState<Device[]>([]);
  const [videoDevices, setVideoDevices] = useState<Device[]>([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');
  const [isPublishing, setIsPublishing] = useState(false);
  const [audioLevel, setAudioLevel] = useState(0);
  const publisherRef = useRef<any>(null);
  // const publisherDivRef = useRef<HTMLDivElement>(null);
  // const { uniqueId } = useParams<{ uniqueId: string }>();
  const history = useHistory();
  const [loggedUser] = useContext(UserContext);
  const [userName, setUserName] = useState<string>('');
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  // const uniqueId = queryParams.get("uniqueId");
  // const appointmentId = queryParams.get("appointmentId");
  const { uniqueId, isMeetStartedPerson } = useParams<{ uniqueId: string, isMeetStartedPerson: string }>();
  
  useEffect(() => {
  
    const handleUnload = () => {
      removeCallContextFromlocalStorage();
    };
  
    window.addEventListener('unload', handleUnload);
  
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  const removeCallContextFromlocalStorage = () => {
    console.log("XXX");
    localStorage.removeItem('callContext');
  }
  

  const populateDeviceSources = async () => {
    try {
      // First get user media to ensure we have permission and can get device labels
      const stream = await OT.getUserMedia();
      
      // Get devices
      OT.getDevices((err, devices) => {
        if (err) {
          alert('getDevices error ' + err.message);
          return;
        }
        console.log(devices);
        
        if (devices){
          const audioInputs = devices.filter(device => device.kind === 'audioInput');
          const videoInputs = devices.filter(device => device.kind === 'videoInput');
          console.log(audioInputs);
          console.log(videoInputs);
          
          
          setAudioDevices(audioInputs);
          setVideoDevices(videoInputs);

          if (audioInputs.length) setSelectedAudioDevice(audioInputs[0].deviceId);
          if (videoInputs.length) setSelectedVideoDevice(videoInputs[0].deviceId);

          // Stop the tracks after we've gotten the labels
          stream.getTracks().forEach(track => track.stop());
        }
        
      });
    } catch (err: any) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_RIGHT,
        className: "foo-bar",
      });
      console.error('Error getting user media:', err);
    }
  };

  useEffect(() => {
    setUserName(loggedUser?.firstname ? loggedUser?.firstname : '');
    populateDeviceSources();
  }, []);

  const setupAudioLevelMeter = (publisher: any) => {
    let movingAvg: number | null = null;
    
    publisher.on('audioLevelUpdated', (event: AudioLevelUpdatedEvent) => {
      if (movingAvg === null || movingAvg <= event.audioLevel) {
        movingAvg = event.audioLevel;
      } else {
        movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
      }

      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      let logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      setAudioLevel(logLevel);
    });
  };

  const handlePublish = async () => {
    try {
      const storedData = localStorage.getItem('callContext');
      let callContext;
      if (storedData) {
        callContext = JSON.parse(storedData);
      }
      const decodeValue = decodeString(isMeetStartedPerson);
      console.log("isMeetStartedPerson ", isMeetStartedPerson,"decodeValue ",decodeValue);
      console.log(callContext);
      
      if (callContext !== undefined && decodeValue == 'yes'){ 
        if (callContext.isCallInitiator && callContext.isCallInitiator == true){
          const dataForInitiateMeeting =  {
            isAppointmentBased : false,
            isTranscribeAllowed: false,
            callDuration : 60,
            clientId: callContext?.receiverId,
            therapistId: callContext?.callInitiatorId,
            isAudioCall: false,
            vonageSessionName: uniqueId,
            secretKeyForJoinMeeting: callContext?.secretKeyForJoinMeeting,
            middlePartOfLink: 'session/waiting-room'
          }
          const initiateMeetingRes = await VonageNativeSdkService.initializeVonageNativeVideoCall(dataForInitiateMeeting);
          console.log(initiateMeetingRes);
          if(initiateMeetingRes.success){
            history.push(`/session/video-room/${uniqueId}`);
          } else {
            toast.error(initiateMeetingRes.error, {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
        } else {
          const dataForJoinMeeting =  {
            vonageSessionName: uniqueId
          }
          const joinToMeetingRes = await VonageNativeSdkService.joinVonageNativeVideoCall(dataForJoinMeeting)
          console.log(joinToMeetingRes);
          if (joinToMeetingRes.success) {
            history.push(`/session/video-room/${uniqueId}`);
          } else {
            toast.error(joinToMeetingRes.error, {
              position: toast.POSITION.TOP_RIGHT,
              className: "foo-bar",
            });
          }
        }
      } else if (decodeValue == "no"){
        const dataForJoinMeeting =  {
          vonageSessionName: uniqueId
        }
        const joinToMeetingRes = await VonageNativeSdkService.joinVonageNativeVideoCall(dataForJoinMeeting)
        console.log(joinToMeetingRes);
        if (joinToMeetingRes.success) {
          history.push(`/session/video-room/${uniqueId}`);
        } else {
          toast.error(joinToMeetingRes.error, {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("Url is not correct!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "foo-bar",
        });
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const handleAudioSourceChange = async (deviceId: string) => {
    if (!publisherRef.current) return;

    try {
      setSelectedAudioDevice(deviceId);
      await publisherRef.current.setAudioSource(deviceId);
    } catch (err) {
      alert(`setAudioSource failed: ${(err as Error).message}`);
    }
  };

  const handleCycleVideo = async () => {
    if (!publisherRef.current) return;

    try {
      const response: PublisherCycleVideoResponse = await publisherRef.current.cycleVideo();
      setSelectedVideoDevice(response.deviceId);
    } catch (err) {
      alert('cycleVideo error ' + (err as Error).message);
    }
  };

  const onChangeParticipantName = (e: any) => {
    const userNameEvent = e.target.value;
    if (userNameEvent === "" || userNameEvent.trim() === "") {
      setUserName("");
      return;
    }
    setUserName(userNameEvent);
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        
        <div className={styles.formContainer}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="publisher-name"
          label="Name"
          name="name"
          required
          autoComplete="Name"
          helperText={userName === "" ? "Empty Field" : " "}
          value={userName}
          onChange={onChangeParticipantName}
        />
        <h1 className={styles.heading}>Publish Devices</h1>
          <FormControl className={styles.formControl}>
            <InputLabel id="audio-source-label">Select Audio Source</InputLabel>
            <Select
              labelId="audio-source-label"
              id="audio-source"
              value={selectedAudioDevice}
              onChange={(e) => handleAudioSourceChange(e.target.value)}
              disabled={!isPublishing}
              label="Select Audio Source"
            >
              {audioDevices.map((device, index) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Audio Input ${index + 1}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={styles.formControl}>
            <InputLabel id="video-source-label">Select Video Source</InputLabel>
            <Select
              labelId="video-source-label"
              id="video-source"
              value={selectedVideoDevice}
              onChange={(e) => setSelectedVideoDevice(e.target.value)}
              disabled={isPublishing}
              label="Select Video Source"
            >
              {videoDevices.map((device, index) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Video Input ${index + 1}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePublish}
              disabled={!selectedAudioDevice || !selectedVideoDevice}
              className={styles.actionButton}
            >
              Join
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenTokDevicePublisher;