import React from "react";
import { Modal, ModalBody, Button, Row } from "reactstrap";
import ScheduleAppointmentsAdmin from "src/pages/SuperAdmin/AdminStatisticsComponents/ScheduleAppointments";
import Close from "../../../assets/images/icons/close.png";
import { Client } from "src/models/Client";

type ModalComponentProps = {
  isOpen: boolean;
  toggle: () => void;
  setIsOpen: any;
  clientData: Client;
};

const ScheduleAppointmentModal: React.FC<ModalComponentProps> = ({ isOpen, toggle, setIsOpen, clientData }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="modal-xl">
      <div style={{ textAlign: "center", paddingRight: "0px" }}>
        <span></span>
        <Button
          close
          onClick={toggle}
          style={{
            position: "absolute",
            right: "15px",
            background: "none",
            border: "none",
            padding: "0",
            zIndex: "10",
          }}
        >
          <img src={Close} alt="Close" className="me-2 mt-2" />
        </Button>
      </div>
      <ModalBody>
        <Row>
          <span className="font-size-18 profileFont mb-2 cursor-pointer pt-4 text-center">Schedule an appointment</span>
        </Row>
        <Row>
          <ScheduleAppointmentsAdmin clientData={clientData} />
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ScheduleAppointmentModal;
