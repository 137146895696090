import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalHeader, FormGroup, Input, ModalBody, Row, Col, Label } from "reactstrap";
import { SocketContext } from "../../context/ScoketContext";
import UserContext from "../../context/UserContext";
import { ModalType, Repeat } from "../../models/Appointment";
import { Client, PremiumStatus, testSubscriptionStatus } from "../../models/Client";
import { NotificationModel, NotificationEvent, NotificationVarient } from "../../models/Notification";
import { Therapist } from "../../models/Therapist";
import { WorkingHour } from "../../models/WorkingHour";
import { AppointmentService } from "../../services/AppointmentService";
import { ClientService } from "../../services/ClientService";
import { NotificationService } from "../../services/NotificationService";
import { Util } from "../../Util";
import Modal15 from "./Modal15";
import Modal14 from "./Modal14";
import Swal from "sweetalert2";
import { sendNotificationSocketData } from "../../models/sendNotificationCallSocketData";
import DatePicker from "react-date-picker";
import ShowPopupSheduleContext from "src/context/ShowSchedulePopupContext";
import CustomTimePicker from "src/components/CustomTimePicker";

interface AppointmentModalProps {
  clientId?: string;
  appointment: any;
  setAppointment: (value: any) => void;
  modalType: ModalType;
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  errorModalText: string;
  setErrorModalText: (value: string) => void;
  setShowErrorModal: (value: boolean) => void;
  setShowEventTimeValidationViewModal: (value: boolean) => void;
  setShowModalForTherapist: (value: boolean) => void;
  timeValue: string;
  setTimeValue: (value: string) => void;
  updateMatchedClient: (clientId: string) => void;
  hourValue: number;
  setHourValue: (value: any) => void;
  minuteValue: number;
  setMinuteValue: (value: any) => void;
  updateAppointmentObject: (appointment: any) => void;
}

const AppointmentModal: React.FC<AppointmentModalProps> = ({
  clientId,
  appointment,
  setAppointment,
  modalType,
  setShowModal,
  showModal,
  errorModalText,
  setErrorModalText,
  setShowModalForTherapist,
  timeValue,
  setTimeValue,
  updateMatchedClient,
  hourValue,
  setHourValue,
  minuteValue,
  setMinuteValue,
  updateAppointmentObject
}: AppointmentModalProps) => {
  const [slots, setSlots] = useState<string[]>([]);
  const [haveRead, setHaveRead] = useState(false);
  const [haveAgreed, setHaveAgreed] = useState(false);
  const [haveAgreedPolicy, setHaveAgreedPolicy] = useState(false);
  const [workingDaysOfTherapist, setWorkingDaysOfTherapist] = useState<string[]>([]);
  const [clientDetails, setClientDetails] = useState<Client>({} as Client);
  const [therapist, setTherapist] = useState<Therapist>({} as Therapist);
  const [client, setClient] = useState<string>("");
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [disabledCreateBtn, setDisabledCreateBtn] = useState(false);
  const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(false);
  const [appointmentUpdated, setAppointmentUpdated] = useState(false);
  const [appointmentCreated, setAppointmentCreated] = useState(false);
  const [user] = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [showSchedulePopup, setShowSchedulePopup, therapistIdForSchedulePopup, setTherapistIdForSchedulePopup, clientIdForSchedulePopup, setClientIdForSchedulePopup, showScheduleInTherapistChat, setShowScheduleInTherapistChat] = useContext(ShowPopupSheduleContext);
  const LOCALE = "en";

  useEffect(() => {
    if (user?.role == "THERAPIST") {
      setTherapist(user as Therapist);
      setClient(clientId ? clientId : "");
      getWorkingDaysOfWeek(user?.workingHours);
      getClientByClientId(clientId);
    }

    if (user?.role == "CLIENT") {
      setTherapist(appointment.therapist);
      setClient(user?._id ? user._id : "");
      getWorkingDaysOfWeek(appointment.therapist.workingHours);
      getClientByClientId(user?._id ? user._id : undefined);
    }

    createHoursForTimeSlots();
  }, [showModal]);

  const checkAvailableTime = (workingHoursOfTherapist: WorkingHour[] | undefined) => {
    const availableDays: { startTime: any; endTime: any; daysOfWeek: any[] }[] = [];

    workingHoursOfTherapist?.length &&
      workingHoursOfTherapist?.map((obj: any) => {
        const dayAsNumber = dayOfWeekAsNumber(obj.day);

        if (Util.convertUTCDateToLocalDate(obj.endTime) > Util.convertUTCDateToLocalDate(obj.startTime)) {
          return availableDays.push({
            startTime: Util.convertUTCDateToLocalDate(obj.startTime),
            endTime: Util.convertUTCDateToLocalDate(obj.endTime),
            daysOfWeek: [dayAsNumber],
          });
        } else {
          availableDays.push({
            startTime: Util.convertUTCDateToLocalDate(obj.startTime),
            endTime: "24:00 AM",
            daysOfWeek: [dayAsNumber - 1],
          });

          return availableDays.push({
            startTime: "00:00 AM",
            endTime: Util.convertUTCDateToLocalDate(obj.endTime),
            daysOfWeek: [dayAsNumber],
          });
        }
      });

    return availableDays;
  };

  const getAvailableTimeBySelectedDay = (dayNumber: number, isAvailableHours?: any) => {
    const availableHours: string[] = [];

    isAvailableHours
      .filter((obj: any) => obj.daysOfWeek[0] === dayNumber)
      .map((obj: any) => {
        for (let hour = parseInt(obj.startTime); hour <= parseInt(obj.endTime); hour++) {
          if (hour == parseInt(obj.startTime)) {
            const mST = moment(obj.startTime, "HH:mm").minute();

            if (mST == 0) {
              availableHours.push(moment({ hour }).format("H:mm A"));
            }
          } else {
            availableHours.push(moment({ hour }).format("H:mm A"));
          }

          if (hour != parseInt(obj.endTime)) {
            availableHours.push(
              moment({
                hour,
                minute: 30,
              }).format("H:mm A")
            );
          } else {
            const mET = moment(obj.endTime, "HH:mm").minute();

            if (mET == 30) {
              availableHours.push(
                moment({
                  hour,
                  minute: 30,
                }).format("H:mm A")
              );
            }
          }
        }
      });

    return availableHours;
  };

  const getClientByClientId = (clientId: string | undefined) => {
    if (clientId) {
      ClientService.getClientByClientId(clientId).then(res => {
        if (res.success) {
          setClientDetails(res.data);
        } else {
          setClientDetails({} as Client);
        }
      });
    }
  };

  const createHoursForTimeSlots = () => {
    moment.locale(LOCALE);
    for (let hour = 0; hour < 24; hour++) {
      slots.push(moment({ hour }).format("H:mm A"));
      slots.push(
        moment({
          hour,
          minute: 30,
        }).format("H:mm A")
      );
    }

    setSlots(slots);
  };

  const handleChangeComplete = (color: any) => {
    appointment.color = color.hex;
  };

  const getWorkingDaysOfWeek = (workingHours: any) => {
    const workingDays: string[] = [];

    workingHours?.map((session: any) => {
      if (!workingDays.includes(session.day)) workingDays.push(session.day);
    });

    const dup = [...new Set(workingDays)];

    setWorkingDaysOfTherapist(dup);
  };

  const eventModalToggle = () => {
    setShowModal(!showModal);
    if (showModal) {
      setShowScheduleInTherapistChat(false)
    }
  };

  const eventTimeValidationModalToggle = () => {
    setEventTimeValidationViewModal(!isEventTimeValidationViewModal);
    setErrorModalText("");
  };

  const createAppointment = async () => {
    if (user?.role == "THERAPIST") {
      if (clientId) {
        const res1 = await ClientService.getClientByClientId(clientId);

        if (res1.success) {
          const retrivedClient = res1.data;

          if (
            retrivedClient.premiumStatus != "active" &&
            (retrivedClient.subscriptionId == null || retrivedClient.subscriptionStatus != "active") &&
            retrivedClient.testSubscriptionStatus != "active"
          ) {
            return setShowModalForTherapist(true);
          }

          const selectedDate = moment(appointment.selectedDate);

          if (selectedDate.minutes() != 0 && selectedDate.minutes() != 30) {
            setErrorModalText("You have selected invalid time.");

            return setEventTimeValidationViewModal(true);
          }

          AppointmentService.getAllAppointmentAndDetailsByUserId(Util.calculateWeekNumberAndDates(appointment.date, retrivedClient._id!, user._id!)).then(
            res2 => {
              if (res2.success) {
                if (Util.skipCheckingForPremiumUsers(retrivedClient)) {
                  if (res2.data.sessionTimeOfWeek != 0 && res2.data.sessionTimeOfWeek != 60) {
                    setErrorModalText(`Sorry! Client's weekly session time has exceeded.`);

                    return setEventTimeValidationViewModal(true);
                  }

                  if (res2.data.sessionTimeOfWeek != 0 && res2.data.sessionTimeOfWeek == 60) {
                    setErrorModalText(`Sorry! Client's weekly session time has exceeded.`);

                    return setEventTimeValidationViewModal(true);
                  }
                }

                if (!appointment.startTime) {
                  return toast.error("Please select valid start time.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                  });
                }

                const mST = moment(appointment.startTime, "HH:mm").minute();

                if (mST != 0 && mST != 30) {
                  return toast.error("Please select valid start time.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                  });
                }

                if (!appointment.date) {
                  return toast.error("Please select valid date.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                  });
                }

                const dayNumber = dayOfWeekAsNumber(moment(appointment.date).format("dddd"));

                const isAvailableHours = checkAvailableTime(therapist.workingHours);

                const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, isAvailableHours);

                if (!hoursSlots.includes(appointment.startTime) || !hoursSlots.includes(appointment.endTime)) {
                  Swal.fire({
                    icon: "warning",
                    title: "Your working hours are not within selected time. Do you still want to proceed?",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#50a5f1",
                    cancelButtonText: "No",
                    cancelButtonColor: "#f46a6a",
                  }).then(result => {
                    if (result.isConfirmed) {
                      addAppointmentByTherapist(user, retrivedClient, true);
                    }
                  });
                } else {
                  addAppointmentByTherapist(user, retrivedClient, false);
                }
              } else {
                setShowModal(!showModal);
                setErrorModalText(`No appointments found for this Therapist.`);
              }
            }
          );
        } else {
          return toast.error("Invalid client id.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        return toast.error(`Invalid client id.`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } else {
      return toast.error(`You can't create appointements from here. Please go to Therapist calendar.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const addAppointmentByTherapist = (userObj: any, retrivedClient: any, holidayEvent: boolean) => {
    if (
      !moment(new Date()).isBefore(
        moment(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
      )
    ) {
      return toast.error(`Sorry! You can't create appointment in a past date!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!appointment.title) {
      return toast.error(`Please add title.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!appointment.repeat) {
      return toast.error(`Please select repeat type.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveRead && userObj.disclosureStatementId?.url) {
      return toast.error(`You must read disclosure statement & accept before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreed) {
      return toast.error(`You must agree to the terms & conditions of Lavni  before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreedPolicy) {
      return toast.error(`You must agree to the No Show and Cancellation Policy of Lavni Inc. before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }


    setDisabledCreateBtn(true);

    const appointmentObj = {
      therapistId: userObj._id,
      clientId: retrivedClient._id,
      start: new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0)),
      end: new Date(new Date(appointment.date).setHours(parseInt(appointment.endTime.split(":")[0]), parseInt(appointment.endTime.split(":")[1]), 0, 0)),
      reminders: [30],
      title: appointment.title,
      repeatInfo: {
        repeatType: appointment.repeat,
        interval: "",
        repeatDays: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
        endingDate: Util.monthsNextFrom(new Date(appointment.date), 1),
        endingAfter: 10,
        endingType: "",
      },
      color: appointment.color,
      groupId:
        user?._id +
        "_" +
        Util.dateConvertToMilisecond(
          new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
        ),
      eventOnHolidays: holidayEvent,
    };

    AppointmentService.createAppointmentByTherapist(appointmentObj).then(res => {
      if (res.success) {
        const appointmentNotification: NotificationModel = {
          senderId: therapist?._id,
          receiverId: client,
          event: NotificationEvent.APPOINMENT_CREATED,
          link: "/appointments",
          content: "Appointment is created by " + therapist?.firstname + " " + therapist?.lastname,
          variant: NotificationVarient.INFO,
        };

        NotificationService.sendNotification(appointmentNotification).then(res => {
          const socketData: sendNotificationSocketData = {
            socketId: res.data.socketId,
            notifyData: appointmentNotification,
            senderId: therapist?._id,
            receiverId: client,
          };

          socket.emit("send-notification", socketData);
        });

        setShowModal(!showModal);
        setAppointmentCreated(true);
        setDisabledCreateBtn(false);
        setTimeValue("");
        updateMatchedClient(client);
      } else {
        setErrorModalText(res.error as string);
        setDisabledCreateBtn(false);

        return setEventTimeValidationViewModal(true);
      }
    });
  };

  const updateAppointment = async () => {
    if (user?.role == "CLIENT") {
      if (
        user &&
        ((user.subscriptionId != null && user.subscriptionStatus == "active") ||
          user.premiumStatus == PremiumStatus.ACTIVE ||
          user.testSubscriptionStatus == testSubscriptionStatus.ACTIVE)
      ) {
        const selectedDate = moment(appointment.selectedDate);

        if (selectedDate.minutes() != 0 && selectedDate.minutes() != 30) {
          setErrorModalText("You have selected invalid time.");

          return setEventTimeValidationViewModal(true);
        }

        if (appointment.therapist.blockedDates) {
          const isInRange = appointment.therapist.blockedDates.some((range: any) => {
            return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate.toISOString()));
          });

          if (isInRange) {
            setErrorModalText("Sorry! Therapist `" + therapist.firstname + " " + therapist.lastname + "` has blocked this date.");

            return setEventTimeValidationViewModal(true);
          }
        }

        AppointmentService.getAllAppointmentAndDetailsByUserId(Util.calculateWeekNumberAndDates(appointment.date, appointment.client._id, appointment.therapist._id)).then(res => {
          if (res.success) {
            if (Util.skipCheckingForPremiumUsers(user)) {
              if (res.data.sessionTimeOfWeek != 0 && res.data.sessionTimeOfWeek != 60) {
                setErrorModalText(`Sorry! Your weekly session time has exceeded.`);

                return setEventTimeValidationViewModal(true);
              }

              if (res.data.sessionTimeOfWeek != 0) {
                const meetingIsOnCurrentSelectedWeek = res.data.allSessionOfWeek.filter(obj => obj._id === appointment.id);

                if (res.data.sessionTimeOfWeek == 60) {
                  if (meetingIsOnCurrentSelectedWeek.length == 0) {
                    setErrorModalText(`Sorry! Your weekly session time has exceeded.`);

                    return setEventTimeValidationViewModal(true);
                  } else {
                    if (res.data.allSessionOfWeek.length > 1) {
                      setErrorModalText(`Sorry! Your weekly session time has exceeded.`);

                      return setEventTimeValidationViewModal(true);
                    }
                  }
                }
              }
            }

            if (!appointment.startTime) {
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            const mST = moment(appointment.startTime, "HH:mm").minute();

            if (mST != 0 && mST != 30) {
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!appointment.date) {
              return toast.error("Please select valid date.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (
              !moment(new Date()).isBefore(
                moment(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
              )
            ) {
              appointment.date = new Date();
              // return toast.error(`Sorry! You can't create appointment in a past date!`, {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   className: "foo-bar",
              // });
            }

            const dayNumber = dayOfWeekAsNumber(moment(appointment.date).format("dddd"));

            const isAvailableHours = checkAvailableTime(therapist.workingHours);

            const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, isAvailableHours);

            if (therapist.workingHours == null || !therapist.workingHours || therapist.workingHours.length == 0) {
              updateOpenedAppointment(true);
            } else {
              if (!hoursSlots.includes(appointment.startTime) || !hoursSlots.includes(appointment.endTime)) {
                Swal.fire({
                  icon: "error",
                  title:
                    therapist.firstname +
                    " " +
                    therapist.lastname +
                    " is not available during this time.",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                  confirmButtonColor: "#f46a6a",
                });
              } else {
                updateOpenedAppointment(false);
              }
            }
          } else {
            setErrorModalText(`No appointments found for this Therapist.`);
          }
        });
      } else {
        setShowModalForTherapist(true);
      }
    } else if (user?.role == "THERAPIST") {
      const res1 = await ClientService.getClientByClientId(appointment.client._id);

      if (res1.success) {
        const client = res1.data;

        if (
          client.premiumStatus != "active" &&
          (client.subscriptionId == null || client.subscriptionStatus != "active") &&
          client.testSubscriptionStatus != "active"
        ) {
          return setShowModalForTherapist(true);
        }

        const selectedDate = moment(appointment.selectedDate);

        if (selectedDate.minutes() != 0 && selectedDate.minutes() != 30) {
          setErrorModalText("You have selected invalid time.");

          return setEventTimeValidationViewModal(true);
        }

        AppointmentService.getAllAppointmentAndDetailsByUserId(
          Util.calculateWeekNumberAndDates(appointment.date, appointment.client._id, appointment.therapist._id)
        ).then(res2 => {
          if (res2.success) {
            if (Util.skipCheckingForPremiumUsers(client)) {
              if (res2.data.sessionTimeOfWeek != 0 && res2.data.sessionTimeOfWeek != 60) {
                setErrorModalText(`Sorry! Client's weekly session time has exceeded.`);

                return setEventTimeValidationViewModal(true);
              }

              if (res2.data.sessionTimeOfWeek != 0) {
                const meetingIsOnCurrentSelectedWeek = res2.data.allSessionOfWeek.filter(obj => obj._id === appointment.id);

                if (res2.data.sessionTimeOfWeek == 60) {
                  if (meetingIsOnCurrentSelectedWeek.length == 0) {
                    setErrorModalText(`Sorry! Client's weekly session time has exceeded.`);

                    return setEventTimeValidationViewModal(true);
                  } else {
                    if (res2.data.allSessionOfWeek.length > 1) {
                      setErrorModalText(`Sorry! Client's weekly session time has exceeded.`);

                      return setEventTimeValidationViewModal(true);
                    }
                  }
                }
              }
            }

            if (!appointment.startTime) {
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            const mST = moment(appointment.startTime, "HH:mm").minute();

            if (mST != 0 && mST != 30) {
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!appointment.date) {
              return toast.error("Please select valid date.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (
              !moment(new Date()).isBefore(
                moment(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
              )
            ) {
              appointment.date = new Date();
              // return toast.error(`Sorry! You can't create appointment in a past date!`, {
              //   position: toast.POSITION.BOTTOM_RIGHT,
              //   className: "foo-bar",
              // });
            }

            const dayNumber = dayOfWeekAsNumber(moment(appointment.date).format("dddd"));

            const isAvailableHours = checkAvailableTime(therapist.workingHours);

            const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, isAvailableHours);

            if (!hoursSlots.includes(appointment.startTime) || !hoursSlots.includes(appointment.endTime)) {
              Swal.fire({
                icon: "warning",
                title: "Your working hours are not within selected time. Do you still want to proceed?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: "#50a5f1",
                cancelButtonText: "No",
                cancelButtonColor: "#f46a6a",
              }).then(result => {
                if (result.isConfirmed) {
                  updateOpenedAppointment(true);
                }
              });
            } else {
              updateOpenedAppointment(false);
            }
          } else {
            setErrorModalText(`No appointments found for this Therapist.`);
          }
        });
      } else {
        return toast.error("Invalid client id.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } else {
      return toast.error("Invalid user role.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const updateOpenedAppointment = (eventOnHolidays: boolean) => {
    if (!appointment.title) {
      return toast.error(`Please add title.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!appointment.repeat) {
      return toast.error(`Please select repeat type.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveRead && appointment.therapist.disclosureStatementId?.url) {
      return toast.error(`You must read disclosure statement & accept before updating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreed) {
      return toast.error(`You must agree to the terms & conditions of Lavni  before updating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreedPolicy) {
      return toast.error(`You must agree to the No Show and Cancellation Policy of Lavni Inc. before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }


    setDisabledUpdateBtn(true);

    const obj = {
      appointmentId: appointment.id,
      therapistId: appointment.therapist._id,
      clientId: appointment.client._id,
      start: new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0)),
      end: new Date(new Date(appointment.date).setHours(parseInt(appointment.endTime.split(":")[0]), parseInt(appointment.endTime.split(":")[1]), 0, 0)),
      reminders: [30],
      title: appointment.title,
      repeatInfo: {
        repeatType: appointment.repeat,
        interval: "",
        repeatDays: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
        endingDate: Util.monthsNextFrom(new Date(appointment.date), 1),
        endingAfter: 10,
        endingType: "",
      },
      color: appointment.color,
      groupId: appointment.groupId,
      eventOnHolidays: eventOnHolidays,
    };

    AppointmentService.updateAppointment(obj).then(res => {
      if (res.success) {
        setAppointmentUpdated(true);
        setShowModal(false);

        const appointmentNotification: NotificationModel = {
          senderId: user?._id,
          receiverId: appointment.therapist._id,
          event: NotificationEvent.APPOINMENT_UPDATED,
          link: "/appointments",
          content: "Appointment is updated by " + user?.firstname + " " + user?.lastname,
          variant: NotificationVarient.INFO,
        };

        NotificationService.sendNotification(appointmentNotification).then(res => {
          const socketData: sendNotificationSocketData = {
            socketId: res.data.socketId,
            notifyData: appointmentNotification,
            senderId: user?._id,
            receiverId: appointment.therapist._id,
          };

          socket.emit("send-notification", socketData);
        });

        setDisabledUpdateBtn(false);
        updateAppointmentObject(obj);
      } else {
        setDisabledUpdateBtn(false);
        setErrorModalText(res.error as string);
        return setEventTimeValidationViewModal(true);
      }
    });
  };

  const dayOfWeekAsNumber = (day: string) => {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
  };

  return (
    <React.Fragment>
      <Modal isOpen={showModal} centered toggle={eventModalToggle} unmountOnClose={true}>
        <ModalHeader toggle={eventModalToggle}>
          <div className="title-input">
            <FormGroup>
              <Input
                className="modal-title new-input-v"
                name="title"
                type="text"
                placeholder="Add title - Optional"
                onChange={e => setAppointment({ ...appointment, title: e.target.value })}
                value={appointment.title}
              />
              <span className="awsome_input_border" />
            </FormGroup>
          </div>
        </ModalHeader>

        <ModalBody className="awsome-area">
          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className="bx bx-time-five"></i>
            </Col>
            <Col lg={11} md={11} sm={11} xs={12}>
              <Row>
                <Col lg={4} md={4} sm={4} xs={6} className="sm-mb5">
                  <DatePicker
                    minDate={moment().toDate()}
                    value={moment(appointment?.date).toDate()}
                    onChange={(date: Date) => {
                      const selectedTime = moment(date);

                      selectedTime.hour(hourValue).minute(minuteValue);

                      const stateT = moment(selectedTime).format("H:mm A");
                      const endT = moment(selectedTime).add(60, "minutes").format("H:mm A");
                      const sDate = moment(selectedTime).toISOString();

                      setAppointment({ ...appointment, startTime: stateT, endTime: endT, selectedDate: sDate, date: selectedTime.format("YYYY/MM/DD") });
                    }}
                  />
                </Col>

                <Col lg={8} md={8} sm={8} xs={6}>
                  <CustomTimePicker timeValue={timeValue} setTimeValue={setTimeValue} appointment={appointment} setAppointment={setAppointment} setHourValue={setHourValue} setMinuteValue={setMinuteValue} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className="bx bx-video"></i>
            </Col>
            <Col lg={11} md={11} sm={11} xs={12}>
              Video - 1 Hour Session
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className={Util.meetingRepeatTypeIcon(appointment.repeat)}></i>
            </Col>
            <Col lg={11} md={11} sm={11} xs={12}>
              {
                modalType == ModalType.CREATE ? <Input
                  type="select"
                  name="repeat"
                  onChange={e => setAppointment({ ...appointment, repeat: e.target.value as Repeat })}
                  value={appointment.repeat}
                >
                  <option defaultChecked value={Repeat.DOES_NOT_REPEAT}>
                    Does Not Repeat
                  </option>
                  <option value={Repeat.WEEKLY}>Weekly</option>
                  <option value={Repeat.BI_WEEKLY}>Bi Weekly</option>
                </Input> : <>
                  <div>{appointment.repeat == Repeat.DOES_NOT_REPEAT && <span className="does-not-repeat">Does Not Repeat</span>}</div>
                  <div>{appointment.repeat == Repeat.WEEKLY && <span className="weekly">Weekly</span>}</div>
                  <div>{appointment.repeat == Repeat.BI_WEEKLY && <span className="bi-weekly">Bi Weekly</span>}</div>
                </>
              }
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className="bx bx-palette"></i>
            </Col>
            <Col lg={11} md={11} sm={11} xs={12}>
              <TwitterPicker width="100%" onChangeComplete={handleChangeComplete} color={appointment.color} />
            </Col>
          </Row>

          {user?.role == "CLIENT" && appointment.therapist.disclosureStatementId?.url && (
            <Row className="mb-4">
              <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
              <Col className="" lg={11} md={11} sm={11} xs={12}>
                <FormGroup check inline>
                  <Input type="checkbox" onChange={(e: any) => setHaveRead(e.target.checked)} />
                  <Label check>
                    I have read the&nbsp;
                    <Link to={{ pathname: Util.fileURL(appointment.therapist.disclosureStatementId?._id) }} target="_blank">
                      disclosure statement&nbsp;
                    </Link>
                    & Accept.
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          )}

          {user?.role == "THERAPIST" && user?.disclosureStatementId?.url && (
            <Row className="mb-4">
              <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
              <Col className="" lg={11} md={11} sm={11} xs={12}>
                <FormGroup check inline>
                  <Input type="checkbox" onChange={(e: any) => setHaveRead(e.target.checked)} />
                  <Label check>
                    I have read the&nbsp;
                    <Link to={{ pathname: Util.fileURL(appointment.therapist.disclosureStatementId?._id) }} target="_blank">
                      disclosure statement&nbsp;
                    </Link>
                    & Accept.
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
            <Col className="" lg={11} md={11} sm={11} xs={12}>
              <FormGroup check inline>
                <Input type="checkbox" onChange={(e: any) => setHaveAgreedPolicy(e.target.checked)} />
                <Label check>
                  I agree to&nbsp;
                  <a href="/noshow-policy" className="hover" target="_blank">
                    No Show and Cancellation Policy&nbsp;
                  </a>
                  of Lavni Inc.
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
            <Col className="" lg={11} md={11} sm={11} xs={12}>
              <FormGroup check inline>
                <Input type="checkbox" onChange={(e: any) => setHaveAgreed(e.target.checked)} />
                <Label check>
                  I agree to&nbsp;
                  <a href="/consent-document" className="hover" target="_blank">
                    Consent Document&nbsp;
                  </a>
                  of Lavni Inc.
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col className="cont-center" lg={1}></Col>
            <Col className="appoint-btn">
              {modalType == ModalType.CREATE ? (
                <button className={disabledCreateBtn ? "session-btn updating" : "session-btn"} disabled={disabledCreateBtn} onClick={createAppointment}>
                  Create
                </button>
              ) : (
                <button className={disabledUpdateBtn ? "session-btn updating" : "session-btn"} disabled={disabledUpdateBtn} onClick={updateAppointment}>
                  Update
                </button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal isOpen={isEventTimeValidationViewModal} centered toggle={eventTimeValidationModalToggle}>
        <ModalBody>
          <div className="modal-val">
            <h5 className="model-error">{errorModalText}</h5>

            <button className="btn btn-sm text-center btn-success mt-3" onClick={() => eventTimeValidationModalToggle()}>
              Got It
            </button>
          </div>
        </ModalBody>
      </Modal>

      {appointmentUpdated && <Modal15 setShowModal={setAppointmentUpdated} showModal={appointmentUpdated} />}
      {appointmentCreated && <Modal14 setShowModal={setAppointmentCreated} showModal={appointmentCreated} />}
    </React.Fragment>
  );
};

export default AppointmentModal;
