import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/css/onboarding.css";
import "../../../assets/css/publicMedia.css";
import { TherapistService } from "src/services/TherapistService";
import HeaderMain from "../../../common/main/PublicShareHeader";
import FooterMain from "../../../common/main/FooterMain";
import share_cover from "../../../assets/images/icons/share-cover-img.png";
import { Therapist } from "src/models/Therapist";

const PublicTherapistShare: React.FC = () => {
    const [userId,setUserId] = useState('');
    const [userData,setUserData] = useState<Therapist>();
    const [isReferralValid, setIsReferralValid] = useState(true);
    useEffect(() => {
        const splittedURL = window.location.pathname.split("/");
        const userId = splittedURL[splittedURL.length - 1];
        setUserId(userId)
        if(userId){
            getTherapistData(userId);
        }
      }, []);

      const getTherapistData = (id: any) => {
        TherapistService.getTherapistDetailsbyIdPublic(id).then(res => {
            const data = res.data;
            setUserData(data);

            if (data && data.role === "THERAPIST") {
              localStorage.setItem('referralCode', id);
            } else {
              setIsReferralValid(false);
              toast.error("The referral link you provided is invalid. Please verify the link and try again.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

        });
    };

  if (!isReferralValid) {
    return (
      <div>
        <div className="content-wrapper">
          <HeaderMain />
          <section className="mt-12 mt-md-12 mb-4 mb-md-12">
            <div className="container pt-4 pt-md-12">
              <div className="row justify-content-center">
                <div className="col-lg-7 text-center">
                  <h3 className="text-danger" style={{ marginTop: '180px', marginBottom: '180px' }}>
                    The referral link you provided is invalid. Please verify the link and try again.
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <FooterMain />
        </div>
      </div>
    );
  }
    
  return (
    <div>
      <div className="content-wrapper ">
        <HeaderMain />

        <section className="mt-12 mt-md-12 mb-4 mb-md-12">
          <div className="container pt-4 pt-md-12">
            <div className="row justify-content-center">
              <div className="col-lg-7 ">
                <p className="share-page-text text-black-referral">
                  I trust this message finds you thriving. I&apos;m thrilled to share an exclusive invitation from&nbsp;
                  <span className="text-orange-referral">{userData?.firstname}&nbsp;{userData?.lastname}</span> to join Lavni, an advanced software platform crafted to revolutionize the management of
                  therapy practices.
                </p>
                <p className="share-page-text text-black-referral">
                  Lavni stands out for its comprehensive support in handling every aspect of your practice, from streamlined credentialing to simplified claims
                  management. A standout feature is Lavni&apos;s AI-notes system, enabling therapists like you to save an astounding 90 minutes per day with its
                  intuitive note-taking capabilities.
                </p>
              </div>
              <div className="col-lg-5  d-flex justify-center">
                <img src={share_cover} className="share-cover-img" />
              </div>
            </div>
          </div>
        </section>
        <section className="mt-4 mt-md-12 mb-4 mb-md-12">
          <div className="container pt-4 pt-md-12 pb-4 pb-md-12  ps-md-5 pe-md-5 bg-share-special-offer d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-black-referral ps-md-5 pe-md-5 ">Session Completion Bonus</h1>
            <p className="share-page-text text-black-referral text-center ps-md-5 pe-md-5 ms-md-5 me-md-5">
              upon signing up, completing the credentialing process, and finishes four sessions, you&apos;ll receive a <span className="fs-32">$50</span> cash bonus. But wait,
              there&apos;s more! Once you&apos;ve conducted 20 sessions using Lavni, you&apos;ll earn another <span className="fs-32">$50</span> cash bonus.
            </p>
          </div>
        </section>
        <section className="mt-4 mt-md-12 mb-4 mb-md-12">
          <div className="container pt-4 pt-md-12 pb-4 pb-md-12 d-flex justify-content-center align-items-center flex-column">
            <p className="share-page-text text-black-referral text-center ">
              That&apos;s not all – here&apos;s an opportunity to further boost your earnings. When you refer another therapist to Lavni, and then they finishes four sessions, you could earn an
              additional $50. Once your referred therapist signs up, completes credentialing, and finishes four sessions, you get $50 and then $50 once they complete 20 sessions on the platform
              <br />
              Seize this chance to optimize your practice, earn bonuses, and extend these benefits to your colleagues in the field.
            </p>
          </div>
        </section>
        <section className="mt-4 mt-md-12 mb-12 mb-md-12">
          <div className="container pt-4 pt-md-12 pb-4 pb-md-12  ps-md-5 pe-md-5 bg-start-journey ">
            <p className="text-black-referral text-start share-page-text">To embark on this transformative journey with Lavni,</p>
            <div className="d-flex align-items-center justify-content-center">
              <a href="/signUp" className="btn btn-primary btn-start-journey mt-3 mb-3">Get Started 
              </a>
            </div>
            <p className="share-page-text21 text-black-referral text-center ps-md-5 pe-md-5 ms-md-5 me-md-5">
            Elevate your practice management with Lavni&apos;s support and unlock these exclusive rewards today!
            </p>
          </div>
        </section>
        <FooterMain />
      </div>
    </div>
  );
};

export default PublicTherapistShare;
