import React, { useContext, useState } from "react";
import SimpleBar from "simplebar-react";
import NotificationContext from "../../../context/NotificationContext";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import { NotificationEvent, NotificationModel, NotificationVarient } from "../../../models/Notification";
import { NotificationService } from "../../../services/NotificationService";
import moment from "moment";
import { environment } from "../../../environment/environment";
import BellIcon from "../../../assets/images/icons/fi_bell.svg";
import { Role } from "src/models/Role";
import UserContext from "src/context/UserContext";

const NotificationDropdown: React.FC = () => {
  const [menu, setMenu] = useState(false);
  const [
    notificationDeatils,
    setNotificationDeatils,
    selectedClientsIdForChat,
    setSelectedClientsIdForChat,
    selectedClientsDetailsForChat,
    setSelectedClientsDetailsForChat,
  ] = useContext(NotificationContext);
  const history = useHistory();
  const [user] = useContext(UserContext);

  const markAsRead = () => {
    NotificationService.markAllNotificationsRead().then(res => {
      setNotificationDeatils({ notifications: notificationDeatils!.notifications, unreadCount: 0 });
    });
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} onClick={markAsRead} className="dropdown d-inline-block" tag="li">
        <DropdownToggle className="btn header-item noti-icon pl-pr-noti-icon " tag="button" id="page-header-notifications-dropdown">
          <div className="notify-container d-flex justify-content-center align-items-center">
            {/* <i className="bx bx-bell bx-tada" /> */}
            <img src={BellIcon} />

            {notificationDeatils && notificationDeatils.unreadCount > 0 && (
              <span className="badge bg-danger rounded-pill notify-dot d-flex justify-content-center align-items-center">
                {notificationDeatils?.unreadCount}
              </span>
            )}
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Notifications</h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notificationDeatils && notificationDeatils.notifications && notificationDeatils.notifications?.length > 0 ? (
              <>
                {notificationDeatils.notifications &&
                  notificationDeatils.notifications.map((n: NotificationModel) => (
                    <div
                      onClick={() => {
                        try {
                          if (n.event && n.event == NotificationEvent.NEW_TWILIO_MESSAGE_FROM_CLIENT) {
                            if (n.senderId && n.content && selectedClientsIdForChat != null && selectedClientsIdForChat != n.senderId) {
                              if (user && user?.role && (user?.role == Role.SUPER_ADMIN || user?.role == Role.SUB_ADMIN)) {
                                const incomingContent = n.content;
                                const contentToRemove = "New message received from";
                                const newContent: string = incomingContent.replace(contentToRemove, "").trim();
                                setSelectedClientsDetailsForChat(newContent);
                                setSelectedClientsIdForChat(n.senderId);
                              }
                            }
                          } else {
                            const items = n.link.split(environment.app_url);

                            if (items.length > 1) {
                              history.push(n.link.split(environment.app_url)[1] + "#" + n._id);
                            } else {
                              history.push(n.link + "#" + n._id);
                            }
                          }

                          setMenu(!menu);
                        } catch (error) {}
                      }}
                      className="text-reset notification-item"
                      key={n._id}
                    >
                      <div className="d-flex">
                        <div className="avatar-xs me-3">
                          <span
                            className={
                              "avatar-title notify-bg-" + (n.variant ? n.variant : "bg-primary") + " rounded-circle font-size-16 avatar-title-notification"
                            }
                          >
                            {(n.variant == NotificationVarient.INFO && <i className="fa fa-info-circle"></i>) ||
                              (n.variant == NotificationVarient.SUCCESS && <i className="fa fa-check-circle"></i>) ||
                              (n.variant == NotificationVarient.WARNING && <i className="fa fa-exclamation-circle"></i>) ||
                              (n.variant == NotificationVarient.ERROR && <i className="fa fa-times-circle"></i>)}
                          </span>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1">{n.content}</h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />
                              &nbsp; {moment(n.createdAt?.toString()).fromNow()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <div className="text-reset notification-item">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">No New Notifications.</h6>
                  </div>
                </div>
              </div>
            )}
          </SimpleBar>

          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/notifications" onClick={() => setMenu(!menu)}>
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View All</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
