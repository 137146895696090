import React, { useContext, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Calendar from "react-calendar";
import { Card, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Appointment, Repeat } from "../../models/Appointment";
import { AppointmentService } from "../../services/AppointmentService";
import moment from "moment";
import { BlockedDate, Therapist } from "../../models/Therapist";
import { TherapistService } from "../../services/TherapistService";
import user1 from "../../assets/images/default_profile.png";
import profileBackground from "../../assets/images/default_cover.png";
import { TwitterPicker } from "react-color";
import UserContext from "../../context/UserContext";
import { Role } from "../../models/Role";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { ParameterTypes } from "../../utils/ParameterTypes";
import { WorkingHour } from "../../models/WorkingHour";
import { NotificationModel, NotificationEvent, NotificationVarient } from "../../models/Notification";
import { NotificationService } from "../../services/NotificationService";
import { SocketContext } from "../../context/ScoketContext";
import MessageModal from "../Popup/MessageModal";
import Swal from "sweetalert2";
import images from "../../assets/images";
import { Client, PremiumStatus, testSubscriptionStatus } from "../../models/Client";
import { Util } from "../../Util";
import Modal4 from "../Popup/Modal4";
import Modal5 from "../Popup/Modal5";
import Modal11 from "../Popup/Modal11";
import Modal12 from "../Popup/Modal12";
import Modal13 from "../Popup/Modal13";
import Modal14 from "../Popup/Modal14";
import { ClientService } from "../../services/ClientService";
import Modal15 from "../Popup/Modal15";
import Modal17 from "../Popup/Modal17";
import Modal18 from "../Popup/Modal18";
import Modal22 from "../Popup/Modal22";
import { sendNotificationSocketData } from "../../models/sendNotificationCallSocketData";
import DatePicker from "react-date-picker";
import CustomTimePicker from "src/components/CustomTimePicker";
import momentTimezone from "moment-timezone";

const AppointmentCalendar: React.FC = () => {
  const [isFriend, setIsFriend] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const { userId } = useParams<ParameterTypes>();
  const [user] = useContext(UserContext);
  const calendarComponentRef: any = React.createRef();
  const [dateValue, onChangeDateValue] = useState(new Date());
  const [isEventModal, setIsEventModal] = useState(false);
  const [isEventViewModal, setIsEventViewModal] = useState(false);
  const [haveRead, setHaveRead] = useState(false);
  const [haveAgreed, setHaveAgreed] = useState(false);
  const [haveAgreedPolicy, setHaveAgreedPolicy] = useState(false);
  const [isEventTimeValidationViewModal, setEventTimeValidationViewModal] = useState(false);
  const [appointments, setAppointments] = useState([] as any[]);
  const [blockDatesList, setBlockDatesList] = useState([] as any[]);
  const [isView, setIsView] = useState<boolean>(true);
  const [timeValue, setTimeValue] = React.useState("");
  const [hourValue, setHourValue] = React.useState(0 as number);
  const [minuteValue, setMinuteValue] = React.useState(0 as number);
  const socket = useContext(SocketContext);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [workingDaysOfTherapist, setWorkingDaysOfTherapist] = useState<string[]>([]);
  const [therapist, setTherapist] = useState({} as Therapist);
  const [appointmentDetails, setAppointmentDetails] = useState<Appointment>();
  const [timeList, setTimeList] = useState<string[]>([]);
  const [therapistAvailableHours, setTherapistAvailableHours] = useState([] as any);
  const [disabledCreateBtn, setDisabledCreateBtn] = useState(false);
  const [disabledUpdateBtn, setDisabledUpdateBtn] = useState(false);
  const [showAlreadyCompleted, setShowAlreadyCompleted] = useState(false);
  const [showTooEarly, setShowTooEarly] = useState(false);
  const [appointmentCreated, setAppointmentCreated] = useState(false);
  const [appointmentUpdated, setAppointmentUpdated] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const [showModalExceeded, setShowModalExceeded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [singleAppointment, setSingleAppointment] = useState({
    id: "",
    title: "Lavni Therapy session",
    startTime: "",
    endTime: "",
    date: "",
    repeat: Repeat.DOES_NOT_REPEAT,
    color: "#FF6900",
    groupId: "",
    therapist: {} as Therapist,
    client: {} as Client,
    selectedDate: ""
  });

  const [eventOnHolidays, setEventOnHolidays] = useState<boolean>(false);

  const locale = "en";
  const hours: string[] = [];

  useEffect(() => {
    ClientService.checkIfUserIsFriend(userId).then(res => {
      if (res.success) {
        setIsFriend(true);

        TherapistService.getTherapistDetailsbyId(userId).then(res => {
          if (res.success) {
            setIsLoading(false);
            setTherapist(res.data);
            checkAvailableTime(res.data?.workingHours);
            getWorkingDaysOfWeek(res.data?.workingHours);

            const blockedSlots = res.data?.blockedDates || [];

            setBlockDatesList(blockedSlots);

            getAppointmentsByUser(blockedSlots);
          }
        });

        createHoursForTimeSlots();
      } else {
        setIsFriend(false);
        setIsLoading(false);
      }
    });
  }, []);

  const getAppointmentsByUser = (blockedSlots?: BlockedDate[]) => {
    switch (user?.role) {
      case Role.CLIENT:
        getAllAppointmentsBySelectedTherapistId(blockedSlots);
        break;
      case Role.THERAPIST:
        getAllAppointmentsByTherapist(blockedSlots);
        break;
      default:
        break;
    }
  };

  const getAvailableTimeBySelectedDay = (dayNumber: number, isAvailableHours?: any) => {
    const availableHours: string[] = [];

    isAvailableHours
      .filter((obj: any) => obj.daysOfWeek[0] === dayNumber)
      .map((obj: any) => {
        for (let hour = parseInt(obj.startTime); hour <= parseInt(obj.endTime); hour++) {
          if (hour == parseInt(obj.startTime)) {
            const mST = moment(obj.startTime, "HH:mm").minute();

            if (mST == 0) {
              availableHours.push(moment({ hour }).format("H:mm A"));
            }
          } else {
            availableHours.push(moment({ hour }).format("H:mm A"));
          }

          if (hour != parseInt(obj.endTime)) {
            availableHours.push(
              moment({
                hour,
                minute: 30,
              }).format("H:mm A")
            );
          } else {
            const mET = moment(obj.endTime, "HH:mm").minute();

            if (mET == 30) {
              availableHours.push(
                moment({
                  hour,
                  minute: 30,
                }).format("H:mm A")
              );
            }
          }
        }
      });

    return availableHours;
  };

  const createHoursForTimeSlots = () => {
    moment.locale(locale);

    for (let hour = 0; hour < 24; hour++) {
      hours.push(moment({ hour }).format("H:mm A"));
      hours.push(
        moment({
          hour,
          minute: 30,
        }).format("H:mm A")
      );
    }
    setTimeList(hours);
  };

  const getAllAppointmentsBySelectedTherapistId = (blockedSlots?: BlockedDate[]) => {
    AppointmentService.getAllAppointmentByTherapistId(userId).then(res => {
      const appointmentList = res.data;

      appointmentList.map((item: any) => {
        if (item.clientId?._id !== user?._id) {
          item.color = "#665a5a";
        }
      });

      const updatedAppointmentList = appointmentList.map(appointment => {
        return { ...appointment, start: moment(appointment.start).toDate(), end: moment(appointment.end).toDate() };
      });

      if (blockedSlots) {
        setAppointments([...updatedAppointmentList, ...blockedSlots]);
      } else {
        setAppointments([...updatedAppointmentList, ...blockDatesList]);
      }
    });
  };

  const getAllAppointmentsByTherapist = (blockedSlots?: BlockedDate[]) => {
    AppointmentService.getAppointmentsByTherapist().then(res => {
      if (blockedSlots) {
        setAppointments([...res.data, ...blockedSlots]);
      } else {
        setAppointments([...res.data, ...blockDatesList]);
      }
    });
  };

  const changeDate = (e: Date) => {
    onChangeDateValue(e);
    const calendarApi = calendarComponentRef.current.getApi();
    calendarApi.gotoDate(e);
  };

  const eventModalToggle = () => {
    setIsEventModal(!isEventModal);
  };

  const eventTimeValidationModalToggle = () => {
    setEventTimeValidationViewModal(!isEventTimeValidationViewModal);
    setErrorModalText("");
  };

  const handleChangeComplete = (color: any) => {
    singleAppointment.color = color.hex;
  };

  const addEvent = (selectedDetails: any) => {
    const selectedDate = moment(selectedDetails.startStr).toISOString();

    const isInRange = blockDatesList.some(range => {
      return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate));
    });

    if (isInRange) {
      return false;
    }

    setIsUpdate(false);

    if (
      user &&
      ((user.subscriptionId != null && user.subscriptionStatus == "active") ||
        user.premiumStatus == PremiumStatus.ACTIVE ||
        user.testSubscriptionStatus == testSubscriptionStatus.ACTIVE)
    ) {
      if (selectedDetails) {
        const dayNumber = dayOfWeekAsNumber(moment(selectedDetails.start).format("dddd"));

        if (therapistAvailableHours == null || !therapistAvailableHours || therapistAvailableHours.length == 0) {
          setEventOnHolidays(true);
          openCreateAppointmentPopup(selectedDetails, user, true);
        } else {
          const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, therapistAvailableHours);

          if (!hoursSlots.includes(moment(selectedDetails.start).format("H:mm A")) || !hoursSlots.includes(moment(selectedDetails.end).format("H:mm A"))) {
            Swal.fire({
              icon: "error",
              title:
                therapist.firstname +
                " " +
                therapist.lastname +
                " is not available during this time.",
              showCancelButton: false,
              confirmButtonText: "Okay",
              confirmButtonColor: "#f46a6a",
            });
          } else {
            openCreateAppointmentPopup(selectedDetails, user, false);
          }
        }
      }
    } else {
      setShowModalForTherapist(true);
    }
  };

  const openCreateAppointmentPopup = (selectedDetails: any, userObj: any, eventOnHolidays: boolean) => {
    AppointmentService.getAllAppointmentAndDetailsByUserId(Util.calculateWeekNumberAndDates(selectedDetails?.start, userObj._id!, userId)).then(res => {
      if (res.success) {
        if (res.data && res.data.sessionTimeOfWeek >= 60 && userObj && Util.skipCheckingForPremiumUsers(userObj)) {
          setErrorModalText("Your weekly session time has exceeded.");
          return setEventTimeValidationViewModal(true);
        } else if (res.data && res.data.sessionTimeOfWeek < 60) {
          if (!selectedDetails.start) {
            return toast.error("Please select valid date.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          if (!moment(new Date()).isBefore(moment(selectedDetails.start))) {
            return toast.error(`Sorry! You can't create appointment in a past date!`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }
        }

        if (user?.role == Role.CLIENT) {
          setTimeValue(selectedDetails.start);
          setHourValue(moment(selectedDetails.start).hour());
          setMinuteValue(moment(selectedDetails.start).minute());

          setSingleAppointment({
            ...singleAppointment,
            date: moment(selectedDetails.start).format("YYYY/MM/DD HH:mm"),
            startTime: moment(selectedDetails.start).format("H:mm A"),
            endTime: moment(selectedDetails.start).add(60, "minutes").format("H:mm A"),
            selectedDate: moment(selectedDetails.startStr).toISOString()
          });

          setIsEventModal(!isEventModal);
        }
      }
    });
  };

  const getWorkingDaysOfWeek = (workingHours: any) => {
    const workingDays: string[] = [];

    workingHours?.map((session: any) => {
      if (!workingDays.includes(session.day)) workingDays.push(session.day);
    });

    const dup = [...new Set(workingDays)];

    setWorkingDaysOfTherapist(dup);
  };

  const checkAvailableTime = (workingHoursOfTherapist: WorkingHour[] | undefined) => {
    const availableDays: { startTime: any; endTime: any; daysOfWeek: any[] }[] = [];

    if (workingHoursOfTherapist?.length) {
      workingHoursOfTherapist?.map((obj: any, i: number) => {
        const dayAsNumber = dayOfWeekAsNumber(obj.day);

        if (Util.convertUTCDateToLocalDate(obj.endTime) > Util.convertUTCDateToLocalDate(obj.startTime)) {
          return availableDays.push({
            startTime: Util.convertUTCDateToLocalDate(obj.startTime),
            endTime: Util.convertUTCDateToLocalDate(obj.endTime),
            daysOfWeek: [dayAsNumber],
          });
        } else {
          availableDays.push({
            startTime: Util.convertUTCDateToLocalDate(obj.startTime),
            endTime: "24:00 AM",
            daysOfWeek: [dayAsNumber - 1],
          });

          return availableDays.push({
            startTime: "00:00 AM",
            endTime: Util.convertUTCDateToLocalDate(obj.endTime),
            daysOfWeek: [dayAsNumber],
          });
        }
      });
    } else {
      availableDays.push({
        startTime: "00:00 AM",
        endTime: "00:00 PM",
        daysOfWeek: [10]
      });
    }

    setTherapistAvailableHours(availableDays);
  };

  const createAppointment = () => {
    if (
      user &&
      ((user.subscriptionId != null && user.subscriptionStatus == "active") ||
        user.premiumStatus == PremiumStatus.ACTIVE ||
        user.testSubscriptionStatus == testSubscriptionStatus.ACTIVE)
    ) {
      const selectedDate = moment(singleAppointment.selectedDate);

      if (selectedDate.minutes() != 0 && selectedDate.minutes() != 30) {
        setErrorModalText("You have selected invalid time.");

        return setEventTimeValidationViewModal(true);
      }

      const isInRange = blockDatesList.some(range => {
        return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate.toISOString()));
      });

      if (isInRange) {
        setErrorModalText("Sorry! Therapist `" + therapist.firstname + " " + therapist.lastname + "` has blocked this date.");

        return setEventTimeValidationViewModal(true);
      }

      AppointmentService.getAllAppointmentAndDetailsByUserId(Util.calculateWeekNumberAndDates(singleAppointment.date, user._id!, userId)).then(res => {
        if (res.success) {
          const timeStart: any = new Date(singleAppointment.date).setHours(
            parseInt(singleAppointment.startTime.split(":")[0]),
            parseInt(singleAppointment.startTime.split(":")[1]),
            0,
            0
          );

          const timeEnd: any = new Date(singleAppointment.date).setHours(
            parseInt(singleAppointment.endTime.split(":")[0]),
            parseInt(singleAppointment.endTime.split(":")[1]),
            0,
            0
          );

          let humanTime;

          if (timeStart > timeEnd) {
            const tStart1: any = new Date(singleAppointment.date).setHours(
              parseInt(singleAppointment.startTime.split(":")[0]),
              parseInt(singleAppointment.startTime.split(":")[1]),
              0,
              0
            );

            const tEnd1: any = new Date(singleAppointment.date).setHours(24, 0, 0, 0);

            const ht1 = Math.abs(tEnd1 - tStart1) / 60000;

            const tStart2: any = new Date(singleAppointment.date).setHours(0, 0, 0, 0);

            const tEnd2: any = new Date(singleAppointment.date).setHours(
              parseInt(singleAppointment.endTime.split(":")[0]),
              parseInt(singleAppointment.endTime.split(":")[1]),
              0,
              0
            );

            const ht2 = Math.abs(tEnd2 - tStart2) / 60000;

            humanTime = ht1 + ht2;
          } else {
            humanTime = Math.abs(timeStart - timeEnd) / 60000;
          }

          if (humanTime > 60) {
            setErrorModalText("You can only create 1 hour sessions.");
            return setEventTimeValidationViewModal(true);
          }

          if (humanTime + res.data.sessionTimeOfWeek > 60 && Util.skipCheckingForPremiumUsers(user)) {
            setErrorModalText(`You have exceeded your session time in this week.`);
            return setEventTimeValidationViewModal(true);
          } else {
            if (!singleAppointment.startTime) {
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            const mST = moment(singleAppointment.startTime, "HH:mm").minute();

            if (mST != 0 && mST != 30) {
              return toast.error("Please select valid start time.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!singleAppointment.date) {
              return toast.error("Please select valid date.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!moment(new Date()).isBefore(singleAppointment.date)) {
              return toast.error(`Sorry! You can't create appointment in a past date!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!singleAppointment.title) {
              return toast.error(`Please add title.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!singleAppointment.repeat) {
              return toast.error(`Pleas select repeat type.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!haveRead && therapist.disclosureStatementId?.url) {
              return toast.error(`You must read disclosure statement & accept before creating appointment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!haveAgreed) {
              return toast.error(`You must agree to the terms & conditions of Lavni Inc. before creating appointment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }

            if (!haveAgreedPolicy) {
              return toast.error(`You must agree to the No Show and Cancellation Policy of Lavni Inc. before creating appointment.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }


            setDisabledCreateBtn(true);

            const appointmentObj = {
              therapistId: therapist._id,
              clientId: user?._id,
              start: new Date(
                new Date(singleAppointment.date).setHours(
                  parseInt(singleAppointment.startTime.split(":")[0]),
                  parseInt(singleAppointment.startTime.split(":")[1]),
                  0,
                  0
                )
              ),
              end: new Date(
                new Date(singleAppointment.date).setHours(
                  parseInt(singleAppointment.endTime.split(":")[0]),
                  parseInt(singleAppointment.endTime.split(":")[1]),
                  0,
                  0
                )
              ),
              reminders: [30],
              title: singleAppointment.title,
              repeatInfo: {
                repeatType: singleAppointment.repeat,
                interval: "",
                repeatDays: {
                  sunday: false,
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                  saturday: false,
                },
                endingDate: Util.monthsNextFrom(new Date(singleAppointment.date), 1),
                endingAfter: 10,
                endingType: "",
              },
              color: singleAppointment.color,
              groupId:
                user?._id +
                "_" +
                Util.dateConvertToMilisecond(
                  new Date(
                    new Date(singleAppointment.date).setHours(
                      parseInt(singleAppointment.startTime.split(":")[0]),
                      parseInt(singleAppointment.startTime.split(":")[1]),
                      0,
                      0
                    )
                  )
                ),
              eventOnHolidays: eventOnHolidays,
            };

            AppointmentService.createAppointmentByClient(appointmentObj).then(res => {
              if (res.success) {
                setHaveRead(false);
                setHaveAgreed(false);
                setHaveAgreedPolicy(false);
                setIsEventModal(false);
                setEventOnHolidays(false);
                getAllAppointmentsBySelectedTherapistId();

                const appointmentNotification: NotificationModel = {
                  senderId: user?._id,
                  receiverId: userId,
                  event: NotificationEvent.APPOINMENT_CREATED,
                  link: "/appointments",
                  content: "Appointment Created by " + user?.firstname + " " + user?.lastname,
                  variant: NotificationVarient.INFO,
                };

                NotificationService.sendNotification(appointmentNotification).then(res => {
                  const socketData: sendNotificationSocketData = {
                    socketId: res.data.socketId,
                    notifyData: appointmentNotification,
                    receiverId: userId,
                    senderId: user?._id,
                  };

                  socket.emit("send-notification", socketData);
                });

                setAppointmentCreated(true);

                setDisabledCreateBtn(false);
              } else {
                setErrorModalText(res.error as string);
                setDisabledCreateBtn(false);
                getAllAppointmentsBySelectedTherapistId();

                return setEventTimeValidationViewModal(true);
              }
            });
          }
        }
      });
    } else {
      setShowModalForTherapist(true);
    }
  };

  const updateAppointment = () => {
    if (
      user &&
      ((user.subscriptionId != null && user.subscriptionStatus == "active") ||
        user.premiumStatus == PremiumStatus.ACTIVE ||
        user.testSubscriptionStatus == testSubscriptionStatus.ACTIVE)
    ) {
      const selectedDate = moment(singleAppointment.selectedDate);

      if (selectedDate.minutes() != 0 && selectedDate.minutes() != 30) {
        setErrorModalText("You have selected invalid time.");

        return setEventTimeValidationViewModal(true);
      }

      const isInRange = blockDatesList.some(range => {
        return Util.isDateInBlockedRange(moment(range.start), moment(range.end), moment(selectedDate.toISOString()));
      });

      if (isInRange) {
        setErrorModalText("Sorry! Therapist `" + therapist.firstname + " " + therapist.lastname + "` has blocked this date.");

        return setEventTimeValidationViewModal(true);
      }

      AppointmentService.getAllAppointmentAndDetailsByUserId(Util.calculateWeekNumberAndDates(singleAppointment.date, user._id!, userId)).then(res => {
        if (res.success) {
          if (
            res.data.sessionTimeOfWeek != 0 &&
            res.data.sessionTimeOfWeek != 60 &&
            Util.skipCheckingForPremiumUsers(user)
          ) {
            setErrorModalText(`Sorry! Your weekly session time has exceeded.`);

            return setEventTimeValidationViewModal(true);
          }

          if (res.data.sessionTimeOfWeek != 0 && Util.skipCheckingForPremiumUsers(user)) {
            const meetingIsOnCurrentSelectedWeek = res.data.allSessionOfWeek.filter(obj => obj._id === singleAppointment.id);

            if (res.data.sessionTimeOfWeek == 60) {
              if (meetingIsOnCurrentSelectedWeek.length == 0) {
                setErrorModalText(`Sorry! Your weekly session time has exceeded.`);

                return setEventTimeValidationViewModal(true);
              } else {
                if (res.data.allSessionOfWeek.length > 1) {
                  setErrorModalText(`Sorry! Your weekly session time has exceeded.`);

                  return setEventTimeValidationViewModal(true);
                }
              }
            }
          }

          if (!singleAppointment.startTime) {
            return toast.error("Please select valid start time.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          const mST = moment(singleAppointment.startTime, "HH:mm").minute();

          if (mST != 0 && mST != 30) {
            return toast.error("Please select valid start time.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          if (!singleAppointment.date) {
            return toast.error("Please select valid date.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "foo-bar",
            });
          }

          const dayNumber = dayOfWeekAsNumber(moment(singleAppointment.date).format("dddd"));

          if (therapistAvailableHours == null || !therapistAvailableHours || therapistAvailableHours.length == 0) {
            setEventOnHolidays(true);
            updateOpenedAppointment(singleAppointment);
          } else {
            const hoursSlots = getAvailableTimeBySelectedDay(dayNumber, therapistAvailableHours);

            if (!hoursSlots.includes(singleAppointment.startTime) || !hoursSlots.includes(singleAppointment.endTime)) {
              Swal.fire({
                icon: "error",
                title:
                  therapist.firstname +
                  " " +
                  therapist.lastname +
                  " is not available during this time.",
                showCancelButton: false,
                confirmButtonText: "Okay",
                confirmButtonColor: "#f46a6a",
              });
            } else {
              updateOpenedAppointment(singleAppointment);
            }
          }
        } else {
          setErrorModalText(`No appointments found for this Therapist.`);
        }
      });
    } else {
      setShowModalForTherapist(true);
    }
  };

  const updateOpenedAppointment = (appointment: any) => {
    if (
      !moment(new Date()).isBefore(
        moment(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0))
      )
    ) {
      return toast.error(`Sorry! You can't create appointment in a past date!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!appointment.title) {
      return toast.error(`Please add title.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!appointment.repeat) {
      return toast.error(`Please select repeat type.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveRead && therapist.disclosureStatementId?.url) {
      return toast.error(`You must read disclosure statement & accept before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreed) {
      return toast.error(`You must agree to the terms & conditions of Lavni  before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }

    if (!haveAgreedPolicy) {
      return toast.error(`You must agree to the No Show and Cancellation Policy of Lavni Inc. before creating appointment.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }


    setDisabledUpdateBtn(true);

    const obj = {
      appointmentId: appointment.id,
      therapistId: appointment.therapist._id,
      clientId: appointment.client._id,
      start: new Date(new Date(appointment.date).setHours(parseInt(appointment.startTime.split(":")[0]), parseInt(appointment.startTime.split(":")[1]), 0, 0)),
      end: new Date(new Date(appointment.date).setHours(parseInt(appointment.endTime.split(":")[0]), parseInt(appointment.endTime.split(":")[1]), 0, 0)),
      reminders: [30],
      title: appointment.title,
      repeatInfo: {
        repeatType: appointment.repeat,
        interval: "",
        repeatDays: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false
        },
        endingDate: Util.monthsNextFrom(new Date(appointment.date), 1),
        endingAfter: 10,
        endingType: "",
      },
      color: appointment.color,
      groupId: appointment.groupId,
    };

    AppointmentService.updateAppointment(obj).then(res => {
      if (res.success) {
        setIsUpdate(false);

        setAppointmentUpdated(true);

        setHaveRead(false);
        setHaveAgreed(false);
        setHaveAgreedPolicy(false);
        setIsEventModal(false);
        getAllAppointmentsBySelectedTherapistId();

        const appointmentNotification: NotificationModel = {
          senderId: user?._id,
          receiverId: userId,
          event: NotificationEvent.APPOINMENT_UPDATED,
          link: "/appointments",
          content: "Appointment Created by " + user?.firstname + " " + user?.lastname,
          variant: NotificationVarient.INFO,
        };

        NotificationService.sendNotification(appointmentNotification).then(res => {
          const socketData: sendNotificationSocketData = {
            socketId: res.data.socketId,
            notifyData: appointmentNotification,
            receiverId: userId,
            senderId: user?._id,
          };

          socket.emit("send-notification", socketData);
        });

        setDisabledUpdateBtn(false);
      } else {
        setDisabledUpdateBtn(false);
        setErrorModalText(res.error as string);
        return setEventTimeValidationViewModal(true);
      }
    });
  };

  const dayOfWeekAsNumber = (day: string) => {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
  };

  const [showNotApproved, setShowNotApproved] = useState(false);
  const [showNotAfriend, setShowNotAfriend] = useState(false);

  const hideAndShow = () => {
    setIsView(!isView);
  };

  return (
    <React.Fragment>
      {showNotAfriend && <Modal18 setShowModal={setShowNotAfriend} showModal={showNotAfriend} />}
      {showNotApproved && <Modal17 setShowModal={setShowNotApproved} showModal={showNotApproved} />}
      {showAlreadyCompleted && <Modal13 setShowModal={setShowAlreadyCompleted} showModal={showAlreadyCompleted} />}
      {showExpired && <Modal12 setShowModal={setShowExpired} showModal={showExpired} />}
      {showTooEarly && <Modal11 setShowModal={setShowTooEarly} showModal={showTooEarly} />}
      {appointmentCreated && <Modal14 setShowModal={setAppointmentCreated} showModal={appointmentCreated} />}
      {appointmentUpdated && <Modal15 setShowModal={setAppointmentUpdated} showModal={appointmentUpdated} />}
      {showModal && <MessageModal setShowModal={setShowModal} showModal={showModal} message={"Please subscribe to a provided plan to access this feature."} />}
      {showModalForTherapist && user?.insuranceId && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
      {showModalForTherapist && !user?.subscriptionId && !user?.insuranceId && user?.premiumStatus != PremiumStatus.ACTIVE && (
        <Modal22 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />
      )}
      {showModalExceeded && <Modal5 setShowModal={setShowModalExceeded} showModal={showModalExceeded} />}
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Lavni" breadcrumbItem="Calendar" />

          {isLoading && (
            <div className="flex justify-content-center mt-5">
              <img src={images.cubicLoader} style={{ width: "90px" }} />
            </div>
          )}

          {!isLoading && (
            <>
              {!isFriend ? (
                <>
                  <h5 className="text-center mt50">Sorry! You haven&lsquo;t connected with this Therapist yet.</h5>
                </>
              ) : (
                <Row>
                  <Col lg={3}>
                    <div id="external-events">
                      {user?.role == Role.THERAPIST && <Calendar onChange={(e: Date) => changeDate(e)} value={dateValue} />}
                      {user?.role == Role.CLIENT && userId && (
                        <>
                          <Label className="mb-2 d-flex j-btwn">
                            <span> Therapist</span>
                            {isView ? (
                              <i className="bx bxs-down-arrow mr-2 more-icon" onClick={hideAndShow}></i>
                            ) : (
                              <i className="bx bxs-up-arrow mr-2 more-icon" onClick={hideAndShow}></i>
                            )}
                          </Label>

                          <Card className={!isView ? `view-therapist-card` : "hide-therapist-card"}>
                            <div className="col-xl-12">
                              <div className="row">
                                <div className="col-xl-12">
                                  <Row>
                                    <Col>
                                      <div
                                        className="profileImage-appoint imageFit BorderRadiusLeftRight"
                                        style={{
                                          backgroundImage:
                                            therapist?.coverPhotoId == null || therapist?.coverPhotoId == undefined || !therapist?.coverPhotoId?._id
                                              ? `url(${profileBackground})`
                                              : `url("${Util.fileURL(therapist?.coverPhotoId?._id)}")`,
                                        }}
                                      ></div>
                                      <Row>
                                        <Col xl={4} lg={4}>
                                          <div className="avatar-md profile-user-wid mb-4">
                                            <div
                                              className="img-thumbnail avatar-md profileImageShow rounded-circle imageFit mr-profile"
                                              style={{
                                                backgroundImage:
                                                  therapist?.photoId == null || therapist?.photoId == undefined
                                                    ? `url(${user1})`
                                                    : `url("${Util.fileURL(therapist?.photoId?._id)}")`,
                                                borderRadius: "10px",
                                                position: "relative",
                                              }}
                                            ></div>
                                          </div>
                                        </Col>
                                        <Col xl={8} lg={8}>
                                          {therapist?.role == "THERAPIST" && (
                                            <span className="appointment-profile-name2">
                                              {therapist?.firstname} {therapist?.lastname}
                                            </span>
                                          )}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl={12}>
                                      <div className="working-hours">
                                        <Label className="px-2">
                                          <i className="bx bx-time-five"></i> &nbsp;Working Hours:
                                        </Label>
                                        <div>
                                          {therapist?.workingHours &&
                                            therapist?.workingHours.map((opt, index) => (
                                              <Label className="flex-wrap -mx-2 md:space-y-0 mt-2 px-2" key={index}>
                                                {opt.day} {opt.day && "|"} {Util.convertUTCDateToLocalDate(opt.startTime)} -
                                                {Util.convertUTCDateToLocalDate(opt.endTime)}
                                              </Label>
                                            ))}
                                          {!therapist?.workingHours?.length && <p>No working hours added.</p>}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </>
                      )}
                    </div>
                  </Col>

                  <Col lg={9} sm={12}>
                    <FullCalendar
                      plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin, timeGridPlugin]}
                      slotMinTime={15}
                      editable={false}
                      slotDuration={"00:30:00"}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                      }}
                      eventTimeFormat={{
                        hour12: false,
                      }}
                      firstDay={dayOfWeekAsNumber(moment(new Date()).format("dddd"))}
                      initialView="timeGridWeek"
                      selectLongPressDelay={-1}
                      longPressDelay={-1}
                      eventLongPressDelay={-1}
                      // events={appointments}
                      events={appointments.map(appointment => {
                        const startMoment = momentTimezone(appointment.start).tz('America/New_York');
                        const endMoment = momentTimezone(appointment.end).tz('America/New_York');
                        const startOffset = startMoment.isDST() ? -4 * 60 : -5 * 60;
                        const endOffset = endMoment.isDST() ? -4 * 60 : -5 * 60;
                        return {
                          ...appointment,
                          start: startMoment.utcOffset(startOffset, true).toDate(),
                          end: endMoment.utcOffset(endOffset, true).toDate()
                        };
                      })}
                      droppable={false}
                      selectable={true}
                      ref={calendarComponentRef}
                      select={(e: any) => addEvent(e)}
                      businessHours={therapistAvailableHours}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </Container>
      </div>

      {showErrorModal && (
        <MessageModal showModal={showErrorModal} setShowModal={setShowErrorModal} message={"Please subscribe to a provided plan to access this feature."} />
      )}

      <Modal isOpen={isEventModal} centered toggle={eventModalToggle} unmountOnClose={true}>
        <ModalHeader toggle={eventModalToggle}>
          <div className="title-input">
            <FormGroup>
              <Input
                className="modal-title new-input-v"
                name="title"
                type="text"
                placeholder="Add title - Optional"
                onChange={e => setSingleAppointment({ ...singleAppointment, title: e.target.value })}
                value={singleAppointment.title}
              />
              <span className="awsome_input_border" />
            </FormGroup>
          </div>
        </ModalHeader>

        <ModalBody className="awsome-area">
          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className="bx bx-time-five"></i>
            </Col>
            <Col lg={11} md={11} sm={11} xs={12}>
              <Row>
                <Col lg={4} md={4} sm={4} xs={6} className="sm-mb5">
                  <DatePicker
                    minDate={moment().toDate()}
                    value={moment(singleAppointment?.date).toDate()}
                    onChange={(date: Date) => {
                      const selectedTime = moment(date);

                      selectedTime.hour(hourValue).minute(minuteValue);

                      const stateT = moment(selectedTime).format("H:mm A");
                      const endT = moment(selectedTime).add(60, "minutes").format("H:mm A");
                      const sDate = moment(selectedTime).toISOString();

                      setSingleAppointment({ ...singleAppointment, startTime: stateT, endTime: endT, selectedDate: sDate, date: selectedTime.format("YYYY/MM/DD") });
                    }}
                  />
                </Col>

                <Col lg={8} md={8} sm={8} xs={6}>
                  <CustomTimePicker timeValue={timeValue} setTimeValue={setTimeValue} appointment={singleAppointment} setAppointment={setSingleAppointment} setHourValue={setHourValue} setMinuteValue={setMinuteValue} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className="bx bx-video"></i>
            </Col>
            <Col lg={11} md={11} sm={11} xs={12}>
              Video - 1 Hour Session
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className={Util.meetingRepeatTypeIcon(singleAppointment.repeat)}></i>
            </Col>
            <Col lg={11} md={11} sm={11} xs={12}>
              <Input
                type="select"
                name="repeat"
                onChange={e => setSingleAppointment({ ...singleAppointment, repeat: e.target.value as Repeat })}
                value={singleAppointment.repeat}
              >
                <option defaultChecked value={Repeat.DOES_NOT_REPEAT}>
                  Does Not Repeat
                </option>
                <option value={Repeat.WEEKLY}>Weekly</option>
              </Input>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}>
              <i className="bx bx-palette"></i>
            </Col>
            <Col lg={11} md={11} sm={11} xs={12}>
              <TwitterPicker width="100%" onChangeComplete={handleChangeComplete} color={singleAppointment.color} />
            </Col>
          </Row>

          {therapist.disclosureStatementId?.url && (
            <Row className="mb-4">
              <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
              <Col className="" lg={11} md={11} sm={11} xs={12}>
                <FormGroup check inline>
                  <Input type="checkbox" onChange={(e: any) => setHaveRead(e.target.checked)} />
                  <Label check>
                    I have read the&nbsp;
                    <Link to={{ pathname: therapist.disclosureStatementId?.url }} target="_blank">
                      disclosure statement&nbsp;
                    </Link>
                    & Accept.
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
            <Col className="" lg={11} md={11} sm={11} xs={12}>
              <FormGroup check inline>
                <Input type="checkbox" onChange={(e: any) => setHaveAgreedPolicy(e.target.checked)} />
                <Label check>
                  I agree to&nbsp;
                  <a href="/noshow-policy" className="hover" target="_blank">
                    No Show and Cancellation Policy&nbsp;
                  </a>
                  of Lavni Inc.
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className="cont-center sm-hide" lg={1} md={1} sm={1} xs={1}></Col>
            <Col className="" lg={11} md={11} sm={11} xs={12}>
              <FormGroup check inline>
                <Input type="checkbox" onChange={(e: any) => setHaveAgreed(e.target.checked)} />
                <Label check>
                  I agree to&nbsp;
                  <a href="/consent-document" className="hover" target="_blank">
                    Consent Document&nbsp;
                  </a>
                  of Lavni Inc.
                </Label>
              </FormGroup>
            </Col>
          </Row>

          {singleAppointment.id && isUpdate ? (
            <Row>
              <Col className="cont-center" lg={1}></Col>
              <Col className="appoint-btn">
                <button className={disabledUpdateBtn ? "session-btn updating" : "session-btn"} disabled={disabledUpdateBtn} onClick={updateAppointment}>
                  Update
                </button>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="cont-center" lg={1}></Col>
              <Col className="appoint-btn">
                <button className={disabledCreateBtn ? "session-btn updating" : "session-btn"} disabled={disabledCreateBtn} onClick={createAppointment}>
                  Create
                </button>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={isEventTimeValidationViewModal} centered toggle={eventTimeValidationModalToggle}>
        <ModalBody>
          <div className="modal-val">
            <h5 className="model-error">{errorModalText}</h5>

            <button className="btn btn-sm text-center btn-success mt-3" onClick={() => eventTimeValidationModalToggle()}>
              Got It
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AppointmentCalendar;
