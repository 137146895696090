import React, { useContext, useRef, useState, useCallback, useEffect, useMemo } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { VideoQuality } from "@zoom/videosdk";
import classnames from "classnames";
import _ from "lodash";
// import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from "../../context/media-context";
import Avatar from "./components/avatar";
import VideoFooter from "./components/video-footer";
import { useShare } from "./hooks/useShare";
import { useParticipantsChange } from "./hooks/useParticipantsChange";
import { useCanvasDimension } from "./hooks/useCanvasDimension";
import { useMount, useSizeCallback } from "../../hooks";
import { Participant } from "../../index-types";
import { SELF_VIDEO_ID } from "./video-constants";
import { isShallowEqual } from "../../utils/util";
import { useLocalVolume } from "./hooks/useLocalVolume";
import "./video.scss";
import { useNetworkQuality } from "./hooks/useNetworkQuality";
import Countdown from "react-countdown";
import LocalCallContext from "src/context/LocalCallContext";
import ZoomContext from "src/pages/Chat/VideoCallNew/context/ZoomContext";
import UserContext from "src/context/UserContext";
import VideoButtonContext from "../../context/video-button-context";
import { FaceLandmarker, FaceLandmarkerOptions, FilesetResolver } from "@mediapipe/tasks-vision";
import { Color, Euler, Matrix4 } from "three";
import { Canvas, useFrame, useGraph } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import images from "src/assets/images";
import { VideoChatService } from "src/services/VideoChatService";
import cameraOffIcon from "../../../../../assets/images/icons/camera-off.png";
import userIcon from "../../../../../assets/images/icons/user-at-phone.png";
import BadNetwork from "src/pages/Popup/BadNetworkModal";
import { Button } from "reactstrap";
import CallInitializationContext from "src/context/CallInitializationContext";
import { toast } from "react-toastify";

interface AppProps {
  leaveMeeting?: any;
}

let video: HTMLVideoElement;
let faceLandmarker: FaceLandmarker;
let lastVideoTime = -1;
let blendshapes: any[] = [];
let rotation: Euler;
const headMesh: any[] = [];

const options: FaceLandmarkerOptions = {
  baseOptions: {
    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
    delegate: "GPU",
  },
  numFaces: 1,
  runningMode: "VIDEO",
  outputFaceBlendshapes: true,
  outputFacialTransformationMatrixes: true,
};

function Avatar2({ url }: { url: string }) {
  const { scene } = useGLTF(url);
  const { nodes } = useGraph(scene);
  useEffect(() => {
    if (nodes.Wolf3D_Head) headMesh.push(nodes.Wolf3D_Head);
    if (nodes.Wolf3D_Teeth) headMesh.push(nodes.Wolf3D_Teeth);
    if (nodes.Wolf3D_Beard) headMesh.push(nodes.Wolf3D_Beard);
    if (nodes.Wolf3D_Avatar) headMesh.push(nodes.Wolf3D_Avatar);
    if (nodes.Wolf3D_Head_Custom) headMesh.push(nodes.Wolf3D_Head_Custom);
  }, [nodes, url]);

  useFrame(() => {
    if (blendshapes.length > 0) {
      blendshapes.forEach(element => {
        headMesh.forEach(mesh => {
          const index = mesh.morphTargetDictionary[element.categoryName];

          if (index >= 0) {
            mesh.morphTargetInfluences[index] = element.score;
          }
        });
      });

      nodes.Head.rotation.set(rotation.x, rotation.y, rotation.z);
      nodes.Neck.rotation.set(rotation.x / 5 + 0.3, rotation.y / 5, rotation.z / 5);
      nodes.Spine2.rotation.set(rotation.x / 10, rotation.y / 10, rotation.z / 10);
    }
  });

  return <primitive object={scene} position={[0, -1.75, 3]} />;
}

const VideoContainer3: React.FC<{
  leaveMeeting?: any;
}> = (props: AppProps) => {
  const zoomClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady },
  } = useContext(ZoomMediaContext);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareRef = useRef<HTMLCanvasElement | null>(null);
  const selfShareRef = useRef<HTMLCanvasElement & HTMLVideoElement>(null);
  const shareContainerRef = useRef<HTMLDivElement | null>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [activeVideo, setActiveVideo] = useState<number>(0);
  const previousActiveUser = useRef<Participant>();
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(zoomClient, mediaStream, shareRef);
  const isSharing = isRecieveSharing || isStartedShare;
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 0,
  });
  const [shareViewDimension, setShareViewDimension] = useState({
    width: 0,
    height: 0,
  });
  const { userVolumeList, setLocalVolume } = useLocalVolume();
  const networkQuality = useNetworkQuality(zoomClient);
  const [user] = useContext(UserContext);
  const [userType, setUserType] = useState("THERAPIST");
  const [cameraButtonClick, setCameraButtonClick] = useContext(VideoButtonContext);
  const [isTherapistCameraBtnClicked, setIsTherapistCameraBtnClicked] = useState(false);
  const [isClientCameraBtnClicked, setIsClientCameraBtnClicked] = useState(false);
  const cameraStream: MediaStream | null = null;
  let cameraStream2: MediaStream | null = null;
  const [stream1, setStream1] = useState<MediaStream>();
  const [isPredictFunctionRun, setIsPredictFunctionRun] = useState<boolean>(false);
  // const [url, setUrl] = useState<string>("https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb");
  const cameraElementRef = useRef<HTMLVideoElement | null>(null);
  const [isSecondPersonJoined, setIsSecondPersonJoined] = useState<boolean>(false);
  const [localUserOnCall] = useContext(LocalCallContext);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [avatarBackground, setAvatarBackground] = useState("");
  const [usersIncogniteModeOn, setRecieversIncogniteModeOn] = useState(false);
  const [isTherapistTurnOnCam, setIsTherapistTurnOnCam] = useState<boolean>(false);
  const [otherPartyName, setOtherPartyName] = useState("");
  const [networkQ, setNetworkQ] = useState(2);
  const [transcribeList, setTranscribeList] = useState([])


  useEffect(() => {
    if (!localUserOnCall.isAudioCall){
      
      if (user?.role == "CLIENT") {
        VideoChatService.getAvatarDetailsOfOwnUser().then(res => {
          if (res.success) {
            setRecieversIncogniteModeOn(res.data.incognito);
            if (res.data.useDefaultAvatar) {
              switch (res.data.avatarBackgroundId) {
                case "backgroundOne":
                  setAvatarBackground(images.avatarBackgroundOne);
                  break;
                case "backgroundTwo":
                  setAvatarBackground(images.avatarBackgroundTwo);
                  break;
                default:
                  setAvatarBackground(images.avatarBackgroundOne);
              }
  
              switch (res.data.avatarId) {
                case "avatarOne":
                  setAvatarUrl(images.avatarOne);
                  break;
  
                case "avatarTwo":
                  setAvatarUrl(images.avatarTwo);
  
                  break;
                default:
                  setAvatarUrl(images.avatarOne);
              }
            }
  
            if (!res.data.useDefaultAvatar) {
              switch (res.data.avatarBackgroundId) {
                case "backgroundOne":
                  setAvatarBackground(images.avatarBackgroundOne);
  
                  break;
                case "backgroundTwo":
                  setAvatarBackground(images.avatarBackgroundTwo);
  
                  break;
                default:
                  setAvatarBackground(images.avatarBackgroundOne);
              }
              setAvatarUrl(res.data.avatarId);
            }
          } else {
            setAvatarBackground(images.avatarBackgroundOne);
            setAvatarUrl(images.avatarOne);
          }
        });
      }
    }
    
  }, []);

  useEffect(() => {
      if (user?.role == "THERAPIST") {
        getAvatarDetailsOfClient();
      }
      if (user?.role == "CLIENT") {
        getAvatarDetailsOfTherapist();
      }
  }, [localUserOnCall]);

  function getAvatarDetailsOfTherapist() {
    setRecieversIncogniteModeOn(localUserOnCall.recieversIncogniteModeOn);
    setOtherPartyName(localUserOnCall.recieversName);
  }

  function getAvatarDetailsOfClient() {
    setRecieversIncogniteModeOn(localUserOnCall.recieversIncogniteModeOn);
    setOtherPartyName(localUserOnCall.recieversName);

    if (!localUserOnCall.isAudioCall){
      if (localUserOnCall.recieversUseDefaultAvatarValue) {
        switch (localUserOnCall.recieversAvatarBackgroundId) {
          case "backgroundOne":
            setAvatarBackground(images.avatarBackgroundOne);
            break;
          case "backgroundTwo":
            setAvatarBackground(images.avatarBackgroundTwo);
            break;
          default:
            setAvatarBackground(images.avatarBackgroundOne);
        }
  
        switch (localUserOnCall.recieversAvatarId) {
          case "avatarOne":
            setAvatarUrl(images.avatarOne);
            break;
  
          case "avatarTwo":
            setAvatarUrl(images.avatarTwo);
  
            break;
          default:
            setAvatarUrl(images.avatarOne);
        }
      } else {
        switch (localUserOnCall.recieversAvatarBackgroundId) {
          case "backgroundOne":
            setAvatarBackground(images.avatarBackgroundOne);
            break;
          case "backgroundTwo":
            setAvatarBackground(images.avatarBackgroundTwo);
            break;
          default:
            setAvatarBackground(images.avatarBackgroundOne);
        }
        // const avatarU = localUserOnCall.recieversAvatarId
        const avatarU = localUserOnCall.recieversAvatarId + "?lod=2&quality=low";
  
        setAvatarUrl(avatarU);
      }
    }
    
  }

  useParticipantsChange(zoomClient, (payload: any) => {
    try {
      // const currentUser = zoomClient.getCurrentUserInfo();
      // let hasTwoUsers = false;

      // payload.forEach((element: any) => {
      //   if (currentUser.userIdentity != element.userIdentity) {
      //     hasTwoUsers = true;
      //   }
      // });

      // if (hasTwoUsers) {
      //   setIsSecondPersonJoined(true);
      // }

      if (payload.length > 1) {
        setIsSecondPersonJoined(true);
      }
      setParticipants(payload);
    } catch (error) {
      console.log(error);
    }
  });

  const onActiveVideoChange = useCallback((payload: any) => {
    const { userId } = payload;
    setActiveVideo(userId);
  }, []);

  useEffect(() => {
    if (user?.role == "CLIENT") {
      mediaStream!.isBrowserSupportPTZ();
      setUserType("CLIENT");
    } else if (user?.role == "THERAPIST") {
      mediaStream!.isBrowserSupportPTZ();
      setUserType("THERAPIST");
    }
  }, [userType]);

  useEffect(() => {
    try {
      if (!localUserOnCall.isAudioCall){
        
        if (userType == "CLIENT") {
          if (cameraButtonClick) {
            setIsClientCameraBtnClicked(true);
            clientClickedCameraBtn();
          } else {
            setIsClientCameraBtnClicked(false);
            setIsPredictFunctionRun(false);
          }
  
          return () => {
            if (cameraStream2) {
              const tracks2 = cameraStream2?.getTracks();
              tracks2?.forEach((track: MediaStreamTrack) => {
                track.stop();
              });
              cameraStream2 = null;
            }
          };
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [cameraButtonClick]);

  const clientClickedCameraBtn = async () => {
    try {
      setIsPredictFunctionRun(true);
      const filesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm");
      faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, options);
      video = document.getElementById("camera-view-avatar") as HTMLVideoElement;
      navigator.mediaDevices
        .getUserMedia({
          // video: { width: 1280, height: 720 },
          video: true,
          audio: false,
        })
        .then(function (stream) {
          if (video != null) {
            cameraStream2 = stream;
            video.srcObject = stream;
            video.addEventListener("loadeddata", predict);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const predict = () => {
    try {
      const nowInMs = Date.now();
      video = document.getElementById("camera-view-avatar") as HTMLVideoElement;
      if (video != null) {
        if (lastVideoTime !== video.currentTime) {
          lastVideoTime = video?.currentTime;
          const faceLandmarkerResult = faceLandmarker?.detectForVideo(video, nowInMs);

          if (faceLandmarkerResult.faceBlendshapes && faceLandmarkerResult.faceBlendshapes.length > 0 && faceLandmarkerResult.faceBlendshapes[0].categories) {
            blendshapes = faceLandmarkerResult.faceBlendshapes[0].categories;

            const matrix = new Matrix4().fromArray(faceLandmarkerResult.facialTransformationMatrixes[0].data);
            rotation = new Euler().setFromRotationMatrix(matrix);
          }
        }
        window.requestAnimationFrame(predict);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    zoomClient?.on("video-active-change", onActiveVideoChange);
    return () => {
      zoomClient?.off("video-active-change", onActiveVideoChange);
    };
  }, [zoomClient, onActiveVideoChange]);

  const activeUser = useMemo(() => participants.find(user => user.userId === activeVideo), [participants, activeVideo]);

  const isCurrentUserStartedVideo = zoomClient?.getCurrentUserInfo()?.bVideoOn;

  useEffect(() => {
    if (mediaStream && videoRef.current && isVideoDecodeReady) {
      if (activeUser?.bVideoOn !== previousActiveUser.current?.bVideoOn) {
        if (activeUser?.bVideoOn) {
          mediaStream
            .renderVideo(videoRef.current, activeUser.userId, canvasDimension.width, canvasDimension.height, 0, 0, VideoQuality.Video_360P as any)
            .then(() => {
              if(!localUserOnCall.isAudioCall){
                fetchData();
              }
            });
        } else {
          if (userType == "THERAPIST") {
            setIsPredictFunctionRun(false);
          }
          if (userType == "CLIENT") {
            setIsTherapistTurnOnCam(false);
          }
          if (previousActiveUser.current?.bVideoOn) {
            mediaStream.stopRenderVideo(videoRef.current, previousActiveUser.current?.userId);
            setNetworkQ(2);
          }
        }
      }

      if (activeUser?.bVideoOn && previousActiveUser.current?.bVideoOn && activeUser.userId !== previousActiveUser.current.userId) {
        mediaStream.stopRenderVideo(videoRef.current, previousActiveUser.current?.userId);
        setNetworkQ(2);
        mediaStream
          .renderVideo(videoRef.current, activeUser.userId, canvasDimension.width, canvasDimension.height, 0, 0, VideoQuality.Video_360P as any)
          .then(() => {
            if(!localUserOnCall.isAudioCall){
              fetchData();
            }
          });
      }

      previousActiveUser.current = activeUser;
    }
  }, [mediaStream, activeUser, isVideoDecodeReady, canvasDimension]);

  async function fetchData() {
    try {
      if (userType == "THERAPIST") {
        setIsPredictFunctionRun(true);
        const filesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm");
        faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, options);

        video = document.getElementById("camera-view-avatar") as HTMLVideoElement;
        videoRef.current
          ?.captureStream()
          .getTracks()
          .forEach(track => {
            if (video != null) {
              setStream1(new MediaStream([track]));
              video.srcObject = new MediaStream([track]);
              video.addEventListener("loadeddata", predict);
            }
          });
      }
      if (userType == "CLIENT") {
        setIsTherapistTurnOnCam(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useMount(() => {
    if (mediaStream) {
      setActiveVideo(mediaStream.getActiveVideoId());
    }
  });

  useEffect(() => {
    if (isSharing && shareContainerRef.current) {
      const { width, height } = sharedContentDimension;
      const { width: containerWidth, height: containerHeight } = containerDimension;
      const ratio = Math.min(containerWidth / width, containerHeight / height, 1);
      setShareViewDimension({
        width: Math.floor(width * ratio),
        height: Math.floor(height * ratio),
      });
    }
  }, [isSharing, sharedContentDimension, containerDimension]);

  const onShareContainerResize = useCallback(({ width, height }: any) => {
    _.throttle(() => {
      setContainerDimension({ width, height });
    }, 50)();
  }, []);

  useSizeCallback(shareContainerRef.current, onShareContainerResize);

  useEffect(() => {
    if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
      mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height);
    }
  }, [mediaStream, sharedContentDimension, shareViewDimension]);

  // check network when own video on
  const [showBadNetwork, setShowBadNetwork] = useState(false);
  const [badNetworkDisplayed, setBadNetworkDisplayed] = useState(false);

  const [ownPreNetworkQ, setOwnPreNetworkQ] = useState(10);
  const [otherPreNetworkQ, setOtherPreNetworkQ] = useState(2);
  const [popupTimeOut, setPopupTimeOut] = useState<any>();

  useEffect(() => {
    try {
      if (mediaStream && videoRef.current && isVideoDecodeReady) {
        if (activeUser?.bVideoOn && previousActiveUser.current?.bVideoOn) {
          console.log(
            networkQ == 1
              ? (VideoQuality.Video_180P as any)
              : networkQ == 2
              ? (VideoQuality.Video_360P as any)
              : networkQ == 3
              ? (VideoQuality.Video_720P as any)
              : (VideoQuality.Video_90P as any)
          );
          // mediaStream.stopRenderVideo(videoRef.current, previousActiveUser.current?.userId);
          mediaStream
            .renderVideo(
              videoRef.current,
              activeUser.userId,
              canvasDimension.width,
              canvasDimension.height,
              0,
              0,
              networkQ == 1
                ? (VideoQuality.Video_180P as any)
                : networkQ == 2
                ? (VideoQuality.Video_360P as any)
                : networkQ == 3
                ? (VideoQuality.Video_720P as any)
                : (VideoQuality.Video_90P as any)
            )
            .then(() => {
              fetchData();
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [networkQ]);

  const closePopUp = () => {
    if (popupTimeOut) {
      clearTimeout(popupTimeOut);
    }
    setShowBadNetwork(false);
  };

  useEffect(() => {
    // current user
    try {
      if (zoomClient && zoomClient?.getCurrentUserInfo() && zoomClient?.getCurrentUserInfo()?.userId && networkQuality) {
        const ownNetworkQuality = networkQuality[`${zoomClient?.getCurrentUserInfo()?.userId}`];

        if (ownNetworkQuality && ownNetworkQuality.uplink) {
          const value = ownNetworkQuality.uplink;
          if (zoomClient?.getCurrentUserInfo()?.bVideoOn) {
            if (
              (!badNetworkDisplayed && (value == 0 || value == 1)) ||
              (badNetworkDisplayed && ownPreNetworkQ != 0 && ownPreNetworkQ != 1 && ownPreNetworkQ != 2 && (value == 0 || value == 1))
            ) {
              console.log(`own Bad network quality ${value}`);

              if (!showBadNetwork) {
                const timeOutTime = 15 * 1 * 1000;
                if (popupTimeOut) {
                  clearTimeout(popupTimeOut);
                }
                const timeOut = setTimeout(() => {
                  closePopUp();
                }, timeOutTime);

                setPopupTimeOut(timeOut);

                setShowBadNetwork(true);
                setBadNetworkDisplayed(true);
              }
            } else if (value == 2) {
              closePopUp();
              console.log(`own Normal network quality ${value}`);
            } else if (value == 3 || value == 4) {
              closePopUp();
              console.log(`own Normal network quality ${value}`);
            } else if (value == 5) {
              closePopUp();
              console.log(`own Good network quality ${value}`);
            } else {
              console.log("own Network value not found");
            }
          }
          if (value) {
            setOwnPreNetworkQ(value);
          }
        }
      }
      // joined user
      if (zoomClient && activeUser && activeUser.userId && networkQuality) {
        const otherNetworkQuality = networkQuality[`${activeUser.userId}`];

        if (otherNetworkQuality && otherNetworkQuality.uplink) {
          const value = otherNetworkQuality.uplink;
          // if (value && Math.abs(value - otherPreNetworkQ) > 1) {
          if (value == 0 || value == 1) {
            console.log(`other Bad network quality ${value}`);
            setNetworkQ(0);
          } else if (value == 2) {
            setNetworkQ(1);
            console.log(`other Normal network quality ${value}`);
          } else if (value == 3 || value == 4) {
            setNetworkQ(2);
            console.log(`other Normal network quality ${value}`);
          } else if (value == 5) {
            setNetworkQ(3);
            console.log(`other Good network quality ${value}`);
          } else {
            console.log("other Network value not found");
          }
          if (value) {
            setOtherPreNetworkQ(value);
          }
          // }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [networkQuality]);

  const getTranscribeList = async (data: any) => { 
    try {
      
      if (data.length > 0){
        
        const meetingIdForTranscribe = localUserOnCall.callId;
        const conversationForTranscribe: any = [];
  
        if (user?.role == "CLIENT") {
          data.forEach((element: any) => {
            if (element.displayName.includes(user?.firstname)){
              const val = `CLIENT: ${element.text}`
              conversationForTranscribe.push(val);
            } else {
              const val = `THERAPIST: ${element.text}`
              conversationForTranscribe.push(val  );
            }
          });
        }
  
        if (user?.role == "THERAPIST") {
          
          data.forEach((element: any) => {
            if (element.displayName.includes(user?.firstname)){
              const val = `THERAPIST: ${element.text}`
              conversationForTranscribe.push(val);
            } else {
              const val = `CLIENT: ${element.text}`
              conversationForTranscribe.push(val  );
            }
          });
        }
        
        const transcribeData = await VideoChatService.createTranscribeNew(meetingIdForTranscribe, conversationForTranscribe);
  
        if(transcribeData.success){
          toast.success("Transcribe added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            className: "foo-bar",
          });
        } else {
          toast.error("Transcribe added failed!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
      
      
    } catch (error) {
      console.log(error);
      
    }
    
  }

  return (
    <React.Fragment>
      {showBadNetwork && <BadNetwork setShowModal={closePopUp} showModal={showBadNetwork} />}
      <div className="viewport" style={{ background: "linear-gradient(45deg, #ffee97, #ff8f05)" }}>
        <div
          className={classnames("share-container", {
            "in-sharing": isSharing,
          })}
          ref={shareContainerRef}
        >
          <div
            className="share-container-viewport"
            style={{
              width: `${shareViewDimension.width}px`,
              height: `${shareViewDimension.height}px`,
            }}
          >
            <canvas className={classnames("share-canvas", { hidden: isStartedShare })} ref={shareRef} />
            {mediaStream?.isStartShareScreenWithVideoElement() ? (
              <video className={classnames("share-canvas", { hidden: isRecieveSharing })} ref={selfShareRef} />
            ) : (
              <canvas className={classnames("share-canvas", { hidden: isRecieveSharing })} ref={selfShareRef} />
            )}
          </div>
        </div>

        {!localUserOnCall.isAudioCall && (
          <div
            className={classnames("video-container", {
              "in-sharing": isSharing,
            })}
          >
            {userType == "CLIENT" ? (
              <>
                {isTherapistTurnOnCam === false ? (
                  <div className="user-msg01">
                    <div className="camera_off_msg_main">
                      <img className="user_icon_img" src={userIcon} />
                      <h2 className="camera_off_msg">
                        
                        {otherPartyName}
                        {"'"}s Camera is turned off
                      </h2>
                    </div>
                    <img className="camera_off_img" src={cameraOffIcon} alt="My Image" />
                  </div>
                ) : (
                  <div className="camera_off_msg_main">
                    <img className="user_icon_img_top" src={userIcon} />
                    <h2 className="camera_off_msg"> {otherPartyName}</h2>
                  </div>
                )}
                <canvas className="video-canvas" id="video-canvas" ref={videoRef} />
                {mediaStream?.isRenderSelfViewWithVideoElement() ? (
                  <video
                    id={SELF_VIDEO_ID}
                    playsInline
                    style={{ display: user?.incognito ? "none" : "block" }}
                    className={classnames("self-video", {
                      "single-self-video": participants.length === 1,
                      "self-video-show": isCurrentUserStartedVideo,
                    })}
                  />
                ) : (
                  <canvas
                    id={SELF_VIDEO_ID}
                    style={{ display: user?.incognito ? "none" : "block" }}
                    width="254"
                    height="143"
                    className={classnames("self-video", {
                      "single-self-video": participants.length === 1,
                      "self-video-show": isCurrentUserStartedVideo,
                    })}
                  />
                )}
                {isPredictFunctionRun && <video id="camera-view-avatar" autoPlay ref={cameraElementRef} style={{ opacity: "0", height: "1px" }} playsInline />}
                {isClientCameraBtnClicked && user?.incognito && (
                  <>
                    <Canvas
                      camera={{ fov: 15 }}
                      shadows
                      style={{ width: "20%", height: "25%", position: "fixed", top: "1%", right: "1%", paddingBottom: "10px" }}
                    >
                      <ambientLight intensity={0.5} />
                      <pointLight position={[10, 10, 10]} color={new Color(1, 1, 0)} intensity={0.5} castShadow />
                      <pointLight position={[-10, 0, 10]} color={new Color(1, 0, 0)} intensity={0.5} castShadow />
                      <pointLight position={[0, 0, 10]} intensity={0.5} castShadow />
                      <Avatar2 url={avatarUrl} />
                    </Canvas>
                  </>
                )}
              </>
            ) : (
              <>
                {localUserOnCall.recieversIncogniteModeOn ? (
                  <div>
                    <video
                      id={SELF_VIDEO_ID}
                      playsInline
                      className={classnames("self-video", {
                        "single-self-video": participants.length === 1,
                        "self-video-show": isCurrentUserStartedVideo,
                      })}
                    />
                    {isPredictFunctionRun && (
                      <video id="camera-view-avatar" autoPlay ref={cameraElementRef} style={{ opacity: "0", height: "1px" }} playsInline />
                    )}
                    {isSecondPersonJoined ? (
                      isPredictFunctionRun ? (
                        <div style={{ height: "100vh", width: "100%", position: "relative" }}>
                          <div className="camera_off_msg_main">
                            <img className="user_icon_img_top" src={userIcon} />
                            <h2 className="camera_off_msg"> {otherPartyName}</h2>
                          </div>
                          <Canvas
                            camera={{ fov: 15 }}
                            shadows
                            style={{ height: "70%", background: "linear-gradient(45deg, #ffee97, #ff8f05)", paddingBottom: "10px" }}
                          >
                            <ambientLight intensity={0.5} />
                            <pointLight position={[10, 10, 10]} color={new Color(1, 1, 0)} intensity={0.5} castShadow />
                            <pointLight position={[-10, 0, 10]} color={new Color(1, 0, 0)} intensity={0.5} castShadow />
                            <pointLight position={[0, 0, 10]} intensity={0.5} castShadow />
                            <Avatar2 url={avatarUrl} />
                          </Canvas>
                        </div>
                      ) : (
                        <div className="user-msg01">
                          <div className="camera_off_msg_main">
                            <img className="user_icon_img" src={userIcon} />
                            <h2 className="camera_off_msg">
                              
                              {otherPartyName}
                              {"'"}s Camera is turned off
                            </h2>
                          </div>
                          <img className="camera_off_img" src={cameraOffIcon} alt="My Image" />
                        </div>
                      )
                    ) : (
                      <h1 style={{ padding: "20px" }}>Waiting for other party to join...</h1>
                    )}

                    <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} style={{ opacity: "0" }} />
                  </div>
                ) : (
                  <div>
                    {isSecondPersonJoined ? (
                      <div style={{ height: "100vh", width: "100%", position: "relative" }}>
                        {isPredictFunctionRun ? (
                          <div className="camera_off_msg_main">
                            <img className="user_icon_img_top" src={userIcon} />
                            <h2 className="camera_off_msg"> {otherPartyName}</h2>
                          </div>
                        ) : (
                          <div className="user-msg01" style={{ zIndex: 999, display: "flex" }}>
                            <div className="camera_off_msg_main">
                              <img className="user_icon_img" src={userIcon} />
                              <h2 className="camera_off_msg">
                                
                                {otherPartyName}
                                {"'"}s Camera is turned off
                              </h2>
                            </div>
                            <img className="camera_off_img" src={cameraOffIcon} alt="My Image" />
                          </div>
                        )}
                        <canvas className="video-canvas" id="video-canvas" ref={videoRef} style={{ height: "83%" }} />
                        {mediaStream?.isRenderSelfViewWithVideoElement() ? (
                          <video
                            id={SELF_VIDEO_ID}
                            playsInline
                            className={classnames("self-video", {
                              "single-self-video": participants.length === 1,
                              "self-video-show": isCurrentUserStartedVideo,
                            })}
                          />
                        ) : (
                          <canvas
                            id={SELF_VIDEO_ID}
                            width="254"
                            height="143"
                            className={classnames("self-video", {
                              "single-self-video": participants.length === 1,
                              "self-video-show": isCurrentUserStartedVideo,
                            })}
                          />
                        )}
                      </div>
                    ) : (
                      <h1 style={{ padding: "20px", zIndex: 999, display: "flex" }}>Waiting for other party to join...</h1>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {localUserOnCall.isAudioCall && (
          <div>
            {!isSecondPersonJoined ? (
              <h1 style={{ padding: "20px" }}>Waiting for other party to join...</h1>
            ): (
              <div className="user-msg01" style={{ zIndex: 999, display: "flex" }}>
                <div className="camera_off_msg_main">
                  <img className="user_icon_img" src={userIcon} />
                  <h2 className="camera_off_msg">
                    {otherPartyName}
                  </h2>
                </div>
              </div>
            )}

          </div>
        )}
        <VideoFooter className="video-operations" sharing shareRef={selfShareRef} leaveMeeting={props.leaveMeeting} setTranscribeList ={getTranscribeList}/>
      </div>
    </React.Fragment>
  );
};

export default VideoContainer3;
