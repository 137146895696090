import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { toast } from "react-toastify";
import Spinner from "src/common/spinner/spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import Picker from "emoji-picker-react";
import { isEmpty } from "lodash";
import { SocketContext } from "src/context/ScoketContext";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ClientChatMessageModel } from "src/models/AdminClientChat/ClientChatMessage";
import { AdminClientChatService } from "src/services/AdminClientChatService";
import { encryptAdminClientMessage } from "src/pages/Chat/GroupChat/Common/AdminClientChatEncryption";
import ClientChatReplyMessageDisplayComp from "./ClientChatReplyMessageDisplayComp";
import DisplayClientChatMessageComp from "./DisplayClientChatMessageComp";

const ClientChatView: React.FC<{ clientId: string }> = ({ clientId }) => {
  const limit = 30;
  const [offset, setOffset] = useState(0);
  // const [startDateToSend, setStartDateToSend] = useState<Date>(moment(new Date()).subtract(dateLimit, "days").startOf("day").toDate());
  // const [endDateToSend, setEndDateToSend] = useState<Date>(moment(new Date()).endOf("day").toDate());

  const socket = useContext(SocketContext);
  const [showEmojiPanel, setShowEmojiPanel] = useState(false);
  const [messageBox, setMessageBox] = useState<HTMLInputElement | null>(null);
  const [messageList, setMessageList] = useState<ClientChatMessageModel[]>([]);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isLoadingMessagesInitial, setIsLoadingMessagesInitial] = useState(true);
  const [isLoadingMessagesLazy, setIsLoadinMessagesLazy] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [newSocketRecieveMsg, setNewSocketRecieveMsg] = useState<any>();
  const editorRef = useRef<any>(null);

  const [showReplyPanel, setShowReplyPanel] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<ClientChatMessageModel | undefined>(undefined);

  const editorConfiguration = {
    toolbar: ["bold", "italic", "link", "|", "bulletedList", "numberedList", "|", "undo", "redo"],
    placeholder: "Type a message",
  };

  useEffect(() => {
    setIsLoadingMessagesInitial(true);

    if (editorRef.current) {
      editorRef.current.setData("");
    }

    setShowEmojiPanel(false);
    setShowReplyPanel(false);
    setSelectedMessage(undefined);
    setMessageList([]);
    setIsSendingMessage(false);
    setIsLoadinMessagesLazy(false);
    setHasMoreData(true);

    // setStartDateToSend(moment(new Date()).subtract(dateLimit, "days").startOf("day").toDate());
    // setEndDateToSend(moment(new Date()).endOf("day").toDate());

    if (clientId && clientId != "") {
      getGroupMessages(true, clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (!isEmpty(messageList)) {
      scrollToBottom();
    }
  }, [messageList]);

  useEffect(() => {
    if (
      clientId &&
      clientId != "" &&
      newSocketRecieveMsg &&
      newSocketRecieveMsg.clientId &&
      newSocketRecieveMsg.clientId == clientId &&
      newSocketRecieveMsg._id &&
      newSocketRecieveMsg.firstname != null &&
      newSocketRecieveMsg.lastname != null &&
      newSocketRecieveMsg.messageText != null &&
      newSocketRecieveMsg.createdBy &&
      newSocketRecieveMsg.createdAt
    ) {
      const newMessage: ClientChatMessageModel = {
        _id: newSocketRecieveMsg._id,
        firstname: newSocketRecieveMsg.firstname,
        lastname: newSocketRecieveMsg.lastname,
        messageText: newSocketRecieveMsg.messageText,
        createdBy: newSocketRecieveMsg.createdBy,
        createdAt: newSocketRecieveMsg.createdAt,
        messageStatus: newSocketRecieveMsg.messageStatus,
      };

      const isExists = messageList.some(message => message._id == newMessage._id);
      if (!isExists) {
        setMessageList([...messageList, newMessage]);
      }
    }
  }, [newSocketRecieveMsg]);

  useEffect(() => {
    try {
      if (clientId != null && clientId != "") {
        socket.on(`new-admin-client-chat-message-${clientId}`, message => {
          setNewSocketRecieveMsg(message);
        });
      }

      return () => {
        socket.off(`new-admin-client-chat-message-${clientId}`);
      };
    } catch (error) {}
  }, [socket, clientId]);

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollTop + 100;
    }
  };

  const openEmojiPanel = () => {
    try {
      if (!isSendingMessage) {
        editorRef.current.focus();
        setShowEmojiPanel(!showEmojiPanel);
      } else {
        toast.error("Please wait, message sending in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      toast.error("invalid input", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const onEmojiClick = (event: any, { emoji }: any) => {
    try {
      const editor = editorRef.current;
      const model = editor.model;

      const selection = model.document.selection;

      if (selection.isCollapsed) {
        const position = selection.getFirstPosition();

        model.change((writer: any) => {
          writer.insertText(emoji, position);
        });
      } else {
        const range = selection.getFirstRange();

        model.change((writer: any) => {
          writer.insertText(emoji, range.end);
        });
      }

      editor.focus();
    } catch (error) {
      toast.error("invalid input", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const onIconClick = () => {
    const input = document.getElementById("image-input");
    if (input) {
      input.click();
    }
  };

  const isSupported = (array: string[], extension: string) => {
    let isFound = false;
    array.map((item: string) => {
      if (item === extension) {
        isFound = true;
      }
      return item;
    });
    return isFound;
  };

  const openReplyPanel = (messageDetails: ClientChatMessageModel) => {
    if (!isSendingMessage) {
      setShowReplyPanel(true);
      setSelectedMessage(messageDetails);
    } else {
      toast.error("Please wait, message sending in progress!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const getGroupMessages = async (isInitial: boolean, clientId: string) => {
    try {
      const clientIdFrom = clientId;
      if (!isInitial && (isLoadingMessagesLazy || isLoadingMessagesInitial)) {
        return;
      }
      if (!isInitial && messageList.length <= 0) {
        return;
      }

      if (isInitial) {
        setIsLoadingMessagesInitial(true);
      } else {
        setIsLoadinMessagesLazy(true);
      }

      // let startDateForSendFinal;
      // let endDateForSendFinal;
      // if (isInitial) {
      //   startDateForSendFinal = moment(new Date()).subtract(dateLimit, "days").startOf("day").toDate();
      //   endDateForSendFinal = moment(new Date()).endOf("day").toDate();
      // } else {
      //   startDateForSendFinal = moment(startDateToSend).subtract(dateLimit, "days").startOf("day").toDate();
      //   endDateForSendFinal = moment(startDateToSend).startOf("day").toDate();
      // }

      let finalLimit;
      let finalOffset;
      const initialOffset = offset;
      if (isInitial) {
        finalLimit = limit;
        finalOffset = 0;
      } else {
        finalLimit = limit;
        finalOffset = initialOffset * limit;
      }
      const dataObject = {
        clientId: clientIdFrom,
        // startDate: startDateForSendFinal,
        // endDate: endDateForSendFinal,
        // isInitial: isInitial,
        limit: finalLimit,
        offset: finalOffset,
      };
      const res = await AdminClientChatService.getTwilioMessageHistoryWithClient(dataObject);
      if (clientIdFrom == clientId) {
        if (res.success && res.data) {
          setOffset(initialOffset + 1);
          if (isInitial) {
            setIsLoadingMessagesInitial(false);
          } else {
            setIsLoadinMessagesLazy(false);
          }

          const newMessages: ClientChatMessageModel[] = res.data;
          const prevMessages: ClientChatMessageModel[] = messageList;
          if (newMessages.length < limit) {
            setHasMoreData(false);
          }
          if (isInitial) {
            setMessageList([...newMessages.reverse()]);
          } else {
            setMessageList([...newMessages.reverse(), ...prevMessages]);
          }
        } else {
          if (!isInitial) {
            setIsLoadinMessagesLazy(false);
          }
          toast.error("Error retrieving messages!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      }
    } catch (error) {
      if (!isInitial) {
        setIsLoadinMessagesLazy(false);
      }
      toast.error("Error retrieving messages!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const sendClientMessage = async (clientId: string) => {
    try {
      setShowEmojiPanel(false);
      const clientIdFrom = clientId;

      const messageText = editorRef.current.getData().toString();

      if (!isSendingMessage) {
        if (messageText && messageText != "" && messageText.trim() != "") {
          setIsSendingMessage(true);

          const clientMessageData = {
            clientId: clientIdFrom,
            messageText: encryptAdminClientMessage(messageText ?? ""),
            preMessageText:
              selectedMessage != null && selectedMessage._id != null && selectedMessage._id != "" && selectedMessage.messageText
                ? selectedMessage.messageText
                : "",
          };

          const res = await AdminClientChatService.sendTwilioMessage(clientMessageData);
          if (clientIdFrom == clientId) {
            if (res && res.success) {
              setShowReplyPanel(false);
              setSelectedMessage(undefined);
              setIsSendingMessage(false);

              if (editorRef.current) {
                editorRef.current.setData("");
                editorRef.current.focus();
              }

              const newMessage: ClientChatMessageModel = res.data;
              setMessageList([...messageList, newMessage]);
            } else {
              setIsSendingMessage(false);
              toast.error("Can't send message, please try again!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
              });
            }
            setIsSendingMessage(false);
          }
        } else {
          toast.error("You can't send empty message!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "foo-bar",
          });
        }
      } else {
        toast.error("Please wait, message sending in progress!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "foo-bar",
        });
      }
    } catch (error) {
      setIsSendingMessage(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  };

  const messageDisplayComponent = () => (
    <div>
      {!isLoadingMessagesInitial && (
        <div>
          <div className="chat-conversation p-3">
            <ul className="list-unstyled">
              <div
                id="scrollableDiv"
                style={{
                  height: "70vh",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                ref={(ref: any) => setMessageBox(ref)}
              >
                {messageList && messageList.length > 0 ? (
                  <InfiniteScroll
                    hasMore={hasMoreData}
                    next={() => {
                      getGroupMessages(false, clientId ?? "");
                    }}
                    inverse={true}
                    loader={null}
                    dataLength={messageList.length}
                    scrollableTarget="scrollableDiv"
                  >
                    {messageList &&
                      messageList.length > 0 &&
                      messageList.map((groupMessage, index: number) => (
                        <DisplayClientChatMessageComp key={index} clientMessageFrom={groupMessage} openReplyPanel={openReplyPanel} />
                      ))}
                  </InfiniteScroll>
                ) : (
                  <div className="admin-client-chat-empty-view">No messages</div>
                )}
              </div>
            </ul>
          </div>

          {showEmojiPanel && (
            <div className="emoji-panel1 position-absolute" style={{ zIndex: 9999 }}>
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}

          {showReplyPanel && (
            <>
              <div className="reply-container">
                <ClientChatReplyMessageDisplayComp
                  selectedMessage={selectedMessage}
                  closeModal={() => {
                    if (!isSendingMessage) {
                      setShowReplyPanel(false);
                      setSelectedMessage(undefined);
                    } else {
                      toast.error("Please wait, message sending in progress!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "foo-bar",
                      });
                    }
                  }}
                />
              </div>
            </>
          )}

          <div className="p-3 chat-input-section">
            <Row>
              <Col className="col-auto">
                <li className="list-inline-item" style={{ color: "#fd7f00" }}>
                  <div
                    className="mdi mdi-emoticon-happy-outline pointer1"
                    id="Imagetooltip2"
                    onClick={() => {
                      openEmojiPanel();
                    }}
                    style={{
                      fontSize: "25px",
                    }}
                  >
                    <UncontrolledTooltip placement="bottom" target="Imagetooltip2">
                      Pick an Emoji
                    </UncontrolledTooltip>
                  </div>
                </li>
              </Col>

              <Col className="col" style={{ padding: "0px" }}>
                <div className="">
                  <div className="chat-input-editor">
                    <CKEditor
                      editor={Editor}
                      onReady={(editor: any) => {
                        editorRef.current = editor;
                        editor.focus();
                      }}
                      data={""}
                      config={editorConfiguration}
                    />
                  </div>
                </div>
              </Col>

              <Col className="col-auto">
                <Button
                  type="button"
                  color={`${isSendingMessage ? "secondary" : "primary"}`}
                  onClick={() => sendClientMessage(clientId ?? "")}
                  className="btn btn-primary btn-rounded chat-send w-md "
                >
                  <span className="d-none d-sm-inline-block me-2">{isSendingMessage ? "Sending" : "Send"}</span>
                  <i className="mdi mdi-send" />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {isLoadingMessagesInitial && (
        <div>
          <div className="chat-conversation p-3">
            <ul className="list-unstyled">
              <div
                id="scrollableDivForLoading"
                style={{
                  height: "85vh",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <div className="admin-client-chat-empty-view">
                  <Spinner />
                </div>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      {clientId != null && clientId != "" && (
        <div>
          <Card className="mb-0">
            <div>{messageDisplayComponent()}</div>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default ClientChatView;
