import { Util } from "../Util";
import { environment } from "../environment/environment";

export const ChatGroupService = {
  getAllChatGroupCallsByTherapistId: async (therapistId: string, limit: number = 10, offset: number = 0) => {
    try {
      const response = await fetch(`${environment.api_url}/api/auth/chatGroup/getAllChatGroupCallsByTherapistId`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          therapistId,
          limit,
          offset,
        }),
      });

      return await response.json();
    } catch (error) {
      console.error("Error fetching group calls:", error);
      return { success: false, error: "Failed to fetch group calls" };
    }
  },
}; 