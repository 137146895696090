import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom";
import UserContext from "../../../context/UserContext";
import user1 from "../../../assets/images/default_profile.png";
import { Role } from "../../../models/Role";
import { RouteName } from "../../../RouteName";
import LoaderModal from "../../../pages/Popup/Modal3";
import { Util } from "../../../Util";

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);
  const [user] = useContext(UserContext);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const logout = () => {
    setShowModal(true);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/signin";
  };

  return (
    <React.Fragment>
      <LoaderModal showModal={showModal} text={"Logging out..."} />

      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn mt-2 border-0 pl-pr-profile-header" id="page-header-user-dropdown" tag="button">
          <img
            className="rounded-circle header-profile-user imageFit "
            src={user?.photoId == null || user?.photoId == undefined ? user1 : Util.fileURL(user?.photoId?._id)}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {user?.firstname} {user?.lastname}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {location.pathname != RouteName.ONBOARDING && location.pathname != RouteName.THERAPISTPERSONALINFORMATIONONBOARDINGPAGE && location.pathname != RouteName.THERAPISTPROFESSIONALEXPERIENCEONBOARDINGPAGE && location.pathname != RouteName.THERAPISTWORKINGDAYSONBOARDINGPAGE && location.pathname != RouteName.THERAPISTINTRODUCTORYVIDEOONBOARDINGPAGE && (
            <>
              <Link to="/profile">
                <DropdownItem>
                  <i className="bx bx-user font-size-16 align-middle me-1" />
                  {props.t("Profile")}
                </DropdownItem>
              </Link>

              {/* <DropdownItem tag="a" href="/requests">
                <i className="bx bx-wallet font-size-16 align-middle me-1" />
                {props.t("Requests")}
              </DropdownItem> */}

              <div className="dropdown-divider" />
            </>
          )}

          <DropdownItem onClick={() => logout()} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
